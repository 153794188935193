

import React from 'react';
import { Widget } from 'react-chat-widget';
import HeaderFloating from '../../Header/HeaderFloating';
import HeaderNew from '../../Header/HeaderNew';

//import Header from '../../Header/Header';
import Header from '../../Header/HeaderOld';
import MainSectionSignIn from './MainSectionSignIn';

import MainSectionSignUp from './MainSectionSignUp';


function SignInPage() {
    return (
        <div>
           

          
       <HeaderFloating/>

       <MainSectionSignIn/>

       <Widget/>
             
            


           

        </div>
    )
}

export default SignInPage
