import React from 'react'


import { useContext } from 'react';

import Sidebar from './Sidebar';
import Topbar from '../Ftopbar';
import Footer from '../Ffooter';

import { AuthContext } from '../../../helpers/AuthContext'

import { Helmet } from "react-helmet";
import TopBarCustomer from './TopBarCustomer';

function CustomerProfile() {
    const {authState} = useContext(AuthContext);


    const {setAuthState} = useContext(AuthContext);
    return (
    <div>
   
  
  
    <Helmet>
  
  
    
            
    <link rel="preconnect" href="https://fonts.googleapis.com/" />
    <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;display=swap" rel="stylesheet" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/boxicons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/fontawesome.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/flag-icons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/core.css" class="template-customizer-core-css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/theme-default.css" class="template-customizer-theme-css" />
    <link rel="stylesheet" href="assets_admin/css/demo.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/apex-charts/apex-charts.css" />
  
  
  
  
  
    <link rel="stylesheet" href="assets_admin/vendor/libs/select2/select2.css" />
  
  
    <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-bs5/datatables.bootstrap5.css"/>
  <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css"/>
  <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.css"/>
  
  
  
    
  
    
    <script src="assets_admin/vendor/js/helpers.js"></script>
    <script src="assets_admin/vendor/js/template-customizer.js"></script>
    <script src="assets_admin/js/config.js"></script>
        </Helmet>
  
        
        
        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                {/* Menu */}
                
                
                <Sidebar />
  
  
                {/* / Menu */}
                {/* Layout container */}
                <div className="layout-page">
                    {/* Navbar */}
                    
                  <TopBarCustomer/>
  
                    {/* / Navbar */}
                    {/* Content wrapper */}
                    
                    <div class="content-wrapper">
  
    
  
    
                    <div class="container-xxl flex-grow-1 container-p-y">
              
              
                    <h4 class="py-3 breadcrumb-wrapper mb-4">
                      <span class="text-muted fw-light">User Profile /</span> Profile
                    </h4>
                    
                    
                    
                    <div class="row">
                      <div class="col-12">
                        <div class="card mb-4">
                          <div class="user-profile-header-banner">
                            <img src="assets_admin/img/pages/profile-banner.png" alt="Banner image" class="rounded-top"/>
                          </div>
                          <div class="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                            <div class="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                              <img src="assets_admin/img/profile/kinyozi-man.jpg" alt="user image" class="d-block h-auto ms-0 ms-sm-4 rounded-3 user-profile-img"/>
                            </div>
                            <div class="flex-grow-1 mt-3 mt-sm-5">
                              <div class="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                                <div class="user-profile-info">
                                  <h4>{authState.first_name}</h4>
                                  <ul class="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                                    <li class="list-inline-item fw-semibold">
                                      <i class='bx bx-pen'></i> {authState.role}
                                    </li>
                                    <li class="list-inline-item fw-semibold">
                                      <i class='bx bx-map'></i> Eastern By-Pass Nrb
                                    </li>
                                    <li class="list-inline-item fw-semibold">
                                      <i class='bx bx-calendar-alt'></i> Joined April 2022
                                    </li>
                                  </ul>
                                </div>
                                <a href="javascript:void(0)" class="btn btn-primary text-nowrap">
                                  <i class='bx bx-user-check'></i> Connected
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                    <div class="row">
                      <div class="col-md-12">
                        <ul class="nav nav-pills flex-column flex-sm-row mb-4">
                          <li class="nav-item"><a class="nav-link active" href="javascript:void(0);"><i class='bx bx-user'></i> Profile</a></li>
                          <li class="nav-item"><a class="nav-link" href="pages-profile-teams.html"><i class='bx bx-group'></i> Teams</a></li>
                          <li class="nav-item"><a class="nav-link" href="pages-profile-projects.html"><i class='bx bx-grid-alt'></i> Projects</a></li>
                          <li class="nav-item"><a class="nav-link" href="pages-profile-connections.html"><i class='bx bx-link-alt'></i> Connections</a></li>
                        </ul>
                      </div>
                    </div>
                   
                    <div class="row">
                      <div class="col-xl-4 col-lg-5 col-md-5">
                      
                        <div class="card mb-4">
                          <div class="card-body">
                            <small class="text-muted text-uppercase">About</small>
                            <ul class="list-unstyled mb-4 mt-3">
                              <li class="d-flex align-items-center mb-3"><i class="bx bx-user"></i><span class="fw-semibold mx-2">Full Name:</span> <span>{authState.first_name}</span></li>
                              <li class="d-flex align-items-center mb-3"><i class="bx bx-check"></i><span class="fw-semibold mx-2">Status:</span> <span>Active</span></li>
                              <li class="d-flex align-items-center mb-3"><i class="bx bx-star"></i><span class="fw-semibold mx-2">Role:</span> <span>{authState.first_name}</span></li>
                              <li class="d-flex align-items-center mb-3"><i class="bx bx-flag"></i><span class="fw-semibold mx-2">Country:</span> <span>{authState.country}</span></li>
                              <li class="d-flex align-items-center mb-3"><i class="bx bx-detail"></i><span class="fw-semibold mx-2">Languages:</span> <span>English</span></li>
                            </ul>
                            <small class="text-muted text-uppercase">Contacts</small>
                            <ul class="list-unstyled mb-4 mt-3">
                              <li class="d-flex align-items-center mb-3"><i class="bx bx-phone"></i><span class="fw-semibold mx-2">Contact:</span> <span>{authState.phone_no}</span></li>
                              
                              <li class="d-flex align-items-center mb-3"><i class="bx bx-envelope"></i><span class="fw-semibold mx-2">Email:</span> <span>john.kama@example.com</span></li>
                            </ul>
                          
                          </div>
                        </div>
                       
                        <div class="card mb-4">
                          <div class="card-body">
                            <small class="text-muted text-uppercase">Overview</small>
                            <ul class="list-unstyled mt-3 mb-0">
                              <li class="d-flex align-items-center mb-3"><i class="bx bx-check"></i><span class="fw-semibold mx-2">Task Compiled:</span> <span>13.5k</span></li>
                              <li class="d-flex align-items-center mb-3"><i class='bx bx-customize'></i><span class="fw-semibold mx-2">Products Sold:</span> <span>146</span></li>
                              <li class="d-flex align-items-center"><i class="bx bx-user"></i><span class="fw-semibold mx-2">Connections:</span> <span>897</span></li>
                            </ul>
                          </div>
                        </div>
                      
                      </div>
                      <div class="col-xl-8 col-lg-7 col-md-7">
                       
                        <div class="card card-action mb-4">
                          <div class="card-header align-items-center">
                            <h5 class="card-action-title mb-0"><i class='bx bx-list-ul bx-sm me-2'></i>Activity Timeline</h5>
                            <div class="card-action-element btn-pinned">
                              <div class="dropdown">
                                <button type="button" class="btn dropdown-toggle hide-arrow p-0" data-bs-toggle="dropdown" aria-expanded="false"><i class="bx bx-dots-vertical-rounded"></i></button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li><a class="dropdown-item" href="javascript:void(0);">Share timeline</a></li>
                                  <li><a class="dropdown-item" href="javascript:void(0);">Suggest edits</a></li>
                                  <li>
                                    <hr class="dropdown-divider"/>
                                  </li>
                                  <li><a class="dropdown-item" href="javascript:void(0);">Report bug</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="card-body">
                            <ul class="timeline ms-2">
                              <li class="timeline-item timeline-item-transparent">
                                <span class="timeline-point timeline-point-warning"></span>
                                <div class="timeline-event">
                                  <div class="timeline-header mb-1">
                                    <h6 class="mb-0">Client Meeting</h6>
                                    <small class="text-muted">Today</small>
                                  </div>
                                  <p class="mb-2">Project meeting with john @10:15am</p>
                                  <div class="d-flex flex-wrap">
                                    <div class="avatar me-3">
                                      <img src="assets_admin/img/avatars/3.png" alt="Avatar" class="rounded-circle" />
                                    </div>
                                    <div>
                                      <h6 class="mb-0">Lester McCarthy (Client)</h6>
                                      <span>CEO of Infibeam</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li class="timeline-item timeline-item-transparent">
                                <span class="timeline-point timeline-point-info"></span>
                                <div class="timeline-event">
                                  <div class="timeline-header mb-1">
                                    <h6 class="mb-0">Create a new project for client</h6>
                                    <small class="text-muted">2 Day Ago</small>
                                  </div>
                                  <p class="mb-0">Add files to new design folder</p>
                                </div>
                              </li>
                              <li class="timeline-item timeline-item-transparent">
                                <span class="timeline-point timeline-point-primary"></span>
                                <div class="timeline-event">
                                  <div class="timeline-header mb-1">
                                    <h6 class="mb-0">Shared 2 New Project Files</h6>
                                    <small class="text-muted">6 Day Ago</small>
                                  </div>
                                  <p class="mb-2">Sent by Mollie Dixon <img src="assets_admin/img/avatars/4.png" class="rounded-circle ms-3" alt="avatar" height="20" width="20"/></p>
                                  <div class="d-flex flex-wrap gap-2">
                                    <a href="javascript:void(0)" class="me-3">
                                      <img src="assets_admin/img/icons/misc/pdf.png" alt="Document image" width="20" class="me-2"/>
                                      <span class="h6">App Guidelines</span>
                                    </a>
                                    <a href="javascript:void(0)">
                                      <img src="assets_admin/img/icons/misc/doc.png" alt="Excel image" width="20" class="me-2"/>
                                      <span class="h6">Testing Results</span>
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li class="timeline-item timeline-item-transparent">
                                <span class="timeline-point timeline-point-success"></span>
                                <div class="timeline-event pb-0">
                                  <div class="timeline-header mb-1">
                                    <h6 class="mb-0">Project status updated</h6>
                                    <small class="text-muted">10 Day Ago</small>
                                  </div>
                                  <p class="mb-0">Woocommerce iOS App Completed</p>
                                </div>
                              </li>
                              <li class="timeline-end-indicator">
                                <i class="bx bx-check-circle"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                    
                        <div class="row">
                        
                          <div class="col-lg-12 col-xl-6">
                            <div class="card card-action mb-4">
                              <div class="card-header align-items-center">
                                <h5 class="card-action-title mb-0">Connections</h5>
                                <div class="card-action-element btn-pinned">
                                  <div class="dropdown">
                                    <button type="button" class="btn dropdown-toggle hide-arrow p-0" data-bs-toggle="dropdown" aria-expanded="false"><i class="bx bx-dots-vertical-rounded"></i></button>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                      <li><a class="dropdown-item" href="javascript:void(0);">Share connections</a></li>
                                      <li><a class="dropdown-item" href="javascript:void(0);">Suggest edits</a></li>
                                      <li>
                                        <hr class="dropdown-divider"/>
                                      </li>
                                      <li><a class="dropdown-item" href="javascript:void(0);">Report bug</a></li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="card-body">
                                <ul class="list-unstyled mb-0">
                                  <li class="mb-3">
                                    <div class="d-flex align-items-start">
                                      <div class="d-flex align-items-start">
                                        <div class="avatar me-3">
                                          <img src="assets_admin/img/avatars/2.png" alt="Avatar" class="rounded-circle" />
                                        </div>
                                        <div class="me-2">
                                          <h6 class="mb-0">Cecilia Payne</h6>
                                          <small class="text-muted">45 Connections</small>
                                        </div>
                                      </div>
                                      <div class="ms-auto">
                                        <button class="btn btn-label-primary p-1 btn-sm"><i class="bx bx-user"></i></button>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="mb-3">
                                    <div class="d-flex align-items-start">
                                      <div class="d-flex align-items-start">
                                        <div class="avatar me-3">
                                          <img src="assets_admin/img/avatars/3.png" alt="Avatar" class="rounded-circle" />
                                        </div>
                                        <div class="me-2">
                                          <h6 class="mb-0">Curtis Fletcher</h6>
                                          <small class="text-muted">1.32k Connections</small>
                                        </div>
                                      </div>
                                      <div class="ms-auto">
                                        <button class="btn btn-primary p-1 btn-sm"><i class="bx bx-user"></i></button>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="mb-3">
                                    <div class="d-flex align-items-start">
                                      <div class="d-flex align-items-start">
                                        <div class="avatar me-3">
                                          <img src="assets_admin/img/avatars/10.png" alt="Avatar" class="rounded-circle" />
                                        </div>
                                        <div class="me-2">
                                          <h6 class="mb-0">Alice Stone</h6>
                                          <small class="text-muted">125 Connections</small>
                                        </div>
                                      </div>
                                      <div class="ms-auto">
                                        <button class="btn btn-primary p-1 btn-sm"><i class="bx bx-user"></i></button>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="mb-3">
                                    <div class="d-flex align-items-start">
                                      <div class="d-flex align-items-start">
                                        <div class="avatar me-3">
                                          <img src="assets_admin/img/avatars/7.png" alt="Avatar" class="rounded-circle" />
                                        </div>
                                        <div class="me-2">
                                          <h6 class="mb-0">Darrell Barnes</h6>
                                          <small class="text-muted">456 Connections</small>
                                        </div>
                                      </div>
                                      <div class="ms-auto">
                                        <button class="btn btn-label-primary p-1 btn-sm"><i class="bx bx-user"></i></button>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="mb-3">
                                    <div class="d-flex align-items-start">
                                      <div class="d-flex align-items-start">
                                        <div class="avatar me-3">
                                          <img src="assets_admin/img/avatars/12.png" alt="Avatar" class="rounded-circle" />
                                        </div>
                                        <div class="me-2">
                                          <h6 class="mb-0">Eugenia Moore</h6>
                                          <small class="text-muted">1.2k Connections</small>
                                        </div>
                                      </div>
                                      <div class="ms-auto">
                                        <button class="btn btn-label-primary p-1 btn-sm"><i class="bx bx-user"></i></button>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="text-center">
                                    <a href="javascript:;">View all connections</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                         
                          <div class="col-lg-12 col-xl-6">
                            <div class="card card-action mb-4">
                              <div class="card-header align-items-center">
                                <h5 class="card-action-title mb-0">Teams</h5>
                                <div class="card-action-element btn-pinned">
                                  <div class="dropdown">
                                    <button type="button" class="btn dropdown-toggle hide-arrow p-0" data-bs-toggle="dropdown" aria-expanded="false"><i class="bx bx-dots-vertical-rounded"></i></button>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                      <li><a class="dropdown-item" href="javascript:void(0);">Share teams</a></li>
                                      <li><a class="dropdown-item" href="javascript:void(0);">Suggest edits</a></li>
                                      <li>
                                        <hr class="dropdown-divider"/>
                                      </li>
                                      <li><a class="dropdown-item" href="javascript:void(0);">Report bug</a></li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="card-body">
                                <ul class="list-unstyled mb-0">
                                  <li class="mb-3">
                                    <div class="d-flex align-items-center">
                                      <div class="d-flex align-items-start">
                                        <div class="avatar me-3">
                                          <img src="assets_admin/img/icons/brands/react-label.png" alt="Avatar" class="rounded-circle" />
                                        </div>
                                        <div class="me-2">
                                          <h6 class="mb-0">React Developers</h6>
                                          <small class="text-muted">72 Members</small>
                                        </div>
                                      </div>
                                      <div class="ms-auto">
                                        <a href="javascript:;"><span class="badge bg-label-danger">Developer</span></a>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="mb-3">
                                    <div class="d-flex align-items-center">
                                      <div class="d-flex align-items-start">
                                        <div class="avatar me-3">
                                          <img src="assets_admin/img/icons/brands/support-label.png" alt="Avatar" class="rounded-circle" />
                                        </div>
                                        <div class="me-2">
                                          <h6 class="mb-0">Support Team</h6>
                                          <small class="text-muted">122 Members</small>
                                        </div>
                                      </div>
                                      <div class="ms-auto">
                                        <a href="javascript:;"><span class="badge bg-label-primary">Support</span></a>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="mb-3">
                                    <div class="d-flex align-items-center">
                                      <div class="d-flex align-items-start">
                                        <div class="avatar me-3">
                                          <img src="assets_admin/img/icons/brands/figma-label.png" alt="Avatar" class="rounded-circle" />
                                        </div>
                                        <div class="me-2">
                                          <h6 class="mb-0">UI Designers</h6>
                                          <small class="text-muted">7 Members</small>
                                        </div>
                                      </div>
                                      <div class="ms-auto">
                                        <a href="javascript:;"><span class="badge bg-label-info">Designer</span></a>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="mb-3">
                                    <div class="d-flex align-items-center">
                                      <div class="d-flex align-items-start">
                                        <div class="avatar me-3">
                                          <img src="assets_admin/img/icons/brands/vue-label.png" alt="Avatar" class="rounded-circle" />
                                        </div>
                                        <div class="me-2">
                                          <h6 class="mb-0">Vue.js Developers</h6>
                                          <small class="text-muted">289 Members</small>
                                        </div>
                                      </div>
                                      <div class="ms-auto">
                                        <a href="javascript:;"><span class="badge bg-label-danger">Developer</span></a>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="mb-3">
                                    <div class="d-flex align-items-center">
                                      <div class="d-flex align-items-start">
                                        <div class="avatar me-3">
                                          <img src="assets_admin/img/icons/brands/twitter-label.png" alt="Avatar" class="rounded-circle" />
                                        </div>
                                        <div class="me-w">
                                          <h6 class="mb-0">Digital Marketing</h6>
                                          <small class="text-muted">24 Members</small>
                                        </div>
                                      </div>
                                      <div class="ms-auto">
                                        <a href="javascript:;"><span class="badge bg-label-secondary">Marketing</span></a>
                                      </div>
                                    </div>
                                  </li>
                                  <li class="text-center">
                                    <a href="javascript:;">View all teams</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        
                        </div>
                       
                        <div class="card">
                          <div class="card-datatable table-responsive">
                            <table class="datatables-projects border-top table">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th>Name</th>
                                  <th>Leader</th>
                                  <th>Team</th>
                                  <th class="w-px-200">Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                     
                      </div>
                    </div>
                   
                    
                                
                              </div>
                    
                <footer class="content-footer footer bg-footer-theme">
                <div class="container-fluid d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                {/* <div class="mb-2 mb-md-0">
                  © <script>
                  document.write(new Date().getFullYear())
                  </script>
                  , made with ❤️ by <a href="https://pixinvent.com/" target="_blank" class="footer-link fw-semibold">PIXINVENT</a>
                </div> */}
                <div>
                  
                  <a href="https://themeforest.net/licenses/standard" class="footer-link me-4" target="_blank">License</a>
                  <a href="https://1.envato.market/pixinvent_portfolio" target="_blank" class="footer-link me-4">More Themes</a>
                  
                  <a href="https://pixinvent.com/demo/frest-clean-bootstrap-admin-dashboard-template/documentation-bs5/" target="_blank" class="footer-link me-4">Documentation</a>
                  
                  <a href="https://pixinvent.ticksy.com/" target="_blank" class="footer-link d-none d-sm-inline-block">Support</a>
                  
                </div>
                </div>
                </footer>
                
                
                      
                      <div class="content-backdrop fade"></div>
                    </div>
  
                    
                    {/* Content wrapper */}
                </div>
                {/* / Layout page */}
            </div>
            {/* Overlay */}
            <div className="layout-overlay layout-menu-toggle" />
            {/* Drag Target Area To SlideIn Menu On Small Screens */}
            <div className="drag-target" />
        </div>
        <Helmet>
        <script src="assets_admin/vendor/libs/jquery/jquery.js"></script>
        <script src="assets_admin/vendor/libs/popper/popper.js"></script>
        <script src="assets_admin/vendor/js/bootstrap.js"></script>
        <script src="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
        <script src="assets_admin/vendor/libs/hammer/hammer.js"></script>
        <script src="assets_admin/vendor/libs/i18n/i18n.js"></script>
        <script src="assets_admin/vendor/libs/typeahead-js/typeahead.js"></script>
        <script src="assets_admin/vendor/js/menu.js"></script>
  
  
      
  
  
  
        <script src="assets_admin/vendor/libs/select2/select2.js"></script>
  <script src="assets_admin/assets/vendor/libs/flatpickr/flatpickr.js"></script>
  <script src="assets_admin/assets/vendor/libs/moment/moment.js"></script>
  
  
  
  <script src="assets_admin/vendor/libs/datatables/jquery.dataTables.js"></script>
  <script src="assets_admin/vendor/libs/datatables-bs5/datatables-bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-responsive/datatables.responsive.js"></script>
  <script src="assets_admin/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/datatables-buttons.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/jszip/jszip.js"></script>
  <script src="assets_admin/vendor/libs/pdfmake/pdfmake.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/buttons.html5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/buttons.print.js"></script>
  
  
    
        <script src="assets_admin/js/main.js"></script>
     
  
  
        <script src="assets_admin/js/app-calendar-events.js"></script>
        <script src="assets_admin/js/app-calendar.js"></script>
        </Helmet>
    </div>
    )
  }

export default CustomerProfile