import React from 'react'

export default function Cfooter() {
    return (
        <div>
        <div className="flex-grow-1" />
        <div className="app-footer">
          <div className="row">
            <div className="col-md-9">
              <p><strong>Pamojour</strong></p>
              <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero quis beatae officia saepe perferendis voluptatum minima eveniet voluptates dolorum, temporibus nisi maxime nesciunt totam repudiandae commodi sequi dolor quibusdam
                <sunt />
              </p>
            </div>
          </div>
          <div className="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
            {/* <a class="btn btn-primary text-white btn-rounded" href="https://themeforest.net/item/gull-bootstrap-laravel-admin-dashboard-template/23101970" target="_blank">Buy Gull HTML</a> */}
            <span className="flex-grow-1" />
            <div className="d-flex align-items-center">
              <img className="logo" src="<?php echo base_url(); ?>assets/dist-assets/images/logo.png" alt="" />
              <div>
                <p className="m-0">© 2021 Pamojour</p>
                <p className="m-0">All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
        </div>
    )
}
