
import React, { useEffect,useState,useContext} from 'react';
import { useNavigate  } from "react-router-dom";
import Topbar from '../Ftopbar';
import Footer from '../Ffooter';
import FeaturedInfo from '../Fcontent';

import axios from 'axios';


import {useParams} from "react-router-dom"


import { Helmet } from "react-helmet";
import SidebarFarmer from './Sidebar';
import DashboardContent from './DashboardContent';
import TopBarFarmer from './TopBarFarmer';


import {toast,ToastContainer,Zoom,Bounce} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import API from '../../../services';

function OrderDetails() {

    let {id}=useParams("");


    const [name, setName] = useState("");

    const [productName, setProductName] = useState("");


    const [requestId, setRequestId] = useState('');
    const [type, setType] = useState("");
    const [product_description, setProduct_description] = useState("");
    const [price, setPrice] = useState("");

    const [customer_phone_no, setCustomer_phone_no] = useState("");

    

    const [quantity, setQuantity] = useState(40);

    const [quantity_ordered, setQuantity_ordered] = useState("");

    const [geo_location, setGeo_location] = useState("");

    const [userId, setUserId] = useState("");

    const [productId, setProductId] = useState('');

    const [unit_of_measure, setunit_of_measure] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [isLoading,setLoading]=useState(false);



    useEffect(()=>{

     
   API.get('/order/order/view/'+id).then((response) => {
    //axios.get('http://localhost:3001/request/getById/'+id).then((response) => {

        setName(response.data.product_name)

        setCustomer_phone_no(response.data.customer_phone_no)

        setProductId(response.data.ProductId)

        setRequestId(response.data.id)

        setQuantity_ordered(response.data.quantity_ordered)

        setProductName(response.data.Product.name)

       // setProduct_description(response.data.Product.product_description)


           
       // console.log("THE PRODUCT ID IS "+productId)
         
            

            //setSeller_name(response.data.Users.first_name)
            
        })


        axios.get('https://kilimomazaoapi-dmi-cyber.herokuapp.com/product/getproductbyId/'+productId).then((response) => {
          //axios.get('http://localhost:3001/product/getproductbyId/'+productId).then((response) => {
 
                setProduct_description(response.data.product_description)

                setQuantity(response.data.quantity)


                console.log("THE PRODUCT ID IS "+productId)
 
    
             //setSeller_name(response.data.Users.first_name)
             
         }).catch(() => {
            setErrorMessage("Unable to fetch your products list");
           // setIsDivLoading(false);
         });
  
  
  },[]);



  const approveRequest = (id) => {

    let status='Approved';

    let new_quantity=quantity-quantity_ordered;


    setLoading(true);

    API
      .put(
        "/order/confirmrequest",
        //"http://localhost:3001/request/confirmrequest",
        { status:status,quantity_ordered:quantity_ordered,id: id }
      
      )
      .then((response) => {

        
        setTimeout(() => {
          setLoading(false);
         // setIsDivLoading(false)   // Hide loading screen 
          toast.info('Request Confirmed');
      }, 1000);


      }
      );



      axios
      .put(
       "https://kilimomazaoapi-dmi-cyber.herokuapp.com/product/updateproduct",
        //"http://localhost:3001/product/updateproduct",
        { quantity:new_quantity,id: productId }
      
      )
      .then((response) => {

        setTimeout(() => {
          setLoading(false);
         // setIsDivLoading(false)   // Hide loading screen 
          toast.info('Quantity Updated');
      }, 1000);


      }
      );
    
    

  }






  return (
    <div>
    <Helmet>
    
<link rel="preconnect" href="https://fonts.googleapis.com/" />
<link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;display=swap" rel="stylesheet" />
<link rel="stylesheet" href="assets/vendor/fonts/boxicons.css" />
<link rel="stylesheet" href="assets/vendor/fonts/fontawesome.css" />
<link rel="stylesheet" href="assets/vendor/fonts/flag-icons.css" />
<link rel="stylesheet" href="assets/vendor/css/rtl/core.css" class="template-customizer-core-css" />
<link rel="stylesheet" href="assets/vendor/css/rtl/theme-default.css" class="template-customizer-theme-css" />
<link rel="stylesheet" href="assets/css/demo.css" />
<link rel="stylesheet" href="assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
<link rel="stylesheet" href="assets/vendor/libs/typeahead-js/typeahead.css" />
<link rel="stylesheet" href="assets/vendor/libs/apex-charts/apex-charts.css" />


<script src="assets/vendor/js/helpers.js"></script>
<script src="assets/vendor/js/template-customizer.js"></script>
<script src="assets/js/config.js"></script>
    </Helmet>
    <div className="layout-wrapper layout-content-navbar  ">
        <div className="layout-container">
            {/* Menu */}
            
            
           <SidebarFarmer/>


            {/* / Menu */}
            {/* Layout container */}
            <div className="layout-page">
                {/* Navbar */}
                
               <TopBarFarmer/>




               <div className="content-wrapper">
            {/* Content */}
            <div class="container-xxl flex-grow-1 container-p-y">


            <div class="row">



</div>
    
    
<div class="row">










<div class="col-md-6 col-lg-6 col-xl-4 mb-4 mb-xl-0">
<div class="card h-100">
<div class="card-header">
<h5 class="card-title mb-2">Commonly Searched</h5>
<h1 class="display-6 fw-normal mb-0"></h1>
</div>
<div class="card-body">
<span class="d-block mb-2">Current Activity</span>
<div class="progress progress-stacked mb-3 mb-xl-5" style={{height:'8px'}}>
  <div class="progress-bar bg-success" role="progressbar" style={{width: '30%'}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
  <div class="progress-bar bg-danger" role="progressbar" style={{width: '15%'}}  aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
  <div class="progress-bar bg-info" role="progressbar" style={{width: '10%'}}  aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
  <div class="progress-bar bg-primary" role="progressbar"  style={{width: '40%'}}  aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
  <div class="progress-bar bg-warning" role="progressbar" style={{width: '15%'}}  aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
</div>
<ul class="p-0 m-0">
  <li class="mb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center lh-1 me-3">
      <span class="badge badge-dot bg-success me-2"></span> Onion
    </div>
    <div class="d-flex gap-3">
      <span>29.5k</span>
      <span class="fw-semibold">56%</span>
    </div>
  </li>
  <li class="mb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center lh-1 me-3">
      <span class="badge badge-dot bg-danger me-2"></span> Water Mellon
    </div>
    <div class="d-flex gap-3">
      <span>25.7k</span>
      <span class="fw-semibold">26%</span>
    </div>
  </li>
  <li class="mb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center lh-1 me-3">
      <span class="badge badge-dot bg-info me-2"></span> Chicken
    </div>
    <div class="d-flex gap-3">
      <span>11.5k</span>
      <span class="fw-semibold">34%</span>
    </div>
  </li>
  <li class="mb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center lh-1 me-3">
      <span class="badge badge-dot bg-primary me-2"></span> Goat
    </div>
    <div class="d-flex gap-3">
      <span>48.5k</span>
      <span class="fw-semibold">45%</span>
    </div>
  </li>
 
</ul>
</div>
</div>
</div>

<div class="col-xl-8">
<div class="card">
<div class="card-header d-flex justify-content-between align-items-center">
<h5 class="card-title mb-0">Latest Bookings</h5>
<div class="dropdown">
  <button class="btn p-0" type="button" id="marketingOptions" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <i class="bx bx-dots-vertical-rounded"></i>
  </button>
  <div class="dropdown-menu dropdown-menu-end" aria-labelledby="marketingOptions">
    <a class="dropdown-item" href="javascript:void(0);">Select All</a>
    <a class="dropdown-item" href="javascript:void(0);">Refresh</a>
    <a class="dropdown-item" href="javascript:void(0);">Share</a>
  </div>
</div>
</div>
<div class="card-body">
<div class="d-flex align-items-center justify-content-between flex-wrap gap-3">
  <div class="d-flex justify-content-between align-content-center flex-wrap gap-4">
    <div class="d-flex align-items-center gap-2">
      <div id="marketingCampaignChart1"></div>
      <div>
        <div class="d-flex align-items-center">
          <h6 class="mb-0 me-2">25,768</h6>
          <span class="text-success">(+16.2%)</span>
        </div>
        <div class="d-flex align-items-center">
          <span class="badge badge-dot bg-success me-2"></span> Sep 08,2023
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center gap-2">
      <div id="marketingCampaignChart2"></div>
      <div>
        <div class="d-flex align-items-center">
          <h6 class="mb-0 me-2">5,352</h6>
          <span class="text-danger">(-4.9%)</span>
        </div>
        <div class="d-flex align-items-center">
          <span class="badge badge-dot bg-danger me-2"></span> Sep 08,2023
        </div>
      </div>
    </div>
  </div>
  <a href="javascript:;" class="btn btn-sm btn-primary" type="button">View Report</a>
</div>
</div>




            <div class="col-xxl">
            <div class="card mb-4">
              <div class="card-header d-flex align-items-center justify-content-between">
                <h5 class="mb-0">Basic Layout</h5> <small class="text-muted float-end">Default label</small>
              </div>
              <div class="card-body">
                
                  <div class="row mb-3">
                    <label class="col-sm-2 col-form-label" for="basic-default-name">Product Name</label>

                    <div class="col-sm-10">
                      <input type="text" class="form-control" id="basic-default-name" value={productName}
                
                      onChange={(event) => {
                          setProductName(event.target.value);
                        }} />
                    </div>
                  </div>


                  <div class="row mb-3">
                

                  <div class="col-sm-10">
                    <input type="hidden" class="form-control" id="basic-default-name" value={productId}
              
                    onChange={(event) => {
                        setProductId(event.target.value);
                      }} />
                  </div>
                </div>


                  <div class="row mb-3">
                  



                    <label class="col-sm-2 col-form-label" for="basic-default-company">Quantity Ordered</label>
                    <div class="col-sm-10">
                      <input type="text" class="form-control" id="basic-default-company" 
                      value={quantity_ordered}
                        onChange={(event) => {
                            setQuantity_ordered(event.target.value);
                      }} placeholder=" eg.5" />

                      
                    </div>
                  </div>
           
                  <div class="row mb-3">
                    <label class="col-sm-2 col-form-label" for="basic-default-phone">Customer Phone No</label>
                    <div class="col-sm-10">
                      <input type="text" id="basic-default-phone" class="form-control phone-mask" value={customer_phone_no}
                
                      onChange={(event) => {
                          setCustomer_phone_no(event.target.value);
                        }}  aria-label="658 799 8941" aria-describedby="basic-default-phone" />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-sm-2 col-form-label" for="basic-default-message">Message</label>
                    <div class="col-sm-10">
                      <textarea id="basic-default-message" class="form-control"  placeholder="Hi, Do you have a moment to talk Joe?" aria-label="Hi, Do you have a moment to talk Joe?" aria-describedby="basic-icon-default-message2"> </textarea>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-sm-10">


                    
      {!isLoading &&  <button class="btn btn-primary"  onClick={() => {
        approveRequest(requestId);
      }} type='submit'>Confirm</button> 

                } 
                {isLoading &&
                    <button type="submit" class="btn bg-success" disabled> <i class="fas fa-sync fa-spin"></i>confirming..</button>
                }



                     
                    </div>
                  </div>
                
                <ToastContainer />
              </div>
            </div>
          </div>





<div class="modal fade" id="enableOTP" tabindex="-1" aria-hidden="true">
<div class="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
  <div class="modal-content p-3 p-md-5">
    <div class="modal-body">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      <div class="text-center mb-4">
        <h3 class="mb-4">Approve request</h3>
      </div>
   
      <form id="enableOTPForm" class="row g-3 mt-3" onsubmit="return false">
        
        <div class="col-12">
          <button type="submit" class="btn btn-primary me-sm-3 me-1">Yes</button>
          <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>



</div>
</div>

</div>


    
  </div>
            {/* / Content */}
            {/* Footer */}
            


            
            {/* / Footer */}
            <div className="content-backdrop fade" />
        </div>





               
            </div>
            {/* / Layout page */}
        </div>
        {/* Overlay */}
        <div className="layout-overlay layout-menu-toggle" />
        {/* Drag Target Area To SlideIn Menu On Small Screens */}
        <div className="drag-target" />
    </div>
    <Helmet>
    <script src="assets/vendor/libs/jquery/jquery.js"></script>
    <script src="assets/vendor/libs/popper/popper.js"></script>
    <script src="assets/vendor/js/bootstrap.js"></script>
    <script src="assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
    <script src="assets/vendor/libs/hammer/hammer.js"></script>
    <script src="assets/vendor/libs/i18n/i18n.js"></script>
    <script src="assets/vendor/libs/typeahead-js/typeahead.js"></script>
    <script src="assets/vendor/js/menu.js"></script>
    <script src="assets_admin/vendor/libs/apex-charts/apexcharts.js"></script>
    <script src="assets/js/main.js"></script>
    <script src="assets/js/dashboards-ecommerce.js"></script>
    </Helmet>
</div>
  )
}

export default OrderDetails