import React,{useEffect, useState} from 'react';
import FeaturedInfo from './FeaturedInfo';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, Link  } from "react-router-dom";
import pimage from '../../assets/dist-assets/images/faces/3.jpg';


// import '../../assets/dist-assets/css/themes/lite-purple.min.css';
// import '../../assets/dist-assets/css/plugins/perfect-scrollbar.min.css';

import { Helmet } from "react-helmet";
import CustomizedDialogs from './Addcustomer';



export default function Customers() {
    

    const pathname = window.location.pathname;
    const [listOfCustomers, setListOfCustomers]=useState([]);
    const [topCustomer, setTopCustomer]=useState([]);
    const navigate=useNavigate();
    const phoneRegExp=/^[0-9]{2}[0-9]{8}/
    const segment = pathname.substring(pathname.lastIndexOf('/') + 1);
    // alert(segment);
    useEffect( ()=> {
        if(!localStorage.getItem("accessToken")){
          navigate("/login");  
        }else{
            if(pathname==='/customer'){

            axios.get("http://localhost:3001/customer",{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
            console.log(response.data);
            setListOfCustomers(response.data.myCustomersList);
            setTopCustomer(response.data.topCustomer);
            });
        }else{
            axios.get(`http://localhost:3001/customer/{}`,{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
            console.log(response.data);
            setListOfCustomers(response.data.myCustomersList);
            setTopCustomer(response.data.topCustomer);
            });
        }

        }
    },[]);

    
    const initialValues = {
        name: topCustomer.name,
        username: '',
        email: topCustomer.email,
        phone: topCustomer.phone,
        address: topCustomer.address,
    }
    const validationSchema  = Yup.object().shape({
        username: Yup.string().min(4, "It's too short").max(15, "Its Long Maximu 15 Characters").required("Required"),
        name: Yup.string().min(3, "It's too short").required("Required"),
        email: Yup.string().email("Enter valid email").required("Required"),
        phone:Yup.string().matches(phoneRegExp,"Enter valid Phone number").required("Required")
    })
    const onSubmit = (data, e) => console.log(data, e);

    const getCustomer=(id)=>{
        axios.get(`http://localhost:3001/customer/${id}`,{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
            console.log(response.data);
            setListOfCustomers(response.data.myCustomersList);
            setTopCustomer(response.data.topCustomer);
            });
    }

    const updateCustomer=()=>{
        alert("hello");
    }
    
    return (
        <div>
            <Helmet>
                <link rel="stylesheet" href="dist-assets/css/themes/lite-purple.min.css" />
                <link rel="stylesheet" href="dist-assets/css/plugins/perfect-scrollbar.min.css" />
            </Helmet>
            <div className="text-left">
                <div className="app-admin-wrap layout-sidebar-large">
                    <Topbar />
                    <Sidebar />
                    <div className="main-content-wrap sidenav-open d-flex flex-column" style={{marginTop:"55px"}}>
                        {/* ============ Body content start ============= */}
                        <div className="main-content">
                            <div className="card chat-sidebar-container" data-sidebar-container="chat">
                                <div className="chat-sidebar-wrap" data-sidebar="chat">
                                    <div className="border-right">
                                        <div className="pt-2 pb-2 pl-3 pr-3 d-flex align-items-center o-hidden box-shadow-1 chat-topbar p-4" style={{backgroundColor:"aliceblue"}}><a className="link-icon d-md-none" data-sidebar-toggle="chat"><i className="icon-regular ml-0 mr-3 i-Left" /></a>
                                            <CustomizedDialogs title="Add Customer">
 
                                            </CustomizedDialogs>
                                            <div className="form-group m-0 flex-grow-1">
                                                <inpu className="form-control form-control-rounded" id="search" type="text" placeholder="Search Customers" />
                                            </div>
                                        </div>
                                        <div className="contacts-scrollable perfect-scrollbar">
                                            <div className="mt-3 pb-2 pl-3 pr-3 font-weight-bold text-muted border-bottom">Customers </div>
                                            { listOfCustomers.map((value, key) => {
                                            return (
                                            <div key={key} className="p-3 d-flex border-bottom align-items-center contact online"><img className="avatar-sm rounded-circle mr-3" src={pimage} alt="alt" />
                                                <h6>
                                                    <a onClick={()=>{ getCustomer(value.id) }} href='javascript:;'>{ value.name }</a>
                                                {/* <Link to={`/customer/${value.id}`}> */}
                                                    
                                                {/* </Link> */}
                                                </h6>
                                            </div>
                                            );
                                            }) }
                                        </div>
                                    </div>
                                </div>
                                <div className="chat-content-wrap" data-sidebar-content="chat">
                                    <div className="d-flex pl-3 pr-3 pt-2 pb-2 o-hidden box-shadow-1 chat-topbar" style={{backgroundColor:"aliceblue"}}><a className="link-icon d-md-none" data-sidebar-toggle="chat"><i className="icon-regular i-Right ml-0 mr-3" /></a>
                                    </div>
                                    <div className="chat-content perfect-scrollbar" data-suppress-scroll-x="true">
                                        <div className="row">
                                            <div className="col-lg-6 mb-4">
                                                <div className="d-flex align-items-center border-bottom-dotted-dim pb-3 mb-3">
                                                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                                                        <Form noValidate>   
                                                            <div className="flex-grow-1">
                                                                <img className="avatar-md rounded mr-3" src={pimage} alt="" />
                                                                <div className="form-group row">
                                                                    <div className="col-sm-12">
                                                                        <Field className="form-control" id="inputName" onBlur={()=>{updateCustomer()}}  name="name" value={topCustomer.name} type="text" placeholder="name" />
                                                                        <ErrorMessage name='name' component="span" />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label" htmlFor="inputEmail3">Email</label>
                                                                    <div className="col-sm-8">
                                                                        <Field className="form-control" name="email" value={topCustomer.email} id="inputEmail3" type="email" placeholder="Email" />
                                                                        <ErrorMessage name='email' component="span" />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label" htmlFor="inputPhone">Mobile</label>
                                                                    <div className="col-sm-8">
                                                                        <Field className="form-control" name="phone" value={topCustomer.phone} id="inputPhone" type="text" placeholder={+254} />
                                                                        <ErrorMessage name='phone' component="span" />
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label" htmlFor="inputAddress">Address</label>
                                                                    <div className="col-sm-8">
                                                                        <Field className="form-control" id="inputAddress" value={topCustomer.address} type="text" placeholder="Address" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </Formik>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-4">
                                                <div className="ul-widget__head">
                                                    <div className="ul-widget__head-toolbar">
                                                        <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold ul-widget-nav-tabs-line" role="tablist">
                                                            <li className="nav-item"><a className="nav-link active show" data-toggle="tab" href="#__g-widget-s6-tab1-content" role="tab" aria-selected="true">Appointments</a></li>
                                                            <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#__g-widget-s6-tab2-content" role="tab" aria-selected="false">Notes</a></li>
                                                            <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#__g-widget-s6-tab3-content" role="tab" aria-selected="false">Stats</a></li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <button className="btn btn-outline-primary btn-rounded btn-sm">Delete</button>
                                                    </div>
                                                </div>
                                                <div className="ul-widget__body">
                                                    <div className="tab-content">
                                                        <div className="tab-pane active show" id="__g-widget-s6-tab1-content">
                                                            <div className="ul-widget-s6__items">
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane" id="__g-widget-s6-tab2-content">
                                                            <div className="ul-widget2">
                                                                <div className="ul-widget-s6__items">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane" id="__g-widget-s6-tab3-content">
                                                            <div className="ul-widget2">
                                                                <div className="ul-widget-s6__items">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* end of main-content */}
                        </div>{/* Footer Start */}
                        <div className="flex-grow-1" />
                        <div className="app-footer">
                            <div className="row">
                                <div className="col-md-9">
                                    <p><strong>Pamojour</strong></p>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero quis beatae officia saepe perferendis voluptatum minima eveniet voluptates dolorum, temporibus nisi maxime nesciunt totam repudiandae commodi sequi dolor quibusdam
                                        <sunt />
                                    </p>
                                </div>
                            </div>
                            <div className="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
                                {/* <a class="btn btn-primary text-white btn-rounded" href="https://themeforest.net/item/gull-bootstrap-laravel-admin-dashboard-template/23101970" target="_blank">Buy Gull HTML</a> */}
                                <span className="flex-grow-1" />
                                <div className="d-flex align-items-center">
                                    <img className="logo" src="" alt="" />
                                    <div>
                                        <p className="m-0">© 2023 mavunosoko</p>
                                        <p className="m-0">All rights reserved</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* fotter end */}
                    </div>
                </div>
            </div>
            <Helmet>
                <script src="dist-assets/js/plugins/jquery-3.3.1.min.js"></script>
                <script src="dist-assets/js/plugins/bootstrap.bundle.min.js"></script>
                <script src="dist-assets/js/plugins/perfect-scrollbar.min.js"></script>
                <script src="dist-assets/js/scripts/script.min.js"></script>
                <script src="dist-assets/js/scripts/sidebar.large.script.min.js"></script>
                <script src="dist-assets/js/scripts/sidebar.script.min.js"></script>
                <script src="dist-assets/js/plugins/echarts.min.js"></script>
                <script src="dist-assets/js/scripts/echart.options.min.js"></script>
                <script src="dist-assets/js/scripts/dashboard.v1.script.min.js"></script>
            </Helmet>
        </div>
    )
}
