import React from 'react'
import SideBarMenu from '../SideBarMenu'

export default function MainFeatures() {
  return (
    <div>

    <section class="bg-soft-primary-pattern-1 pt-6 pb-8">
    <div class="container">
    <div class="row align-items-center justify-content-center justify-content-lg-between mb-6">
    <div class="col-lg-6 col-md-10 mb-5 mb-lg-0 text-center text-lg-left">
       
        <h1 class="font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="50">Repeat Clients </h1>
        <p class="text-muted" data-aos="fade-up" data-aos-delay="100">Add to your enterprise bottom line and guarantee future income by creating membership packages and rewarding loyal clients.</p>
        
        <div class="d-flex flex-column align-items-center flex-sm-row justify-content-center justify-content-lg-start mt-6">
            <a href="javascript:;" class="d-inline-block btn btn-primary waves-effect mr-4 mb-3 mb-sm-0" data-aos="fade-up" data-aos-delay="150">View our services</a>
            <a href="javascript:;" class="d-inline-block font-weight-semibold text-primary" data-aos="fade-up" data-aos-delay="200">Learn More <i class="icon-chevron-right"></i></a>
        </div>
    </div>
    {/**  <div class="col-xxl-4 col-lg-5 col-md-9" data-aos="fade-up" data-aos-delay="200">
       <div class="card shadow-lg p-3 p-xl-5">
            <div class="d-flex">
                <span class="mr-3 fs-32">👋</span>
                <div>
                    <h4 class="font-weight-bold mb-1">
                    The next feature of appointment </h4>
                    <p class="text-gray-400 font-size-sm mb-0">What solution are you interested?</p>
                </div>
            </div>
            
            <hr/>
            
          
       </div>
    </div> */}
   
</div>   
    </div>
</section>

    <section class="py-6 mt-lg-n11">
    <div class="container">

    

    <div class="row pt-6 pt-lg-8">

    <SideBarMenu/>
 
    <div class="col-lg-9 order-1 order-lg-2 mb-5 mb-lg-0">

     
    <div class="clear"></div>
                      <div class="container features-descriptions padding-bottom-37">
                          <div class="row">
                              <div class="col-md-12 features-headings">
                                  <h3>Run a membership program </h3>
                                  <span>Add to your enterprise bottom line and guarantee future income by creating membership packages and rewarding loyal clients.</span>
                                  <a href="unlimited-appointments-clients.html" class="btn btn-tt-lightbkgrd-small-inverted">Learn more</a>
                              </div>
                              <div class="col-md-6 topmargin-sm text-center large-horizontal">
                                  <img src="images/features/repeat-clients.jpg" alt="A scheduling system that offers unlimited appointments" />
                              </div>
                              <div class="col-md-6 topmargin-sm">
                              <ul class="steps vertical list-unstyled mb-0">
                              <li data-aos="fade-up">
                                <span>1</span> 
                                Gift vouchers
                                <p>Sell gift cards and vouchers in-store and online. Give your clients the opportunity to pick-up the perfect last-minute gift for their friends and dear ones. </p>
                              </li>
                              <li data-aos="fade-up" data-aos-delay="100">
                                <span>2</span>
                                Availability online for bookings 24/7
                                <p>Provide a seamless customer journey with online appointment scheduling</p>
                              </li>
                              <li data-aos="fade-up" data-aos-delay="150">
                                <span>3</span>
                                Create a more seamless customer journey with online scheduling
                                <p>Providing an excellent customer experience is the most important values of any business and the ability to manage bookings and appointments remotely will give your clients an easy way to interact with you on their 
                                terms whenever and wherever it’s convenient.</p>
                              </li>
                              <li data-aos="fade-up" data-aos-delay="150">
                              <span>4</span>
                              Reduce cancellations & no-shows
                              <p>The ability to send out automated reminders to your clients is important in minimizing cancellations and missed appointments and is a win-win situation
                               for both your business and customer satisfaction.</p>
                            </li>
                          </ul>
                              </div>
                          </div>
                          
    
    
                      </div>


      
       


     

    </div>
</div>
</div>
</section>
    
    
    </div>
  )
}
