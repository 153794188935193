import React from 'react'
import { Grid, Paper, Button, Typography } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from "axios"
import {useNavigate} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'  
import 'react-toastify/dist/ReactToastify.css'

const RegistrationForm = () => {
    const navigate=useNavigate();
    const paperStyle = { padding: '0 15px 40px 15px', width: 250, }
    const btnStyle = { marginTop: 10 }
    const phoneRegExp=/^[0-9]{2}[0-9]{8}/
    const passwordRegExp=/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
    const initialValues = {
        staff_name: '',
        username: '',
        email: '',
        phone_no: '',
        // password: '',
        // confirmPassword:''
    }
    const validationSchema  = Yup.object().shape({
        username: Yup.string().min(4, "It's too short").max(15, "Its Long Maximu 15 Characters").required("Required"),
        staff_name: Yup.string().min(3, "It's too short").required("Required"),
        email: Yup.string().email("Enter valid email").required("Required"),
        // phoneNumber: Yup.number().typeError("Enter valid Phone number").required("Required"),
        phone_no:Yup.string().matches(phoneRegExp,"Enter valid Phone number").required("Required")
        // password: Yup.string().min(8, "Minimum characters should be 8")
        // .matches(passwordRegExp,"Password must have one upper, lower case, number, special symbol").required('Required'),
        // confirmPassword:Yup.string().oneOf([Yup.ref('password')],"Password not matches").required('Required')
    })
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const onSubmit = (data, props) => {

        // alert(JSON.stringify(data), null, 2)
        axios.post("http://localhost:3001/staff", data,{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
        // console.log(response);
        // navigate("/");
        if (response.status === 200){
            // window.location.reload();
        }
        // alert("hello")
        handleClose();
        toast.success('Success Message')
        // props.resetForm()
        }); 
        
    }
    return (
        <Grid>
            <Paper elevation={0} style={paperStyle}>
                {/* <Grid align='center'>
                    <Typography variant='caption'>Fill the form to create an account</Typography>
                </Grid> */}
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                            {/* <TextField label='Name' name="name" fullWidth value={props.values.name}
                    onChange={props.handleChange} /> */}

                            <Field as={TextField} name='staff_name' label='Name' fullWidth
                                error={props.errors.staff_name && props.touched.staff_name}
                                helperText={<ErrorMessage name='staff_name' />} required />

                            <Field as={TextField} name='username' label='Username' fullWidth
                                error={props.errors.username && props.touched.username}
                                helperText={<ErrorMessage name='username' />} required />

                            {/* <TextField label='Email' name='email' type='Email' fullWidth 
                    {...props.getFieldProps('email')}/> */}

                            <Field as={TextField} name='email' label='Email' fullWidth
                                error={props.errors.email && props.touched.email}
                                helperText={<ErrorMessage name='email' />} required />

                            <Field as={TextField} name="phone_no" label='Phone Number' fullWidth
                                error={props.errors.phone_no && props.touched.phone_no}
                                helperText={<ErrorMessage name='phone_no' />} required />

                            {/* <Field as={TextField} name='password' label='Password' type='password' fullWidth
                                error={props.errors.password && props.touched.password}
                                helperText={<ErrorMessage name='password' />} required />

                            <Field as={TextField} name='confirmPassword' label='Confirm Password' type='password' fullWidth
                                error={props.errors.confirmPassword && props.touched.confirmPassword}
                                helperText={<ErrorMessage name='confirmPassword' />} required /> */}

                            <Button type='submit' variant='contained'
                                className="btn btn-primary">Add Staff</Button>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </Grid>
    )
}

export default RegistrationForm;