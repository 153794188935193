import React,{useContext} from 'react'
import axios from 'axios';

import { useEffect,useState } from 'react';


import {toast,ToastContainer,Zoom,Bounce} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import API from '../../services';


export default function FeaturedInfo(){


  const [requestsList, setRequestsList] = useState([]);


  const [ordersList, setOrdersList] = useState([]);

  const [isLoading,setLoading]=useState(false);

  useEffect(()=>{
  
    //   axios.get("https://tunepbackend.herokuapp.com/customer/mycustomers").then((response) => {
      //axios.get("https://ngeritbackend.herokuapp.com/users/getuser/"+id).then((response) => {
        API.get("/subscriptionrequest/current_requests").then((response) => {
           // axios.get(`https://ngeritbackend.herokuapp.com/product/search/${item}`).then((response) => {

          
            setRequestsList(response.data)

            console.log("THE SELLER DETAILS ARE "+response.data)
        });



        API.get("/order/allorders",{ headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {
          // axios.get(`https://ngeritbackend.herokuapp.com/product/search/${item}`).then((response) => {

         
          setOrdersList(response.data)

           console.log("THE SELLER DETAILS ARE "+response.data)
       });
  
  },[]);


  const approveRequest = (id) => {

    let status='approved';

    setLoading(true);

    axios
      .put(
        "https://ngeritbackend.herokuapp.com/subscriptionrequest/acceptrequest",
       // "http://localhost:3001/subscriptionrequest/acceptrequest",
        { status:status,id: id }
      
      )
      .then((response) => {

        
        setTimeout(() => {
          setLoading(false);
         // setIsDivLoading(false)   // Hide loading screen 
          toast.info('Request Confirmed');
      }, 1000);


      }
      );
    
    

  }
    




    return (
        <div>
            <div className="content-wrapper">
                    {/* Content */}
                    <div class="container-xxl flex-grow-1 container-p-y">


                    <div class="row">
  <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center gap-3">
            <div class="avatar">
              <span class="avatar-initial rounded-circle bg-label-primary"><i class='bx bx-user fs-4'></i></span>
            </div>
            <div class="card-info">
              <h5 class="card-title mb-0 me-2">10</h5>
              <small class="text-muted">Farmers</small>
            </div>
          </div>
          <div id="conversationChart"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center gap-3">
            <div class="avatar">
              <span class="avatar-initial rounded-circle bg-label-warning"><i class='bx bx-dollar fs-4'></i></span>
            </div>
            <div class="card-info">
              <h5 class="card-title mb-0 me-2">0 KES</h5>
              <small class="text-muted">Income</small>
            </div>
          </div>
          <div id="incomeChart"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center gap-3">
            <div class="avatar">
              <span class="avatar-initial rounded-circle bg-label-success"><i class='bx bx-wallet fs-4'></i></span>
            </div>
            <div class="card-info">
              <h5 class="card-title mb-0 me-2">0 KES</h5>
              <small class="text-muted">Profit</small>
            </div>
          </div>
          <div id="profitChart"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-6 col-sm-6 mb-4">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center gap-3">
            <div class="avatar">
              <span class="avatar-initial rounded-circle bg-label-danger"><i class='bx bx-cart fs-4'></i></span>
            </div>
            <div class="card-info">
              <h5 class="card-title mb-0 me-2">0 KES</h5>
              <small class="text-muted">Subscriptions</small>
            </div>
          </div>
          <div id="expensesLineChart"></div>
        </div>
      </div>
    </div>
  </div>
</div>
            
            
<div class="row">

 

  

 
 

 

 
 
  <div class="col-md-6 col-lg-6 col-xl-4 mb-4 mb-xl-0">
    <div class="card h-100">
      <div class="card-header">
        <h5 class="card-title mb-2">All Users</h5>
        <h1 class="display-6 fw-normal mb-0">21</h1>
      </div>
      <div class="card-body">
        <span class="d-block mb-2">Current Activity</span>
        <div class="progress progress-stacked mb-3 mb-xl-5" style={{height:'8px'}}>
          <div class="progress-bar bg-success" role="progressbar" style={{width: '30%'}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
          <div class="progress-bar bg-danger" role="progressbar" style={{width: '15%'}}  aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
          <div class="progress-bar bg-info" role="progressbar" style={{width: '10%'}}  aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
          <div class="progress-bar bg-primary" role="progressbar"  style={{width: '40%'}}  aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
          <div class="progress-bar bg-warning" role="progressbar" style={{width: '15%'}}  aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <ul class="p-0 m-0">
          <li class="mb-3 d-flex justify-content-between">
            <div class="d-flex align-items-center lh-1 me-3">
              <span class="badge badge-dot bg-success me-2"></span> Requests
            </div>
            <div class="d-flex gap-3">
              <span>3</span>
              <span class="fw-semibold">5%</span>
            </div>
          </li>
          <li class="mb-3 d-flex justify-content-between">
            <div class="d-flex align-items-center lh-1 me-3">
              <span class="badge badge-dot bg-danger me-2"></span> Bookings
            </div>
            <div class="d-flex gap-3">
              <span>25</span>
              <span class="fw-semibold">26%</span>
            </div>
          </li>
         
          <li class="mb-1 d-flex justify-content-between">
            <div class="d-flex align-items-center lh-1 me-3">
              <span class="badge badge-dot bg-warning me-2"></span> Searches
            </div>
            <div class="d-flex gap-3">
              <span>22</span>
              <span class="fw-semibold">1%</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
 
  <div class="col-xl-8">
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="card-title mb-0">New Requests</h5>
        <div class="dropdown">
          <button class="btn p-0" type="button" id="marketingOptions" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="bx bx-dots-vertical-rounded"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="marketingOptions">
            <a class="dropdown-item" href="javascript:void(0);">Select All</a>
            <a class="dropdown-item" href="javascript:void(0);">Refresh</a>
            <a class="dropdown-item" href="javascript:void(0);">Share</a>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between flex-wrap gap-3">
          <div class="d-flex justify-content-between align-content-center flex-wrap gap-4">
          
          </div>
          <a href="javascript:;" class="btn btn-sm btn-primary" type="button">View Report</a>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table border-top">
          <thead>
            <tr>
              <th>#</th>
              <th>Names</th>
              <th>Phone No</th>
              <th>Email</th>
              <th>Location</th>
              <th>Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody class="table-border-bottom-0">
          {ordersList.map((value, key) => {
            return (
            <tr>
              <td class="text-nowrap"><img src="assets_admin/img/icons/brands/fastrack.png" class="me-3" width="22" alt="Fastrack"/>{value.id}</td>
              <td class="text-nowrap"><i class="bx bx-trending-up text-success me-2"></i>{value.last_name}</td>
              <td class="text-nowrap"><i class="bx bx-trending-up text-success me-2"></i>{value.phone_no}</td>
              <td>{value.email}</td>
              <td>Utawala-Capital Mall</td>
              <td><span class="text-success">{value.status}</span></td>
              <td>
               {/** <a href='#'  class="badge bg-success"  data-bs-toggle="modal" data-bs-target="#enableOTP"><i class="fa fa-check" aria-hidden="true"></i>Approve</a>*/} 

               {!isLoading &&  <button class="badge bg-success"  onClick={() => {
                approveRequest(value.id);
              }} type='submit'>Confirm</button> 
        
                        } 
                        {isLoading &&
                            <button type="submit" class="badge bg-success" disabled> <i class="fas fa-sync fa-spin"></i>loading..</button>
                        }
               
               <a href='#' class="badge bg-danger"><i class="fa fa-times-circle" data-bs-toggle="modal" data-bs-target="#DeclineRequest"  aria-hidden="true">Decline</i></a>


                
              </td>
            </tr>
            );
          })}
           
          </tbody>
        </table>

        <ToastContainer />


  <div class="modal fade" id="enableOTP" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
    <div class="modal-content p-3 p-md-5">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="text-center mb-4">
          <h3 class="mb-4">Approve request</h3>
        </div>
     
        <form id="enableOTPForm" class="row g-3 mt-3" onsubmit="return false">
          
          <div class="col-12">
            <button type="submit" class="btn btn-primary me-sm-3 me-1">Yes</button>
            <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="DeclineRequest" tabindex="-1" aria-hidden="true">
<div class="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
  <div class="modal-content p-3 p-md-5">
    <div class="modal-body">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      <div class="text-center mb-4">
        <h3 class="mb-4">Decline request</h3>
      </div>
   
      <form id="enableOTPForm" class="row g-3 mt-3" onsubmit="return false">
        
        <div class="col-12">
          <button type="submit" class="btn btn-primary me-sm-3 me-1">Yes</button>
          <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">No</button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
      </div>
    </div>
  </div>
 
</div>


            
          </div>
                    {/* / Content */}
                    {/* Footer */}
                    


                    
                    {/* / Footer */}
                    <div className="content-backdrop fade" />
                </div>
        </div>
    )
}