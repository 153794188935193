import React from 'react'
import CalendarFC from './Calendar/CalendarFC'


function CalendarContent() {
  return (
    <div class="content-wrapper">

    
    
      <div class="container-xxl flex-grow-1 container-p-y">
        
        
<div class="card app-calendar-wrapper">
<div class="row g-0">





<CalendarFC/>





</div>
</div>

        
      </div>
    
<footer class="content-footer footer bg-footer-theme">
<div class="container-fluid d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
<div class="mb-2 mb-md-0">
  © <script>
  document.write(new Date().getFullYear())
  </script>
  , made with ❤️ by <a href="https://pixinvent.com/" target="_blank" class="footer-link fw-semibold">PIXINVENT</a>
</div>
<div>
  
  <a href="https://themeforest.net/licenses/standard" class="footer-link me-4" target="_blank">License</a>
  <a href="https://1.envato.market/pixinvent_portfolio" target="_blank" class="footer-link me-4">More Themes</a>
  
  <a href="https://pixinvent.com/demo/frest-clean-bootstrap-admin-dashboard-template/documentation-bs5/" target="_blank" class="footer-link me-4">Documentation</a>
  
  <a href="https://pixinvent.ticksy.com/" target="_blank" class="footer-link d-none d-sm-inline-block">Support</a>
  
</div>
</div>
</footer>


      
      <div class="content-backdrop fade"></div>
    </div>
  )
}

export default CalendarContent