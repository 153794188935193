import React from 'react'

import {useEffect,useState,useContext } from 'react';

import { AuthContext } from '../../../helpers/AuthContext'

import {toast,ToastContainer,Zoom,Bounce} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


import {useNavigate,Link} from 'react-router-dom'

import axios from 'axios';

import API from '../../../services';

import { Modal, Button } from "react-bootstrap";


function IncomeContent() {


    const {authState} = useContext(AuthContext);

    const [requestList, setRequestList] = useState([]);

    const [ordersList, setOrdersList] = useState([]);


    const [searchLogs, setSearchLogs] = useState([]);


    const [payments, setPayments] = useState([]);

    const [errorMessage, setErrorMessage] = useState("");

    const [isDivLoading, setIsDivLoading] = useState(false);

    const [isLoading,setLoading]=useState(false);

    const [userId, setUserId] = useState("");

    const [totalAmount, setTotalAmount] = useState(0);

    const [PhoneNumber, setPhoneNumber] = useState("");

    const [amount, setAmount] = useState("");

    const [withdrawalAmount, setWithdrawalAmount] = useState("");

    

    let orderedItem=localStorage.getItem('itemsearched')
    orderedItem=JSON.parse(orderedItem)


    let sellerId=localStorage.getItem('sellerId')
    sellerId=JSON.parse(sellerId)


    const [show, setShow] = useState(false);



    const handleWithrawModal = (amount) =>{

     //setLoading(true)
  
      setShow(true);
    
      setTimeout(() => {
  
     // setLoading(false)
  
      // history("/dashboard-customer");
      // window.location.reload(false);
  
    
  
      
  }, 500);
  
  
    }



    const handleClose = (amount) =>{

      //setLoading(true)
   
       setShow(false);
     
       setTimeout(() => {
   
      // setLoading(false)
   
       // history("/dashboard-customer");
       // window.location.reload(false);
   
     
   
       
   }, 500);
   
   
     }






    useEffect(()=>{
  
        //axios.get("https://ngeritbackend.herokuapp.com/product/getproducts").then((response) => {
 
        // setIsDivLoading(true);
     
        API.get("/request/current_requests", {
         // axios.get("http://localhost:3001/request/current_requests", {

           headers: { accessToken: localStorage.getItem("accessToken")},
         })
         .then((response) => {
            setRequestList(response.data);
 
             console.log("THE SELLER DATA IS"+ response.data)
 
            
          
         }).catch(() => {
           setErrorMessage("Unable to fetch your products list");
           //setIsDivLoading(false);
        });


        API.get("mpesa/mysales", {
            // axios.get("http://localhost:3001/request/current_requests", {
   
              headers: { accessToken: localStorage.getItem("accessToken")},
            })
            .then((response) => {
               //setRequestList(response.data);
               setPayments(response.data);
    
                console.log("THE SALES DATA"+JSON.stringify(response.data))



                const total_sales=response.data.reduce((total, transaction) => total + transaction.Amount, 0);


                setTotalAmount(total_sales)



                
    
               
             
            }).catch(() => {
              setErrorMessage("Unable to fetch your products list");
              //setIsDivLoading(false);
           });


        API.get("/product/searchlogs")
            .then((response) => {
               setSearchLogs(response.data);
    
                console.log("THE SEARCH LOG LIST IS"+ response.data)
    
               
             
            }).catch(() => {
              setErrorMessage("Unable to fetch your products list");
              //setIsDivLoading(false);
           });
 
 
        API.get('/users/auth',{headers:{
         //  axios.get('http://localhost:3001/users/auth',{headers:{
             accessToken:localStorage.getItem("accessToken"),
           }}).then((response)=>{
         
         
         
                setUserId(response.data.id);
               
         
                console.log("THIS IS THE USER DATA "+response.data)
         
              });


              API.get("/order/allorders",{ headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {
                // axios.get(`https://ngeritbackend.herokuapp.com/product/search/${item}`).then((response) => {
      
               
                setOrdersList(response.data)
      
                 console.log("THE SELLER DETAILS ARE "+response.data)
             });


            //  const total = getTotalAmount();
            //  setTotalAmount(total); // Update the totalAmount state when the component mounts
   
   },[]);



 
  //  const getTotalAmount = () => {
  //   // If transactions array is not empty, sum up the amounts
  //   if (payments.length > 0) {
  //     return payments.reduce((total, transaction) => total + transaction.Amount, 0);
  //   }
  //   return 0;
  // };

   const approveRequest = (id) => {

    let status='confirmed';

    setLoading(true);

    API
      .put(
       // "https://ngeritbackend.herokuapp.com/request/confirmrequest",
        "/request/confirmrequest",
        { status:status,id: id }
      
      )
      .then((response) => {

        
        setTimeout(() => {
          setLoading(false);
         // setIsDivLoading(false)   // Hide loading screen 
          toast.info('Request Confirmed');
      }, 1000);


      }
      );
    
    

  }


  const paydata={
    PhoneNumber:PhoneNumber,
  
    Amount:withdrawalAmount,
   
     
  }







  const withdrawDoo = ()  => {

    setLoading(true);

      // axios.post("https://ngeritbackend.herokuapp.com/product",data).then((response)=>{
  
      API.post('mpesa/withdraw',paydata).then((response)=>{

 
    
  // axios.post("http://localhost:3001/request",data).then((response)=>{
     

    console.log("The response is"+response.data)

       
        setTimeout(() => {
            setLoading(false);
            toast.info('Request confirmed successfully');

            // history('/login');

        }, 1000);
     
       //  history("/dashboard");
      
       
    })

}

    
  return (
    <div>
    <div className="content-wrapper">
            {/* Content */}
            <div class="container-xxl flex-grow-1 container-p-y">


            <div class="row">



</div>
    
    
<div class="row">

<div class="col-lg-4 col-md-6 col-12 mb-4">
<div class="card h-100">

<div class="card-body">
<div class="row align-items-end">
  <div class="col-6">
    <h1 class="display-6 text-primary mb-2 pt-4 pb-1">{totalAmount}</h1>
    <small class="d-block mb-3">You have done 57.6% <br/>more sales today.</small>

    <a href="javascript:;" onClick={() => {
                                    handleWithrawModal(totalAmount);
                                      }}  class="btn btn-sm btn-primary">WithDraw Now</a>
  </div>
  <div class="col-6">
    {/* <img src="assets_admin/img/illustrations/prize-light.png" width="140" height="150" class="rounded-start" alt="View Sales" data-app-light-img="assets_admin/img/illustrations/prize-light.png" data-app-dark-img="prize-dark.html"/> */}
  </div>
</div>
</div>
</div>
</div>




<Modal class="modal fade" id="modaldemo8" show={show}>

<Modal.Header>
  <Modal.Title>Your Balance is: {totalAmount}</Modal.Title>
</Modal.Header>
<Modal.Body class="modal-body">


<form id="enableOTPForm" class="row g-3 mt-3" onsubmit="return false">

<div class="col-12">
            <label class="form-label" for="modalEnableOTPPhone">Enter Amount</label>
            <div class="input-group input-group-merge">
              <span class="input-group-text">Ksh.</span>
              <input type="number" id="modalEnableOTPPhone" name="modalEnableOTPPhone"

              onChange={(event) => {
                setWithdrawalAmount(event.target.value);
              }}
              
              
              class="form-control" placeholder="Enter Amount" />
            </div>
          </div>
          <div class="col-12">
            <label class="form-label" for="modalEnableOTPPhone">Enter Your Mpesa Number</label>
            <div class="input-group input-group-merge">
              <span class="input-group-text">+254</span>
              <input type="text" id="modalEnableOTPPhone" name="modalEnableOTPPhone"

              onChange={(event) => {
                setPhoneNumber(event.target.value);
              }}
              
              
              class="form-control phone-number-otp-mask" placeholder="+254714639773" />
            </div>
          </div>
         
        </form>






  

</Modal.Body>
<Modal.Footer class="modal-footer">


{!isLoading && <button type="submit"  onClick={() => {
                                    withdrawDoo();
                                      }}  class="btn btn-primary">Withdraw</button>

} 
{isLoading &&
  

    <button class="btn btn-primary my-1" type="button" disabled="">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Preparing Your Order....
</button>
} <button class="btn btn-light"  onClick={() => {
                                    handleClose();
                                      }}  data-bs-dismiss="modal">Close</button>

{/* <Button variant="secondary" onClick={handleClose}>
    Close
  </Button>
  <Button variant="primary" onClick={handleClose}>
    Save Changes
  </Button> */}
 
</Modal.Footer>
</Modal>

<div class="col-lg-4 col-md-6 col-12 mb-4">
<div class="card">
<div class="card-header d-flex justify-content-between align-items-center">
<h5 class="card-title mb-0">Searched this month</h5>
<div class="dropdown">
<span class="avatar-initial rounded-circle bg-label-success"><i class='bx bx-wallet fs-4'></i></span>
<span class="d-block text-nowrap">Total Search Logs</span>
    <h2 class="mb-0">150</h2>
</div>
</div>
<div class="card-body">
<div id="visitsRadialChart"></div>
</div>
</div>
</div>

<div class="col-lg-4 col-12">
<div class="row">

<div class="col-6 col-md-3 col-lg-6 mb-4">
<div class="card h-100">
  <div class="card-body text-center">
    <div class="avatar mx-auto mb-2">
      <span class="avatar-initial rounded-circle bg-label-success"><i class="bx bx-purchase-tag fs-4"></i></span>
    </div>
    <span class="d-block text-nowrap">Searches</span>
    <h2 class="mb-0">10</h2>
  </div>
</div>
</div>
<div class="col-6 col-md-3 col-lg-6 mb-4">
<div class="card h-100">
  <div class="card-body text-center">
    <div class="avatar mx-auto mb-2">
      <span class="avatar-initial rounded-circle bg-label-danger"><i class="bx bx-cart fs-4"></i></span>
    </div>
    <span class="d-block text-nowrap">Order</span>
    <h2 class="mb-0">3</h2>
  </div>
</div>
</div>


<div class="col-12 col-md-6 col-lg-12 mb-4">
<div class="card">
  <div class="card-header d-flex justify-content-between align-items-center pb-0">
    <h5 class="card-title mb-0">Revenue Growth</h5>
    <span>0%</span>
  </div>
  <div class="card-body pb-0">
    <div id="revenueGrowthChart"></div>
  </div>
</div>
</div>



</div>
</div>




<div class="col-md-6 col-lg-6 col-xl-4 mb-4 mb-xl-0">
<div class="card h-100">
<div class="card-header">
<h5 class="card-title mb-2">Latest Payments</h5>
<h1 class="display-6 fw-normal mb-0"></h1>
</div>
<div class="card-body">
<span class="d-block mb-2">Current Activity</span>
<div class="progress progress-stacked mb-3 mb-xl-5" style={{height:'8px'}}>
  <div class="progress-bar bg-success" role="progressbar" style={{width: '30%'}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
  <div class="progress-bar bg-danger" role="progressbar" style={{width: '15%'}}  aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
  <div class="progress-bar bg-info" role="progressbar" style={{width: '10%'}}  aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
  <div class="progress-bar bg-primary" role="progressbar"  style={{width: '40%'}}  aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
  <div class="progress-bar bg-warning" role="progressbar" style={{width: '15%'}}  aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
</div>
<ul class="p-0 m-0">


{payments.map((value, key) => {
    return (
  <li class="mb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center lh-1 me-3">
      <span class="badge badge-dot bg-success me-2"></span> {value.TransactionDesc}
    </div>
    <div class="d-flex gap-3">
      <span></span>
      <span class="fw-semibold">{value.Amount}</span>
    </div>
  </li>
   );
  })}
  {/* <li class="mb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center lh-1 me-3">
      <span class="badge badge-dot bg-danger me-2"></span> Water Mellon
    </div>
    <div class="d-flex gap-3">
      <span>25.7k</span>
      <span class="fw-semibold">26%</span>
    </div>
  </li>
  <li class="mb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center lh-1 me-3">
      <span class="badge badge-dot bg-info me-2"></span> Chicken
    </div>
    <div class="d-flex gap-3">
      <span>11.5k</span>
      <span class="fw-semibold">34%</span>
    </div>
  </li>
  <li class="mb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center lh-1 me-3">
      <span class="badge badge-dot bg-primary me-2"></span> Goat
    </div>
    <div class="d-flex gap-3">
      <span>48.5k</span>
      <span class="fw-semibold">45%</span>
    </div>
  </li> */}
 
</ul>
</div>
</div>
</div>

<div class="col-xl-8">
<div class="card">
<div class="card-header d-flex justify-content-between align-items-center">
<h5 class="card-title mb-0">All Payments</h5>
<div class="dropdown">
  <button class="btn p-0" type="button" id="marketingOptions" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <i class="bx bx-dots-vertical-rounded"></i>
  </button>
  <div class="dropdown-menu dropdown-menu-end" aria-labelledby="marketingOptions">
    <a class="dropdown-item" href="javascript:void(0);">Select All</a>
    <a class="dropdown-item" href="javascript:void(0);">Refresh</a>
    <a class="dropdown-item" href="javascript:void(0);">Share</a>
  </div>
</div>
</div>
{/* <div class="card-body">
<div class="d-flex align-items-center justify-content-between flex-wrap gap-3">
  <div class="d-flex justify-content-between align-content-center flex-wrap gap-4">
    <div class="d-flex align-items-center gap-2">
      <div id="marketingCampaignChart1"></div>
      <div>
        <div class="d-flex align-items-center">
          <h6 class="mb-0 me-2">25,768</h6>
          <span class="text-success">(+16.2%)</span>
        </div>
        <div class="d-flex align-items-center">
          <span class="badge badge-dot bg-success me-2"></span> Jan 12,2022
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center gap-2">
      <div id="marketingCampaignChart2"></div>
      <div>
        <div class="d-flex align-items-center">
          <h6 class="mb-0 me-2">5,352</h6>
          <span class="text-danger">(-4.9%)</span>
        </div>
        <div class="d-flex align-items-center">
          <span class="badge badge-dot bg-danger me-2"></span> Jan 12,2022
        </div>
      </div>
    </div>
  </div>
  <a href="javascript:;" class="btn btn-sm btn-primary" type="button">View Report</a>
</div>
</div> */}
<div class="table-responsive">
<table class="table border-top">
  <thead>
    <tr>
      <th>#</th>
      <th>Name</th>
      <th>Phone No.</th>
      <th>TransactionDesc</th>
      
      <th>Amount</th>
      <th>Date</th>
     
    </tr>
  </thead>
  <tbody class="table-border-bottom-0">
  {payments.map((value, key) => {
    return (
    <tr>
      <td>{key}</td>
      <td class="text-nowrap">{value.CustomerName}</td>
      <td class="text-nowrap"><i class="bx bx-trending-up text-success me-2"></i>{value.PhoneNumber}</td>
      <td class="text-nowrap"><i class="bx bx-trending-up text-success me-2"></i>{value.TransactionDesc}</td>
      
      <td><span class="text-success">{value.Amount}</span></td>

      <td><span class="text-success">{value.createdAt}</span></td>
      {/* <td>

      
       
                <Link  to={`/order_details/${value.id}`} class="badge bg-success"> View Order</Link>
     
      </td> */}

     
    </tr>
    );
})}
    
    
    
  </tbody>
</table>
<ToastContainer />
</div>




<div class="modal fade" id="enableOTP" tabindex="-1" aria-hidden="true">
<div class="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
  <div class="modal-content p-3 p-md-5">
    <div class="modal-body">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      <div class="text-center mb-4">
        <h3 class="mb-4">Approve request</h3>
      </div>
   
      <form id="enableOTPForm" class="row g-3 mt-3" onsubmit="return false">
        
        <div class="col-12">
          <button type="submit" class="btn btn-primary me-sm-3 me-1">Yes</button>
          <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>



</div>
</div>

</div>


    
  </div>
            {/* / Content */}
            {/* Footer */}
            


            
            {/* / Footer */}
            <div className="content-backdrop fade" />
        </div>
</div>
  )
}

export default IncomeContent