import React from 'react'

import './TimetapStyles.css'

export default function GreatFeatures() {
  return (
    <div>

    <section id="content" class="bg-medium2-gray-tt padding-bottom-20">

    <div class="content-wrap">

    <div class="container clearfix">
    <div id="section-home" class="heading-block title-center nobottomborder page-section ">
        <h1>Need a Web Scheduler that's got your back?</h1>
        <h2 class="margin-one letter-spacing-2">Climb out from under the piles of missed calls & voicemails and back to the front-lines of your business</h2>
    </div>
    <div class="center bottommargin features-hero-buttons">
        <a class="btn btn-medium button btn-tt-lightbkgrd-small-inverted xs-margin-bottom-five xs-no-margin-right" href="https://www.timetap.com/businessWeb/web/signup/TEAMBUSM">Sign Up Free</a> <a class="btn btn-medium button btn-tt-yellowbkgrd xs-margin-bottom-five xs-no-margin-right" href="online-support-demo.html">Schedule a Demo</a>
    </div>
    <div class="clear"></div>
    <div class="col-md-8 col-sm-8 col-xs-12 topmargin nobottommargin features-hero-images">
        <div  style={{position: "relative;"}} data-height-lg="535" data-height-md="442" data-height-sm="338" data-height-xs="316" data-height-xxs="201">
            <img class="wow fadeInLeft animated" src="https://9a812d2609e610ab07eb-b463fa4ca2c8095be4f297e4d7f6781b.ssl.cf2.rackcdn.com/web-scheduler-features1.png" alt="Schedule online appointments on a Mac" style={{position: "absolute", top: 0, left: "0;"}}/>
            <img class="wow fadeInRight animated" data-delay="300" src="https://9a812d2609e610ab07eb-b463fa4ca2c8095be4f297e4d7f6781b.ssl.cf2.rackcdn.com/web-scheduler-features2.png" alt="Manage appointments on your iPad with TimeTap's web calendar" style={{position: "absolute", top: "0",left: "0"}}/>
            <img class="wow fadeInUp animated" data-delay="600" src="https://9a812d2609e610ab07eb-b463fa4ca2c8095be4f297e4d7f6781b.ssl.cf2.rackcdn.com/web-scheduler-features3.png" alt="Manage staff scheduling on your iPhone with our scheduling system" style={{position: "absolute", top: "0",left: "0"}}/>
        </div>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-12 topmargin nobottommargin features-page-top mobile-features-adjust">
        <h3>The Right Cure for your Scheduling Pains:</h3>
        <p>Our philosophy is to give you space. With a web scheduler that works for you 24/7, you can keep the phone on the hook and focus on growing your business. </p>
        <p>Try an appointment manager that organizes your day to day and gives you room in your schedule to be the best service provider for the clients that are with you right now.</p>
        <p style={{textAlign: "center;"}}>
            <a target="_blank" href="https://www.capterra.com/p/139709/TimeTap/?utm_source=vendor&utm_medium=badge&utm_campaign=cap_best_functionality_rating_autumn2017&utm_term=TimeTap&utm_content=badge_only">
                <img src="https://badges.capterra.com/80/ba9bdd57-f319-4b48-bdb5-8af4bd61185f.png?p=TimeTap"
                    alt="Capterra functionality badge"
                    title="Capterra functionality badge"
                    style={{width: "80px", height: "80px", border:"0"}} />
            </a>
        </p>
    </div>
    <div class="clear clearfix-top clearfix-mobile-adjust"></div>
    
 
</div>

<div id="notification" class="container clearfix">
    <div id="section-home" class="heading-block title-center nobottomborder page-section ">
        <h1>Notifications and Reminders</h1>
        <h2 class="margin-one letter-spacing-2">Seamlessly send notifications and appointment reminders to employees and clients respectively.</h2>
    </div>
  
    <div class="clear"></div>
    <div class="col-md-8 col-sm-8 col-xs-12 topmargin nobottommargin features-hero-images">
        <div  style={{position: "relative;"}} data-height-lg="535" data-height-md="442" data-height-sm="338" data-height-xs="316" data-height-xxs="201">


            <img  class="wow fadeInUp animated" data-delay="600"  src="images/features/notifications-reminders.jpg" alt="A scheduling system that offers unlimited appointments"  style={{position: "absolute", top: "0",left: "0"}} />
        </div>
    </div>

    <div class="col-md-4 col-sm-4 col-xs-12 topmargin nobottommargin features-page-top mobile-features-adjust">
    <div class="media flex-column flex-sm-row mb-5" data-aos="fade-up" data-aos-delay="50">
        <div class="mb-3 mb-sm-0 mr-2 mr-sm-4 text-primary">
            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.1528 5.55553C10.2037 5.65919 10.2373 5.77021 10.2524 5.88434L10.5308 10.0243L10.669 12.1051C10.6705 12.3191 10.704 12.5317 10.7687 12.736C10.9356 13.1326 11.3372 13.3846 11.7741 13.367L18.4313 12.9315C18.7196 12.9268 18.998 13.0346 19.2052 13.2313C19.3779 13.3952 19.4894 13.6096 19.5246 13.8402L19.5364 13.9802C19.2609 17.7949 16.4592 20.9767 12.6524 21.798C8.84555 22.6193 4.94186 20.8843 3.06071 17.5349C2.51839 16.5618 2.17965 15.4923 2.06438 14.389C2.01623 14.0624 1.99503 13.7325 2.00098 13.4025C1.99503 9.31273 4.90747 5.77696 8.98433 4.92457C9.47501 4.84816 9.95603 5.10792 10.1528 5.55553Z" fill="currentColor"/>
                <path opacity="0.4" d="M12.87 2.00082C17.4299 2.11683 21.2623 5.39579 22 9.81229L21.993 9.84488L21.9728 9.89227L21.9756 10.0224C21.9652 10.1947 21.8986 10.3605 21.784 10.4945C21.6645 10.634 21.5013 10.729 21.3216 10.7659L21.212 10.7809L13.5312 11.2786C13.2757 11.3038 13.0213 11.2214 12.8314 11.052C12.673 10.9107 12.5718 10.7201 12.5432 10.5147L12.0277 2.84506C12.0187 2.81913 12.0187 2.79102 12.0277 2.76508C12.0347 2.55367 12.1278 2.35384 12.2861 2.21023C12.4443 2.06662 12.6547 1.9912 12.87 2.00082Z" fill="currentColor"/>
            </svg>                    
        </div>

        <div class="media-body">
            <h6 class="font-weight-bold">Send staff notification</h6>
            <p class="text-muted font-size-sm mb-0">Send out a message or email to your employees at the start of each day. Include the daily appointment schedule and changes, if any. Tunep notifications and reminders will ensure they never miss an appointment.</p>    
        </div>
    </div>
    <div class="media flex-column flex-sm-row mb-5" data-aos="fade-up" data-aos-delay="100">
        <div class="mb-3 mb-sm-0 mr-2 mr-sm-4 text-primary">
            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M16.6756 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0711 3.92889 22 7.33333 22H16.6756C20.08 22 22 20.0711 22 16.6667V7.33333C22 3.92889 20.08 2 16.6756 2Z" fill="currentColor"/>
                <path d="M7.36872 9.36898C6.91539 9.36898 6.54205 9.74231 6.54205 10.2045V17.0756C6.54205 17.529 6.91539 17.9023 7.36872 17.9023C7.83094 17.9023 8.20428 17.529 8.20428 17.0756V10.2045C8.20428 9.74231 7.83094 9.36898 7.36872 9.36898Z" fill="currentColor"/>
                <path d="M12.0354 6.08898C11.582 6.08898 11.2087 6.46231 11.2087 6.92453V17.0756C11.2087 17.529 11.582 17.9023 12.0354 17.9023C12.4976 17.9023 12.8709 17.529 12.8709 17.0756V6.92453C12.8709 6.46231 12.4976 6.08898 12.0354 6.08898Z" fill="currentColor"/>
                <path d="M16.6399 12.9956C16.1777 12.9956 15.8044 13.369 15.8044 13.8312V17.0756C15.8044 17.529 16.1777 17.9023 16.631 17.9023C17.0932 17.9023 17.4666 17.529 17.4666 17.0756V13.8312C17.4666 13.369 17.0932 12.9956 16.6399 12.9956Z" fill="currentColor"/>
            </svg>                                                           
        </div>

        <div class="media-body">
            <h6 class="font-weight-bold">Send appointment reminders</h6>
            <p class="text-muted font-size-sm mb-0">Reduce no-show rate by sending automated messaging and email confirmations. You can also send for two-way SMS confirmations.</p>    
        </div>
    </div>
    <div class="media flex-column flex-sm-row mb-5" data-aos="fade-up" data-aos-delay="150">
        <div class="mb-3 mb-sm-0 mr-2 mr-sm-4 text-primary">
            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 16.87V9.257H21V16.931C21 20.07 19.0241 22 15.8628 22H8.12733C4.99561 22 3 20.03 3 16.87ZM7.95938 14.41C7.50494 14.431 7.12953 14.07 7.10977 13.611C7.10977 13.151 7.46542 12.771 7.91987 12.75C8.36443 12.75 8.72997 13.101 8.73985 13.55C8.7596 14.011 8.40395 14.391 7.95938 14.41ZM12.0198 14.41C11.5653 14.431 11.1899 14.07 11.1701 13.611C11.1701 13.151 11.5258 12.771 11.9802 12.75C12.4248 12.75 12.7903 13.101 12.8002 13.55C12.82 14.011 12.4643 14.391 12.0198 14.41ZM16.0505 18.09C15.596 18.08 15.2305 17.7 15.2305 17.24C15.2206 16.78 15.5862 16.401 16.0406 16.391H16.0505C16.5148 16.391 16.8902 16.771 16.8902 17.24C16.8902 17.71 16.5148 18.09 16.0505 18.09ZM11.1701 17.24C11.1899 17.7 11.5653 18.061 12.0198 18.04C12.4643 18.021 12.82 17.641 12.8002 17.181C12.7903 16.731 12.4248 16.38 11.9802 16.38C11.5258 16.401 11.1701 16.78 11.1701 17.24ZM7.09989 17.24C7.11965 17.7 7.49506 18.061 7.94951 18.04C8.39407 18.021 8.74973 17.641 8.72997 17.181C8.72009 16.731 8.35456 16.38 7.90999 16.38C7.45554 16.401 7.09989 16.78 7.09989 17.24ZM15.2404 13.601C15.2404 13.141 15.596 12.771 16.0505 12.761C16.4951 12.761 16.8507 13.12 16.8705 13.561C16.8804 14.021 16.5247 14.401 16.0801 14.41C15.6257 14.42 15.2503 14.07 15.2404 13.611V13.601Z" fill="currentColor"/>
                <path opacity="0.4" d="M3.00336 9.2569C3.0162 8.6699 3.0656 7.5049 3.15846 7.1299C3.63267 5.0209 5.24298 3.6809 7.54485 3.4899H16.4559C18.738 3.6909 20.3681 5.0399 20.8423 7.1299C20.9342 7.4949 20.9836 8.6689 20.9964 9.2569H3.00336Z" fill="currentColor"/>
                <path d="M8.30486 6.59C8.73955 6.59 9.06556 6.261 9.06556 5.82V2.771C9.06556 2.33 8.73955 2 8.30486 2C7.87017 2 7.54416 2.33 7.54416 2.771V5.82C7.54416 6.261 7.87017 6.59 8.30486 6.59Z" fill="currentColor"/>
                <path d="M15.6949 6.59C16.1197 6.59 16.4556 6.261 16.4556 5.82V2.771C16.4556 2.33 16.1197 2 15.6949 2C15.2603 2 14.9342 2.33 14.9342 2.771V5.82C14.9342 6.261 15.2603 6.59 15.6949 6.59Z" fill="currentColor"/>
            </svg>                   
        </div>

        <div class="media-body">
            <h6 class="font-weight-bold">Birthday and anniversary greetings</h6>
            <p class="text-muted font-size-sm mb-0">Never miss a client’s birthday or anniversary. Get notified and send them exclusive offers on their special day. </p>    
        </div>
    </div>

    <div class="media flex-column flex-sm-row mb-5" data-aos="fade-up" data-aos-delay="150">
        <div class="mb-3 mb-sm-0 mr-2 mr-sm-4 text-primary">
            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3 16.87V9.257H21V16.931C21 20.07 19.0241 22 15.8628 22H8.12733C4.99561 22 3 20.03 3 16.87ZM7.95938 14.41C7.50494 14.431 7.12953 14.07 7.10977 13.611C7.10977 13.151 7.46542 12.771 7.91987 12.75C8.36443 12.75 8.72997 13.101 8.73985 13.55C8.7596 14.011 8.40395 14.391 7.95938 14.41ZM12.0198 14.41C11.5653 14.431 11.1899 14.07 11.1701 13.611C11.1701 13.151 11.5258 12.771 11.9802 12.75C12.4248 12.75 12.7903 13.101 12.8002 13.55C12.82 14.011 12.4643 14.391 12.0198 14.41ZM16.0505 18.09C15.596 18.08 15.2305 17.7 15.2305 17.24C15.2206 16.78 15.5862 16.401 16.0406 16.391H16.0505C16.5148 16.391 16.8902 16.771 16.8902 17.24C16.8902 17.71 16.5148 18.09 16.0505 18.09ZM11.1701 17.24C11.1899 17.7 11.5653 18.061 12.0198 18.04C12.4643 18.021 12.82 17.641 12.8002 17.181C12.7903 16.731 12.4248 16.38 11.9802 16.38C11.5258 16.401 11.1701 16.78 11.1701 17.24ZM7.09989 17.24C7.11965 17.7 7.49506 18.061 7.94951 18.04C8.39407 18.021 8.74973 17.641 8.72997 17.181C8.72009 16.731 8.35456 16.38 7.90999 16.38C7.45554 16.401 7.09989 16.78 7.09989 17.24ZM15.2404 13.601C15.2404 13.141 15.596 12.771 16.0505 12.761C16.4951 12.761 16.8507 13.12 16.8705 13.561C16.8804 14.021 16.5247 14.401 16.0801 14.41C15.6257 14.42 15.2503 14.07 15.2404 13.611V13.601Z" fill="currentColor"/>
                <path opacity="0.4" d="M3.00336 9.2569C3.0162 8.6699 3.0656 7.5049 3.15846 7.1299C3.63267 5.0209 5.24298 3.6809 7.54485 3.4899H16.4559C18.738 3.6909 20.3681 5.0399 20.8423 7.1299C20.9342 7.4949 20.9836 8.6689 20.9964 9.2569H3.00336Z" fill="currentColor"/>
                <path d="M8.30486 6.59C8.73955 6.59 9.06556 6.261 9.06556 5.82V2.771C9.06556 2.33 8.73955 2 8.30486 2C7.87017 2 7.54416 2.33 7.54416 2.771V5.82C7.54416 6.261 7.87017 6.59 8.30486 6.59Z" fill="currentColor"/>
                <path d="M15.6949 6.59C16.1197 6.59 16.4556 6.261 16.4556 5.82V2.771C16.4556 2.33 16.1197 2 15.6949 2C15.2603 2 14.9342 2.33 14.9342 2.771V5.82C14.9342 6.261 15.2603 6.59 15.6949 6.59Z" fill="currentColor"/>
            </svg>                   
        </div>

        <div class="media-body">
            <h6 class="font-weight-bold">E-invoices</h6>
            <p class="text-muted font-size-sm mb-0">Embrace paperless approach and send invoices to clients via email and SMS. Send rebooking reminders to get clients to walk back through the doors of your business. </p>    
        </div>
    </div>

    <button class="btn btn-outline-primary waves-effect" data-aos="fade-up" data-aos-delay="200">Explore more Features</button>
</div>
    
  
    <div class="clear clearfix-top clearfix-mobile-adjust"></div>
    
 
</div>


<div id="integrations" class="container clearfix">
    <div id="section-home" class="heading-block title-center nobottomborder page-section ">
        <h1>INTEGRATIONS</h1>
        <h2 class="margin-one letter-spacing-2">Powerful integrations in the system to help you deliver your magic.</h2>
    </div>
  
    <div class="clear"></div>
    <div class="col-md-8 col-sm-8 col-xs-12 topmargin nobottommargin features-hero-images">
        <div  style={{position: "relative;"}} data-height-lg="535" data-height-md="442" data-height-sm="338" data-height-xs="316" data-height-xxs="201">


        
        
        
        <img  class="wow fadeInUp animated" data-delay="600"  src="images/features/integrations.jpg" alt="A scheduling system that offers unlimited appointments"  style={{position: "absolute", top: "0",left: "0"}} />
        </div>
    </div>




<div class="col-md-4 col-sm-4 col-xs-12 topmargin nobottommargin features-page-top mobile-features-adjust">
<div class="media flex-column flex-sm-row mb-5" data-aos="fade-up" data-aos-delay="50">
    <div class="mb-3 mb-sm-0 mr-2 mr-sm-4 text-primary">
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1528 5.55553C10.2037 5.65919 10.2373 5.77021 10.2524 5.88434L10.5308 10.0243L10.669 12.1051C10.6705 12.3191 10.704 12.5317 10.7687 12.736C10.9356 13.1326 11.3372 13.3846 11.7741 13.367L18.4313 12.9315C18.7196 12.9268 18.998 13.0346 19.2052 13.2313C19.3779 13.3952 19.4894 13.6096 19.5246 13.8402L19.5364 13.9802C19.2609 17.7949 16.4592 20.9767 12.6524 21.798C8.84555 22.6193 4.94186 20.8843 3.06071 17.5349C2.51839 16.5618 2.17965 15.4923 2.06438 14.389C2.01623 14.0624 1.99503 13.7325 2.00098 13.4025C1.99503 9.31273 4.90747 5.77696 8.98433 4.92457C9.47501 4.84816 9.95603 5.10792 10.1528 5.55553Z" fill="currentColor"/>
            <path opacity="0.4" d="M12.87 2.00082C17.4299 2.11683 21.2623 5.39579 22 9.81229L21.993 9.84488L21.9728 9.89227L21.9756 10.0224C21.9652 10.1947 21.8986 10.3605 21.784 10.4945C21.6645 10.634 21.5013 10.729 21.3216 10.7659L21.212 10.7809L13.5312 11.2786C13.2757 11.3038 13.0213 11.2214 12.8314 11.052C12.673 10.9107 12.5718 10.7201 12.5432 10.5147L12.0277 2.84506C12.0187 2.81913 12.0187 2.79102 12.0277 2.76508C12.0347 2.55367 12.1278 2.35384 12.2861 2.21023C12.4443 2.06662 12.6547 1.9912 12.87 2.00082Z" fill="currentColor"/>
        </svg>                    
    </div>

    <div class="media-body">
        <h6 class="font-weight-bold">Social media integration</h6>
        <p class="text-muted font-size-sm mb-0">Tunep is making online booking easy by adding the booking widget to your social media business pages. It automatically reflects in your calendar.</p>    
    </div>
</div>
<div class="media flex-column flex-sm-row mb-5" data-aos="fade-up" data-aos-delay="100">
    <div class="mb-3 mb-sm-0 mr-2 mr-sm-4 text-primary">
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M16.6756 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0711 3.92889 22 7.33333 22H16.6756C20.08 22 22 20.0711 22 16.6667V7.33333C22 3.92889 20.08 2 16.6756 2Z" fill="currentColor"/>
            <path d="M7.36872 9.36898C6.91539 9.36898 6.54205 9.74231 6.54205 10.2045V17.0756C6.54205 17.529 6.91539 17.9023 7.36872 17.9023C7.83094 17.9023 8.20428 17.529 8.20428 17.0756V10.2045C8.20428 9.74231 7.83094 9.36898 7.36872 9.36898Z" fill="currentColor"/>
            <path d="M12.0354 6.08898C11.582 6.08898 11.2087 6.46231 11.2087 6.92453V17.0756C11.2087 17.529 11.582 17.9023 12.0354 17.9023C12.4976 17.9023 12.8709 17.529 12.8709 17.0756V6.92453C12.8709 6.46231 12.4976 6.08898 12.0354 6.08898Z" fill="currentColor"/>
            <path d="M16.6399 12.9956C16.1777 12.9956 15.8044 13.369 15.8044 13.8312V17.0756C15.8044 17.529 16.1777 17.9023 16.631 17.9023C17.0932 17.9023 17.4666 17.529 17.4666 17.0756V13.8312C17.4666 13.369 17.0932 12.9956 16.6399 12.9956Z" fill="currentColor"/>
        </svg>                                                           
    </div>

    <div class="media-body">
        <h6 class="font-weight-bold">Mailchimp</h6>
        <p class="text-muted font-size-sm mb-0">Create and send targeted email campaigns by syncing your client database from Tunep.</p>    
    </div>
</div>
<div class="media flex-column flex-sm-row mb-5" data-aos="fade-up" data-aos-delay="150">
    <div class="mb-3 mb-sm-0 mr-2 mr-sm-4 text-primary">
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 16.87V9.257H21V16.931C21 20.07 19.0241 22 15.8628 22H8.12733C4.99561 22 3 20.03 3 16.87ZM7.95938 14.41C7.50494 14.431 7.12953 14.07 7.10977 13.611C7.10977 13.151 7.46542 12.771 7.91987 12.75C8.36443 12.75 8.72997 13.101 8.73985 13.55C8.7596 14.011 8.40395 14.391 7.95938 14.41ZM12.0198 14.41C11.5653 14.431 11.1899 14.07 11.1701 13.611C11.1701 13.151 11.5258 12.771 11.9802 12.75C12.4248 12.75 12.7903 13.101 12.8002 13.55C12.82 14.011 12.4643 14.391 12.0198 14.41ZM16.0505 18.09C15.596 18.08 15.2305 17.7 15.2305 17.24C15.2206 16.78 15.5862 16.401 16.0406 16.391H16.0505C16.5148 16.391 16.8902 16.771 16.8902 17.24C16.8902 17.71 16.5148 18.09 16.0505 18.09ZM11.1701 17.24C11.1899 17.7 11.5653 18.061 12.0198 18.04C12.4643 18.021 12.82 17.641 12.8002 17.181C12.7903 16.731 12.4248 16.38 11.9802 16.38C11.5258 16.401 11.1701 16.78 11.1701 17.24ZM7.09989 17.24C7.11965 17.7 7.49506 18.061 7.94951 18.04C8.39407 18.021 8.74973 17.641 8.72997 17.181C8.72009 16.731 8.35456 16.38 7.90999 16.38C7.45554 16.401 7.09989 16.78 7.09989 17.24ZM15.2404 13.601C15.2404 13.141 15.596 12.771 16.0505 12.761C16.4951 12.761 16.8507 13.12 16.8705 13.561C16.8804 14.021 16.5247 14.401 16.0801 14.41C15.6257 14.42 15.2503 14.07 15.2404 13.611V13.601Z" fill="currentColor"/>
            <path opacity="0.4" d="M3.00336 9.2569C3.0162 8.6699 3.0656 7.5049 3.15846 7.1299C3.63267 5.0209 5.24298 3.6809 7.54485 3.4899H16.4559C18.738 3.6909 20.3681 5.0399 20.8423 7.1299C20.9342 7.4949 20.9836 8.6689 20.9964 9.2569H3.00336Z" fill="currentColor"/>
            <path d="M8.30486 6.59C8.73955 6.59 9.06556 6.261 9.06556 5.82V2.771C9.06556 2.33 8.73955 2 8.30486 2C7.87017 2 7.54416 2.33 7.54416 2.771V5.82C7.54416 6.261 7.87017 6.59 8.30486 6.59Z" fill="currentColor"/>
            <path d="M15.6949 6.59C16.1197 6.59 16.4556 6.261 16.4556 5.82V2.771C16.4556 2.33 16.1197 2 15.6949 2C15.2603 2 14.9342 2.33 14.9342 2.771V5.82C14.9342 6.261 15.2603 6.59 15.6949 6.59Z" fill="currentColor"/>
        </svg>                   
    </div>

    <div class="media-body">
        <h6 class="font-weight-bold">Mobile money </h6>
        <p class="text-muted font-size-sm mb-0">Receive payments for purchases from customers 24/7 by syncing your Tunep booking widget with mobile money payment gateways.</p>    
    </div>
</div>

<div class="media flex-column flex-sm-row mb-5" data-aos="fade-up" data-aos-delay="150">
    <div class="mb-3 mb-sm-0 mr-2 mr-sm-4 text-primary">
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 16.87V9.257H21V16.931C21 20.07 19.0241 22 15.8628 22H8.12733C4.99561 22 3 20.03 3 16.87ZM7.95938 14.41C7.50494 14.431 7.12953 14.07 7.10977 13.611C7.10977 13.151 7.46542 12.771 7.91987 12.75C8.36443 12.75 8.72997 13.101 8.73985 13.55C8.7596 14.011 8.40395 14.391 7.95938 14.41ZM12.0198 14.41C11.5653 14.431 11.1899 14.07 11.1701 13.611C11.1701 13.151 11.5258 12.771 11.9802 12.75C12.4248 12.75 12.7903 13.101 12.8002 13.55C12.82 14.011 12.4643 14.391 12.0198 14.41ZM16.0505 18.09C15.596 18.08 15.2305 17.7 15.2305 17.24C15.2206 16.78 15.5862 16.401 16.0406 16.391H16.0505C16.5148 16.391 16.8902 16.771 16.8902 17.24C16.8902 17.71 16.5148 18.09 16.0505 18.09ZM11.1701 17.24C11.1899 17.7 11.5653 18.061 12.0198 18.04C12.4643 18.021 12.82 17.641 12.8002 17.181C12.7903 16.731 12.4248 16.38 11.9802 16.38C11.5258 16.401 11.1701 16.78 11.1701 17.24ZM7.09989 17.24C7.11965 17.7 7.49506 18.061 7.94951 18.04C8.39407 18.021 8.74973 17.641 8.72997 17.181C8.72009 16.731 8.35456 16.38 7.90999 16.38C7.45554 16.401 7.09989 16.78 7.09989 17.24ZM15.2404 13.601C15.2404 13.141 15.596 12.771 16.0505 12.761C16.4951 12.761 16.8507 13.12 16.8705 13.561C16.8804 14.021 16.5247 14.401 16.0801 14.41C15.6257 14.42 15.2503 14.07 15.2404 13.611V13.601Z" fill="currentColor"/>
            <path opacity="0.4" d="M3.00336 9.2569C3.0162 8.6699 3.0656 7.5049 3.15846 7.1299C3.63267 5.0209 5.24298 3.6809 7.54485 3.4899H16.4559C18.738 3.6909 20.3681 5.0399 20.8423 7.1299C20.9342 7.4949 20.9836 8.6689 20.9964 9.2569H3.00336Z" fill="currentColor"/>
            <path d="M8.30486 6.59C8.73955 6.59 9.06556 6.261 9.06556 5.82V2.771C9.06556 2.33 8.73955 2 8.30486 2C7.87017 2 7.54416 2.33 7.54416 2.771V5.82C7.54416 6.261 7.87017 6.59 8.30486 6.59Z" fill="currentColor"/>
            <path d="M15.6949 6.59C16.1197 6.59 16.4556 6.261 16.4556 5.82V2.771C16.4556 2.33 16.1197 2 15.6949 2C15.2603 2 14.9342 2.33 14.9342 2.771V5.82C14.9342 6.261 15.2603 6.59 15.6949 6.59Z" fill="currentColor"/>
        </svg>                   
    </div>

    <div class="media-body">
        <h6 class="font-weight-bold">Google Calendar </h6>
        <p class="text-muted font-size-sm mb-0">Tunep automatically syncs the appointment with customer's Google calendar, so the customer gets complete control of their schedule on their device and be reminded automatically. </p>    
    </div>
</div>

<div class="media flex-column flex-sm-row mb-5" data-aos="fade-up" data-aos-delay="150">
    <div class="mb-3 mb-sm-0 mr-2 mr-sm-4 text-primary">
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 16.87V9.257H21V16.931C21 20.07 19.0241 22 15.8628 22H8.12733C4.99561 22 3 20.03 3 16.87ZM7.95938 14.41C7.50494 14.431 7.12953 14.07 7.10977 13.611C7.10977 13.151 7.46542 12.771 7.91987 12.75C8.36443 12.75 8.72997 13.101 8.73985 13.55C8.7596 14.011 8.40395 14.391 7.95938 14.41ZM12.0198 14.41C11.5653 14.431 11.1899 14.07 11.1701 13.611C11.1701 13.151 11.5258 12.771 11.9802 12.75C12.4248 12.75 12.7903 13.101 12.8002 13.55C12.82 14.011 12.4643 14.391 12.0198 14.41ZM16.0505 18.09C15.596 18.08 15.2305 17.7 15.2305 17.24C15.2206 16.78 15.5862 16.401 16.0406 16.391H16.0505C16.5148 16.391 16.8902 16.771 16.8902 17.24C16.8902 17.71 16.5148 18.09 16.0505 18.09ZM11.1701 17.24C11.1899 17.7 11.5653 18.061 12.0198 18.04C12.4643 18.021 12.82 17.641 12.8002 17.181C12.7903 16.731 12.4248 16.38 11.9802 16.38C11.5258 16.401 11.1701 16.78 11.1701 17.24ZM7.09989 17.24C7.11965 17.7 7.49506 18.061 7.94951 18.04C8.39407 18.021 8.74973 17.641 8.72997 17.181C8.72009 16.731 8.35456 16.38 7.90999 16.38C7.45554 16.401 7.09989 16.78 7.09989 17.24ZM15.2404 13.601C15.2404 13.141 15.596 12.771 16.0505 12.761C16.4951 12.761 16.8507 13.12 16.8705 13.561C16.8804 14.021 16.5247 14.401 16.0801 14.41C15.6257 14.42 15.2503 14.07 15.2404 13.611V13.601Z" fill="currentColor"/>
            <path opacity="0.4" d="M3.00336 9.2569C3.0162 8.6699 3.0656 7.5049 3.15846 7.1299C3.63267 5.0209 5.24298 3.6809 7.54485 3.4899H16.4559C18.738 3.6909 20.3681 5.0399 20.8423 7.1299C20.9342 7.4949 20.9836 8.6689 20.9964 9.2569H3.00336Z" fill="currentColor"/>
            <path d="M8.30486 6.59C8.73955 6.59 9.06556 6.261 9.06556 5.82V2.771C9.06556 2.33 8.73955 2 8.30486 2C7.87017 2 7.54416 2.33 7.54416 2.771V5.82C7.54416 6.261 7.87017 6.59 8.30486 6.59Z" fill="currentColor"/>
            <path d="M15.6949 6.59C16.1197 6.59 16.4556 6.261 16.4556 5.82V2.771C16.4556 2.33 16.1197 2 15.6949 2C15.2603 2 14.9342 2.33 14.9342 2.771V5.82C14.9342 6.261 15.2603 6.59 15.6949 6.59Z" fill="currentColor"/>
        </svg>                   
    </div>

    <div class="media-body">
        <h6 class="font-weight-bold">SMS Integrations </h6>
        <p class="text-muted font-size-sm mb-0">Send your clients unlimited SMS reminders and marketing messages by creating your own SMS gateway account and integrating it with Tunep. </p>    
    </div>
</div>

<button class="btn btn-outline-primary waves-effect" data-aos="fade-up" data-aos-delay="200">Explore more Features</button>
</div>



    
  
    <div class="clear clearfix-top clearfix-mobile-adjust"></div>
    
 
</div>





<div id="repeat-clients" class="container clearfix">
    <div id="section-home" class="heading-block title-center nobottomborder page-section ">
        <h1>REPEAT CLIENTS</h1>
        <h2 class="margin-one letter-spacing-2">Add to your enterprise bottom line and guarantee future income by creating membership packages and rewarding loyal clients.</h2>
    </div>
  
    <div class="clear"></div>
    <div class="col-md-8 col-sm-8 col-xs-12 topmargin nobottommargin features-hero-images">
        <div  style={{position: "relative;"}} data-height-lg="535" data-height-md="442" data-height-sm="338" data-height-xs="316" data-height-xxs="201">


        
        
        
        <img  class="wow fadeInUp animated" data-delay="600"  src="images/features/repeat-clients.jpg" alt="A scheduling system that offers unlimited appointments"  style={{position: "absolute", top: "0",left: "0"}} />
        </div>
    </div>




    <div class="col-md-4 col-sm-4 col-xs-12 topmargin nobottommargin features-page-top mobile-features-adjust">
    <ul class="steps vertical list-unstyled mb-0">
    <li data-aos="fade-up">
      <span>1</span> 
      Gift vouchers
      <p>Sell gift cards and vouchers in-store and online. Give your clients the opportunity to pick-up the perfect last-minute gift for their friends and dear ones. </p>
    </li>
    <li data-aos="fade-up" data-aos-delay="100">
      <span>2</span>
      Availability online for bookings 24/7
      <p>Provide a seamless customer journey with online appointment scheduling</p>
    </li>
    <li data-aos="fade-up" data-aos-delay="150">
      <span>3</span>
      Create a more seamless customer journey with online scheduling
      <p>Providing an excellent customer experience is the most important values of any business and the ability to manage bookings and appointments remotely will give your clients an easy way to interact with you on their 
      terms whenever and wherever it’s convenient.</p>
    </li>
    <li data-aos="fade-up" data-aos-delay="150">
    <span>4</span>
    Reduce cancellations & no-shows
    <p>The ability to send out automated reminders to your clients is important in minimizing cancellations and missed appointments and is a win-win situation
     for both your business and customer satisfaction.</p>
  </li>
</ul>
    </div>



    
  
    <div class="clear clearfix-top clearfix-mobile-adjust"></div>
    
 
</div>





<div id="marketing" class="container clearfix">
    <div id="section-home" class="heading-block title-center nobottomborder page-section ">
        <h1>MARKETING</h1>
        <h2 class="margin-one letter-spacing-2">Create targeted promotions and engage with new clients at the right time with the right offers. With simple and effective marketing strategies, put your business where customers are looking.</h2>
    </div>
  
    <div class="clear"></div>
    <div class="col-md-8 col-sm-8 col-xs-12 topmargin nobottommargin features-hero-images">
        <div  style={{position: "relative;"}} data-height-lg="535" data-height-md="442" data-height-sm="338" data-height-xs="316" data-height-xxs="201">


        
        
        
        <img  class="wow fadeInUp animated" data-delay="600"  src="images/features/marketing.jpg" alt="A scheduling system that offers unlimited appointments"  style={{position: "absolute", top: "0",left: "0"}} />
        </div>
    </div>









    <div class="col-md-4 col-sm-4 col-xs-12 topmargin nobottommargin features-page-top mobile-features-adjust">
    <ul class="steps vertical list-unstyled mb-0">
    <li data-aos="fade-up">
      <span>1</span> 
      Personalised campaigns
      <p>Use booking preference and appointment history to filter clients based on service, age, gender, visit and value.</p>
    </li>
    <li data-aos="fade-up" data-aos-delay="100">
      <span>2</span>
      SMS & Email campaigns
      <p>Boost client engagement with automated messaging and email campaigns. Create targeted messages for your active and inactive clients.</p>
    </li>
    <li data-aos="fade-up" data-aos-delay="150">
      <span>3</span>
      Set goals and track your success
      <p>Set retail, service and performance goals for your employees. Check recent staff activity and decide where your staff is succeeding and where they can improve.</p>
    </li>
    <li data-aos="fade-up" data-aos-delay="150">
    <span>4</span>
    Reminders
    <p>Send out reminders to clients for services booked or who have not visited in a while and to those who are due for their next appointment.</p>
  </li>
  <li data-aos="fade-up" data-aos-delay="150">
  <span>5</span>
  Bulk SMS and email management
  <p>An in-built feature to send out bulk text messages and emails to your existing and targeted customers to announce new schemes, new launches, special offers and advertise the business.</p>
</li>
</ul>
    </div>




  



    
  
    <div class="clear clearfix-top clearfix-mobile-adjust"></div>
    
 
</div>



<div id="book-keeping" class="container clearfix">
    <div id="section-home" class="heading-block title-center nobottomborder page-section ">
        <h1>Book Keeping</h1>
        <h2 class="margin-one letter-spacing-2">Ensure that your team and your business are on the same page by managing all the aspects vital to your business from one location.</h2>
    </div>
  
    <div class="clear"></div>
    <div class="col-md-8 col-sm-8 col-xs-12 topmargin nobottommargin features-hero-images">
        <div  style={{position: "relative;"}} data-height-lg="535" data-height-md="442" data-height-sm="338" data-height-xs="316" data-height-xxs="201">


        
        
        
        <img  class="wow fadeInUp animated" data-delay="600"  src="images/features/book-keeping.jpg" alt="A scheduling system that offers unlimited appointments"  style={{position: "absolute", top: "0",left: "0"}} />
        </div>
    </div>


    <div class="col-md-4 col-sm-4 col-xs-12 topmargin nobottommargin features-page-top mobile-features-adjust">
    <ul class="steps vertical list-unstyled mb-0">
    <li data-aos="fade-up">
      <span>1</span> 
      Product inventory management
      <p>Manage product usage, expiry and purchase orders with simplified inventory management tools. Get notified when you’re low on product and stock.</p>
    </li>
    <li data-aos="fade-up" data-aos-delay="100">
      <span>2</span>
      Employee management
      <p>Get all the details about your employees and their performance with a single click. Analyse their productivity and give them incentives.</p>
    </li>
    <li data-aos="fade-up" data-aos-delay="150">
      <span>3</span>
      Client management
      <p>Get instant access to client demographics, past service history, stylist preferences and more. Keep track of past purchases and appointment history to personalise every client’s experience.</p>
    </li>
    <li data-aos="fade-up" data-aos-delay="150">
    <span>4</span>
    Expense management
    <p>Keep track of client expenditure to ensure swift processing of payments. Get instant notifications about pending payments and minimise the loss in your business.</p>
  </li>

</ul>
    </div>


  
  
    <div class="clear clearfix-top clearfix-mobile-adjust"></div>
    
 
</div>




<div id="analytics-reports" class="container clearfix">
    <div id="section-home" class="heading-block title-center nobottomborder page-section ">
        <h1>Analytics & Reports</h1>
        <h2 class="margin-one letter-spacing-2">Get to know how your business is doing overall with individual reports and detailed insights on revenue, tax, campaigns, staff and sales performance.</h2>
    </div>
  
    <div class="clear"></div>
    <div class="col-md-8 col-sm-8 col-xs-12 topmargin nobottommargin features-hero-images">
        <div  style={{position: "relative;"}} data-height-lg="535" data-height-md="442" data-height-sm="338" data-height-xs="316" data-height-xxs="201">


        
        
        
        <img  class="wow fadeInUp animated" data-delay="600"  src="images/features/reports.jpg" alt="A scheduling system that offers unlimited appointments"  style={{position: "absolute", top: "0",left: "0"}} />
        </div>
    </div>



    <div class="col-md-4 col-sm-4 col-xs-12 topmargin nobottommargin features-page-top mobile-features-adjust">
    <ul class="steps vertical list-unstyled mb-0">
    <li data-aos="fade-up">
      <span>1</span> 
      Dashboard
      <p>Get an instant overview of how your business is doing at the click of a button. Also, keep track of client retention, referrals, staff productivity and sales.</p>
    </li>
    <li data-aos="fade-up" data-aos-delay="100">
      <span>2</span>
      Staff performance analysis
      <p>Keep the wheels of your business running smoothly by generating staff performance reports, total hours booked, product sales and client retention rate for each team member.</p>
    </li>
    <li data-aos="fade-up" data-aos-delay="150">
      <span>3</span>
      Customer 360 degree
      <p>Enhance client relationships by getting valuable insights into customer activity. Pull reports on rebooking, no-shows, cancelled appointments and customer retention.</p>
    </li>
    <li data-aos="fade-up" data-aos-delay="150">
    <span>4</span>
    Customer segmentation
    <p>Segment clients on different parameters to get insights on active and inactive clients.</p>
  </li>

</ul>
    </div>


   


  
  
    <div class="clear clearfix-top clearfix-mobile-adjust"></div>
    
 
</div>



<div id="feedback-system" class="container clearfix">
    <div id="section-home" class="heading-block title-center nobottomborder page-section ">
    <h3>Feedback System </h3>
    <span>The online reputation manager on Tunep gives you complete control on how people treat, react and rate your business online.</span>
    </div>
  
    <div class="clear"></div>
    <div class="col-md-8 col-sm-8 col-xs-12 topmargin nobottommargin features-hero-images">
        <div  style={{position: "relative;"}} data-height-lg="535" data-height-md="442" data-height-sm="338" data-height-xs="316" data-height-xxs="201">


        
        
        
        <img  class="wow fadeInUp animated" data-delay="600"  src="images/features/feedback-system.jpg" alt="A scheduling system that offers unlimited appointments"  style={{position: "absolute", top: "0",left: "0"}} />
        </div>
    </div>





    <div class="col-md-4 col-sm-4 col-xs-12 topmargin nobottommargin features-page-top mobile-features-adjust">
    <ul class="steps vertical list-unstyled mb-0">
    <li data-aos="fade-up">
      <span>1</span> 
      Enhanced reputation
      <p>Gain more ratings through automated settings. Gets your business to stand out from your competition online and improves your business’ ranking.</p>
    </li>
    <li data-aos="fade-up" data-aos-delay="100">
      <span>2</span>
      Feedback alerts
      <p>Get notifications and alerts each time a rating is given. </p>
    </li>
    <li data-aos="fade-up" data-aos-delay="150">
      <span>3</span>
      Feedback response
      <p>Respond to negative and positive comments online from one clean and clear dashboard.</p>
    </li>
    <li data-aos="fade-up" data-aos-delay="150">
    <span>4</span>
    Rating by SMS
    <p>Send out text messages to clients after the service to collect dynamic ratings by SMS.</p>
  </li>

  <li data-aos="fade-up" data-aos-delay="150">
  <span>5</span>
  Feedback at store
  <p>Get clients to review your business during checkout using the software on your tablet. </p>
</li>

<li data-aos="fade-up" data-aos-delay="150">
<span>5</span>
Feedback questionnaires
<p>Setup feedback questions on staff performance, appointment timeliness, quality of services and more.</p>
</li>


</ul>
    </div>




  
  
    <div class="clear clearfix-top clearfix-mobile-adjust"></div>
    
 
</div>


                

              
             

     

   
                   
    
    
    
    
    </div>

    <div class="promo promo-dark promo-flat bottommargin">
    <span class="promo-heading">Stop stressing about your schedule.</span>
    <span>Get a web scheduler on your site today! Start your 30 day free trial. No credit card required.</span>
    <a class="btn btn-large button  btn-tt-lightbkgrd-small-inverted xs-margin-bottom-five xs-no-margin-right" href="https://www.timetap.com/businessWeb/web/signup/TEAMBUSM">Sign Up Free</a>
</div>




    </section>


    
    
    
    
    </div>
  )
}
