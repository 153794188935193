

import React,{useEffect}from 'react';
import Section2 from '../Home/Section2';
import Section3 from '../Home/Section3';
import Section4 from '../Home/Section4';

//import Header from '../../Header/Header';
import Header from '../../Header/HeaderOld';


import MainSectionPricing from '../Home/MainSectionPricing';
import MainContent from './MainContent';
import HeaderNew from '../../Header/HeaderNew';
import { Widget,addResponseMessage  } from 'react-chat-widget';

import 'react-chat-widget/lib/styles.css';

import logo from '../../../../src/assets/images/tunepp.jpg';
import HeaderFloating from '../../Header/HeaderFloating';

function HelpAndSupport(){
    useEffect(() => {
        addResponseMessage('Hi! Welcome to mavunosoko.This is our live support. You can as well reach us on 0724512561');
      }, []);
    
      const handleNewUserMessage = (newMessage) => {
        console.log(`New message incoming! ${newMessage}`);
        // // Now send the message throught the backend API
        // addResponseMessage(newMessage);
      };
    return (
        <div>
           

           <HeaderFloating/>

           <MainContent/>


           <Widget 

           handleNewUserMessage={handleNewUserMessage}
           profileAvatar={logo}
           title="Live customer support"
           subtitle="24/7 customer support"
           
         
           />
          
            

             
           
        </div>
    )


}

export default HelpAndSupport;