import React from 'react'
import HeaderFloating from '../../../Header/HeaderFloating'

import MainContent from './MainContent'

function ArtsAndRecreation() {
  return (
    <div>

    <HeaderFloating/>

    <MainContent/>
    
    
    </div>
  )
}

export default ArtsAndRecreation