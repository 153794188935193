import React from 'react';
import SignIn from './SignIn';

import SignUp from './SignUp';
import SignUpModal from './SignUpModal';

export default function MainSectionSignIn() {
  return(
   <div>

     <main>
          
            <section class="pt-6 cloud-pattern">
                <div class="container">
                    <div class="row align-items-center justify-content-center justify-content-lg-between mb-6">
                        
                        
                       <SignIn/>
                        
                    </div>   
                </div>

                <div class="text-white">
                    <svg preserveAspectRatio="none" viewBox="0 0 2000 150" xmlns="http://www.w3.org/2000/svg">
                      <polygon fill="currentColor" points="2000 0 2000 150 0 150"></polygon>
                    </svg>
                </div>
            </section>


          <SignUpModal/>




        <div class="modal fade" id="startTrialModal3" tabindex="-1" aria-labelledby="startTrialModalLabel3" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down modal-xl">
        <div class="modal-content overflow-hidden  border-0">
            <button type="button" class="modal-close close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>

            <div class="modal-body p-0">
                <div class="row align-items-center no-gutters">
                    <div class="col-xl-7 px-2 px-sm-5 py-5">
                        <h2 class="h3 font-weight-bold">Create an Account</h2>
                        <p class="text-muted font-size-sm">100% Money-back guarantee, cancel any time.</p>

                        <div class="d-flex flex-column flex-sm-row pt-3">
                            <a href="javascript:;" class="btn btn-sm btn-white waves-effect mb-2 mb-sm-0">
                                <svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.54594 9.66899L2.989 11.7481L0.953406 11.7912C0.345063 10.6628 0 9.37186 0 7.99999C0 6.67339 0.322625 5.42239 0.8945 4.32086H0.894938L2.70719 4.65311L3.50106 6.45449C3.33491 6.93889 3.24434 7.45889 3.24434 7.99999C3.24441 8.58724 3.35078 9.14989 3.54594 9.66899Z" fill="#FBBB00"/>
                                    <path d="M15.8602 6.50549C15.9521 6.98943 16 7.48921 16 7.99999C16 8.57274 15.9398 9.13143 15.8251 9.67034C15.4357 11.5042 14.4181 13.1055 13.0084 14.2387L13.008 14.2382L10.7253 14.1218L10.4023 12.1051C11.3377 11.5565 12.0687 10.698 12.4537 9.67034H8.1759V6.50549H12.5161H15.8602Z" fill="#518EF8"/>
                                    <path d="M13.0079 14.2383L13.0083 14.2387C11.6373 15.3407 9.89578 16 7.99996 16C4.95337 16 2.30459 14.2972 0.953369 11.7912L3.5459 9.66904C4.22149 11.4721 5.96084 12.7556 7.99996 12.7556C8.87643 12.7556 9.69756 12.5187 10.4022 12.1051L13.0079 14.2383Z" fill="#28B446"/>
                                    <path d="M13.1063 1.84175L10.5147 3.9635C9.78547 3.50769 8.92347 3.24437 7.99997 3.24437C5.91469 3.24437 4.14281 4.58678 3.50106 6.4545L0.894908 4.32088H0.89447C2.22591 1.75384 4.9081 0 7.99997 0C9.94106 0 11.7208 0.691437 13.1063 1.84175Z" fill="#F14336"/>
                                </svg>
                                <span>Signup with Google</span>
                            </a>
                            <a href="javascript:;" class="btn btn-sm btn-light border waves-effect ml-sm-2 mb-2 mb-sm-0">
                                <svg class="mr-2 mr-sm-0" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.5527 0H1.44733C0.648 0 0 0.648 0 1.44733V14.5526C0 15.352 0.648 16 1.44733 16H14.5526C15.352 16 16 15.352 16 14.5526V1.44733C16 0.648 15.352 0 14.5527 0ZM4.95108 13.8155C4.95108 14.0481 4.76251 14.2366 4.52988 14.2366H2.73696C2.50434 14.2366 2.31577 14.0481 2.31577 13.8155V6.2996C2.31577 6.06697 2.50434 5.87841 2.73696 5.87841H4.52988C4.76251 5.87841 4.95108 6.06697 4.95108 6.2996V13.8155ZM3.63342 5.16993C2.69273 5.16993 1.93014 4.40733 1.93014 3.46664C1.93014 2.52595 2.69273 1.76335 3.63342 1.76335C4.57412 1.76335 5.33671 2.52595 5.33671 3.46664C5.33671 4.40733 4.57416 5.16993 3.63342 5.16993ZM14.3208 13.8494C14.3208 14.0632 14.1474 14.2366 13.9336 14.2366H12.0096C11.7958 14.2366 11.6224 14.0632 11.6224 13.8494V10.324C11.6224 9.79811 11.7766 8.01948 10.248 8.01948C9.06228 8.01948 8.82178 9.2369 8.77349 9.78325V13.8494C8.77349 14.0632 8.60013 14.2366 8.38622 14.2366H6.52545C6.31158 14.2366 6.13818 14.0632 6.13818 13.8494V6.26568C6.13818 6.05181 6.31158 5.87841 6.52545 5.87841H8.38622C8.60008 5.87841 8.77349 6.05181 8.77349 6.26568V6.92138C9.21315 6.26157 9.86655 5.75229 11.2578 5.75229C14.3385 5.75229 14.3208 8.63045 14.3208 10.2119V13.8494Z" fill="#0077B7"/>
                                </svg>   
                                
                                <span class="d-sm-none">Signup with Linkedln</span>
                            </a>
                            <a href="javascript:;" class="btn btn-sm btn-light border waves-effect ml-sm-2">
                                <svg class="mr-2 mr-sm-0" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.78926 16C3.40798 15.9863 1.43823 11.1253 1.43823 8.64969C1.43823 4.60581 4.47183 3.7205 5.64095 3.7205C6.16783 3.7205 6.73042 3.92741 7.22664 4.1105C7.57364 4.23822 7.93251 4.37006 8.13211 4.37006C8.25158 4.37006 8.53311 4.25791 8.78167 4.1595C9.31176 3.94847 9.97139 3.68619 10.7395 3.68619C10.7409 3.68619 10.7427 3.68619 10.7441 3.68619C11.3176 3.68619 13.0567 3.81206 14.1022 5.38219L14.3471 5.75022L13.9947 6.01619C13.4911 6.39613 12.5724 7.08919 12.5724 8.46203C12.5724 10.088 13.6129 10.7133 14.1128 11.0141C14.3334 11.1468 14.5618 11.2837 14.5618 11.5831C14.5618 11.7785 13.0018 15.9757 10.7363 15.9757C10.182 15.9757 9.79011 15.8091 9.44448 15.6622C9.09476 15.5134 8.79308 15.3853 8.29458 15.3853C8.04189 15.3853 7.72236 15.5047 7.38408 15.6315C6.92179 15.8041 6.39855 16 5.80483 16H5.78926Z" fill="black"/>
                                    <path d="M10.9795 0C11.0385 2.12737 9.51713 3.60325 7.9975 3.51069C7.74709 1.81297 9.51694 0 10.9795 0Z" fill="black"/>
                                </svg>      
                                <span class="d-sm-none">Signup with Apple ID</span>                                                                                                              
                            </a>
                        </div>

                        <div class="divider font-size-sm">Or Signup with</div>
                        <form>
                            <div class="form-row">
                                <div class="col-lg px-2">
                                    <div class="form-group">
                                        <label for="firstName">First name</label>
                                        <input class="form-control" type="text" id="firstName" placeholder="Robert" aria-describedby="firstName"/>
                                    </div>
                                </div>
                                <div class="col-lg px-2">
                                    <div class="form-group">
                                        <label for="lastName">Last name</label>
                                        <input class="form-control" type="text" id="lastName" placeholder="Matthews" aria-describedby="lastName"/>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-lg px-2">
                                    <div class="form-group">
                                        <label for="emailAddress">Email address</label>
                                        <input class="form-control" type="email" id="emailAddress" placeholder="robertmatthews@email.com" aria-describedby="emailAddress"/>
                                    </div>
                                </div>
                                <div class="col-lg px-2">
                                    <div class="form-group">
                                    <label>Password</label>
                                    <div class="input-group password-input">
                                        <input type="password" class="form-control rounded password" placeholder="Enter your password" required=""/>
                                        
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <i class="pw-btn-toggle icon-eye"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-lg px-2">
                                    <button type="submit" class="btn btn-primary font-size-sm">Create an Account</button>
                                    <div class="small text-muted mt-3">By submitting this form you agree to our <a href="">terms and conditions</a>  and our <a href="">Privacy Policy</a>.</div>
                                
                                </div>
                            </div>
                            
                        </form>
                    </div>

                    <div class="col-xl-5">
                        <div id="carouselExampleCaptions" class="carousel carousel-bottom-gradient slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
                              <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                              <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                            </ol>
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <img src="./images/media/media-01.jpg" class="d-block w-100" alt="..."/>
                                <div class="carousel-caption">
                                  <h5>First slide label</h5>
                                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                </div>
                              </div>
                              <div class="carousel-item">
                                <img src="./images/media/media-01.jpg" class="d-block w-100" alt="..."/>
                                <div class="carousel-caption">
                                  <h5>Second slide label</h5>
                                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                              </div>
                              <div class="carousel-item">
                                <img src="./images/media/media-01.jpg" class="d-block w-100" alt="..."/>
                                <div class="carousel-caption">
                                  <h5>Third slide label</h5>
                                  <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                                </div>
                              </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                    </div>
                </div>
            </div>

        </div>
        </div>
    </div>   
        
        




 
        </main>
   
   
   </div>

  )
}
