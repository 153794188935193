import React,{useEffect}from 'react';
import HeaderNew from '../../Header/HeaderNew'
import CustomersReviews from './CustomersReviews'
import MainContent from './MainContent'
import MajorIndustries from './MajorIndustries'

import { Widget,addResponseMessage  } from 'react-chat-widget';

import 'react-chat-widget/lib/styles.css';

import logo from '../../../../src/assets/images/tunepp.jpg';
import AllIndustriesSection from './AllIndustriesSection';
import TimeTapContent from './TimeTapContent';
import HeaderFloating from '../../Header/HeaderFloating';

function Industries() {

  useEffect(() => {
    addResponseMessage('Hi! Let us know your Business/Industries. Kindly conduct our customer care on 0713292421');
  }, []);

  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    // // Now send the message throught the backend API
    // addResponseMessage(newMessage);
  };
  return (
    <div>

    <HeaderFloating/>

    <MainContent/>

    <TimeTapContent/>


    <CustomersReviews/>

    <Widget 

            handleNewUserMessage={handleNewUserMessage}
            profileAvatar={logo}
            title="Business and Industries"
            subtitle="24/7 customer support"
            
          
            />
    
    
    </div>
  )
}

export default Industries