import React from 'react'
import { Grid, Paper, Button, Typography } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from "axios"
import {useNavigate} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'  
import 'react-toastify/dist/ReactToastify.css'

const RegistrationForm = () => {
    const navigate=useNavigate();
    const paperStyle = { padding: '0 15px 40px 15px', width: 250, }
    const btnStyle = { marginTop: 10 }
    const initialValues = {
        category_name: '',
    }
    const validationSchema  = Yup.object().shape({
        category_name: Yup.string().min(3, "It's too short").required("Required"),
    })
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const onSubmit = (data, props) => {
        axios.post("http://localhost:3001/service/category", data,{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
        // console.log(response);
        // navigate("/");
        if (response.status === 200)
        // alert("hello")
        handleClose();
        toast.success('Success Message')
        // props.resetForm()
        }); 
        
    }
    return (
        <Grid>
            <Paper elevation={0} style={paperStyle}>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    {(props) => (
                        <Form noValidate>
                            <Field as={TextField} name='category_name' label='Name' fullWidth
                                error={props.errors.category_name && props.touched.category_name}
                                helperText={<ErrorMessage name='category_name' />} required />
                            <Button type='submit' variant='contained'
                                className="btn btn-primary">Add Category</Button>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </Grid>
    )
}

export default RegistrationForm;