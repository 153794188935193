import axios from 'axios';

const API = axios.create({
  //  baseURL: 'https://yoteorder-server.herokuapp.com/'
   // baseURL: 'http://localhost:3001/'

   

  // baseURL: 'http://localhost:8080/api/'

  // baseURL: 'https://data.hesgoal.website/api/'


   baseURL: 'https://server.mavunosoko.co.ke/api/'



  //  baseURL: 'https://portal.defmis.org/nodejs'
  //  baseURL: 'https://portal.defmis.org/apidefmis/portalbackend'
  // baseURL: 'http://192.168.91.124/apidefmis/portalbackend'


 // baseURL: 'https://apibackend.patamtaani.com/api/'

})


export default API;