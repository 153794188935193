import React from 'react'

import './TimetapStyles.css'

export default function TimeTapContent() {
  return (
    
    <div>

    <section id="content" class="bg-medium2-gray-tt padding-bottom-0">

    <div class="content-wrap padding-bottom-20">

        <div class="container clearfix">

            <div id="section-home" class="heading-block title-center nobottomborder page-section ">
                <h1>Quick & Easy Online Scheduling Built for Your Business</h1>
                <h2 class="margin-one letter-spacing-2">Guaranteed to make meetings & appointments more efficient for your organization</h2>
            </div>

            <div class="center bottommargin">
                <a class="btn btn-medium button  btn-tt-lightbkgrd-small-inverted xs-margin-bottom-five xs-no-margin-right" href="https://www.timetap.com/businessWeb/web/signup/TEAMBUSM">Sign Up Free</a> <a class="btn btn-medium button btn-tt-yellowbkgrd xs-margin-bottom-five xs-no-margin-right" href="online-support-demo.html">Schedule a Demo</a>
            </div>
    </div>
  </div>

    <div class="content-wrap no-padding-top">

        <div class="container clearfix">
            <div id="section-features" class="title-center page-section">
                <h2 style={{fontSize: "30px", fontWeight:"700", letterSpacing:"1px", textTransform:"uppercase", marginBottom:"40px",color:"#333", fontFamily:"Open Sans', sans-serif"}}>Industries Include</h2>
            </div>
            <div class="row features-link-list">
            <div class="col-md-10 center-col ">
                <div class="col-md-3 col-md-offset-1 col-sm-4 col-xs-4">
                     <ul class="iconlist iconlist-large iconlist-color no-margin-left">
                        <li><a href="#salonbeauty">Salon & Beauty <em class="fa fa-chevron-circle-right"></em></a> </li>
                        <li><a href="#barbershops">Barbershops<em class="fa fa-chevron-circle-right"></em></a> </li>
                        <li><a href="#creative">Creative <em class="fa fa-chevron-circle-right"></em></a> </li>
                       
                    </ul>
                </div>
                <div class="col-md-3 col-md-offset-1 col-sm-4 col-xs-4">
                    <ul class="iconlist iconlist-large iconlist-color no-margin-left">
                        <li><a href="#well-being-and-fitness">Wellbeing & Fitness <em class="fa fa-chevron-circle-right"></em></a> </li>
                        <li><a href="#healthcare">Healthcare <em class="fa fa-chevron-circle-right"></em></a> </li>
                        <li><a href="#automotive">Automotive & Repair <em class="fa fa-chevron-circle-right"></em></a> </li>
                        <li><a href="#maintenanceandrepair">Maintenance and prepair<em class="fa fa-chevron-circle-right"></em></a> </li>
                       
                    </ul>
                </div>
                 <div class="col-md-3 col-md-offset-1 col-sm-4 col-xs-4">
                    <ul class="iconlist iconlist-large iconlist-color no-margin-left">
                        <li><a href="#education">Education <em class="fa fa-chevron-circle-right"></em></a> </li>
                        <li><a href="#professionalservices">Professional Services <em class="fa fa-chevron-circle-right"></em></a> </li>
                        <li><a href="#momecareservices">Homecare Services <em class="fa fa-chevron-circle-right"></em></a> </li>
                       
                    </ul>
                </div>
                </div>
            </div>
        </div>
     </div>
   </section>

   <section class="fix-background industries-page">
   <div class="opacity-light bg-white"></div>
   <div class="container position-relative">
       <div class="row">
           <div class="col-md-4 col-sm-6 col-xs-6 sm-margin-bottom-ten sm-text-center">
               <h2 class="title-med no-padding-bottom letter-spacing-2" style={{fontWeight: "700"}}>Schedule with the Best</h2>
               <p class="text-med margin-five" style={{color: "#000;"}}>Tunep works with Thousands of different businesses to help automate their scheduling process</p>
               <p class="text-med margin-five" style={{color: "#000;"}}>We’ve built Tunep to streamline the most pervasive appointment-based problems into a Swiss Army Knife of a solution.</p>
               <a class="btn-tt-lightbkgrd-small-inverted btn btn-small button" href="https://www.timetap.com/businessWeb/web/signup/TEAMBUSM">Sign Up Free</a>
           </div>
           <div class="col-md-2 col-md-offset-1 text-center margin-three xs-margin-bottom-ten small-mobile-hide">
               <em class="icon-calendar medium-icon black-text display-block"></em>
               <h3 class="font-weight-600 margin-five no-margin-bottom" style={{fontSize: "24px"}}>4,000,000+</h3>
               <p class="text-uppercase black-text letter-spacing-2 text-small margin-three">Appointments Booked</p>
           </div>
           <div class="col-md-2 text-center margin-three xs-margin-bottom-ten small-mobile-hide">
               <em class="icon-briefcase medium-icon black-text display-block"></em>
               <h3 class="font-weight-600 margin-five no-margin-bottom" style={{fontSize: "24px"}}>1,000+</h3>
               <p class="text-uppercase black-text letter-spacing-2 text-small margin-three">TimeTap Businesses</p>
           </div>
           <div class="col-md-2 text-center margin-three small-mobile-hide">
               <em class="icon-happy medium-icon black-text display-block"></em>
               <h3 class="font-weight-600 margin-five no-margin-bottom" style={{fontSize: "24px"}}>1,700,000+</h3>
               <p class="text-uppercase black-text letter-spacing-2 text-small margin-three">Clients Happily Serviced</p>
           </div>
       </div>
   </div>
</section>

<section class="bg-medium2-gray-tt padding-bottom-75 industries-descriptions h2-adjust h4-adjust">
<div class="container">


<div class="row" id='salonbeauty'>
<div class="col-md-5 col-md-offset-1 col-sm-6 col-xs-6 text-center xs-margin-bottom-ten">
<img src="images/services/saloon-beauty.jpg" alt="Easy scheduling for salon appointments" />
    
</div>
<div class="col-md-5 col-sm-6 col-xs-6">
    <h4>Salons & Beauty</h4>
    <div class="separator-line bg-black no-margin-lr"></div>
    <p class="text-med width-90">Hair Salons, Nail Salon, Hair Braiding, Tanning Salons, Bridal salons</p>
    <a href="industries/beauty-wellness-web-scheduler.html" class="btn-tt-lightbkgrd-large btn btn-medium no-margin-top no-margin-right inner-link">Learn More</a>
</div>
</div>
<div class="clear padding-bottom-75"></div>


<div class="row" id='barbershops'>
<div class="col-md-5 col-md-offset-1 col-sm-6 col-xs-6 text-center xs-margin-bottom-ten">
<img src="images/services/barber_.jpg" alt="Easy scheduling for salon appointments" />
    {/*<img src="https://9a812d2609e610ab07eb-b463fa4ca2c8095be4f297e4d7f6781b.ssl.cf2.rackcdn.com/beauty-salon-scheduling.png" alt="Easy scheduling for salon appointments"/> */}
</div>
<div class="col-md-5 col-sm-6 col-xs-6">
    <h4>Barbershops</h4>
    <div class="separator-line bg-black no-margin-lr"></div>
    <p class="text-med width-90">Haircut, Beards cut, Trimming.</p>
    <a href="industries/beauty-wellness-web-scheduler.html" class="btn-tt-lightbkgrd-large btn btn-medium no-margin-top no-margin-right inner-link">Learn More</a>
</div>
</div>
<div class="clear padding-bottom-75"></div>


<div class="row" id='creative'>
<div class="col-md-5 col-md-offset-1 col-sm-6 col-xs-6 text-center xs-margin-bottom-ten">
<img src="images/services/creative-services.jpg" alt="Easy scheduling for salon appointments" />
   
</div>
<div class="col-md-5 col-sm-6 col-xs-6">
    <h4>Creative</h4>
    <div class="separator-line bg-black no-margin-lr"></div>
    <p class="text-med width-90">Photography, Tattoo Studio</p>
    <a href="industries/beauty-wellness-web-scheduler.html" class="btn-tt-lightbkgrd-large btn btn-medium no-margin-top no-margin-right inner-link">Learn More</a>
</div>
</div>
<div class="clear padding-bottom-75"></div>

    <div class="row" id='well-being-and-fitness'>
        <div class="col-md-5 col-md-offset-1 col-sm-6 col-xs-6 text-center xs-margin-bottom-ten">
        <img src="images/services/fitness-trainer.jpg" alt="Easy scheduling for salon appointments" />
       
            
        </div>
        <div class="col-md-5 col-sm-6 col-xs-6">
            <h4>Wellbeing & Fitness</h4>
            <div class="separator-line bg-black no-margin-lr small-mobile-hide"></div>
            <p class="text-med width-90">Wellness, Spa, Massage Therapist, Gyms, Yoga, Acupuncture and Health Clubs.</p>
            <a href="industries/art-class-scheduling.html" class="btn-tt-lightbkgrd-large btn btn-medium no-margin-top no-margin-right inner-link">Learn More</a>
        </div>
    </div>
    <div class="clear padding-bottom-75"></div>

    <div class="row">
    <div class="col-md-5 col-md-offset-1 col-sm-6 col-xs-6 text-center xs-margin-bottom-ten">
    <img src="images/services/healthcare.png" alt="Easy scheduling for salon appointments" />
    
       
    </div>
    <div class="col-md-5 col-sm-6 col-xs-6">
        <h4>Healthcare</h4>
        <div class="separator-line bg-black no-margin-lr small-mobile-hide"></div>
        <p class="text-med width-90">Medical, Doctors, Dentists, Chiropractors, Opticians, Counselling, Physical Therapy, Vaccine Scheduling</p>
        <a href="industries/art-class-scheduling.html" class="btn-tt-lightbkgrd-large btn btn-medium no-margin-top no-margin-right inner-link">Learn More</a>
    </div>
</div>
<div class="clear padding-bottom-75"></div>
<div class="row" id='automotive'>
    <div class="col-md-5 col-md-offset-1 col-sm-6 col-xs-6 text-center xs-margin-bottom-ten">
       

        <img src="https://9a812d2609e610ab07eb-b463fa4ca2c8095be4f297e4d7f6781b.ssl.cf2.rackcdn.com/auto-mechanic-scheduling.png" alt="An easy online scheduling system for automotive businesses"/>
    </div>
    <div class="col-md-5 col-sm-6 col-xs-6">
        <h4>Automotive & Repair</h4>
        <div class="separator-line bg-black no-margin-lr small-mobile-hide"></div>
        <p class="text-med width-90">Auto Repair service, Auto tuning & custom work, Auto Body & glass repair, Car wash & detailing </p>
        <a href="industries/art-class-scheduling.html" class="btn-tt-lightbkgrd-large btn btn-medium no-margin-top no-margin-right inner-link">Learn More</a>
    </div>
</div>
<div class="clear padding-bottom-75"></div>


<div class="row" id='maintenanceandrepair'>
    <div class="col-md-5 col-md-offset-1 col-sm-6 col-xs-6 text-center xs-margin-bottom-ten">
       

        <img src="https://9a812d2609e610ab07eb-b463fa4ca2c8095be4f297e4d7f6781b.ssl.cf2.rackcdn.com/auto-mechanic-scheduling.png" alt="An easy online scheduling system for automotive businesses"/>
    </div>
    <div class="col-md-5 col-sm-6 col-xs-6">
        <h4>Maintenance & Repair Services</h4>
        <div class="separator-line bg-black no-margin-lr small-mobile-hide"></div>
        <p class="text-med width-90">Gadget repair, Plumbing, Carpentry, Electrical, Home appliances repair </p>
        <a href="industries/art-class-scheduling.html" class="btn-tt-lightbkgrd-large btn btn-medium no-margin-top no-margin-right inner-link">Learn More</a>
    </div>
</div>


<div class="clear padding-bottom-75"></div>


<div class="row" id='education'>
    <div class="col-md-5 col-md-offset-1 col-sm-6 col-xs-6 text-center xs-margin-bottom-ten">

    <img src="images/services/education.jpg" alt="Easy scheduling for salon appointments" />
       

       
    </div>
    <div class="col-md-5 col-sm-6 col-xs-6">
        <h4>Education</h4>
        <div class="separator-line bg-black no-margin-lr small-mobile-hide"></div>
        <p class="text-med width-90">Colleges & Universities, Schools, Tutoring, Driving Schools, Dance classes, Cooking lessons, Interview Scheduling </p>
        <a href="industries/art-class-scheduling.html" class="btn-tt-lightbkgrd-large btn btn-medium no-margin-top no-margin-right inner-link">Learn More</a>
    </div>
</div>


<div class="clear padding-bottom-75"></div>


<div class="row" id='professionalservices'>
    <div class="col-md-5 col-md-offset-1 col-sm-6 col-xs-6 text-center xs-margin-bottom-ten">
       

    <img src="images/services/professional_services.png" alt="Easy scheduling for salon appointments" />
    </div>
    <div class="col-md-5 col-sm-6 col-xs-6">
        <h4>Professional Services</h4>
        <div class="separator-line bg-black no-margin-lr small-mobile-hide"></div>
        <p class="text-med width-90">Legal, Financial services, Tax Consultants, Photography Studios, Business Coaching </p>
        <a href="industries/art-class-scheduling.html" class="btn-tt-lightbkgrd-large btn btn-medium no-margin-top no-margin-right inner-link">Learn More</a>
    </div>
</div>


<div class="clear padding-bottom-75"></div>


<div class="row" id='momecareservices'>
    <div class="col-md-5 col-md-offset-1 col-sm-6 col-xs-6 text-center xs-margin-bottom-ten">
       

    <img src="images/services/homecare.png" alt="Easy scheduling for salon appointments" />
    </div>
    <div class="col-md-5 col-sm-6 col-xs-6">
        <h4>Homecare Services</h4>
        <div class="separator-line bg-black no-margin-lr small-mobile-hide"></div>
        <p class="text-med width-90">Nanny Services, House moving, House cleaning services, Lawn care services</p>
        <a href="industries/art-class-scheduling.html" class="btn-tt-lightbkgrd-large btn btn-medium no-margin-top no-margin-right inner-link">Learn More</a>
    </div>
</div>

  
  
  
</div>
</section>

</div>
    
    
  )
}
