import React from 'react'

function SideBarMenu() {
  return (
    <div class="col-lg-3 order-2 card light-shadow order-lg-1"  data-aos="fade-right" data-aos-delay="50">
    <div class="sticky-panel-top">
    <h4 class="font-weight-bold text-center mb-1">Features</h4>

        <ul class="list-unstyled mb-0">
      

            <li> <a class="link-unstyled d-block py-2 font-weight-medium text-muted font-size-sm" href="#about"><em class="fa fa-check"></em>Online Booking </a></li>
            <li><a class="link-unstyled d-block py-2  font-weight-medium text-muted font-size-sm" href="#appointment-scheduling"><em class="fa fa-check"></em>Appointment Scheduling</a></li>
            <li><a class="link-unstyled d-block py-2  font-weight-medium text-muted font-size-sm" href="#notification"><em class="fa fa-check"></em>Notifications & Reminders</a></li>
            <li><a class="link-unstyled d-block py-2 font-weight-medium text-muted font-size-sm" href="#integrations"><em class="fa fa-check"></em>Integrations</a></li>
            <li><a class="link-unstyled d-block py-2 font-weight-medium text-muted font-size-sm" href="#repeat-clients"><em class="fa fa-check"></em>Repeat Clients</a></li>
            <li><a class="link-unstyled d-block py-2 font-weight-medium text-muted font-size-sm" href="#marketing"><em class="fa fa-check"></em>Marketing</a></li>
            <li><a class="link-unstyled d-block py-2 font-weight-medium text-muted font-size-sm" href="#book-keeping"><em class="fa fa-check"></em>Book Keeping</a></li>
            <li><a class="link-unstyled d-block py-2 font-weight-medium text-muted font-size-sm" href="#analytics-reports"><em class="fa fa-check"></em>Analytics & Reports </a></li>
            <li><a class="link-unstyled d-block py-2 font-weight-medium text-muted font-size-sm" href="#feedback-system"><em class="fa fa-check"></em>Feedback System </a></li>
            <li><a class="link-unstyled d-block py-2 font-weight-medium text-muted font-size-sm" href="/multi-location"><em class="fa fa-check"></em>Manage Multi-Location </a></li>
            <li><a class="link-unstyled d-block py-2 font-weight-medium text-muted font-size-sm" href="/sell-online"><em class="fa fa-check"></em>Sell Online</a></li>
            <li><a class="link-unstyled d-block py-2 font-weight-medium text-muted font-size-sm" href="/business-intelligence"><em class="fa fa-check"></em>Business Intelligence</a></li>
          
        </ul>
    </div>
</div>
  )
}

export default SideBarMenu