import React , {Component} from 'react';
import axios from 'axios';
import './login.css';
import SignIn from './Home/Homecomponents/SignIn';



export default class  Login extends Component{


    render(){

        return(
            <div className="auth-wrapper">
            <div className="auth-inner">
          
                <SignIn/>

            </div>
        </div>         
        )

    }
        
}
