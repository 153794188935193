import Navbar from "./components/Navbar/Navbar";
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "../src/components/Pages/login.css"
import  { BrowserRouter as Router, Routes, Switch, Route} from 'react-router-dom'
import  Create  from "./components/Pages/Create";
import Login from './components/Pages/Login';
import Contact from './components/Pages/Contact'
import Home from './components/Pages/Home/Home'
import Footer from "./components/Footer/Footer";
import  Dashboard from "./components/Dashboard/Dashboard";
import {Helmet} from 'react-helmet';

import {useState,useEffect} from "react";
import axios from 'axios';
import { AuthContext } from './helpers/AuthContext';
import Pricing from "./components/Pages/Home/Pricing";
import HelpAndSupport from "./components/Pages/Support/HelpAndSupport";
import ContactUs from "./components/Pages/ContactUs/ContactUs";
import AboutUs from "./components/Pages/Aboutus/AboutUs";
import SignUpPage from "./components/Pages/Home/SignUpPage";
import SignInPage from "./components/Pages/Home/SignInPage";
import Customers from "./components/Dashboard/Customers";
import Account from "./components/Dashboard/Account";
import Staff from "./components/Dashboard/Staff";
import  Services from "./components/Dashboard/Services";
import Addservices from "./components/Dashboard/Addservices";
import BusinessHours from "./components/Dashboard/BusinessHours";
import Company from "./components/Dashboard/Company";
import Features from "./components/Pages/Features/Features";
import Industries from "./components/Pages/Industries/Industries";
import HairAndSalon from "./components/Pages/Industries/Beauty/HairAndSalon";
import HealthCare from "./components/Pages/Industries/HealthCare/HealthCare";
import Fitness from "./components/Pages/Industries/Fitness/Fitness";
import ArtsAndRecreation from "./components/Pages/Industries/ArtsAndRecreation/ArtsAndRecreation";
import NewDashboard from "./components/Dashboard/NewDashboard";
import OnlineBooking from "./components/Pages/Features/OnlineBooking/OnlineBooking";
import AppointmentScheduling from "./components/Pages/Features/AppointmentScheduling/AppointmentScheduling";
import NotificationsAndReminders from "./components/Pages/Features/NotificationsAndReminders/NotificationsAndReminders";
import Integrations from "./components/Pages/Features/Integrations/Integrations";
import RepeatClients from "./components/Pages/Features/RepeatClients/RepeatClients";
import Marketing from "./components/Pages/Features/Marketing/Marketing";
import BookKeeping from "./components/Pages/Features/BookKeeping/BookKeeping";
import AnalyticsAndReports from "./components/Pages/Features/AnalyticsAndReports/AnalyticsAndReports";
import FeedBackSystem from "./components/Pages/Features/FeedBackSystem/FeedBackSystem";
import MultiLocation from "./components/Pages/Features/MultiLocation/MultiLocation";
import OnlineSale from "./components/Pages/Features/OnlineSale/OnlineSale";
import BusinessIntelligence from "./components/Pages/Features/BusinessIntelligence/BusinessIntelligence";
import Calendar from "./components/Dashboard/Calendar";
import ListUsers from "./components/Dashboard/Users/ListUsers";
import UserAccount from "./components/Dashboard/Users/UserAccount";
import Notifications from "./components/Dashboard/Users/Notifications";
import UserProfile from "./components/Dashboard/User/UserProfile";
import MyCustomers from "./components/Dashboard/Customers/MyCustomers";
import MyStaff from "./components/Dashboard/Staff/MyStaff";
import MyProducts from "./components/Dashboard/Products/MyProducts";
import AccountSetting from "./components/Dashboard/Users/AccountSetting";
import SearchResults from "./components/Pages/SearchResults/SearchResults";
import SellerDetails from "./components/Pages/SellerDetails/SellerDetails";
import CustomerDashboard from "./components/Dashboard/Customers/CustomerDashboard";
import AddFarmer from "./components/Dashboard/Users/AddFarmer";
import AddFarmers from "./components/Dashboard/Farmers/AddFarmers";
import FarmerDashboard from "./components/Dashboard/Farmers/FarmerDashboard";
import ManageProducts from "./components/Dashboard/Farmers/ManageProducts";
import CustomerProfile from "./components/Dashboard/Customers/CustomerProfile";
import CustomerAccountSetting from "./components/Dashboard/Customers/CustomerAccountSetting";
import ProfileInfo from "./components/Dashboard/Customers/ProfileInfo";
import FProfileInfor from "./components/Dashboard/Farmers/FProfileInfor";
import AccountSettingFarmer from "./components/Dashboard/Farmers/AccountSettingFarmer";
import MapContainer from "./components/Pages/Home/MapContainer ";
import UserMap from "./components/Pages/Home/UserMap";
import OrderDetails from "./components/Dashboard/Farmers/OrderDetails";
import { OrderDetailsDataProvider } from "./helpers/OrderDetailsContext";
import BusinessAccountSetting from "./components/Dashboard/BusinessAccountSetting";
import SearchLogs from "./components/Dashboard/Farmers/SearchLogs";


import API from './services';
import Income from "./components/Dashboard/Farmers/Income";
import Delivery from "./components/Dashboard/Customers/Delivery";
import Delivrypage from "./components/Dashboard/Customers/Deliverypage";
import Mapps from "./components/Dashboard/Customers/MapsLocation";
import MapsLocation from "./components/Dashboard/Customers/MapsLocation";
import TransporterDashboard from "./components/Dashboard/Customers/TransporterDashboard";
import DispatchDashboard from "./components/Dashboard/Dispatchers/DispatchDashboard";



function App() {

  const [authState, setAuthState] = useState({
    username:"",
    first_name:"",
    phone_no:"",
    role:"",
    id:0,
    status:false,
  });

  useEffect(()=>{
    API.get('/users/auth',{headers:{
      //axios.get('http://localhost:3001/users/auth',{headers:{
      accessToken:localStorage.getItem("accessToken"),
    }}).then((response)=>{

    if(response.data.error){
      setAuthState({...authState,status:false});
    }
    else{
      setAuthState({
        username:response.data.username,
        role:response.data.role,
        first_name:response.data.first_name,
        phone_no:response.data.phone_no,
        id:response.data.id,
        status:true,

      });

      console.log("USER DETAILS",authState);
    }
      
    })
   
 },[]);

 const logout = () => {
  localStorage.removeItem("accessToken");
  setAuthState({ username: "", id: 0, status: false });
};
  
 
  return (
    <div className="container_fluid overflow-hidden">

    <AuthContext.Provider value={{ authState, setAuthState }}>

    

    <OrderDetailsDataProvider>
       
    <Router>
  
    <Routes>
     
        <Route path="/dashboard" element={ <Dashboard/>} />
    </Routes>
        <div>
      
      
    <Routes>
   
        <Route path="/" element={ <Home/>} />
        <Route path="/pricing" element={ <Pricing/>} />
        <Route path="/support" element={ <HelpAndSupport/>} />

        <Route path="/about-us" element={ <AboutUs/>} />

        <Route path="/contact-us" element={ <ContactUs/>} />

        <Route path="/start-now" element={ <SignUpPage/>} />
       
        <Route path="/contact" element={ <Contact/>} />
        <Route path="/login" element={<SignInPage/>} />
        <Route path="/create" element={<Create/>}/>


        <Route path="/customer" element={ <Customers/>} />
        <Route path="/customer/:id" element={ <Customers/>} />
        <Route path="/account" element={ <Account/>} />

        

        <Route path="/staff" element={ <Staff/>} /> 

        <Route path="/my-staff" element={ <MyStaff/>} /> 

        <Route path="/my-customers" element={ <MyCustomers/>} /> 

        <Route path="/services" element={ <Services/>} />

        <Route path="/add-services" element={ <Addservices/>} />

        <Route path="/business_hours" element={ <BusinessHours/>} />
        <Route path="/staff/:id" element={ <Staff/>} />

        <Route path="/company_details" element={ <Company/>} />

        <Route path="/features" element={ <Features/>} />
        <Route path="/industries" element={ <Industries/>} />

        <Route path="/tunep-heath-care" element={ <HealthCare/>} />

        <Route path="/hair-and-salon" element={ <HairAndSalon/>} />


        <Route path="/well-being-and-fitness" element={ <Fitness/>} />

        <Route path="/tunep-arts-recreation" element={ <ArtsAndRecreation/>} />

        <Route path="/new-dashboard" element={ <NewDashboard/>} />


        <Route path="/account-setting" element={ <AccountSetting/>} />


        <Route path="/customer-dashboard" element={ <CustomerDashboard/>} />
        <Route path="/transporter-dashboard" element={ <TransporterDashboard/>} />
        <Route path="/customer-delivery" element={ <Delivrypage/>} />
        <Route path="/trackproduct" element={ <MapsLocation/>} />




        <Route path="/enroll-farmer" element={ <AddFarmers/>} />


        <Route path="/farmer-dashboard" element={ <FarmerDashboard/>} />


        <Route path="/markets" element={ <SearchLogs/>} />


        <Route path="/income" element={ <Income></Income>} />

        <Route path="/my-produce" element={ <ManageProducts/>} />


        <Route path="/profile-customer" element={ <CustomerProfile/>} />



        <Route path="/customer-account" element={ <CustomerAccountSetting/>} />


        <Route path="/dispatch-home" element={ <DispatchDashboard/>} />


        <Route path="/cust-profile" element={ <ProfileInfo/>} />



        <Route path="/profile-farmer" element={ <FProfileInfor/>} />



        <Route path="/farmer-account-settings" element={ <AccountSettingFarmer/>} />





 
        <Route path="/map-test" element={ <MapContainer/>} />

       

        <Route path="/calendar" element={ <Calendar/>} />



        <Route path="/list-users" element={ <ListUsers/>} />

        <Route path="/user-account" element={ <UserAccount/>} />


        <Route path="/user-notifications" element={ <Notifications/>} />


        <Route path="/profile" element={ <UserProfile/>} />


        <Route path="/my-products" element={ <MyProducts/>} />

        <Route path="/account-settings" element={ <BusinessAccountSetting/>} />


        <Route path="/order_details/:id" element={ <OrderDetails/>} />



        <Route path="/search-item/:item/:string_lng" element={ <SearchResults/>} />


        <Route path="/seller-details/:id/:pid" element={ <SellerDetails/>} />
        <Route path="/track-order" element={ <Mapps/>} />


        <Route path="/online-booking" element={ <OnlineBooking/>} />

        <Route path="/appointment-scheduling" element={ <AppointmentScheduling/>} />

        <Route path="/notifications-and-reminders" element={ <NotificationsAndReminders/>} />

        <Route path="/integrations" element={ <Integrations/>} />

        <Route path="/repeat-clients" element={ <RepeatClients/>} />

        <Route path="/marketing" element={ <Marketing/>} />

        <Route path="/book-keeping" element={ <BookKeeping/>} />

        <Route path="/analytics-reports" element={ <AnalyticsAndReports/>} />

        <Route path="/feedback-system" element={ <FeedBackSystem/>} />

        <Route path="/multi-location" element={ <MultiLocation/>} />

        <Route path="/sell-online" element={ <OnlineSale/>} />

        <Route path="/business-intelligence" element={ <BusinessIntelligence/>} />


        <Route path="/user-map" element={ <UserMap/>} />




      

        
    </Routes>
        <Footer />
        </div>
       
       
    
      </Router>
      </OrderDetailsDataProvider>

    </AuthContext.Provider>
 

    </div>
 
 
   
  );
}

export default App;
