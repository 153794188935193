

import React from 'react';

import Section2 from '../Home/Section2';
import Section3 from '../Home/Section3';
import Section4 from '../Home/Section4';

//import Header from '../../Header/Header';
import Header from '../../Header/HeaderOld';

import MainContentSection from './MainContentSection';
import HeaderNew from '../../Header/HeaderNew';
import { Widget } from 'react-chat-widget';
import HeaderFloating from '../../Header/HeaderFloating';

function ContactUs(){
    return (
        <div>
           

          <HeaderFloating/>

          <MainContentSection/>
          <Widget/>
          
            
             
           
        </div>
    )


}

export default ContactUs;