
import React, { useCallback,useState,useEffect,useContext } from 'react';

import {useNavigate} from 'react-router-dom'

import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

import API from '../../../services';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';


  //import {pluscodes} from 'pluscodes'


  function truncate(number, index = 2) {
  	// cutting the number
    return +number.toString().slice(0, (number.toString().indexOf(".")) + (index + 1));
}




function SearchSectionNgerit(props) {

    const [item, setItem] = useState("");

    const [geo_loc, setGeo_loc] = useState("");




    const [city, setCity] = useState("");
    const [state, setState] = useState("");
  
    const [country, setCountry] = useState("");


    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [address_line_1, setaddress_line_1] = useState("");
    const [address_line_2, setaddress_line_2] = useState("");

    const [postal_code, setPostal_code] = useState("");


  
      const [showingInfoWindow, setShowingInfoWindow] = useState(false);

      const [activeMarker, setActiveMarker] = useState({});
      const [selectedPlace, setSelectedPlace] = useState({});


      const [lat, setLat] = useState(null);
      const [lng, setLng] = useState(null);

      const [loclat, setLoclat] = useState(null);
      const [status, setStatus] = useState(null);
  
  
    const [address, setAddress] = React.useState("");
    const [mapCenter, setMapCenter] = useState({
        lat: -1.3031246,
        lng: 36.9200891
    });

    


    const [isLoading,setLoading]=useState(false);

    const history = useNavigate();


   // pluscodes({ setLat: 59.332438, setLng: 18.118813 })






    useEffect(()=>{


      if (!navigator.geolocation) {
        setStatus('Geolocation is not supported by your browser');
      } else {
              setStatus('Locating...');
              navigator.geolocation.getCurrentPosition((position) => {
                  setStatus(null);
                  // setLat(position.coords.latitude);
                  // setLng(position.coords.longitude);

                  setLatitude(position.coords.latitude)
                  setLongitude(position.coords.longitude)

                  setMapCenter({lat:position.coords.latitude,lng:position.coords.longitude})

                  getReverseGeocodingData(position.coords.latitude,position.coords.longitude)


                  const geoApi=`https://api.bigdatacloud.net/data/reverse-geocode-with-timezone?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en&key=9c255dc5e7f944ffb0df249d6588363c`

                  fetch(geoApi).then(res=>res.json()).then(data=>{
                    console.log("MY LOCATION IS "+data.plusCode)
                    console.log("MY CITY IS "+data.city)
                    setAddress(data.plusCode)

                    console.log("THE PLUS CODE ADDRESS IS " +data.compoundAddress);

                  }).catch(() => {
                    console.log('Unable to fetch your products list');
                    // setIsDivLoading(false);
                 });
          



              }, () => {
                  setStatus('Unable to retrieve your location');
              });
          }


       

  
  },[]);


  console.log("THE LONGITUDE ADDRESS IS "+parseFloat(lng))

  let lng_float=parseFloat(lng);

  let text = lng_float.toString(3);

  console.log("THE text is now  "+truncate(lng_float, 2))

  console.log("THE FORMATED LONGITUDE ADDRESS NOW IS "+parseFloat(lng_float).toFixed(2))


 let lng_rounded=parseFloat(lng_float).toFixed(3)



 let string_lng=truncate(lng_float, 2)




 console.log("THE FORMATED LONGITUDE IN STRING FORMAT "+string_lng)







  const getLocation = () => {
    if (!navigator.geolocation) {
  setStatus('Geolocation is not supported by your browser');
} else {
        setStatus('Locating...');
        navigator.geolocation.getCurrentPosition((position) => {
            setStatus(null);
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        }, () => {
            setStatus('Unable to retrieve your location');
        });
    }
}






  const getReverseGeocodingData=(lat, lng)=> {
    var latlng = new props.google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new props.google.maps.Geocoder();
    geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
        if (status !== props.google.maps.GeocoderStatus.OK) {
            alert(status);
        }
        // This is checking to see if the Geoeode Status is OK before proceeding
        if (status == props.google.maps.GeocoderStatus.OK) {
            console.log(results);
          //  var address = (results[0].formatted_address);

          //  var city = (results[1].formatted_address);


          //  setAddress(address)

          //    setCity(address)

          //   setState(address)

            
          //  console.log("THE PLUS CODE ADDRESS IS " +address);

          //   console.log("THE ADDRESS IS " +address);

          //  console.log("THE CITY IS " +city);

            
        }
    });
}



    const handleChange = address => {
        setAddress(address);
          };
  
  
  
          
        const handleSelect = address => {
          setAddress(address);
           geocodeByAddress(address)
            .then(results =>  getLatLng(results[0]))
            .then(latLng => {
              console.log('Success', latLng);
              
              // const address_one = address.results[0].formatted_address;
             
      
              // console.log('Formated Addres', address_one);


              setLat(latLng.lat)
              setLng(latLng.lng)

 
              console.log('THE LATITUDE IS', latLng.lat);

              console.log('THE LONGITUDE IS', latLng.lng);
      
      
              // update center state
              setMapCenter(latLng);
      
              setaddress_line_2(address);
      
              
             
            });
            geocodeByAddress(address).then(response=>{
      
            
              var add= response[0].formatted_address ;
              var  value=add.split(",");
        
            let  count=value.length;
            let  country=value[count-1];
            let   state=value[count-2];
            let  city=value[count-3];
            let  postal_code=value[count-4];
      
           
        
              console.log('COUNTRY'+country);
              console.log('CITY'+city);
              console.log('STATE'+state);
              console.log('ZIP CODE'+postal_code);
      
              // console.log('THE ID IS'+propid);
      
      
              setCity(city);
      
              setPostal_code(postal_code);
      
              setState(state);
      
            
            
        
             // console.log('ADDRESS COMPONENTS',addressComponent[2]);
              
            })
            .catch(error => console.error('Error', error));
        };



    const searchItem = () => {
    setLoading(true);
   
    
    setTimeout(() => {
      setLoading(false);
      //setAddress(string_lng)
     // history.push('/search-location-avon-park-florida');
     history(`/search-item/${item}/${string_lng}`);
    }, 3000);
    
      };


    
  return (
    <div>
    
    <section class="parallax-banner py-6 py-lg-8"  style={{marginTop:'80px',height:'500px' }}>
    <div class="parallax-banner-wrapper">
      
        <img class="parallax-banner-img" src="images/people/team/home_reg_n.jpeg" alt="image"/>
    </div>

    <div class="parallax-content">
        <div class="container">
            <div class="row mb-5 justify-content-center text-center">
                <div class="col">
                    <h1 class="font-weight-bold mb-0">What do you need within your area?</h1>
                    <p>Search and get any agricultural product</p>
                </div>
            </div>
           <button onClick={getLocation}>Get Location</button>  {lat && <p>Latitude: {latitude}</p>}
            {lng && <p>Longitude: {longitude}</p>}

            {/* <h1>Coordinates</h1>
                  <p>{status}</p> */}
            {/* {lat && <p>Latitude: {lat}</p>}
            {lng && <p>Longitude: {lng}</p>} */}

            <form class="form-row justify-content-center align-items-center">
                <div class="col-md-6 col-lg-5">
                    <div class="input-group mb-3">
                        <input type="search" class="form-control"

                        onChange={(event) => {
                            setItem(event.target.value);
                          }}
                        
                        
                        
                        placeholder="Search Item here..."/>

                            <PlacesAutocomplete

                          value={address}
                          onChange={handleChange}
                          onSelect={handleSelect}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>


                             
                              


                                <input
                                  class="form-control"  id="inputAddress" autocomplete="off"
                                  {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'multisteps-form__input form-control',
                                  })}


                                />














                            



                               <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span style={{ color: "#39ec83", backgroundColor: "#f9ce77", border: "1px", borderRadius: "5px" }}>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div> 
                            </div>
                          )}
                        </PlacesAutocomplete>

                      
                    
                    


                        <div class="input-group-append" role="button">

                         {/*  <input name="submit" type="submit" class="advanced_search_submit_button" value="Search" />*/}

        {!isLoading && <button type="submit" onClick={searchItem} class="advanced_search_submit_button" value="Search">Search</button>

    }
    {isLoading &&
      <button type="submit" class="advanced_search_submit_button" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Searching....</button>
    }
                        
                         
                        </div>

                    </div>
                    <div class="d-flex flex-wrap">
                        <span class="text-white font-size-sm my-1 mr-1">Quick topics :</span>
                        <span class="badge badge-soft-primary font-weight-semibold px-2 py-1 my-1 mr-1 small">Agriculture</span>
                        <span class="badge badge-soft-primary font-weight-semibold px-2 py-1 my-1 mr-1 small">Livestock</span>
                        <span class="badge badge-soft-primary font-weight-semibold px-2 py-1 my-1 small">Aquatic</span>
                        <span class="badge badge-soft-primary font-weight-semibold px-2 py-1 my-1 small">Others</span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
    
    
    </div>
  )
}


export default GoogleApiWrapper({
    apiKey: ('AIzaSyALtlAXEgqBUE9v3akrQsHfi-Cs_cPOJ0g')
  })(SearchSectionNgerit)

