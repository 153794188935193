
import React, { useCallback,useState,useEffect,useContext } from 'react';

import {useNavigate} from 'react-router-dom'

import {Helmet} from 'react-helmet'

function SearchSectionNgerit() {

    const [item, setItem] = useState("");

    const [geo_loc, setGeo_loc] = useState("");

    const [isLoading,setLoading]=useState(false);

    const history = useNavigate();



    const searchItem = () => {
    setLoading(true);
    
    setTimeout(() => {
      setLoading(false);
     // history.push('/search-location-avon-park-florida');
     history(`/search-item/${item}/${geo_loc}`);
    }, 3000);
    
      };


    
  return (
    <div>

    <Helmet>
    
    

    <link href="/assets_fheader/vendor/fontawesome-free/css/all.min.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/animate.css/animate.min.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/boxicons/css/boxicons.min.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/glightbox/css/glightbox.min.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/remixicon/remixicon.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/swiper/swiper-bundle.min.css" rel="stylesheet"/>
  
   
    <link href="/assets_fheader/css/style.css" rel="stylesheet"/>

    
    </Helmet>

    

    
    
    </div>
  )
}

export default SearchSectionNgerit