import React from 'react'

import { useEffect,useState,useContext} from 'react';


import Footer from '../Ffooter';

import axios from 'axios';

import { Helmet } from "react-helmet";

import {toast,ToastContainer,Zoom,Bounce} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from '../../../helpers/AuthContext'
import LoadingSpinner from '../../../utils/LoadingSpinner';
import SidebarFarmer from './Sidebar';
import TopBarFarmer from './TopBarFarmer';




import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import Geocode from "react-geocode";
import API from '../../../services';

function ManageProducts(props) {
    const {authState} = useContext(AuthContext);

    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [product_description, setProduct_description] = useState("");
    const [price, setPrice] = useState("");

    const [quantity, setQuantity] = useState("");

    const [geo_location, setGeo_location] = useState("");

    const [userId, setUserId] = useState("");
    const [retailerId, setRetailerId] = useState(1);

    const [unit_of_measure, setunit_of_measure] = useState("");


    const [city, setCity] = useState("");
    const [state, setState] = useState("");
  
    const [country, setCountry] = useState("");


    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [address_line_1, setaddress_line_1] = useState("");
    const [address_line_2, setaddress_line_2] = useState("");

    const [postal_code, setPostal_code] = useState("");


  
      const [showingInfoWindow, setShowingInfoWindow] = useState(false);

      const [activeMarker, setActiveMarker] = useState({});
      const [selectedPlace, setSelectedPlace] = useState({});

      const [lat, setLat] = useState(null);
      const [lng, setLng] = useState(null);

      const [loclat, setLoclat] = useState(null);
      const [status, setStatus] = useState(null);
  
  
    const [address, setAddress] = React.useState("");


    const [selectedFile, setSelectedFile] = useState();


   

  
    const [productsList, setProductsList] = useState([]);

    const [errorMessage, setErrorMessage] = useState("");

    const [isDivLoading, setIsDivLoading] = useState(false);

    const [isLoading,setLoading]=useState(false);


    const [mapCenter, setMapCenter] = useState({
      lat: 0,
      lng: 0
  
  });

  
  
  
    useEffect(()=>{
  
       //axios.get("https://ngeritbackend.herokuapp.com/product/getproducts").then((response) => {
        setIsDivLoading(true);


        API.get('/users/auth',{headers:{
          accessToken:localStorage.getItem("accessToken"),
        }}).then((response)=>{
      
      
      
             setUserId(response.data.id);

             //setUserId("THE LOGGED IN USER ID IS "+response.data.id);

             console.log("THE LOGGED IN USER ID IS "+response.data.id)
            
      
             console.log("THIS IS THE USER DATA "+response.data)
      
           });

        if (!navigator.geolocation) {
          setStatus('Geolocation is not supported by your browser');
        } else {
                setStatus('Locating...');
                navigator.geolocation.getCurrentPosition((position) => {
                    setStatus(null);
                    setLat(position.coords.latitude);
                    setLng(position.coords.longitude);

                    setMapCenter({lat:position.coords.latitude,lng:position.coords.longitude})

                    getReverseGeocodingData(position.coords.latitude,position.coords.longitude)



                    const geoApi=`https://api.bigdatacloud.net/data/reverse-geocode-with-timezone?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en&key=9c255dc5e7f944ffb0df249d6588363c`

                    fetch(geoApi).then(res=>res.json()).then(data=>{
                      console.log("MY LOCATION IS "+data.plusCode)
                   
                     // setaddress_line_2(data.plusCode)
  
                      //console.log("THE PLUS CODE ADDRESS IS " +address);
  
                    })



                }, () => {
                    setStatus('Unable to retrieve your location');
                });
            }

      
          
          
          
        
       
    
      //    axios.get("https://kilimomazaoapi-dmi-cyber.herokuapp.com/users/myproducts", {
      //    //axios.get("http://localhost:3001/users/myproducts", {
      //     headers: { accessToken: localStorage.getItem("accessToken")},
      //   })
      //   .then((response) => {
      //       setProductsList(response.data);

      //       console.log("THE SELLER DATA IS"+ response.data)

      //       setTimeout(() => {
      //         setIsDivLoading(false)   // Hide loading screen 
      //        // toast.info('Product saved successfully');
      //     }, 500);

         
      //   }).catch(() => {
      //     setErrorMessage("Unable to fetch your products list");
      //     setIsDivLoading(false);
      //  });


    //  axios.get('https://kilimomazaoapi-dmi-cyber.herokuapp.com/users/auth',{headers:{
    //        //axios.get('http://localhost:3001/users/auth',{headers:{
    //         accessToken:localStorage.getItem("accessToken"),
    //       }}).then((response)=>{
        
        
        
    //            setUserId(response.data.id);
              
        
    //            console.log("THIS IS THE USER DATA "+response.data)
        
    //          });



             
        API.get('/users/mybizz',{headers:{
          accessToken:localStorage.getItem("accessToken"),
        }}).then((response)=>{
      
      
      
             //setUserId(response.data.id);

             setRetailerId(response.data.my_buss.id)
            
      
             console.log("THE BUSINESS ID IS "+response.data.my_buss.id)
      
           });

    
        // API.get("https://kilimomazaoapi-dmi-cyber.herokuapp.com/users/myproducts", {
         API.get("/users/myproducts", {
          headers: { accessToken: localStorage.getItem("accessToken")},
        })
        .then((response) => {
            setProductsList(response.data);

            console.log("THE SELLER DATA IS"+ response.data)

            setTimeout(() => {
              setIsDivLoading(false)   // Hide loading screen 
             // toast.info('Product saved successfully');
          }, 1000);

         
        }).catch(() => {
          setErrorMessage("Unable to fetch your products list");
          setIsDivLoading(false);
       });


        // axios.get('https://kilimomazaoapi-dmi-cyber.herokuapp.com/users/auth',{headers:{
        



           


  
  },[]);



  localStorage.setItem('lat', JSON.stringify(lat));

  localStorage.setItem('lng', JSON.stringify(lng));


 
  
  console.log(lat)

  console.log(lng)



  let latde=localStorage.getItem('lat')
  latde=JSON.parse(latde)

  let lngtd=localStorage.getItem('lng')
  lngtd=JSON.parse(lngtd)




  console.log("THE LATITUDE IS fRoM STORANGE IS "+latde)


 // setLat(latde);

  



  

  
   



    const handleChange = address => {
      setAddress(address);
        };



      const handleSelect = address => {
        setAddress(address);
         geocodeByAddress(address)
          .then(results =>  getLatLng(results[0]))
          .then(latLng => {
            console.log('Success', latLng);
            
            // const address_one = address.results[0].formatted_address;
           
    
            // console.log('Formated Addres', address_one);


            setLat(latLng.lat)
              setLng(latLng.lng)
    
    
            // update center state
            setMapCenter(latLng );
    
            setaddress_line_2(address);
    
            
           
          });
          geocodeByAddress(address).then(response=>{
    
          
            var add= response[0].formatted_address ;
            var  value=add.split(",");
      
          let  count=value.length;
          let  country=value[count-1];
          let   state=value[count-2];
          let  city=value[count-3];
          let  postal_code=value[count-4];
    
         
      
            console.log('COUNTRY'+country);
            console.log('CITY'+city);
            console.log('STATE'+state);
            console.log('ZIP CODE'+postal_code);
    
            // console.log('THE ID IS'+propid);
    
    
            setCity(city);
    
            setPostal_code(postal_code);
    
            setState(state);
    
          
          
      
           // console.log('ADDRESS COMPONENTS',addressComponent[2]);
            
          })
          .catch(error => console.error('Error', error));
      };


      const getReverseGeocodingData=(lat, lng)=> {
        var latlng = new props.google.maps.LatLng(lat, lng);
        // This is making the Geocode request
        var geocoder = new props.google.maps.Geocoder();
        geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
            if (status !== props.google.maps.GeocoderStatus.OK) {
                alert(status);
            }
            // This is checking to see if the Geoeode Status is OK before proceeding
            if (status == props.google.maps.GeocoderStatus.OK) {
                console.log(results);
                var address = (results[0].formatted_address);

                var city = (results[1].formatted_address);


                setaddress_line_2(address)

                setCity(address)

                setState(address)


                console.log("THE ADDRESS IS " +address);

               // console.log("THE CITY IS " +city);

                
            }
        });
    }

    const data={
      name:name,
      type:type,
      product_description:product_description,
      price: price,
      quantity:quantity,
      geo_location:address_line_2,
      unit_of_measure:unit_of_measure,
      latitude:lat,
      longitude:lng,
      UserId:userId,
      businessId:retailerId,
        
    }
   
  


    const addDetails = ()  => {
      setLoading(true);

      // API.post('/product',data).then((response)=>{

      API.post('/images/save-product',data).then((response)=>{
      
    //axios.post("http://localhost:3001/product",data).then((response)=>{

    const newProduct = response.data;

    // Update the productsList state by adding the new product to the existing list
    setProductsList((prevProductsList) => [...prevProductsList, newProduct]);
       

      console.log("The response is"+response.data)

         
          setTimeout(() => {
              setLoading(false);
              toast.info('Product saved successfully');
          }, 3000);
       
         //  history("/dashboard");
        
         
      })

  }


  const handleServiceIconInputChange = (e) => {
    const file = e.target.files[0];
    //previewServiceIconFile(file);
    setSelectedFile(file);
    //setServiceIconInputState(e.target.value);
  };



  const saveDetails = async e => {
    setLoading(true)

    // if(name==""){
    //     setnameinvalid(true)
    //     setLoading(false)

    //     setTimeout(() => {
    //         setnameinvalid(false)
           
    //      }, 2000);
    //     return
    // }

    // if(price==""){
    //     setpriceinvalid(true)
    //     setLoading(false)

    //     setTimeout(() => {
    //         setpriceinvalid(false)
           
    //      }, 2000);
    //     return
    // }



   
    
    let formData = new FormData();
    formData.append('businessId', retailerId);
    formData.append('file',selectedFile);
    formData.append('name', name);


    formData.append('product_description', product_description);
    formData.append('price',price);
    formData.append('quantity', quantity);

    formData.append('type',type);
    // formData.append('address_line_2', address_line_2);

    formData.append('unit_of_measure',unit_of_measure);
    formData.append('latitude', lat);

    formData.append('longitude', lng);

    formData.append('UserId', userId);

   


   
    let { data } = await API.post('images/save-product', formData, {});


    console.log(data)
    


    
    





   


    





    setTimeout(() => {

    
        
        setLoading(false);
        //setShowActionBtn(false)
        //setShowSucessAlert(true)
        //sethidesavebtn(true)
        
        toast.success('Product saved successfully');
    }, 3000);
    
}






    const renderUserProducts = (

      <div class="table-responsive text-nowrap">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Name</th>
       
          <th>Images</th>
          <th>Quantity</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
      {productsList.map((value, key) => {
        return (
        <tr>
          <td> <strong>{value.name}</strong></td>
        
          <td>
            <ul class="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
              <li data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" class="avatar avatar-xs pull-up" title="Lilian Fuller">
                <img src={value.cloudinary_url} alt="Avatar" class="rounded-circle"/>
              </li>
              <li data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" class="avatar avatar-xs pull-up" title="Sophia Wilkerson">
                <img src={value.cloudinary_url} alt="Avatar" class="rounded-circle"/>
              </li>
              <li data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" class="avatar avatar-xs pull-up" title="Christina Parker">
                <img src={value.cloudinary_url} alt="Avatar" class="rounded-circle"/>
              </li>
            </ul>
          </td>
          <td><span class="badge bg-label-primary me-1">{value.quantity}</span></td>
          <td><span class="badge bg-label-primary me-1">{value.status}</span></td>
          <td>
            <div class="dropdown">
              <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown"><i class="bx bx-dots-vertical-rounded"></i></button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="javascript:void(0);"><i class="bx bx-edit-alt me-1"></i> Edit</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="bx bx-trash me-1"></i> Delete</a>
              </div>
            </div>
          </td>
        </tr>
        );
    })}
        
       
        
      </tbody>
    </table>
  </div>



    )
  return (
    <div class="card h-100">
    <div class="card-header">
      <h3 class="card-title mb-2">#Products</h3>


      <div className="pt-2 pb-2 pl-3 pr-3 d-flex align-items-center o-hidden box-shadow-1 chat-topbar p-4" style={{backgroundColor:"aliceblue"}}><a className="link-icon d-md-none" data-sidebar-toggle="chat"><i className="icon-regular ml-0 mr-3 i-Left" /></a>
      <div class="mb-0">
          <button class="btn btn-primary" data-repeater-create data-bs-toggle="modal" data-bs-target="#modalCenter">
            <i class="bx bx-plus"></i>
            <span class="align-middle">Add</span>
          </button>
        </div>
  </div>
     
    </div>
    <div class="card-body">
    <div class="nav-align-left">
                   
    <ul class="nav nav-pills w-100 gap-1">

    {productsList.map((value, key) => {
      return (
        <li class="nav-item">
        <button class="nav-link" data-bs-target="javascript:void(0);">{value.name}</button>
      </li>

      );
  })}
     
      
     
     
    </ul>
  </div>


  <div class="modal fade" id="modalCenter" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content mb-8">
      <div class="modal-header">
        <h5 class="modal-title" id="modalCenterTitle">Add Your Product</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col mb-3">
            <label for="nameWithTitle" class="form-label">Product Name</label>

           

            
            <input type="text" id="nameWithTitle" class="form-control" placeholder="Enter Name"
            
            onChange={(event) => {
                setName(event.target.value);
              }}
               
            />
          </div>

          <div class="col mb-3">
          <label class="form-label" for="multicol-country">Type</label>
          <select id="multicol-country" class="form-control select2 form-select"   
          onChange={(event) => {
            setType(event.target.value);
          }}
          
          data-allow-clear="true">
            <option value="">Select Type</option>
            <option value="Agricultural">Agricultural</option>
            <option value="Livestock">Livestock</option>
            <option value="Aquatic">Aquatic</option>
           
            
          </select>
        </div>
        </div>
        <div class="row g-2">
          <div class="col mb-0">
            <label for="description" class="form-label">Description</label>
            

               <textarea name="address" class="form-control"   onChange={(event) => {
                setProduct_description(event.target.value);
              }} id="address" rows="2" placeholder="Your Product desciption"></textarea>
          </div>
       
        </div>

                            <div class="row g-2">
                              <div class="col mb-0">
                                <label for="description" class="form-label">Quantity</label>

                                <input type="number" id="price" class="form-control"

                                  onChange={(event) => {
                                    setQuantity(event.target.value);
                                  }}
                                />

                              </div>

                              <div class="col mb-0">
                                <label for="description" class="form-label">Unit of Measure</label>

                                <select id="multicol-country" class="form-control select2 form-select"
                                  onChange={(event) => {
                                    setunit_of_measure(event.target.value);
                                  }}

                                  data-allow-clear="true">
                                  <option value="Per Item">select how you measure</option>
                                  <option value="Kgs">Kgs</option>
                                  <option value="Piece">Piece</option>
                                  <option value="Crates">Crates</option>
                                  <option value="Sacks">Sacks</option>
                                  <option value="Litres">Litres</option>
                                  <option value="None">None</option>


                                </select>

                              </div>
                              <div class="col mb-0">
                                <label for="dobWithTitle" class="form-label">Price(Per unit)</label>
                                <input type="number" id="price" class="form-control"

                                  onChange={(event) => {
                                    setPrice(event.target.value);
                                  }}


                                />
                              </div>
                            </div>

     


                            <div class="row g-2">
                              <div class="col mb-0">
                                <label for="description" class="form-label">Location</label>


                                <div id='googleMaps'>
                                  <PlacesAutocomplete

                                    value={address}
                                    onChange={handleChange}
                                    onSelect={handleSelect}
                                  >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                      <div>


                                        <div class="form-group">
                                          <label for="inputAddress">Address Line 1</label>


                                          <input
                                            class="form-control"  id="inputAddress" autocomplete="off"
                                            {...getInputProps({
                                              placeholder: 'Search Places ...',
                                              className: 'multisteps-form__input form-control',
                                            })}


                                          />
                                        </div>



                                        <div className="autocomplete-dropdown-container">
                                          {loading && <div>Loading...</div>}
                                          {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                              ? 'suggestion-item--active'
                                              : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                              <div
                                                {...getSuggestionItemProps(suggestion, {
                                                  className,
                                                  style,
                                                })}
                                              >
                                                <span style={{ color: "blue", backgroundColor: "#E5E4E2", border: "1px", borderRadius: "1px" }}>{suggestion.description}</span>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>
                              </div>

                              </div>

                              <div class="row g-2">
                            

                                  <div class="col" style={{ height: "200px", padding: "5px" }}>

                                    <Map
                                      google={props.google}
                                      initialCenter={{
                                        lat: mapCenter.lat,
                                        lng: mapCenter.lng
                                      }}
                                      center={{
                                        lat: mapCenter.lat,
                                        lng: mapCenter.lng
                                      }}
                                    >
                                      <Marker
                                        position={{
                                          lat: mapCenter.lat,
                                          lng: mapCenter.lng
                                        }} />
                                    </Map>
                                  </div>

                                
                                </div>

                                </div>




                            <div class="row g-2">
                              <div class="col mb-0">
                                <label for="description" class="form-label">Latitude</label>

                                <input class="form-control"

                                  value={mapCenter.lat}

                                  onChange={(event) => {
                                    setLatitude(event.target.value);
                                  }}

                                  name="latitude" type="text" placeholder="latitude" />
                              </div>

                              <div class="col mb-0">
                                <label for="description" class="form-label">Longitude</label>

                                <input class="form-control"

                                  value={mapCenter.lng}

                                  onChange={(event) => {
                                    setLongitude(event.target.value);
                                  }}


                                  name="longitude" type="text" placeholder="longitude" />
                              </div>
                              <div class="col mb-0">
                                <label for="dobWithTitle" class="form-label">Address Line 2</label>
                                <input class="form-control"


                                  value={address_line_2}

                                  onChange={(event) => {
                                    setaddress_line_2(event.target.value);
                                  }}


                                  name="address_line_2" type="text" placeholder="address_line_2" />
                              </div>
                            </div>




                            
                            <div class="row g-2">
                              <div class="col mb-0">
                                <label for="description" class="form-label">City</label>

                                <input class="form-control"


                                      value={state}
                                      onChange={(event) => {
                                        setCity(event.target.value);
                                      }}

                                      name="city" type="text" placeholder="city" />
                              </div>

                              <div class="col mb-0">
                                <label for="description" class="form-label">State</label>

                                <input class="multisteps-form__input form-control"



                                      value={state}

                                      onChange={(event) => {
                                        setState(event.target.value);
                                      }}

                                      name="state" type="text" placeholder="state" />
                              </div>
                              <div class="col mb-0">
                                <label for="dobWithTitle" class="form-label">Postal Code</label>
                                <input class=" form-control"

                                      value={postal_code}

                                      onChange={(event) => {
                                        setPostal_code(event.target.value);
                                      }}

                                      name="postal_code" type="text" placeholder="postal code" />
                              </div>

                              <div class="col mb-0">
                                <label for="dobWithTitle" class="form-label">User ID</label>
                                <input class=" form-control"

                                      value={userId}

                                      onChange={(event) => {
                                        setUserId(event.target.value);
                                      }}

                                      name="postal_code" type="text" placeholder="postal code" />
                              </div>
                            </div>


                            {lat && <p>Latitude: {lat}</p>}
        {lng && <p>Longitude: {lng}</p>}









                     

                            <div class="row g-2">
                              <div class="col mb-0">
                                <label class="form-label" for="basic-default-upload-file">Product Pic</label>
                                <input type="file" onChange={(e) => {
                      handleServiceIconInputChange(e)
                    }} class="form-control" id="basic-default-upload-file" required />
                              </div>

                            </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>


        
        {!isLoading && <button type="submit" onClick={saveDetails} class="btn btn-primary"  style={{backgroundColor:"#085781"}}>Save changes</button>

    } 
    {isLoading &&
        <button type="submit" class="btn btn-primary btn-md btn-block mt-3 waves-effect" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Saving Infor</button>
    }

    <ToastContainer />
        
      </div>
      
    </div>
  </div>
</div>



    </div>
  </div>
                    
  
  
                    
                   
  )
}


export default GoogleApiWrapper({
  apiKey: ('AIzaSyALtlAXEgqBUE9v3akrQsHfi-Cs_cPOJ0g')
})(ManageProducts)

