import React from 'react'

import '../MyStyles.css'

export default function MainContent() {
  return (
    <div>



    <section class="cover-background" style={{backgroundImage:"url(images/industries/beauty/personal-trainer-scheduling-system.png)"}}>
    <div class="opacity-medium bg-dark-gray"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-sm-8 text-left position-relative industries-subheading">
           <h1 class="title-med text-uppercase letter-spacing-1 white-text font-weight-600 margin-bottom-25">You focus on getting clients to their fitness goals.<br/><br />We’ll make sure they know when they’re on your schedule</h1>
            <a class="btn button btn-tt-lightbkgrd-med-inverted xs-margin-bottom-five xs-no-margin-right" href="https://www.timetap.com/businessWeb/web/signup/TEAMBUSM">Sign Up Free</a>
            </div>
        </div>
    </div>
</section>


<div class="container benefits-demo-section">
  
      <div class="row">
        <div class="col-md-4 col-sm-4">    
         <div class="guide-box sm-display-none">
          <h3>Easy Online Booking for Fitness Studios & Personal Trainers</h3>          
           <hr></hr>
          <p>Max out your fitness classes & streamline your personal training sessions without breaking a sweat.</p>
        </div>
        <div class="clearfix"></div>
        <div class="benefits-box">
              <h3>Perfect for:</h3>
              <ul class="benefits-list">
                <li>Getting more clients registered for your group exercise classes</li>
                <li>Managing client bookings over time & tracking progress</li>
                <li>Running promotions on specific services or classes</li>
                <li>Handling multiple trainer schedules</li>
                <li>Keeping your personal training from conflicting with your classes</li>                
              </ul>
          </div>
        </div>
		<div class="col-md-8 col-sm-8">
        	<div class="demo-box">
        	<h3>Try it Out: <small>Use the embedded appointment scheduler below to try out scheduling an appointment online as if you were a client. You can also check out <a href="https://getphysical.timetap.com/" target="_blank">booking an appointment online</a> on the TimeTap mini website that comes with each account.</small></h3>
            <div class="intrinsic-container intrinsic-container-16x9" style={{textAlign:"center"}}>
               <iframe src='https://www.timetap.com/emb/15327' class="tab-resize"></iframe>
            </div>
        </div>
		</div>
      </div>
</div>
    
    
    
    </div>
  )
}
