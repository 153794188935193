import React from 'react'

import { useEffect,useState } from 'react';


import Sidebar from '../Fsidebar';
import Topbar from '../Ftopbar';
import Footer from '../Ffooter';

import axios from 'axios';



import { Helmet } from "react-helmet";



import {toast,ToastContainer,Zoom,Bounce} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


function MyStaff() {


    const [staff_name, SetStaff_name] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [phone_no, setPhone_no] = useState("");

    const [staffList, setStaffList] = useState([]);



    useEffect(()=>{

      axios.get("https://tunepbackend.herokuapp.com/staff/mystaff").then((response) => {
        setStaffList(response.data);
        });

  },[]);
   


    const data={
        staff_name:staff_name,
      
        email:email,
        phone_no:phone_no,
        
    }

    const [isLoading,setLoading]=useState(false);

   

    const addDetails = ()  => {
        setLoading(true);

           axios.post("https://tunepbackend.herokuapp.com/staff",data).then((response)=>{
        
      //  axios.post("http://localhost:3001/staff",data).then((response)=>{

        console.log("The response is"+response.data)

           
            setTimeout(() => {
                setLoading(false);
                toast.info('Staff Added successfully');
            }, 3000);
         
           //  history("/dashboard");
          
           
        })

    }
    return (
    <div>
   
  
  
    <Helmet>
  
  
    
            
    <link rel="preconnect" href="https://fonts.googleapis.com/" />
    <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;display=swap" rel="stylesheet" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/boxicons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/fontawesome.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/flag-icons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/core.css" class="template-customizer-core-css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/theme-default.css" class="template-customizer-theme-css" />
    <link rel="stylesheet" href="assets_admin/css/demo.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/apex-charts/apex-charts.css" />
  
  
  
  
  
    <link rel="stylesheet" href="assets_admin/vendor/libs/select2/select2.css" />
  
  
    <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-bs5/datatables.bootstrap5.css"/>
  <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css"/>
  <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.css"/>
  
  
  
    
  
    
    <script src="assets_admin/vendor/js/helpers.js"></script>
    <script src="assets_admin/vendor/js/template-customizer.js"></script>
    <script src="assets_admin/js/config.js"></script>
        </Helmet>
  
        
        
        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                {/* Menu */}
                
                
                <Sidebar />
  
  
                {/* / Menu */}
                {/* Layout container */}
                <div className="layout-page">
                    {/* Navbar */}
                    
                    <Topbar />
  
                    {/* / Navbar */}
                    {/* Content wrapper */}
                    
                    <div class="content-wrapper">
  
  
  
                    <div class="container-xxl flex-grow-1 container-p-y">
              
              
  
                    <h4 class="py-3 breadcrumb-wrapper mb-4">
                      <span class="text-muted fw-light">Staff /</span> scheduling
                    </h4>
                    
                    <div class="row">
                  
                      <div class="col-xl-3 col-lg-4 col-md-4 mb-lg-0 mb-4">
  
                      <div class="card h-100">
        <div class="card-header">
          <h3 class="card-title mb-2">Staff</h3>
  
  
          <div className="pt-2 pb-2 pl-3 pr-3 d-flex align-items-center o-hidden box-shadow-1 chat-topbar p-4" style={{backgroundColor:"aliceblue"}}><a className="link-icon d-md-none" data-sidebar-toggle="chat"><i className="icon-regular ml-0 mr-3 i-Left" /></a>
          <div class="mb-0">
              <button class="btn btn-primary" data-repeater-create data-bs-toggle="modal" data-bs-target="#modalCenter">
                <i class="bx bx-plus"></i>
                <span class="align-middle">Add</span>
              </button>
            </div>
      </div>
         
        </div>
        <div class="card-body">
        <div class="nav-align-left">
                       
        <ul class="nav nav-pills w-100 gap-1">


        {staffList.map((value, key) => {
          return (
            <li class="nav-item">
            <button class="nav-link" data-bs-target="javascript:void(0);">{value.staff_name}</button>
          </li>

          );
      })}
     
         
          <li class="nav-item">
            <button class="nav-link active" data-bs-target="javascript:void(0);">Vincent Koech</button>
          </li>
          
         
        </ul>
      </div>
  
  
      <div class="modal fade" id="modalCenter" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalCenterTitle">Add Staff</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col mb-3">
                <label for="nameWithTitle" class="form-label">Name</label>
                <input type="text" id="nameWithTitle" class="form-control" placeholder="Enter Name"
                
                onChange={(event) => {
                    SetStaff_name(event.target.value);
                  }}
                   
                />
              </div>
            </div>
            <div class="row g-2">
              <div class="col mb-0">
                <label for="emailWithTitle" class="form-label">Email</label>
                <input type="text" id="emailWithTitle" class="form-control" placeholder="xxxx@xxx.xx"
                
                
                onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                   />
              </div>
              <div class="col mb-0">
                <label for="dobWithTitle" class="form-label">Phone No.</label>
                <input type="text" id="phoneno" class="form-control"

                onChange={(event) => {
                    setPhone_no(event.target.value);
                  }}
                   
                
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>


            
            {!isLoading && <button type="submit" onClick={addDetails} class="btn btn-primary"  style={{backgroundColor:"#085781"}}>Save changes</button>

        } 
        {isLoading &&
            <button type="submit" class="btn btn-primary btn-md btn-block mt-3 waves-effect" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Saving Infor</button>
        }

        <ToastContainer />
            
          </div>
          
        </div>
      </div>
    </div>
  
  
  
  
        </div>
      </div>
                      
                      
                      </div>
                     
  
                      <div class="col-xl-9 col-lg-8 col-md-8">
                        <div class="card overflow-hidden">
                          <div class="card-body">
  
  
  
                          <div class="card shadow-none text-center border mb-3">
                          <div class="card-header border-bottom">
                            <ul class="nav nav-tabs card-header-tabs" role="tablist">
                              <li class="nav-item">
                                <button type="button" class="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-within-card-active" aria-controls="navs-within-card-active" aria-selected="true">Active</button>
                              </li>
                              <li class="nav-item"><button type="button" class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-within-card-link" aria-controls="navs-within-card-link" aria-selected="false">Link</button>
                              </li>
                              <li class="nav-item">
                                <button type="button" class="nav-link disabled" data-bs-toggle="tab" role="tab" aria-selected="false">Disabled</button>
                              </li>
                            </ul>
                          </div>
                          <div class="tab-content">
                            <div class="tab-pane fade show active" id="navs-within-card-active" role="tabpanel">
                              <h4 class="card-title">Special active title</h4>
                              <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                              <a href="javascript:void(0)" class="btn btn-primary">Go somewhere</a>
                            </div>
                            <div class="tab-pane fade" id="navs-within-card-link" role="tabpanel">
                              <h4 class="card-title">Special link title</h4>
                              <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                              <a href="javascript:void(0)" class="btn btn-secondary">Go somewhere</a>
                            </div>
                          </div>
                        </div>
                          
                          
                    
                            <a class="btn btn-label-primary" href="pages-help-center-landing.html">
                              <i class="bx bx-chevron-left bx-sm scaleX-n1-rtl"></i>
                              <span>Back to help center</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                    
                                
                              </div>
  
    
  
    
                    
                    
                <footer class="content-footer footer bg-footer-theme">
                <div class="container-fluid d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                <div class="mb-2 mb-md-0">
                  © <script>
                  document.write(new Date().getFullYear())
                  </script>
                  , made with ❤️ by <a href="https://pixinvent.com/" target="_blank" class="footer-link fw-semibold">PIXINVENT</a>
                </div>
                <div>
                  
                  <a href="https://themeforest.net/licenses/standard" class="footer-link me-4" target="_blank">License</a>
                  <a href="https://1.envato.market/pixinvent_portfolio" target="_blank" class="footer-link me-4">More Themes</a>
                  
                  <a href="https://pixinvent.com/demo/frest-clean-bootstrap-admin-dashboard-template/documentation-bs5/" target="_blank" class="footer-link me-4">Documentation</a>
                  
                  <a href="https://pixinvent.ticksy.com/" target="_blank" class="footer-link d-none d-sm-inline-block">Support</a>
                  
                </div>
                </div>
                </footer>
                
                
                      
                      <div class="content-backdrop fade"></div>
                    </div>
  
                    
                    {/* Content wrapper */}
                </div>
                {/* / Layout page */}
            </div>
            {/* Overlay */}
            <div className="layout-overlay layout-menu-toggle" />
            {/* Drag Target Area To SlideIn Menu On Small Screens */}
            <div className="drag-target" />
        </div>
        <Helmet>
        <script src="assets_admin/vendor/libs/jquery/jquery.js"></script>
        <script src="assets_admin/vendor/libs/popper/popper.js"></script>
        <script src="assets_admin/vendor/js/bootstrap.js"></script>
        <script src="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
        <script src="assets_admin/vendor/libs/hammer/hammer.js"></script>
        <script src="assets_admin/vendor/libs/i18n/i18n.js"></script>
        <script src="assets_admin/vendor/libs/typeahead-js/typeahead.js"></script>
        <script src="assets_admin/vendor/js/menu.js"></script>
  
  
      
  
  
  
        <script src="assets_admin/vendor/libs/select2/select2.js"></script>
  <script src="assets_admin/assets/vendor/libs/flatpickr/flatpickr.js"></script>
  <script src="assets_admin/assets/vendor/libs/moment/moment.js"></script>
  
  
  
  <script src="assets_admin/vendor/libs/datatables/jquery.dataTables.js"></script>
  <script src="assets_admin/vendor/libs/datatables-bs5/datatables-bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-responsive/datatables.responsive.js"></script>
  <script src="assets_admin/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/datatables-buttons.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/jszip/jszip.js"></script>
  <script src="assets_admin/vendor/libs/pdfmake/pdfmake.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/buttons.html5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/buttons.print.js"></script>
  
  
    
        <script src="assets_admin/js/main.js"></script>
     
  
  
        <script src="assets_admin/js/app-calendar-events.js"></script>
        <script src="assets_admin/js/app-calendar.js"></script>
        </Helmet>
    </div>
    )
  }
  
  export default MyStaff