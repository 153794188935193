import React from 'react'


import Sidebar from '../Fsidebar';
import Topbar from '../Ftopbar';
import Footer from '../Ffooter';



import { Helmet } from "react-helmet";

export default function ListUsers() {
    return (
    <div>

    


    <Helmet>


    
            
    <link rel="preconnect" href="https://fonts.googleapis.com/" />
    <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;display=swap" rel="stylesheet" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/boxicons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/fontawesome.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/flag-icons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/core.css" class="template-customizer-core-css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/theme-default.css" class="template-customizer-theme-css" />
    <link rel="stylesheet" href="assets_admin/css/demo.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/apex-charts/apex-charts.css" />




 
    <link rel="stylesheet" href="assets_admin/vendor/libs/select2/select2.css" />


    <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-bs5/datatables.bootstrap5.css"/>
<link rel="stylesheet" href="assets_admin/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css"/>
<link rel="stylesheet" href="assets_admin/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.css"/>



    

    
    <script src="assets_admin/vendor/js/helpers.js"></script>
    <script src="assets_admin/vendor/js/template-customizer.js"></script>
    <script src="assets_admin/js/config.js"></script>
        </Helmet>
  
        
        
        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                {/* Menu */}
                
                
                <Sidebar />


                {/* / Menu */}
                {/* Layout container */}
                <div className="layout-page">
                    {/* Navbar */}
                    
                    <Topbar />

                    {/* / Navbar */}
                    {/* Content wrapper */}
                    
                    <div class="content-wrapper">

    

    
                    <div class="container-xxl flex-grow-1 container-p-y">
                            
                            
                
                    <div class="row g-4 mb-4">
                      <div class="col-sm-6 col-xl-3">
                        <div class="card">
                          <div class="card-body">
                            <div class="d-flex align-items-start justify-content-between">
                              <div class="content-left">
                                <span>Session</span>
                                <div class="d-flex align-items-end mt-2">
                                  <h4 class="mb-0 me-2">21,459</h4>
                                  <small class="text-success">(+29%)</small>
                                </div>
                                <small>Total Users</small>
                              </div>
                              <span class="badge bg-label-primary rounded p-2">
                                <i class="bx bx-user bx-sm"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-xl-3">
                        <div class="card">
                          <div class="card-body">
                            <div class="d-flex align-items-start justify-content-between">
                              <div class="content-left">
                                <span>Paid Users</span>
                                <div class="d-flex align-items-end mt-2">
                                  <h4 class="mb-0 me-2">4,567</h4>
                                  <small class="text-success">(+18%)</small>
                                </div>
                                <small>Last week analytics </small>
                              </div>
                              <span class="badge bg-label-danger rounded p-2">
                                <i class="bx bx-user-plus bx-sm"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-xl-3">
                        <div class="card">
                          <div class="card-body">
                            <div class="d-flex align-items-start justify-content-between">
                              <div class="content-left">
                                <span>Active Users</span>
                                <div class="d-flex align-items-end mt-2">
                                  <h4 class="mb-0 me-2">19,860</h4>
                                  <small class="text-danger">(-14%)</small>
                                </div>
                                <small>Last week analytics</small>
                              </div>
                              <span class="badge bg-label-success rounded p-2">
                                <i class="bx bx-group bx-sm"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-xl-3">
                        <div class="card">
                          <div class="card-body">
                            <div class="d-flex align-items-start justify-content-between">
                              <div class="content-left">
                                <span>Pending Users</span>
                                <div class="d-flex align-items-end mt-2">
                                  <h4 class="mb-0 me-2">237</h4>
                                  <small class="text-success">(+42%)</small>
                                </div>
                                <small>Last week analytics</small>
                              </div>
                              <span class="badge bg-label-warning rounded p-2">
                                <i class="bx bx-user-voice bx-sm"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                    <div class="card">
                      <div class="card-header border-bottom">
                        <h5 class="card-title">Search Filter</h5>
                        <div class="d-flex justify-content-between align-items-center row py-3 gap-3 gap-md-0">
                          <div class="col-md-4 user_role"></div>
                          <div class="col-md-4 user_plan"></div>
                          <div class="col-md-4 user_status"></div>
                        </div>
                      </div>
                      <div class="card-datatable table-responsive">
                        <table class="datatables-users table border-top">
                          <thead>
                            <tr>
                              <th></th>
                              <th>User</th>
                              <th>Role</th>
                              <th>Plan</th>
                              <th>Billing</th>
                              <th>Status</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      
                      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasAddUser" aria-labelledby="offcanvasAddUserLabel">
                        <div class="offcanvas-header border-bottom">
                          <h6 id="offcanvasAddUserLabel" class="offcanvas-title">Add User</h6>
                          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body mx-0 flex-grow-0">
                          <form class="add-new-user pt-0" id="addNewUserForm" onsubmit="return false">
                            <div class="mb-3">
                              <label class="form-label" for="add-user-fullname">Full Name</label>
                              <input type="text" class="form-control" id="add-user-fullname" placeholder="John Doe" name="userFullname" aria-label="John Doe" />
                            </div>
                            <div class="mb-3">
                              <label class="form-label" for="add-user-email">Email</label>
                              <input type="text" id="add-user-email" class="form-control" placeholder="john.doe@example.com" aria-label="john.doe@example.com" name="userEmail" />
                            </div>
                            <div class="mb-3">
                              <label class="form-label" for="add-user-contact">Contact</label>
                              <input type="text" id="add-user-contact" class="form-control phone-mask" placeholder="+1 (609) 988-44-11" aria-label="john.doe@example.com" name="userContact" />
                            </div>
                            <div class="mb-3">
                              <label class="form-label" for="add-user-company">Company</label>
                              <input type="text" id="add-user-company" class="form-control" placeholder="Web Developer" aria-label="jdoe1" name="companyName" />
                            </div>
                            <div class="mb-3">
                              <label class="form-label" for="country">Country</label>
                              <select id="country" class="select2 form-select">
                                <option value="">Select</option>
                                <option value="Australia">Australia</option>
                                <option value="Bangladesh">Bangladesh</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Brazil">Brazil</option>
                                <option value="Canada">Canada</option>
                                <option value="China">China</option>
                                <option value="France">France</option>
                                <option value="Germany">Germany</option>
                                <option value="India">India</option>
                                <option value="Indonesia">Indonesia</option>
                                <option value="Israel">Israel</option>
                                <option value="Italy">Italy</option>
                                <option value="Japan">Japan</option>
                                <option value="Korea">Korea, Republic of</option>
                                <option value="Mexico">Mexico</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Russia">Russian Federation</option>
                                <option value="South Africa">South Africa</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="United Arab Emirates">United Arab Emirates</option>
                                <option value="United Kingdom">United Kingdom</option>
                                <option value="United States">United States</option>
                              </select>
                            </div>
                            <div class="mb-3">
                              <label class="form-label" for="user-role">User Role</label>
                              <select id="user-role" class="form-select">
                                <option value="subscriber">Subscriber</option>
                                <option value="editor">Editor</option>
                                <option value="maintainer">Maintainer</option>
                                <option value="author">Author</option>
                                <option value="admin">Admin</option>
                              </select>
                            </div>
                            <div class="mb-4">
                              <label class="form-label" for="user-plan">Select Plan</label>
                              <select id="user-plan" class="form-select">
                                <option value="basic">Basic</option>
                                <option value="enterprise">Enterprise</option>
                                <option value="company">Company</option>
                                <option value="team">Team</option>
                              </select>
                            </div>
                            <button type="submit" class="btn btn-primary me-sm-3 me-1 data-submit">Submit</button>
                            <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="offcanvas">Cancel</button>
                          </form>
                        </div>
                      </div>
                    </div>
                    
                    
                                
                              </div>
                   
                    
                <footer class="content-footer footer bg-footer-theme">
                <div class="container-fluid d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                <div class="mb-2 mb-md-0">
                  © <script>
                  document.write(new Date().getFullYear())
                  </script>
                  , made with ❤️ by <a href="https://pixinvent.com/" target="_blank" class="footer-link fw-semibold">PIXINVENT</a>
                </div>
                <div>
                  
                  <a href="https://themeforest.net/licenses/standard" class="footer-link me-4" target="_blank">License</a>
                  <a href="https://1.envato.market/pixinvent_portfolio" target="_blank" class="footer-link me-4">More Themes</a>
                  
                  <a href="https://pixinvent.com/demo/frest-clean-bootstrap-admin-dashboard-template/documentation-bs5/" target="_blank" class="footer-link me-4">Documentation</a>
                  
                  <a href="https://pixinvent.ticksy.com/" target="_blank" class="footer-link d-none d-sm-inline-block">Support</a>
                  
                </div>
                </div>
                </footer>
                
                
                      
                      <div class="content-backdrop fade"></div>
                    </div>

                    
                    {/* Content wrapper */}
                </div>
                {/* / Layout page */}
            </div>
            {/* Overlay */}
            <div className="layout-overlay layout-menu-toggle" />
            {/* Drag Target Area To SlideIn Menu On Small Screens */}
            <div className="drag-target" />
        </div>
        <Helmet>
        <script src="assets_admin/vendor/libs/jquery/jquery.js"></script>
        <script src="assets_admin/vendor/libs/popper/popper.js"></script>
        <script src="assets_admin/vendor/js/bootstrap.js"></script>
        <script src="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
        <script src="assets_admin/vendor/libs/hammer/hammer.js"></script>
        <script src="assets_admin/vendor/libs/i18n/i18n.js"></script>
        <script src="assets_admin/vendor/libs/typeahead-js/typeahead.js"></script>
        <script src="assets_admin/vendor/js/menu.js"></script>


      



        <script src="assets_admin/vendor/libs/select2/select2.js"></script>
<script src="assets_admin/assets/vendor/libs/flatpickr/flatpickr.js"></script>
<script src="assets_admin/assets/vendor/libs/moment/moment.js"></script>



<script src="assets_admin/vendor/libs/datatables/jquery.dataTables.js"></script>
<script src="assets_admin/vendor/libs/datatables-bs5/datatables-bootstrap5.js"></script>
<script src="assets_admin/vendor/libs/datatables-responsive/datatables.responsive.js"></script>
<script src="assets_admin/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.js"></script>
<script src="assets_admin/vendor/libs/datatables-buttons/datatables-buttons.js"></script>
<script src="assets_admin/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.js"></script>
<script src="assets_admin/vendor/libs/jszip/jszip.js"></script>
<script src="assets_admin/vendor/libs/pdfmake/pdfmake.js"></script>
<script src="assets_admin/vendor/libs/datatables-buttons/buttons.html5.js"></script>
<script src="assets_admin/vendor/libs/datatables-buttons/buttons.print.js"></script>


    
        <script src="assets_admin/js/main.js"></script>
     


        <script src="assets_admin/js/app-calendar-events.js"></script>
        <script src="assets_admin/js/app-calendar.js"></script>
        </Helmet>
    </div>
    )
}