import React from 'react'

import {useEffect,useState,useContext } from 'react';

import { AuthContext } from '../../../helpers/AuthContext'

import {toast,ToastContainer,Zoom,Bounce} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


import {useNavigate,Link} from 'react-router-dom'

import axios from 'axios';

import API from '../../../services';


function DashboardContent() {


    const {authState} = useContext(AuthContext);

    const [requestList, setRequestList] = useState([]);

    const [ordersList, setOrdersList] = useState([]);

    const [errorMessage, setErrorMessage] = useState("");

    const [isDivLoading, setIsDivLoading] = useState(false);

    const [isLoading,setLoading]=useState(false);

    const [userId, setUserId] = useState("");

    let orderedItem=localStorage.getItem('itemsearched')
    orderedItem=JSON.parse(orderedItem)


    let sellerId=localStorage.getItem('sellerId')
    sellerId=JSON.parse(sellerId)



    useEffect(()=>{
  
        //axios.get("https://ngeritbackend.herokuapp.com/product/getproducts").then((response) => {
 
        // setIsDivLoading(true);
     
        API.get("/request/current_requests", {
         // axios.get("http://localhost:3001/request/current_requests", {

           headers: { accessToken: localStorage.getItem("accessToken")},
         })
         .then((response) => {
            setRequestList(response.data);
 
             console.log("THE SELLER DATA IS"+ response.data)
 
            
          
         }).catch(() => {
           setErrorMessage("Unable to fetch your products list");
           //setIsDivLoading(false);
        });
 
 
        API.get('/users/auth',{headers:{
         //  axios.get('http://localhost:3001/users/auth',{headers:{
             accessToken:localStorage.getItem("accessToken"),
           }}).then((response)=>{
         
         
         
                setUserId(response.data.id);
               
         
                console.log("THIS IS THE USER DATA "+response.data)
         
              });


              API.get("/order/allorders",{ headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {
                // axios.get(`https://ngeritbackend.herokuapp.com/product/search/${item}`).then((response) => {
      
               
                setOrdersList(response.data)
      
                 console.log("THE SELLER DETAILS ARE "+response.data)
             });
   
   },[]);


   const approveRequest = (id) => {

    let status='confirmed';

    setLoading(true);

    API
      .put(
       // "https://ngeritbackend.herokuapp.com/request/confirmrequest",
        "/request/confirmrequest",
        { status:status,id: id }
      
      )
      .then((response) => {

        
        setTimeout(() => {
          setLoading(false);
         // setIsDivLoading(false)   // Hide loading screen 
          toast.info('Request Confirmed');
      }, 1000);


      }
      );
    
    

  }
    
  return (
    <div>
    <div className="content-wrapper">
            {/* Content */}
            <div class="container-xxl flex-grow-1 container-p-y">


            <div class="row">



</div>
    
    
<div class="row">

<div class="col-lg-4 col-md-6 col-12 mb-4">
<div class="card h-100">

<div class="card-body">
<div class="row align-items-end">
  <div class="col-6">
    <h1 class="display-6 text-primary mb-2 pt-4 pb-1">89k KES</h1>
    <small class="d-block mb-3">You have done 57.6% <br/>more sales today.</small>

    <a href="javascript:;" class="btn btn-sm btn-primary">View sales</a>
  </div>
  <div class="col-6">
    <img src="assets_admin/img/illustrations/prize-light.png" width="140" height="150" class="rounded-start" alt="View Sales" data-app-light-img="assets_admin/img/illustrations/prize-light.png" data-app-dark-img="prize-dark.html"/>
  </div>
</div>
</div>
</div>
</div>

<div class="col-lg-4 col-md-6 col-12 mb-4">
<div class="card">
<div class="card-header d-flex justify-content-between align-items-center">
<h5 class="card-title mb-0">Orders this month</h5>
<div class="dropdown">
<span class="avatar-initial rounded-circle bg-label-success"><i class='bx bx-wallet fs-4'></i></span>
<span class="d-block text-nowrap">Total Orders</span>
    <h2 class="mb-0">30</h2>
</div>
</div>
<div class="card-body">
<div id="visitsRadialChart"></div>
</div>
</div>
</div>

<div class="col-lg-4 col-12">
<div class="row">

<div class="col-6 col-md-3 col-lg-6 mb-4">
<div class="card h-100">
  <div class="card-body text-center">
    <div class="avatar mx-auto mb-2">
      <span class="avatar-initial rounded-circle bg-label-success"><i class="bx bx-purchase-tag fs-4"></i></span>
    </div>
    <span class="d-block text-nowrap">Searches</span>
    <h2 class="mb-0">10</h2>
  </div>
</div>
</div>
<div class="col-6 col-md-3 col-lg-6 mb-4">
<div class="card h-100">
  <div class="card-body text-center">
    <div class="avatar mx-auto mb-2">
      <span class="avatar-initial rounded-circle bg-label-danger"><i class="bx bx-cart fs-4"></i></span>
    </div>
    <span class="d-block text-nowrap">Order</span>
    <h2 class="mb-0">3</h2>
  </div>
</div>
</div>


<div class="col-12 col-md-6 col-lg-12 mb-4">
<div class="card">
  <div class="card-header d-flex justify-content-between align-items-center pb-0">
    <h5 class="card-title mb-0">Revenue Growth</h5>
    <span>0%</span>
  </div>
  <div class="card-body pb-0">
    <div id="revenueGrowthChart"></div>
  </div>
</div>
</div>



</div>
</div>




<div class="col-md-6 col-lg-6 col-xl-4 mb-4 mb-xl-0">
<div class="card h-100">
<div class="card-header">
<h5 class="card-title mb-2">Commonly Searched</h5>
<h1 class="display-6 fw-normal mb-0"></h1>
</div>
<div class="card-body">
<span class="d-block mb-2">Current Activity</span>
<div class="progress progress-stacked mb-3 mb-xl-5" style={{height:'8px'}}>
  <div class="progress-bar bg-success" role="progressbar" style={{width: '30%'}} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
  <div class="progress-bar bg-danger" role="progressbar" style={{width: '15%'}}  aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
  <div class="progress-bar bg-info" role="progressbar" style={{width: '10%'}}  aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
  <div class="progress-bar bg-primary" role="progressbar"  style={{width: '40%'}}  aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
  <div class="progress-bar bg-warning" role="progressbar" style={{width: '15%'}}  aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
</div>
<ul class="p-0 m-0">
  <li class="mb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center lh-1 me-3">
      <span class="badge badge-dot bg-success me-2"></span> Onion
    </div>
    <div class="d-flex gap-3">
      <span>29.5k</span>
      <span class="fw-semibold">56%</span>
    </div>
  </li>
  <li class="mb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center lh-1 me-3">
      <span class="badge badge-dot bg-danger me-2"></span> Water Mellon
    </div>
    <div class="d-flex gap-3">
      <span>25.7k</span>
      <span class="fw-semibold">26%</span>
    </div>
  </li>
  <li class="mb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center lh-1 me-3">
      <span class="badge badge-dot bg-info me-2"></span> Chicken
    </div>
    <div class="d-flex gap-3">
      <span>11.5k</span>
      <span class="fw-semibold">34%</span>
    </div>
  </li>
  <li class="mb-3 d-flex justify-content-between">
    <div class="d-flex align-items-center lh-1 me-3">
      <span class="badge badge-dot bg-primary me-2"></span> Goat
    </div>
    <div class="d-flex gap-3">
      <span>48.5k</span>
      <span class="fw-semibold">45%</span>
    </div>
  </li>
 
</ul>
</div>
</div>
</div>

<div class="col-xl-8">
<div class="card">
<div class="card-header d-flex justify-content-between align-items-center">
<h5 class="card-title mb-0">Latest Orders</h5>
<div class="dropdown">
  <button class="btn p-0" type="button" id="marketingOptions" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <i class="bx bx-dots-vertical-rounded"></i>
  </button>
  <div class="dropdown-menu dropdown-menu-end" aria-labelledby="marketingOptions">
    <a class="dropdown-item" href="javascript:void(0);">Select All</a>
    <a class="dropdown-item" href="javascript:void(0);">Refresh</a>
    <a class="dropdown-item" href="javascript:void(0);">Share</a>
  </div>
</div>
</div>
<div class="card-body">
<div class="d-flex align-items-center justify-content-between flex-wrap gap-3">
  <div class="d-flex justify-content-between align-content-center flex-wrap gap-4">
    <div class="d-flex align-items-center gap-2">
      <div id="marketingCampaignChart1"></div>
      <div>
        <div class="d-flex align-items-center">
          <h6 class="mb-0 me-2">25,768</h6>
          <span class="text-success">(+16.2%)</span>
        </div>
        <div class="d-flex align-items-center">
          <span class="badge badge-dot bg-success me-2"></span> Jan 12,2022
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center gap-2">
      <div id="marketingCampaignChart2"></div>
      <div>
        <div class="d-flex align-items-center">
          <h6 class="mb-0 me-2">5,352</h6>
          <span class="text-danger">(-4.9%)</span>
        </div>
        <div class="d-flex align-items-center">
          <span class="badge badge-dot bg-danger me-2"></span> Jan 12,2022
        </div>
      </div>
    </div>
  </div>
  <a href="javascript:;" class="btn btn-sm btn-primary" type="button">View Report</a>
</div>
</div>
<div class="table-responsive">
<table class="table border-top">
  <thead>
    <tr>
      <th>#</th>
      <th>Product Name</th>
      <th>Customer Phone Number</th>
      <th>Email</th>
      <th>Status</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody class="table-border-bottom-0">
  {ordersList.map((value, key) => {
    return (
    <tr>
      <td>{key}</td>
      <td class="text-nowrap"><img src="assets_admin/img/icons/brands/fastrack.png" class="me-3" width="22"/>{value.Product.name}</td>
      <td class="text-nowrap"><i class="bx bx-trending-up text-success me-2"></i>{value.customer_phone_no}</td>
      <td>{value.request_type}</td>
      <td><span class="text-success">{value.status}</span></td>
      <td>

      
       
                <Link  to={`/order_details/${value.id}`} class="badge bg-success"> View Order</Link>
     
      </td>

      <td>

     
       
     
      <button class="badge bg-danger" data-bs-toggle="modal" data-bs-target="#enableOTP" type='submit'> Decline</button>
       </td>
    </tr>
    );
})}
    
    
    
  </tbody>
</table>
<ToastContainer />
</div>




<div class="modal fade" id="enableOTP" tabindex="-1" aria-hidden="true">
<div class="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
  <div class="modal-content p-3 p-md-5">
    <div class="modal-body">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      <div class="text-center mb-4">
        <h3 class="mb-4">Approve request</h3>
      </div>
   
      <form id="enableOTPForm" class="row g-3 mt-3" onsubmit="return false">
        
        <div class="col-12">
          <button type="submit" class="btn btn-primary me-sm-3 me-1">Yes</button>
          <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>



</div>
</div>

</div>


    
  </div>
            {/* / Content */}
            {/* Footer */}
            


            
            {/* / Footer */}
            <div className="content-backdrop fade" />
        </div>
</div>
  )
}

export default DashboardContent