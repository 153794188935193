import React from 'react';

export default function MainSectionAbout() {
  return(
   <div>

     <main>


     <section class="py-6 bg-light-primary" style={{marginTop:'80px'}}>
     <div class="container">
         <div class="row text-center">
             <div class="col-12">
                 <h1 class="about-us-heading" data-aos="fade-up">About Us</h1>
             </div>
         </div>
     </div>
 </section>




 <section class="py-6">
 <div class="container">
     <div class="row align-items-center justify-content-between">
        
         <div class="col-lg-6 mb-6 mb-lg-0" data-aos="fade-up">
             <h6 class="font-weight-medium font-size-sm text-uppercase text-primary">Know us better</h6>
             <h2 class="font-weight-bold mb-4">Tunep is a Booking software to manage and grow your business<br class="d-none d-lg-block"/></h2>
             <p class="text-muted lead">We EXIST to help booking-based businesses close the gap between leads and sales..</p>
             <p class="text-muted"> Tunep offers unlimited possibilities to drive the future of booking and reservation systems designed for service based booking businesses. Schedule clients, process bookings and payments, send automatic reminders, and market your business. So you can focus on your customers.</p>

             <a class="btn btn-primary waves-effect px-lg-4 mt-5" href="./careers.html">View Current Opening</a>
         </div>

         <div class="col-lg-5" data-aos="fade-up" data-aos-delay="50">
             <div class="card light-shadow hover-scale hover-shadow-smooth-3">
                 <div class="card-body">
                     <div class="p-0 p-md-4">
                         <div class="text-primary mb-3 hw-48">
                             <svg viewBox="0 0 24 24" version="1.1" >
                                 <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                     <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                     <path d="M11.2600599,5.81393408 L2,16 L22,16 L12.7399401,5.81393408 C12.3684331,5.40527646 11.7359848,5.37515988 11.3273272,5.7466668 C11.3038503,5.7680094 11.2814025,5.79045722 11.2600599,5.81393408 Z" fill="currentColor" opacity="0.3"></path>
                                     <path d="M12.0056789,15.7116802 L20.2805786,6.85290308 C20.6575758,6.44930487 21.2903735,6.42774054 21.6939717,6.8047378 C21.8964274,6.9938498 22.0113578,7.25847607 22.0113578,7.535517 L22.0113578,20 L16.0113578,20 L2,20 L2,7.535517 C2,7.25847607 2.11493033,6.9938498 2.31738608,6.8047378 C2.72098429,6.42774054 3.35378194,6.44930487 3.7307792,6.85290308 L12.0056789,15.7116802 Z" fill="currentColor"></path>
                                 </g>
                             </svg>
                         </div>
                         <h5 class="font-weight-semibold">Our Mission</h5>
                         <p class="text-muted mb-0">Tunep EXIST's to help booking-based businesses close the gap between leads and sales.</p>
                     </div>

                     <hr/>

                     <div class="p-0 p-md-4">
                         <div class="text-primary mb-3 hw-48">
                             <svg viewBox="0 0 24 24" version="1.1">
                                 <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                     <rect x="0" y="0" width="24" height="24"></rect>
                                     <circle fill="currentColor" opacity="0.3" cx="12" cy="9" r="8"></circle>
                                     <path d="M14.5297296,11 L9.46184488,11 L11.9758349,17.4645458 L14.5297296,11 Z M10.5679953,19.3624463 L6.53815512,9 L17.4702704,9 L13.3744964,19.3674279 L11.9759405,18.814912 L10.5679953,19.3624463 Z" fill="currentColor" fill-rule="nonzero" opacity="0.3"></path>
                                     <path d="M10,22 L14,22 L14,22 C14,23.1045695 13.1045695,24 12,24 L12,24 C10.8954305,24 10,23.1045695 10,22 Z" fill="currentColor" opacity="0.3"></path>
                                     <path d="M9,20 C8.44771525,20 8,19.5522847 8,19 C8,18.4477153 8.44771525,18 9,18 C8.44771525,18 8,17.5522847 8,17 C8,16.4477153 8.44771525,16 9,16 L15,16 C15.5522847,16 16,16.4477153 16,17 C16,17.5522847 15.5522847,18 15,18 C15.5522847,18 16,18.4477153 16,19 C16,19.5522847 15.5522847,20 15,20 C15.5522847,20 16,20.4477153 16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 C8,20.4477153 8.44771525,20 9,20 Z" fill="currentColor"></path>
                                 </g>
                             </svg>
                         </div>
                         <h5 class="font-weight-semibold">Our Vision</h5>
                         <p class="text-muted mb-0">Tunep aims to offer unlimited possibilities to drive the future of booking and reservation systems designed for service based booking businesses.</p>
                     </div>
                 </div>
             </div>
         </div>
     </div>
 </div>
</section>


            <div class="text-light-primary ">
                <svg preserveAspectRatio="none" viewBox="0 0 2000 150" xmlns="http://www.w3.org/2000/svg">
                  <polygon fill="currentColor" points="2000 0 2000 150 0 150"></polygon>
                </svg>
            </div>

           
            <section class="py-6 bg-light-primary">
                <div class="container">
                  
                    <div class="row justify-content-center text-center mb-6">
                        <div class="col-xl-7 col-lg-9 col-12">
                            <h2 class="font-weight-bold">What Our Clients Say About Us</h2>
                            <span class="font-weight-bold">1200+</span> teams across <span class="font-weight-bold">96</span> countries. <span class="font-weight-bold">1M+</span> unique users visiting every month 
                        </div>
                    </div>

                   
                    <div class="row justify-content-center row-cols-md-2 row-cols-lg-3 mb-4 mb-lg-6">
                        <div class="col-lg mb-3 mb-lg-0">
                            <div class="card card-body shadow-sm border-light hover-shadow-smooth-2" data-aos="fade-up">
                                <p class="p-4 bg-gray-50 rounded">I use Tunepp often. Wow what great service, I love
                                    it! Tunepp impressed me on multiple levels. I will refer everyone I know. Tunepp has really
                                    helped our business.</p>
            
                                <div class="d-flex align-items-center mt-2">
                                    <div class="avatar avatar-sm">
                                        <img class="avatar-img rounded-circle" src="./images/avatar/user-1.jpg" alt=""/>
                                    </div>
            
                                    <div class="ml-3">
                                        <h6 class="mb-1">Charline Mathis</h6>
                                         <div class="rating-group">
                                            <img src="./images/icons/star.svg" alt=""/>
                                            <img src="./images/icons/star.svg" alt=""/>
                                            <img src="./images/icons/star.svg" alt=""/>
                                            <img src="./images/icons/star.svg" alt=""/>
                                            <img src="./images/icons/rating.svg" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg mb-3 mb-lg-0">
                            <div class="card card-body shadow-sm border-light hover-shadow-smooth-2" data-aos="fade-up" data-aos-delay="50">
                                <p class="p-4 bg-gray-50 rounded">I use Tunepp often. Wow what great service, I love
                                    it! Tunepp impressed me on multiple levels. I will refer everyone I know. Tunepp has really
                                    helped our business.</p>
            
                                <div class="d-flex align-items-center mt-2">
                                    <div class="avatar avatar-sm">
                                        <img class="avatar-img rounded-circle" src="./images/avatar/user-1.jpg" alt=""/>
                                    </div>
            
                                    <div class="ml-3">
                                        <h6 class="mb-1">Charline Mathis</h6>
                                        <div class="rating-group">
                                            <img src="./images/icons/star.svg" alt=""/>
                                            <img src="./images/icons/star.svg" alt=""/>
                                            <img src="./images/icons/star.svg" alt=""/>
                                            <img src="./images/icons/star.svg" alt=""/>
                                            <img src="./images/icons/rating.svg" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg mb-3 mb-lg-0">
                            <div class="card card-body shadow-sm border-light hover-shadow-smooth-2" data-aos="fade-up" data-aos-delay="100">
                                <p class="p-4 bg-gray-50 rounded">I use Tunepp often. Wow what great service, I love
                                    it! Tunepp impressed me on multiple levels. I will refer everyone I know. Tunepp has really
                                    helped our business.</p>
            
                                <div class="d-flex align-items-center mt-2">
                                    <div class="avatar avatar-sm">
                                        <img class="avatar-img rounded-circle" src="./images/avatar/user-1.jpg" alt=""/>
                                    </div>
            
                                    <div class="ml-3">
                                        <h6 class="mb-1">Charline Mathis</h6>
                                        <div class="rating-group">
                                            <img src="./images/icons/star.svg" alt=""/>
                                            <img src="./images/icons/star.svg" alt=""/>
                                            <img src="./images/icons/star.svg" alt=""/>
                                            <img src="./images/icons/star.svg" alt=""/>
                                            <img src="./images/icons/rating.svg" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                         
                  
                    <div class="row row-cols-3 row-cols-md-5 justify-content-center px-xxl-12">
                        <div class="col col-md my-3" data-aos="fade-up">
                            <img class="img-fluid" src="./images/logos/brand/spotify.svg" alt=""/>
                        </div>
                        <div class="col col-md my-3" data-aos="fade-up" data-aos-delay="50">
                            <img class="img-fluid" src="./images/logos/brand/airnub.svg" alt=""/>
                        </div>
                        <div class="col col-md my-3" data-aos="fade-up" data-aos-delay="100"/>
                            <img class="img-fluid" src="./images/logos/brand/hubspot.svg" alt=""/>
                        </div>
                       
                       
                    </div>
                   
                    
                
            </section>



     </main>
   
   
     </div>
  
    )
  }
  
