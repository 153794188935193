import React from 'react'


import '../MyStyles.css'

export default function MainContent() {
  return (
    <div>


    <main>
          
    <section class="cover-background" style={{backgroundImage:"url(images/industries/beauty/healthcare-scheduling.jpg)"}}>
    <div class="opacity-medium bg-dark-gray"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-8 text-left position-relative industries-subheading">
                <h1 class="title-med text-uppercase letter-spacing-1 white-text font-weight-600 margin-bottom-25">The scheduling solution you need,<br/>To take your practice virtual</h1>
                <a class="btn button btn-tt-lightbkgrd-med-inverted xs-margin-bottom-five xs-no-margin-right" href="https://www.timetap.com/businessWeb/web/signup/TEAMBUSM">30-Day Free Trial</a>
            </div>
        </div>
    </div>
</section>


<div class="container benefits-demo-section">
    
      <div class="row">
        <div class="col-md-4 col-sm-4">    
         <div class="guide-box sm-display-none">
          <h3>HIPAA Compliant Online Scheduling</h3>          
          <hr></hr>
          <p>Online Scheduling that gives healthcare providers everything they need in their appointment booking process</p>
        </div>
        <div class="clearfix"></div>
        <div class="benefits-box">
              <h3>Perfect for:</h3>
              <ul class="benefits-list">
                <li>Maximizing the schedules of each provider at your practice with real-time availability</li>
                <li>Customizing email + text reminders to reduce no shows</li>
                <li>Communicating efficiently with tools that will increase your value in your patients’ minds</li>
                <li>Getting information about your patient's’ health goals before their visit</li>                
              </ul>
          </div>
        </div>
		<div class="col-md-8 col-sm-8">
        	<div class="demo-box">
        	<h3>Try it Out: <small>Use the embedded web scheduler below to try out scheduling an appointment online as if you were a patient. You can also check out <a href="https://barberphd.timetap.com/" target="_blank">booking an appointment online</a> on the TimeTap mini website that comes with each account.</small></h3>
			<div class="intrinsic-container intrinsic-container-16x9" style={{textAlign:"center"}}>
               <iframe src='https://www.timetap.com/emb/15337' class="tab-resize"></iframe>
            </div>
        </div>
		</div>
      </div>
</div>

    

   {/*

    <section class="industry__nav bg-offset md:mt-2 md:mb-2" id="industry-nav"> 
    <nav class="container br-8 bg-white">
     <a href="#beauty" class="text-xs text-lg-grey active:bg-yellow hover:border-yellow scrollto">Beauty</a>
     <a href="#wellbeing" class="text-xs text-lg-grey active:bg-red hover:border-red">Wellbeing</a>
     <a href="#creative" class="text-xs text-lg-grey active:bg-light-grey hover:border-light-grey">Creative</a> 
     <a href="#healthcare" class="text-xs text-lg-grey active:bg-blue hover:border-blue">Healthcare</a>
     <a href="#homey" class="text-xs text-lg-grey active:bg-yellow hover:border-yellow">Home Maintenace</a>
    <a href="#education" class="text-xs text-lg-grey active:bg-orange hover:border-orange">Education</a>
      
    <a href="#professionalservices" class="text-xs text-lg-grey active:bg-green hover:border-green">Professional services</a> 
     
    <a href="#tradeservices" class="text-xs text-lg-grey active:bg-purple hover:border-purple">Trade</a> 
       
         </nav> 
    </section>
  
  
  */} 













</main>
    
    
    </div>
  )
}
