import React from 'react'

export default function CustomersReviews() {
  return (
    <div>

    <section class="py-6">
    <div class="container">
        <div class="row text-center justify-content-center">
            <div class="col-xl-9 col-lg-10">
                <div class="mb-5">
                    <h2 class="font-weight-bold">Our Customers Say it Best</h2>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12" data-aos="fade-up">
                <div class="masonry-grid overflow-hidden" data-columns="4">
                    <div class="masonry-grid-item">
                        <div class="card card-body align-items-start">
                            <div class="rating-group mb-4">
                                <img src="./images/icons/star.svg" alt=""/>
                                <img src="./images/icons/star.svg" alt=""/>
                                <img src="./images/icons/star.svg" alt=""/>
                                <img src="./images/icons/star.svg" alt=""/>
                                <img src="./images/icons/rating.svg" alt=""/>
                            </div>

                            <p class="font-size-sm">I would also wish to say many thanks to all or any your staff. We were treated royally . If you are not sure, always choose Favland. Favland is both attractive and highly adaptable.</p>
        
                            <a href="javascript:;" class="d-inline-block mt-4">
                                <div class="d-flex align-items-center">
                                    <div class="avatar avatar-xs">
                                        <img class="avatar-img rounded-circle" src="./images/avatar/user-6.jpg" alt=""/>
                                    </div>
                
                                    <div class="ml-3">
                                        <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Charline Mathis</h6>
                                        <span class="line-height-1 text-gray-600 small">Product designer</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="masonry-grid-item">
                        <div class="card card-body overflow-hidden">
                            <p class="font-size-sm">"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur repellat repudiandae voluptatem numquam tempora at tempore possimus."</p>
                     
                            <a href="javascript:;">
                                <div class="d-flex align-items-center pt-4">
                                    <div class="avatar avatar-xs">
                                        <img class="avatar-img rounded-circle" src="./images/avatar/user-2.jpg" alt=""/>
                                    </div>
                
                                    <div class="ml-3">
                                        <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Charline Mathis</h6>
                                        <span class="line-height-1 text-gray-600 small">Product designer</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="masonry-grid-item">
                        <div class="card">
                            <div class="card-body">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0)">
                                        <path d="M7.41533 15.105C7.85455 12.3442 11.3056 9.39512 14.0037 9.33237C14.1919 9.33237 14.3802 9.26962 14.5057 9.14413C14.6312 9.08139 14.7567 9.01864 14.8194 8.8304C15.7606 7.0735 15.2586 5.69308 13.69 4.56365C11.8703 3.24597 8.98399 4.56365 7.54082 5.75583C3.90154 8.76765 0.199501 13.9755 0.38774 18.9326C-0.239723 22.2582 -0.114231 25.8347 0.826964 28.7837C1.45443 30.6661 3.27407 31.6073 5.15646 31.7328C7.03885 31.8584 10.9291 32.423 12.6233 31.2937C14.3174 30.1642 14.4429 27.968 14.6312 26.0857C14.8194 24.0151 15.3841 20.1247 13.6272 18.4933C11.8703 16.9247 6.85061 18.6188 7.41533 15.105Z" fill="black"/>
                                        <path d="M28.1216 15.105C28.5609 12.3442 32.0118 9.39512 34.71 9.33237C34.8982 9.33237 35.0865 9.26962 35.212 9.14413C35.3376 9.08139 35.463 9.01864 35.5258 8.8304C36.467 7.0735 35.965 5.69308 34.3964 4.56365C32.5767 3.24597 29.6904 4.56365 28.2471 5.75583C24.6078 8.76765 20.9058 13.9756 21.094 18.9326C20.4666 22.2582 20.5921 25.8347 21.5332 28.7837C22.1606 30.6661 23.9803 31.6073 25.8628 31.7328C27.7452 31.8584 31.6354 32.423 33.3296 31.2937C35.0236 30.1642 35.1492 27.968 35.3375 26.0857C35.5257 24.0151 36.0904 20.1247 34.3335 18.4933C32.5766 16.9247 27.4942 18.6188 28.1216 15.105Z" fill="black"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                        <rect width="36" height="36" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                
                                <h5 class="my-4 line-height-reset font-weight-bold">I use Favland often. Wow what great template, I love it! Favland impressed me on multiple levels.</h5>
                                <p class="font-size-sm text-muted">I use Favland often. Wow what great template, I love it! Favland impressed me on multiple levels. I will refer everyone I know. Favland has really helped our business.</p>

                                <a href="javascript:;">
                                    <div class="d-flex align-items-center pt-4">
                                        <div class="avatar avatar-xs">
                                            <img class="avatar-img rounded-circle" src="./images/avatar/user-3.jpg" alt=""/>
                                        </div>
                    
                                        <div class="ml-3">
                                            <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Charline Mathis</h6>
                                            <span class="line-height-1 text-gray-600 small">Product designer</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="masonry-grid-item">
                        <div class="card card-body align-items-start">
                            <img class="mb-4" src="images/logos/brand/slack.svg" alt=""/>

                            <p class="font-size-sm">I would also wish to say many thanks to all or any your staff. We were treated royally . If you are not sure, always choose Favland. Favland is both attractive and highly adaptable.</p>
        
                            <div class="mt-3">
                                <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Charline Mathis</h6>
                                <span class="line-height-1 text-gray-600 small">Product designer</span>
                            </div>
                        </div>
                    </div>

                    <div class="masonry-grid-item">
                        <div class="card">
                            <div class="card-body">
                                <img class="mb-4" src="images/logos/brand/flipkart.svg" alt=""/>
                                <p class="font-size-sm">"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur repellat repudiandae voluptatem numquam tempora at tempore possimus."</p>
                            
                                <div class="mt-4">
                                    <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Charline Mathis</h6>
                                    <span class="line-height-1 text-gray-600 small">Product designer</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="masonry-grid-item">
                        <div class="card card-body align-items-start">
                            <div class="rating-group mb-4">
                                <img src="./images/icons/star.svg" alt=""/>
                                <img src="./images/icons/star.svg" alt=""/>
                                <img src="./images/icons/star.svg" alt=""/>
                                <img src="./images/icons/star.svg" alt=""/>
                                <img src="./images/icons/rating.svg" alt=""/>
                            </div>

                            <p class="font-size-sm">I would also wish to say many thanks to all or any your staff. We were treated royally . If you are not sure, always choose Favland. Favland is both attractive and highly adaptable.</p>
        
                            <a href="javascript:;" class="d-inline-block mt-4">
                                <div class="d-flex align-items-center">
                                    <div class="avatar avatar-xs">
                                        <img class="avatar-img rounded-circle" src="./images/avatar/user-6.jpg" alt=""/>
                                    </div>
                
                                    <div class="ml-3">
                                        <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Charline Mathis</h6>
                                        <span class="line-height-1 text-gray-600 small">Product designer</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    
                    <div class="masonry-grid-item">
                        <div class="testimonial-card testimonial-card-bg-2">
                            <div class="testimonial-body">
                                <h5 class="font-size-sm line-height-reset mb-4">"I use Favland often. Wow what great template, I love it! Favland impressed me on multiple levels."</h5>

                                 <h6 class="font-size-sm font-weight-bold mb-0">Lindsy Clames</h6>
                                <p class="small">Envanto Elements</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="masonry-grid-item">
                        <div class="card card-body overflow-hidden">
                            <p class="font-size-sm">"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur repellat repudiandae voluptatem numquam tempora at tempore possimus."</p>
                     
                            <a href="javascript:;">
                                <div class="d-flex align-items-center pt-4">
                                    <div class="avatar avatar-xs">
                                        <img class="avatar-img rounded-circle" src="./images/avatar/user-2.jpg" alt=""/>
                                    </div>
                
                                    <div class="ml-3">
                                        <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Charline Mathis</h6>
                                        <span class="line-height-1 text-gray-600 small">Product designer</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="masonry-grid-item">
                        <div class="card">
                            <div class="card-body">
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip1)">
                                        <path d="M7.41533 15.105C7.85455 12.3442 11.3056 9.39512 14.0037 9.33237C14.1919 9.33237 14.3802 9.26962 14.5057 9.14413C14.6312 9.08139 14.7567 9.01864 14.8194 8.8304C15.7606 7.0735 15.2586 5.69308 13.69 4.56365C11.8703 3.24597 8.98399 4.56365 7.54082 5.75583C3.90154 8.76765 0.199501 13.9755 0.38774 18.9326C-0.239723 22.2582 -0.114231 25.8347 0.826964 28.7837C1.45443 30.6661 3.27407 31.6073 5.15646 31.7328C7.03885 31.8584 10.9291 32.423 12.6233 31.2937C14.3174 30.1642 14.4429 27.968 14.6312 26.0857C14.8194 24.0151 15.3841 20.1247 13.6272 18.4933C11.8703 16.9247 6.85061 18.6188 7.41533 15.105Z" fill="black"/>
                                        <path d="M28.1216 15.105C28.5609 12.3442 32.0118 9.39512 34.71 9.33237C34.8982 9.33237 35.0865 9.26962 35.212 9.14413C35.3376 9.08139 35.463 9.01864 35.5258 8.8304C36.467 7.0735 35.965 5.69308 34.3964 4.56365C32.5767 3.24597 29.6904 4.56365 28.2471 5.75583C24.6078 8.76765 20.9058 13.9756 21.094 18.9326C20.4666 22.2582 20.5921 25.8347 21.5332 28.7837C22.1606 30.6661 23.9803 31.6073 25.8628 31.7328C27.7452 31.8584 31.6354 32.423 33.3296 31.2937C35.0236 30.1642 35.1492 27.968 35.3375 26.0857C35.5257 24.0151 36.0904 20.1247 34.3335 18.4933C32.5766 16.9247 27.4942 18.6188 28.1216 15.105Z" fill="black"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip1">
                                        <rect width="36" height="36" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                
                                <h5 class="my-4 line-height-reset font-weight-bold">I use Favland often. Wow what great template, I love it! Favland impressed me on multiple levels.</h5>
                                <p class="font-size-sm text-muted">I use Favland often. Wow what great template, I love it! Favland impressed me on multiple levels. I will refer everyone I know. Favland has really helped our business.</p>

                                <a href="javascript:;">
                                    <div class="d-flex align-items-center pt-4">
                                        <div class="avatar avatar-xs">
                                            <img class="avatar-img rounded-circle" src="./images/avatar/user-3.jpg" alt=""/>
                                        </div>
                    
                                        <div class="ml-3">
                                            <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Charline Mathis</h6>
                                            <span class="line-height-1 text-gray-600 small">Product designer</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="masonry-grid-item">
                        <div class="testimonial-card testimonial-card-bg-1">
                            <div class="testimonial-body">
                                <h5 class="font-size-sm line-height-reset mb-4">"I use Favland often. Wow what great template, I love it! Favland impressed me on multiple levels."</h5>

                                 <h6 class="font-size-sm font-weight-bold mb-0">Lindsy Clames</h6>
                                <p class="small">Envanto Elements</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="masonry-grid-item">
                        <div class="card card-body overflow-hidden">
                            <p class="font-size-sm">"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur repellat repudiandae voluptatem numquam tempora at tempore possimus."</p>
                     
                            <a href="javascript:;">
                                <div class="d-flex align-items-center pt-4">
                                    <div class="avatar avatar-xs">
                                        <img class="avatar-img rounded-circle" src="./images/avatar/user-2.jpg" alt=""/>
                                    </div>
                
                                    <div class="ml-3">
                                        <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Charline Mathis</h6>
                                        <span class="line-height-1 text-gray-600 small">Product designer</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                   
                    <div class="masonry-grid-item">
                        <div class="card card-body align-items-start">
                            <img class="mb-4" src="images/logos/brand/linkedln.svg" alt=""/>

                            <p class="font-size-sm">I would also wish to say many thanks to all or any your staff. We were treated royally . If you are not sure, always choose Favland. Favland is both attractive and highly adaptable.</p>
        
                            <div class="mt-3">
                                <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Charline Mathis</h6>
                                <span class="line-height-1 text-gray-600 small">Product designer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    
    
    
    </div>
  )
}
