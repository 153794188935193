
import React,{useEffect} from 'react';
import Footer from '../../Footer/Footer';
import Section from './Section';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
//import Header from '../../Header/Header';
import Header from '../../Header/HeaderOld';

import MainSection from './MainSection';
import RaitingSection from './WhyTunep';
import FeaturesSection from './FeaturesSection';
import CustomerReviews from './CustomerReviews';
import MainSectionPricing from './MainSectionPricing';
import HeaderNew from '../../Header/HeaderNew';

import { Widget,addResponseMessage  } from 'react-chat-widget';

import 'react-chat-widget/lib/styles.css';

import logo from '../../../../src/assets/images/tunepp.jpg';
import HeaderFloating from '../../Header/HeaderFloating';

function Pricing(){
    useEffect(() => {
        addResponseMessage("Hi, Do you have any questions about our pricing? We're happy to help");
      }, []);
    
      const handleNewUserMessage = (newMessage) => {
        console.log(`New message incoming! ${newMessage}`);
        // // Now send the message throught the backend API
        // addResponseMessage(newMessage);
      };
    return (
        <div>
           

           <HeaderFloating/>

            <MainSectionPricing/>
          
            

             <Section2 />
            <Section3 />
            <Section4 />
            <Widget 

            handleNewUserMessage={handleNewUserMessage}
            profileAvatar={logo}
            title="Here to help 24/7"
           
            
          
            />

        </div>
    )


}

export default Pricing;