import React from 'react';

export default function CustomerReviews() {
  return (
      
    
    <div>


    <section class="py-6 bg-light-primary mt-n1 mt-sm-0">
    <div class="container">
        <div class="row justify-content-center text-center mb-5">
            <div class="col-xl-7 col-lg-10">
                <h2 class="font-weight-bold">Highly Rated Products By Local Buyers</h2>
                {/* <p class="text-muted mb-0">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laboriosam delectus at, asperiores, assumenda accusantium commodi nam officiis quam deserunt hic</p> */}
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <ul class="nav nav-pills-group mb-3" id="reviewsTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link active" id="customersTab" data-toggle="pill" href="#customers" role="tab" aria-controls="customersTab" aria-selected="true">Customers</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="expertTab" data-toggle="pill" href="#expert" role="tab" aria-controls="expertTab" aria-selected="false">Experts</a>
                    </li>
                </ul>
            </div>

            <div class="col-12">
                <div class="tab-content" id="reviewsTabContent">
                    <div class="tab-pane fade show active" id="customers" role="tabpanel" aria-labelledby="customers">
                        <div class="row row-cols-xl-4 row-cols-md-2 row-cols-1">
                            <div class="col mb-3" data-aos="fade-up">
                                <div class="card card-body align-items-start">
                                    <div class="rating-group mb-4">
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/rating.svg" alt=""/>
                                    </div>

                                    <p class="font-size-sm">"To our amazing mango supplier and the dedicated team behind the scenes, your hard work is truly appreciated. Keep up the fantastic work, and let's continue delivering the juiciest success together!"</p>
                
                                    <a href="javascript:;" class="d-inline-block mt-4">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar avatar-xs">
                                                <img class="avatar-img rounded-circle" src="./images/avatar/mango.jpg" alt=""/>
                                            </div>
                        
                                            <div class="ml-3">
                                                <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Shadrack Musembi</h6>
                                                <span class="line-height-1 text-gray-600 small">Joska Market</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="col mb-3" data-aos="fade-up" data-aos-delay="50">
                                <div class="card card-body align-items-start">
                                    <div class="rating-group mb-4">
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/rating.svg" alt=""/>
                                    </div>

                                    <p class="font-size-sm">"Congratulations on your exceptional apple supply! Your commitment to quality and service is truly appreciated. Here's to continued success together!"</p>
                
                                    <a href="javascript:;" class="d-inline-block mt-4">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar avatar-xs">
                                                <img class="avatar-img rounded-circle" src="./images/avatar/madamapple.jpeg" alt=""/>
                                            </div>
                        
                                            <div class="ml-3">
                                                <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Easter Akoth</h6>
                                                <span class="line-height-1 text-gray-600 small">Ruai Market</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="col mb-3" data-aos="fade-up" data-aos-delay="100">
                                <div class="card card-body align-items-start">
                                    <div class="rating-group mb-4">
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/rating.svg" alt=""/>
                                    </div>

                                    <p class="font-size-sm">"Thank you for being a fantastic tomato supplier! Your consistently excellent produce helps our business thrive. We value your partnership and look forward to continued success together."</p>
                
                                    <a href="javascript:;" class="d-inline-block mt-4">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar avatar-xs">
                                                <img class="avatar-img rounded-circle" src="./images/avatar/farmer.jpg" alt=""/>
                                            </div>
                        
                                            <div class="ml-3">
                                                <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Emmanuel Kamau</h6>
                                                <span class="line-height-1 text-gray-600 small">customer Utawala</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="col mb-3" data-aos="fade-up" data-aos-delay="150">
                                <div class="card card-body align-items-start">
                                    <div class="rating-group mb-4">
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/star.svg" alt=""/>
                                        <img src="./images/icons/rating.svg" alt=""/>
                                    </div>

                                    <p class="font-size-sm">"Congratulations on consistently providing us with top-quality melons! Your dedication to excellence is greatly appreciated. Here's to our continued success together!"</p>
                
                                    <a href="javascript:;" class="d-inline-block mt-4">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar avatar-xs">
                                                <img class="avatar-img rounded-circle" src="./images/avatar/kenyan.jpeg" alt=""/>
                                            </div>
                        
                                            <div class="ml-3">
                                                <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">Dorcas Macharia</h6>
                                                <span class="line-height-1 text-gray-600 small">Kangemi Market</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="row text-center justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="col-xl-9 col-lg-10">
                                <p class="mb-0 font-size-sm text-muted">Trusted by companies who care about their customers rated 4.86/5 based on <a href="">19,456 reviews</a></p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
    
    
    
    
    </div>
    
    )
    
}
