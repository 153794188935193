import React, {useEffect} from 'react';
import { useNavigate  } from "react-router-dom";
import Sidebar from './Fsidebar';
import Topbar from './Ftopbar';
import Footer from './Ffooter';



import { Helmet } from "react-helmet";




    
    import '../../assets/vendor/fonts/fontawesome.css'
    
    import '../../assets/vendor/fonts/flag-icons.css'




import '../../assets/css/demo.css';

import '../../assets/vendor/css/rtl/theme-default.css'

import '../../assets/vendor/css/rtl/core.css'
import CalendarContent from './CalendarContent';


export default function Calendar() {
    return (
    <div>

    


    <Helmet>


    
            
    <link rel="preconnect" href="https://fonts.googleapis.com/" />
    <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;display=swap" rel="stylesheet" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/boxicons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/fontawesome.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/flag-icons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/core.css" class="template-customizer-core-css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/theme-default.css" class="template-customizer-theme-css" />
    <link rel="stylesheet" href="assets_admin/css/demo.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/apex-charts/apex-charts.css" />




    <link rel="stylesheet" href="assets_admin/vendor/libs/fullcalendar/fullcalendar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/flatpickr/flatpickr.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/select2/select2.css" />



    <link rel="stylesheet" href="assets_admin/vendor/css/pages/app-calendar.css" />

    
    <script src="assets_admin/vendor/js/helpers.js"></script>
    <script src="assets_admin/vendor/js/template-customizer.js"></script>
    <script src="assets_admin/js/config.js"></script>
        </Helmet>
  
        
        
        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                {/* Menu */}
                
                
                <Sidebar />


                {/* / Menu */}
                {/* Layout container */}
                <div className="layout-page">
                    {/* Navbar */}
                    
                    <Topbar />

                    {/* / Navbar */}
                    {/* Content wrapper */}
                    
                 

                <CalendarContent/>

                    
                    {/* Content wrapper */}
                </div>
                {/* / Layout page */}
            </div>
            {/* Overlay */}
            <div className="layout-overlay layout-menu-toggle" />
            {/* Drag Target Area To SlideIn Menu On Small Screens */}
            <div className="drag-target" />
        </div>
        <Helmet>
        <script src="assets_admin/vendor/libs/jquery/jquery.js"></script>
        <script src="assets_admin/vendor/libs/popper/popper.js"></script>
        <script src="assets_admin/vendor/js/bootstrap.js"></script>
        <script src="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
        <script src="assets_admin/vendor/libs/hammer/hammer.js"></script>
        <script src="assets_admin/vendor/libs/i18n/i18n.js"></script>
        <script src="assets_admin/vendor/libs/typeahead-js/typeahead.js"></script>
        <script src="assets_admin/vendor/js/menu.js"></script>


        <script src="assets_admin/vendor/libs/fullcalendar/fullcalendar.js"></script>
        <script src="assets_admin/vendor/libs/apex-charts/apexcharts.js"></script>
        <script src="assets_admin/js/main.js"></script>
        <script src="assets_admin/js/dashboards-analytics.js"></script>




        <script src="assets_admin/vendor/libs/select2/select2.js"></script>
<script src="assets_admin/assets/vendor/libs/flatpickr/flatpickr.js"></script>
<script src="assets_admin/assets/vendor/libs/moment/moment.js"></script>



    
        <script src="assets_admin/js/main.js"></script>
     


        <script src="assets_admin/js/app-calendar-events.js"></script>
        <script src="assets_admin/js/app-calendar.js"></script>
        </Helmet>
    </div>
    )
}