import React,{useEffect}from 'react';
import Footer from '../../Footer/Footer';
import Section from './Section';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
//import Header from '../../Header/Header';
import Header from '../../Header/HeaderOld';

import MainSection from './MainSection';
import FeaturesSection from './FeaturesSection';
import CustomerReviews from './CustomerReviews';
import HeaderNew from '../../Header/HeaderNew';
import AllServices from './AllServices';


import { Widget,addResponseMessage  } from 'react-chat-widget';

import 'react-chat-widget/lib/styles.css';

import logo from '../../../../src/assets/images/tunepp.jpg';
import ToolForEveryBuss from './ToolForEveryBuss';
import WhyTunep from './WhyTunep';
import TunepAppSection from './TunepAppSection';
import TunepServices from './TunepServices';
import TunepForBusiness from './TunepForBusiness';
import HeaderFloating from '../../Header/HeaderFloating';
import HomePageFineTuning from './HomePageFineTuning';
import NewFeaturesTunep from './NewFeaturesTunep';
import BookingProcess from './BookingProcess';
import SearchSectionNgerit from './SearchSectionNgerit';


function Home() {

    useEffect(() => {
        addResponseMessage('Hi! Welcome to mavunosoko. Kindly conduct our customer care on 0724512561');
      }, []);
    
      const handleNewUserMessage = (newMessage) => {
        console.log(`New message incoming! ${newMessage}`);
        // // Now send the message throught the backend API
        // addResponseMessage(newMessage);
      };
    return (
        <div>
           
           <HeaderFloating/>


           <SearchSectionNgerit/>
           
           

           

            {/*<AllServices/>*/}

           

           

             {/* <TunepAppSection/> */}


             <CustomerReviews/>
            


        
           
           
            <Widget 

            handleNewUserMessage={handleNewUserMessage}
            profileAvatar={logo}
            title="Welcome to mavunosoko"
            subtitle="24/7 customer support"
            
          
            />

        </div>
    )
}

export default Home
