import React from 'react'

import {useEffect,useState,useContext } from 'react';

import { AuthContext } from '../../../helpers/AuthContext'

import {toast,ToastContainer,Zoom,Bounce} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';

import {useNavigate} from 'react-router-dom'
import OrderDetailsContext from '../../../helpers/OrderDetailsContext';

import API from '../../../services';




function DashboardContent() {

    const {authState} = useContext(AuthContext);

    const {customerOrders,setCustomerOrders} = useContext(OrderDetailsContext);

    const [seller_name, setSeller_name] = useState("");

    const [product_name, setProduct_name] = useState("");
    const [PhoneNumber, setPhoneNumber] = useState("");

    const [customerPhoneNo, setCustomerPhoneNo] = useState("");

    const [customer_address, setCustomer_address] = useState("");
    const [customer_lat, setCustomer_lat] = useState("");


    const [seller_email, setSeller_email] = useState("");
    const [seller_phone_no, setSeller_phone_no] = useState("");


    const [userId, setUserId] = useState('');

    const [actualId, setactualId] = useState('');
  
    const [ordersList, setOrdersList] = useState([]);

    const [bookingList, setBookingList] = useState([]);


    const [customer_longitude, setCustomer_longitude] = useState("");


    const [cust_place_of_residence, setCust_place_of_residence] = useState("");
    const [request_type, setRequest_type] = useState("");
    const [currency_code, setCurrency_code] = useState("");
    const [payment_method_id, setPayment_method_id] = useState("");
    const [request_made_at, setRequest_made_at] = useState("");




    const [request_accepted_at, setRequest_accepted_at] = useState("");
    const [request_declined_at, setRequest_declined_at] = useState("");
    const [request_cancelled_at, setRequest_cancelled_at] = useState("");



    const [username, setUserName] = useState("");
    const [email, setEmail] = useState("");
    
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [country, setCountry] = useState("");
  
    const [phone_no, setPhone_no] = useState("");
  
    const [city, setCity] = useState("");

    const [totalCost, setTotalCost] = useState("500");
  
    //const [id, setId] = useState(authState.id);
  
  
    const [title, setTitle] = useState('');
    const [decsription, setDescription] = useState('');
    const [symbol, setSymbol] = useState('');
    
    const [password, setPassword] = useState("");
    const history = useNavigate();
  
   
    const [name, setName] = useState("");
    
  
    const [farmersList, setFarmersList] = useState([]);
  
  
    const [productsList, setProductsList] = useState([]);
  
    const [errorMessage, setErrorMessage] = useState("");
  
    const [isDivLoading, setIsDivLoading] = useState(false);
  


    const [isLoading,setLoading]=useState(false);


    const [customerOrder, setCustomerOrder] = useState([]);

    let orderedItem=localStorage.getItem('itemsearched')
    orderedItem=JSON.parse(orderedItem)


    let sellerId=localStorage.getItem('sellerId')
    sellerId=JSON.parse(sellerId)

    let productId=localStorage.getItem('ProductId')
    productId=JSON.parse(productId)


    let seller_contacts=localStorage.getItem('seller_contacts')
    seller_contacts=JSON.parse(seller_contacts)


    const orderDetails = localStorage.getItem("order_details")






    useEffect(()=>{

       
      //API.get('users/auth', { headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {
      API.get('users/auth', { headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {
 
         setUserId(response.data.id)

        setCustomerPhoneNo(response.data.phone_no)
   
   
        })

       
 
     //    //API.get("customer/mycustomers").then((response) => {
     //   API.get("order/getallorders").then((response) => {
     //   setOrdersList(response.data);
     //   })
     setIsDivLoading(true);

     try {

        // orderDetails=JSON.stringify(orderDetails)
        // console.log("LOCAL STORANGE CONVERTED TO JSON",orderDetails)

     if(customerOrders!=null){


         setTimeout(() => {

           //setOrdersList(customerOrders)
            setIsDivLoading(false)  
             
          
         }, 1000);

        


     }
     else{

         setOrdersList([]);

         setErrorMessage("Unable to fetch your orders list");
         setIsDivLoading(false);
     }


 } catch (err) {
     console.log(`Error: ${err.message}`);
     setErrorMessage("Unable to fetch your Orders list.Kindly check your internet connection!!");
     setIsDivLoading(false);
 }



    API.get("bookings/myorders",{ headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {
         
       if(response.data!=null){
         setBookingList(response.data);
         //console.log("THE PRODUCT NAME IS "+response.data)

       }
       else{
         setBookingList([]);

         console.log("Pleas login ")

        // history('/sigin')

       }


       
   setTimeout(() => {
    
    // setSeller_name(response.data.Users);
     setIsDivLoading(false)   // Hide loading screen 
    // toast.info('Product saved successfully');
 }, 4000);
       
        
         }).catch(() => {
             setErrorMessage("Unable to fetch your Orders list.Kindly check your internet connection!!");
             setIsDivLoading(false);
          });


          API.get("order/myorders",{ headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {
         
            if(response.data!=null){
              setOrdersList(response.data);
              console.log("YOUR ORDER LIST  IS "+JSON.stringify(response.data))
     
            }
            else{
              setBookingList([]);
     
              console.log("Pleas login ")
     
             // history('/sigin')
     
            }
     
     
            
        setTimeout(() => {
         
         // setSeller_name(response.data.Users);
          setIsDivLoading(false)   // Hide loading screen 
         // toast.info('Product saved successfully');
      }, 4000);
            
             
              }).catch(() => {
                  setErrorMessage("Unable to fetch your Orders list.Kindly check your internet connection!!");
                  setIsDivLoading(false);
               });
     

      


        
 
 
          console.log("LOCAL STORANGE ORDER DETAILS",orderDetails)


          const storedData = localStorage.getItem('productDetails');
          if (storedData) {
            setCustomerOrder(JSON.parse(storedData));
          }
  
          console.log('GATHEE UMEORDER ',JSON.parse(storedData))
      
 
 
 
 },[customerOrders]);



    const data={
      product_name:orderedItem,
    
      seller_email:seller_email,
      seller_phone_no:seller_contacts,
      customer_phone_no:PhoneNumber,
      customer_address:customer_address,
      customer_lat:customer_lat,
      customer_longitude:customer_longitude,
      payment_method_id:1,
      
      cust_place_of_residence:cust_place_of_residence,
      request_type:request_type,

      status:'pending',

      ProductId:productId,

      SellerId:sellerId,

      BuyerId:authState.id,
        
    }


    const data1={
      first_name:first_name,
      last_name:first_name,
      username:username,
      email:email,
      phone_no:phone_no,
      password:phone_no,
      city:city,
      country:country,
      role:'Farmer',
      UserId:authState.id,
      status:'pending',
  }

 


  const addDetails = ()  => {

      setLoading(true);

     axios.post("https://kilimomazaoapi-dmi-cyber.herokuapp.com/subscriptionrequest",data1).then((response)=>{
      
    //axios.post("http://localhost:3001/subscriptionrequest",data1).then((response)=>{

      console.log("The response is"+response.data)

         
          setTimeout(() => {
              setLoading(false);
              toast.info('Request submitted successfully');
          }, 1000);
       
         //  history("/dashboard");
      })

  }


  const paydata={
    PhoneNumber:PhoneNumber,
  
    Amount:customerOrder.price,
    sellerId:customerOrder.sellerId
     
  }







  const makePayment = ()  => {

    setLoading(true);

      // axios.post("https://ngeritbackend.herokuapp.com/product",data).then((response)=>{
  
      API.post('mpesa/pay',paydata).then((response)=>{

 
    
  // axios.post("http://localhost:3001/request",data).then((response)=>{
     

    console.log("The response is"+response.data)

       
        setTimeout(() => {
            setLoading(false);
            toast.info('Request confirmed successfully');

            // history('/login');

        }, 1000);
     
       //  history("/dashboard");
      
       
    })

}

    const addLoginRoute = ()  => {

      setLoading(true);

        // axios.post("https://ngeritbackend.herokuapp.com/product",data).then((response)=>{
    
    axios.post("https://kilimomazaoapi-dmi-cyber.herokuapp.com/request",data).then((response)=>{
      
    // axios.post("http://localhost:3001/request",data).then((response)=>{
       

      console.log("The response is"+response.data)

         
          setTimeout(() => {
              setLoading(false);
              toast.info('Request confirmed successfully');

              // history('/login');

          }, 1000);
       
         //  history("/dashboard");
        
         
      })

  }
    
  return (
    <div>
    <div className="content-wrapper">
            {/* Content */}
            <div class="container-xxl flex-grow-1 container-p-y">


            <div class="row">




</div>
    
    
<div class="row">



<div class="col-md-6 col-lg-6 col-xl-4 mb-4 mb-xl-0">
<div class="card h-100">
  <div class="card-header">
    <h3 class="card-title mb-2">Congratulations <span class="display-6 text-primary mb-2 pt-4 pb-1"> {authState.lastname}!</span> </h3>
    <span class="d-block mb-4 text-nowrap">Thank you for supporting MavunoSoko</span>
  </div>
  <div class="card-body">
    <div class="row align-items-end">
      <div class="col-6">
      <h5>Your current order</h5>
      
      
      <p><strong>Product:</strong> {customerOrder.productName}</p>
      <p><strong>Price:</strong> {customerOrder.price}</p>

      <p><strong>sellerName:</strong> {customerOrder.sellerName}</p>

      <p><strong>sellerName:</strong> {customerOrder.sellerId}</p>

      <p><strong>sellerContacts:</strong> {customerOrder.sellerContacts}</p>
      


        
        {/* <h5>Confirm Order Now!</h5> */}
        <p>Money will any be released to the farmer once the product is delivered</p>
     
        <a href="javascript:;" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#enableOTP">Pay Now</a>

      </div>
      {/* <div class="col-6">
        <img src="assets_admin/img/illustrations/prize-light.png" width="140" height="150" class="rounded-start" alt="View Sales" data-app-light-img="assets_admin/img/illustrations/prize-light.png" data-app-dark-img="prize-dark.html"/>
      </div> */}
    </div>
  </div>
</div>
</div>


<div class="modal fade" id="editUser" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-simple modal-edit-user">
        <div class="modal-content p-3 p-md-5">
          <div class="modal-body">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="text-center mb-4">
              <h3>Send us your Information</h3>
              <p>Submiting your details will receive a privacy audit.</p>
            </div>
            <form id="editUserForm" class="row g-3" onsubmit="return false">
              <div class="col-12 col-md-6">
                <label class="form-label" for="modalEditUserFirstName">First Name</label>
                <input type="text" id="modalEditUserFirstName" name="modalEditUserFirstName"
                value={first_name}

                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
                
                class="form-control" placeholder="Faith" />
              </div>
              <div class="col-12 col-md-6">
                <label class="form-label" for="modalEditUserLastName">Last Name</label>
                <input type="text" id="modalEditUserLastName" name="modalEditUserLastName" 

                value={last_name}
                
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
                
                
                class="form-control" placeholder="Njuguna" />
              </div>
              <div class="col-12 col-md-6">
                <label class="form-label" for="modalEditUserName">Username</label>
                <input type="text" id="modalEditUserName" name="modalEditUserName" class="form-control" 
                
                value={username}

                onChange={(event) => {
                  setUserName(event.target.value);
                }}
                
                placeholder="njugunaf@g.com" />
              </div>
              <div class="col-12 col-md-6">
                <label class="form-label" for="modalEditUserEmail">Email</label>
                <input type="text" id="modalEditUserEmail" name="modalEditUserEmail" class="form-control" 
                
                value={email}

                onChange={(event) => {
                  setEmail(event.target.value);
                }}


                placeholder="frankcode@gmail.com" />
              </div>
             

            
              <div class="col-12 col-md-6">
                <label class="form-label" for="modalEditUserPhone">Phone Number</label>
                <div class="input-group input-group-merge">
                  <span class="input-group-text">+254</span>
                  <input type="text" id="modalEditUserPhone" name="modalEditUserPhone" class="form-control phone-number-mask"
                  
                  value={phone_no}

                  onChange={(event) => {
                    setPhone_no(event.target.value);
                  }}



                  placeholder="+254780077090" />
                </div>
              </div>
             
              <div class="col-12 col-md-6">
                <label class="form-label" for="modalEditUserCountry">Country</label>
                <select id="modalEditUserCountry" name="modalEditUserCountry" class="select2 form-select"

                value={country}
                
                onChange={(event) => {
                  setCountry(event.target.value);
                }}
                
                data-allow-clear="true">
                  <option value="">Select</option>
                  <option value="Australia">Australia</option>

                  <option value="Kenya">Kenya</option>

                  <option value="Uganda">Uganda</option>

                  <option value="Tanzania">Tanzania</option>
                
                  <option value="Canada">Canada</option>
               
                  <option value="Turkey">Turkey</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">United Arab Emirates</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                </select>
              </div>

              <div class="col-12 col-md-6">
              <label class="form-label" for="modalEditUserCountry">Type of Farming</label>
              <select id="modalEditUserCountry" name="modalEditUserCountry" class="select2 form-select"

              value={country}
              
              onChange={(event) => {
                setCountry(event.target.value);
              }}
              
              data-allow-clear="true">
                <option value="">Select</option>
                <option value="Small Scal">Small Scale</option>

                <option value="Large Scale">Large Scale</option>

                
              </select>
            </div>

              <div class="col-12 col-md-6">
                <label class="form-label" for="modalEditTaxID">Where are you located?</label>
                <input type="text" id="modalEditTaxID" name="city" class="form-control modal-edit-tax-id"
                
                value={city}
                
                onChange={(event) => {
                  setCity(event.target.value);
                }}
                
                placeholder="Enter your farm location" />
              </div>

              <div class="col-12">
                <label class="switch">
                  <input type="checkbox" class="switch-input"/>
                  <span class="switch-toggle-slider">
                    <span class="switch-on"></span>
                    <span class="switch-off"></span>
                  </span>
                  <span class="switch-label">Use as a billing address?</span>
                </label>
              </div>
              <div class="col-12 text-center mt-4">

              {!isLoading && <button type="submit" onClick={addDetails} class="btn btn-primary me-sm-3 me-1" title="Save" >Save</button>

            }
            {isLoading &&
              <button type="submit" class="btn btn-primary ml-auto js-btn-next" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Saving Infor</button>
            }


               
                <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>


<div class="modal fade" id="enableOTP" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-simple modal-enable-otp modal-dialog-centered">
    <div class="modal-content p-3 p-md-5">
      <div class="modal-body">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="text-center mb-4">
          {/* <h3 class="mb-4">We Accept Mpesa Payments</h3> */}
        </div>
        
        <img src="images/media/mpesa_n.png" class="me-3" alt="Fastrack"/>
        <h1>Total Cost:Ksh {customerOrder.price}</h1>
        <form id="enableOTPForm" class="row g-3 mt-3" onsubmit="return false">
          <div class="col-12">
            <label class="form-label" for="modalEnableOTPPhone">Enter Your Mpesa Number</label>
            <div class="input-group input-group-merge">
              <span class="input-group-text">+254</span>
              <input type="text" id="modalEnableOTPPhone" name="modalEnableOTPPhone"

              onChange={(event) => {
                setPhoneNumber(event.target.value);
              }}
              
              
              class="form-control phone-number-otp-mask" placeholder="+254714639773" />
            </div>
          </div>
          <div class="col-12">

          {!isLoading && <button type="submit" onClick={makePayment}  class="btn btn-primary me-sm-3 me-1">Pay</button>

        }
        {isLoading &&
          <button type="submit" class="btn btn-primary me-sm-3 me-1" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Processing Payment</button>
        }



            
            <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>





<div class="col-xl-8">
<div class="card">
<div class="card-header d-flex justify-content-between align-items-center">
<h5 class="card-title mb-0">Order Details</h5>
<div class="dropdown">
  <button class="btn p-0" type="button" id="marketingOptions" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <i class="bx bx-dots-vertical-rounded"></i>
  </button>
  <div class="dropdown-menu dropdown-menu-end" aria-labelledby="marketingOptions">
    <a class="dropdown-item" href="javascript:void(0);">Select All</a>
    <a class="dropdown-item" href="javascript:void(0);">Refresh</a>
    <a class="dropdown-item" href="javascript:void(0);">Share</a>
  </div>
</div>
</div>
<div class="card-body">
<div class="d-flex align-items-center justify-content-between flex-wrap gap-3">
  <div class="d-flex justify-content-between align-content-center flex-wrap gap-4">
    <div class="d-flex align-items-center gap-2">
      <div id="marketingCampaignChart1"></div>
      <div>
        <div class="d-flex align-items-center">
          <h6 class="mb-0 me-2">25,768</h6>
          <span class="text-success">(+16.2%)</span>
        </div>
        <div class="d-flex align-items-center">
          <span class="badge badge-dot bg-success me-2"></span> {orderedItem}
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center gap-2">
      <div id="marketingCampaignChart2"></div>
      <div>
        <div class="d-flex align-items-center">
          <h6 class="mb-0 me-2">Seller ID</h6>
          <span class="text-danger">{sellerId}</span>
        </div>
        <div class="d-flex align-items-center">
          <span class="badge badge-dot bg-danger me-2"></span> sep 08,2023
        </div>
      </div>
    </div>
  </div>
  <a href="javascript:;" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#editUser" type="button">Request A farmer Account</a>
</div>
</div>
<div class="table-responsive">
<table class="table border-top">
  <thead>
    <tr>
      <th>Names</th>
      <th>Phone Number</th>
      <th>Order ID</th>
      <th>Status</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody class="table-border-bottom-0">
 
  {ordersList.map((value, key) => {
    return (
    <tr>
      <td class="text-nowrap"><img src="images/icons/trade.svg" class="me-3" width="22" alt="Fastrack"/>{value.item_name}</td>
      <td class="text-nowrap"><i class="bx bx-trending-up text-success me-2"></i>{value.quantity_ordered}</td>
      <td>{value.orderId}</td>

      <td><span class="text-success">{value.order_status}</span></td>
      
      <td>
        <div class="dropdown">
          <button class="btn p-0" type="button" id="action1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="bx bx-dots-vertical-rounded"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="action1">
            <a class="dropdown-item" href="javascript:void(0);">Details</a>
            <a class="dropdown-item" href="javascript:void(0);">Write a Review</a>
            <a class="dropdown-item" href="javascript:void(0);">Download Invoice</a>
          </div>
        </div>
      </td>
    </tr>
);
})}
   
    
    
    
  </tbody>
</table>
</div>
</div>
</div>

</div>


    
  </div>
            {/* / Content */}
            {/* Footer */}
            


            
            {/* / Footer */}
            <div className="content-backdrop fade" />
        </div>
</div>
  )
}

export default DashboardContent