
import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../../helpers/AuthContext";

import './loginbuttons.css'

import API from '../../../services';



function SignIn() {
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthState } = useContext(AuthContext);

  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  let history = useNavigate();



  const [isLoading,setLoading]=useState(false);



  





  const login = () => {
      
    const data = { username: username, password: password };

    setLoading(true);
    // axios.post("https://kilimomazaoapi-dmi-cyber.herokuapp.com/users/login", data).then((response) => {
      API.post("/users/login", data).then((response) => {
      if (response.data.error) {
        alert(response.data.error);
        setLoading(false);
      } else {
        localStorage.setItem("accessToken", response.data.token);
        setAuthState({
          username: response.data.username,
          role: response.data.role,
          first_name: response.data.first_name,
          phone_no: response.data.phone_no,
          id: response.data.id,
          status: true,
        });

        console.log("Response is",response.data)

        if(response.data.role=="Admin"){

          setTimeout(() => {
            setLoading(false);
            history("/new-dashboard");
        }, 1000);

        }

        else if(response.data.role=="Customer"){

          setTimeout(() => {
            setLoading(false);
            history("/customer-dashboard");
        }, 1000);
  
        }
      else if(response.data.role=="Seller"){

        setTimeout(() => {
          setLoading(false);
          history("/farmer-dashboard");
      }, 1000);

      }

      else if(response.data.role=="Dispatch"){

        setTimeout(() => {
          setLoading(false);
          history("/dispatch-home");
      }, 1000);

      }
      else{
        setTimeout(() => {
          setLoading(false);
          history("/new-dashboard");
      }, 1000);


      }
      
      }
    });
  };
    return (
        <div>

        <div className="auth-inner" style={{marginTop:'80px'}}>


        <form>

  <div class="form-outline mb-4">
    <input type="email" id="form2Example1" class="form-control" 
    onChange={(event) => {
      setUsername(event.target.value);
    }}
    
    />
    <label class="form-label" for="form2Example1">Email address</label>
  </div>

  
  <div class="form-outline mb-4">
    <input type="password" id="form2Example2" class="form-control"
    
    onChange={(event) => {
      setPassword(event.target.value);
    }}
    />
    <label class="form-label" for="form2Example2">Password</label>
  </div>


  <div class="row mb-4">
    <div class="col d-flex justify-content-center">
    
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="form2Example31" checked />
        <label class="form-check-label" for="form2Example31"> Remember me </label>
      </div>
    </div>

    <div class="col">
      
      <a href="#!">Forgot password?</a>
    </div>
  </div>



 
{!isLoading && <button type="button" class="btn btn-primary btn-block mb-4" onClick={login}>Sign in</button>

} 


{isLoading &&
  <button id="wp-login-but"  class="btn btn-primary btn-block mb-4"  data-mixval="1745" disabled>
  <i class="fas fa-spinner fa-spin"></i>Signing In</button>
  
 }

 
  <div class="text-center">
    <p>Not a member? <a href="/start-now">Register</a></p>
    <p>or sign up with:</p>
    <button type="button" class="btn btn-link btn-floating mx-1">
      <i class="fab fa-facebook-f"></i>
    </button>

    <button type="button" class="btn btn-link btn-floating mx-1">
      <i class="fab fa-google"></i>
    </button>

    <button type="button" class="btn btn-link btn-floating mx-1">
      <i class="fab fa-twitter"></i>
    </button>

    <button type="button" class="btn btn-link btn-floating mx-1">
      <i class="fab fa-github"></i>
    </button>
  </div>
</form>

</div>

        </div>
    )
}

export default SignIn
