import React from 'react'

import {useEffect,useState,useContext } from 'react';

import Sidebar from './Sidebar';
import Topbar from '../Ftopbar';
import Footer from '../Ffooter';
import { Helmet } from "react-helmet";

import {useNavigate} from 'react-router-dom'

import { AuthContext } from '../../../helpers/AuthContext'

import axios from 'axios';

import {toast,ToastContainer,Zoom,Bounce} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import TopBarCustomer from './TopBarCustomer';


function CustomerAccountSetting() {
    const {authState} = useContext(AuthContext);


  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [country, setCountry] = useState("");

  const [phone_no, setPhone_no] = useState("");

  const [city, setCity] = useState("");

  const [id, setId] = useState(authState.id);


  const [title, setTitle] = useState('');
  const [decsription, setDescription] = useState('');
  const [symbol, setSymbol] = useState('');
  
  const [password, setPassword] = useState("");
  const history = useNavigate();

  const [isLoading,setLoading]=useState(false);




    useEffect(() => {
//     // axios.get(`https://onlinebookingus-app-test.herokuapp.com/users/basicinfo/${id}`).then((response) => {

  axios.get('https://kilimomazaoapi-dmi-cyber.herokuapp.com/users/auth',{headers:{
  // axios.get('http://localhost:3001/users/auth',{headers:{
    accessToken:localStorage.getItem("accessToken"),
  }}).then((response)=>{



       setUserName(response.data.username);
       setEmail(response.data.email)
       setFirstName(response.data.first_name);
       setLastName(response.data.last_name)
       setPhone_no(response.data.phone_no)

       setCountry(response.data.country)

       setCountry(response.data.city)

       console.log("THIS IS THE USER DATA "+response.data)

     });
 }, []);




const updateHandler = async (e) => {

    setLoading(true);

    const data = {
        first_name: first_name,
        last_name:last_name,
        email: email,
        username : username,
        city : city,
        country : country,
       
    }

    axios.put('https://ngeritbackend.herokuapp.com/users/updateuser',data,{
    // axios.put('http://localhost:3001/users/updateuser',data,{
      headers: { accessToken: localStorage.getItem("accessToken") },
    }).then((response) => {

          console.log("PROFILE UPDATES MANAGED"+response.data)

          setTimeout(() => {
            setLoading(false);
            toast.info('Details updated successfully');
            // navigate.push('/add-property');
        }, 1000);
     
    });
  
}







    const data={
      first_name:first_name,
      last_name:first_name,
      username:username,
      email:email,
      phone_no:phone_no,
      password:phone_no,
      city:city,
      country:country,
  }

 


  const addDetails = ()  => {
      setLoading(true);

     axios.post("https://ngeritbackend.herokuapp.com/users",data).then((response)=>{
      
     //axios.post("http://localhost:3001/users",data).then((response)=>{

      console.log("The response is"+response.data)

         
          setTimeout(() => {
              setLoading(false);
              toast.info('Signed Up successfully');
          }, 3000);
       
         //  history("/dashboard");
      })

  }

  return (
    <div>
   
  
  
    <Helmet>
  
  
    
            
    <link rel="preconnect" href="https://fonts.googleapis.com/" />
    <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;display=swap" rel="stylesheet" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/boxicons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/fontawesome.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/flag-icons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/core.css" class="template-customizer-core-css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/theme-default.css" class="template-customizer-theme-css" />
    <link rel="stylesheet" href="assets_admin/css/demo.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/apex-charts/apex-charts.css" />
  
  
  
  
  
    <link rel="stylesheet" href="assets_admin/vendor/libs/select2/select2.css" />
  
  
    <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-bs5/datatables.bootstrap5.css"/>
  <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css"/>
  <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.css"/>
  
  
  
    
  
    
    <script src="assets_admin/vendor/js/helpers.js"></script>
    <script src="assets_admin/vendor/js/template-customizer.js"></script>
    <script src="assets_admin/js/config.js"></script>
        </Helmet>
  
        
        
        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                {/* Menu */}
                
                
                <Sidebar />
  
  
                {/* / Menu */}
                {/* Layout container */}
                <div className="layout-page">
                    {/* Navbar */}
                    
                    <TopBarCustomer/>
  
                    {/* / Navbar */}
                    {/* Content wrapper */}
                    
                    <div class="content-wrapper">
  
    
  
    
                    <div class="container-xxl flex-grow-1 container-p-y">
              
              
                    <h4 class="py-3 breadcrumb-wrapper mb-4">
                      <span class="text-muted fw-light">User / View /</span> Account
                    </h4>
                    <div class="row gy-4">
                    
                      <div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
                     
                        <div class="card mb-4">
                          <div class="card-body">
                            <div class="user-avatar-section">
                              <div class=" d-flex align-items-center flex-column">
                                <img class="img-fluid rounded my-4" src="assets_admin/img/avatars/10.png" height="110" width="110" alt="User avatar" />
                                <div class="user-info text-center">
                                  <h5 class="mb-2">{authState.first_name}</h5>
                                  <span class="badge bg-label-secondary">Farmer</span>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex justify-content-around flex-wrap my-4 py-3">
                              <div class="d-flex align-items-start me-4 mt-3 gap-3">
                                <span class="badge bg-label-primary p-2 rounded"><i class='bx bx-check bx-sm'></i></span>
                                <div>
                                  <h5 class="mb-0">1.23k</h5>
                                  <span>Products Sold</span>
                                </div>
                              </div>
                              <div class="d-flex align-items-start mt-3 gap-3">
                                <span class="badge bg-label-primary p-2 rounded"><i class='bx bx-customize bx-sm'></i></span>
                                <div>
                                  <h5 class="mb-0">568</h5>
                                  <span>Transactions Done</span>
                                </div>
                              </div>
                            </div>
                            <h5 class="pb-2 border-bottom mb-4">Details</h5>
                            <div class="info-container">
                              <ul class="list-unstyled">
                                <li class="mb-3">
                                  <span class="fw-bold me-2">Username:</span>
                                  <span>{authState.username}</span>
                                </li>
                                <li class="mb-3">
                                  <span class="fw-bold me-2">Email:</span>
                                  <span>{authState.email}</span>
                                </li>
                                <li class="mb-3">
                                  <span class="fw-bold me-2">Status:</span>
                                  <span class="badge bg-label-success">Active</span>
                                </li>
                                <li class="mb-3">
                                  <span class="fw-bold me-2">Role:</span>
                                  <span>Farmer</span>
                                </li>
                                
                                <li class="mb-3">
                                  <span class="fw-bold me-2">Contact:</span>
                                  <span>{authState.phone_no}</span>
                                </li>
                                <li class="mb-3">
                                  <span class="fw-bold me-2">Languages:</span>
                                  <span>Kiswahili</span>
                                </li>
                                <li class="mb-3">
                                  <span class="fw-bold me-2">Country:</span>
                                  <span>Kenya</span>
                                </li>
                              </ul>
                              <div class="d-flex justify-content-center pt-3">
                                <a href="javascript:;" class="btn btn-primary me-3" data-bs-target="#editUser" data-bs-toggle="modal">Edit</a>
                                <a href="javascript:;" class="btn btn-label-danger suspend-user">Suspended</a>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                        <div class="card">
                          <div class="card-body">
                            <div class="d-flex justify-content-between align-items-start">
                              <span class="badge bg-label-primary">Standard</span>
                              <div class="d-flex justify-content-center">
                                <sup class="h5 pricing-currency mt-3 mt-sm-4 mb-0 me-1 text-primary">$</sup>
                                <h1 class="display-3 fw-normal mb-0 text-primary">99</h1>
                                <sub class="fs-6 pricing-duration mt-auto mb-4">/month</sub>
                              </div>
                            </div>
                            <ul class="ps-3 g-2 mb-3">
                              <li class="mb-2">10 Users</li>
                              <li class="mb-2">Up to 10 GB storage</li>
                              <li>Basic Support</li>
                            </ul>
                            <div class="d-flex justify-content-between align-items-center mb-1">
                              <h6 class="mb-0">Days</h6>
                              <h6 class="mb-0">65% Completed</h6>
                            </div>
                            <div class="progress mb-1" style={{height: '8px'}}>
                              <div class="progress-bar" role="progressbar" style={{width: '65%'}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <span>4 days remaining</span>
                            <div class="d-grid w-100 mt-3 pt-2">
                              <button class="btn btn-primary" data-bs-target="#upgradePlanModal" data-bs-toggle="modal">Upgrade Plan</button>
                            </div>
                          </div>
                        </div>
                      
                      </div>
                     
                      <div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                      
                        <ul class="nav nav-pills flex-column flex-md-row mb-3">
                          <li class="nav-item"><a class="nav-link active" href="javascript:void(0);"><i class="bx bx-user me-1"></i>Account</a></li>
                          <li class="nav-item"><a class="nav-link" href="#"><i class="bx bx-lock-alt me-1"></i>Security</a></li>
                          <li class="nav-item"><a class="nav-link" href="#"><i class="bx bx-detail me-1"></i>Billing & Plans</a></li>
                          <li class="nav-item"><a class="nav-link" href="/user-notifications"><i class="bx bx-bell me-1"></i>Notifications</a></li>
                          <li class="nav-item"><a class="nav-link" href="#"><i class="bx bx-link-alt me-1"></i>Connections</a></li>
                        </ul>
                       
                        <div class="card mb-4">
                          <h5 class="card-header">User's Projects List</h5>
                          <div class="table-responsive mb-3">
                            <table class="table datatable-project border-top">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Project</th>
                                  <th class="text-nowrap">Total Task</th>
                                  <th>Progress</th>
                                  <th>Hours</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                       
                        <div class="card mb-4">
                          <h5 class="card-header">User Activity Timeline</h5>
                          <div class="card-body">
                            <ul class="timeline">
                              <li class="timeline-item timeline-item-transparent">
                                <span class="timeline-point timeline-point-primary"></span>
                                <div class="timeline-event">
                                  <div class="timeline-header mb-1">
                                    <h6 class="mb-0">12 Invoices have been paid</h6>
                                    <small class="text-muted">12 min ago</small>
                                  </div>
                                  <p class="mb-2">Invoices have been paid to the company</p>
                                  <div class="d-flex">
                                    <a href="javascript:void(0)" class="me-3">
                                      <img src="assets_admin/img/icons/misc/pdf.png" alt="PDF image" width="20" class="me-2"/>
                                      <span class="fw-bold text-body">invoices.pdf</span>
                                    </a>
                                  </div>
                                </div>
                              </li>
                              <li class="timeline-item timeline-item-transparent">
                                <span class="timeline-point timeline-point-warning"></span>
                                <div class="timeline-event">
                                  <div class="timeline-header mb-1">
                                    <h6 class="mb-0">Client Meeting</h6>
                                    <small class="text-muted">45 min ago</small>
                                  </div>
                                  <p class="mb-2">Project meeting with john @10:15am</p>
                                  <div class="d-flex flex-wrap">
                                    <div class="avatar me-3">
                                      <img src="assets_admin/img/avatars/3.png" alt="Avatar" class="rounded-circle" />
                                    </div>
                                    <div>
                                      <h6 class="mb-0">Lester McCarthy (Client)</h6>
                                      <span>CEO of PIXINVENT</span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li class="timeline-item timeline-item-transparent">
                                <span class="timeline-point timeline-point-info"></span>
                                <div class="timeline-event">
                                  <div class="timeline-header mb-1">
                                    <h6 class="mb-0">Create a new project for client</h6>
                                    <small class="text-muted">2 Day Ago</small>
                                  </div>
                                  <p class="mb-2">5 team members in a project</p>
                                  <div class="d-flex align-items-center avatar-group">
                                    <div class="avatar pull-up" data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" title="Vinnie Mostowy">
                                      <img src="assets_admin/img/avatars/5.png" alt="Avatar" class="rounded-circle"/>
                                    </div>
                                    <div class="avatar pull-up" data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" title="Marrie Patty">
                                      <img src="assets_admin/img/avatars/12.png" alt="Avatar" class="rounded-circle"/>
                                    </div>
                                    <div class="avatar pull-up" data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" title="Jimmy Jackson">
                                      <img src="assets_admin/img/avatars/9.png" alt="Avatar" class="rounded-circle"/>
                                    </div>
                                    <div class="avatar pull-up" data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" title="Kristine Gill">
                                      <img src="assets_admin/img/avatars/6.png" alt="Avatar" class="rounded-circle"/>
                                    </div>
                                    <div class="avatar pull-up" data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" title="Nelson Wilson">
                                      <img src="assets_admin/img/avatars/14.png" alt="Avatar" class="rounded-circle"/>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li class="timeline-item timeline-item-transparent">
                                <span class="timeline-point timeline-point-success"></span>
                                <div class="timeline-event">
                                  <div class="timeline-header mb-1">
                                    <h6 class="mb-0">Design Review</h6>
                                    <small class="text-muted">5 days Ago</small>
                                  </div>
                                  <p class="mb-0">Weekly review of freshly prepared design for our new app.</p>
                                </div>
                              </li>
                              <li class="timeline-end-indicator">
                                <i class="bx bx-check-circle"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
          
                        <div class="card">
                          <div class="table-responsive mb-3">
                            <table class="table datatable-invoice border-top">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>ID</th>
                                  <th><i class='bx bx-trending-up'></i></th>
                                  <th>Total</th>
                                  <th>Issued Date</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                      
                      </div>
                     
                    </div>
                    
                  
                    <div class="modal fade" id="editUser" tabindex="-1" aria-hidden="true">
                      <div class="modal-dialog modal-lg modal-simple modal-edit-user">
                        <div class="modal-content p-3 p-md-5">
                          <div class="modal-body">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div class="text-center mb-4">
                              <h3>Edit User Information</h3>
                              <p>Updating user details will receive a privacy audit.</p>
                            </div>
                            <form id="editUserForm" class="row g-3" onsubmit="return false">
                              <div class="col-12 col-md-6">
                                <label class="form-label" for="modalEditUserFirstName">First Name</label>
                                <input type="text" id="modalEditUserFirstName" name="modalEditUserFirstName"
                                value={first_name}

                                onChange={(event) => {
                                  setFirstName(event.target.value);
                                }}
                                
                                class="form-control" placeholder="Faith" />
                              </div>
                              <div class="col-12 col-md-6">
                                <label class="form-label" for="modalEditUserLastName">Last Name</label>
                                <input type="text" id="modalEditUserLastName" name="modalEditUserLastName" 

                                value={last_name}
                                
                                onChange={(event) => {
                                  setLastName(event.target.value);
                                }}
                                
                                
                                class="form-control" placeholder="Njuguna" />
                              </div>
                              <div class="col-12">
                                <label class="form-label" for="modalEditUserName">Username</label>
                                <input type="text" id="modalEditUserName" name="modalEditUserName" class="form-control" 
                                
                                value={username}

                                onChange={(event) => {
                                  setUserName(event.target.value);
                                }}
                                
                                placeholder="njugunaf@g.com" />
                              </div>
                              <div class="col-12 col-md-6">
                                <label class="form-label" for="modalEditUserEmail">Email</label>
                                <input type="text" id="modalEditUserEmail" name="modalEditUserEmail" class="form-control" 
                                
                                value={email}

                                onChange={(event) => {
                                  setEmail(event.target.value);
                                }}


                                placeholder="shadjava@gmail.com" />
                              </div>
                             

                            
                              <div class="col-12 col-md-6">
                                <label class="form-label" for="modalEditUserPhone">Phone Number</label>
                                <div class="input-group input-group-merge">
                                  <span class="input-group-text">+254</span>
                                  <input type="text" id="modalEditUserPhone" name="modalEditUserPhone" class="form-control phone-number-mask"
                                  
                                  value={phone_no}

                                  onChange={(event) => {
                                    setPhone_no(event.target.value);
                                  }}



                                  placeholder="+254780077090" />
                                </div>
                              </div>
                             
                              <div class="col-12 col-md-6">
                                <label class="form-label" for="modalEditUserCountry">Country</label>
                                <select id="modalEditUserCountry" name="modalEditUserCountry" class="select2 form-select"

                                value={country}
                                
                                onChange={(event) => {
                                  setCountry(event.target.value);
                                }}
                                
                                data-allow-clear="true">
                                  <option value="">Select</option>
                                  <option value="Australia">Australia</option>

                                  <option value="Kenya">Kenya</option>

                                  <option value="Uganda">Uganda</option>

                                  <option value="Tanzania">Tanzania</option>
                                
                                  <option value="Canada">Canada</option>
                               
                                  <option value="Turkey">Turkey</option>
                                  <option value="Ukraine">Ukraine</option>
                                  <option value="United Arab Emirates">United Arab Emirates</option>
                                  <option value="United Kingdom">United Kingdom</option>
                                  <option value="United States">United States</option>
                                </select>
                              </div>

                              <div class="col-12 col-md-6">
                                <label class="form-label" for="modalEditTaxID">Where are you located?</label>
                                <input type="text" id="modalEditTaxID" name="city" class="form-control modal-edit-tax-id"
                                
                                value={city}
                                
                                onChange={(event) => {
                                  setCity(event.target.value);
                                }}
                                
                                placeholder="Enter your farm location" />
                              </div>

                              <div class="col-12">
                                <label class="switch">
                                  <input type="checkbox" class="switch-input"/>
                                  <span class="switch-toggle-slider">
                                    <span class="switch-on"></span>
                                    <span class="switch-off"></span>
                                  </span>
                                  <span class="switch-label">Use as a billing address?</span>
                                </label>
                              </div>
                              <div class="col-12 text-center mt-4">

                              {!isLoading && <button type="submit" onClick={updateHandler} class="btn btn-primary me-sm-3 me-1" title="Save" >Save</button>

                            }
                            {isLoading &&
                              <button type="submit" class="btn btn-primary ml-auto js-btn-next" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Saving Infor</button>
                            }


                               
                                <button type="reset" class="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="modal fade" id="upgradePlanModal" tabindex="-1" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered modal-simple modal-upgrade-plan">
                        <div class="modal-content p-3 p-md-5">
                          <div class="modal-body">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div class="text-center mb-4">
                              <h3>Upgrade Plan</h3>
                              <p>Choose the best plan for user.</p>
                            </div>
                            <form id="upgradePlanForm" class="row g-3" onsubmit="return false">
                              <div class="col-sm-9">
                                <label class="form-label" for="choosePlan">Choose Plan</label>
                                <select id="choosePlan" name="choosePlan" class="form-select" aria-label="Choose Plan">
                                  <option selected>Choose Plan</option>
                                  <option value="standard">Standard - $99/month</option>
                                  <option value="exclusive">Exclusive - $249/month</option>
                                  <option value="Enterprise">Enterprise - $499/month</option>
                                </select>
                              </div>
                              <div class="col-sm-3 d-flex align-items-end">
                                <button type="submit" class="btn btn-primary">Upgrade</button>
                              </div>
                            </form>
                          </div>
                          <hr class="mx-md-n5 mx-n3"/>
                          <div class="modal-body">
                            <h6 class="mb-0">User current plan is standard plan</h6>
                            <div class="d-flex justify-content-between align-items-center flex-wrap">
                              <div class="d-flex justify-content-center me-2 mt-2">
                                <sup class="h5 pricing-currency fw-normal pt-2 mt-4 mb-0 me-1 text-primary">$</sup>
                                <h1 class="fw-normal display-1 mb-0 text-primary">99</h1>
                                <sub class="h5 pricing-duration mt-auto mb-3">/month</sub>
                              </div>
                              <button class="btn btn-label-danger cancel-subscription mt-3">Cancel Subscription</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                                
                              </div>
                   
                    
                <footer class="content-footer footer bg-footer-theme">
                <div class="container-fluid d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                <div class="mb-2 mb-md-0">
                  © <script>
                  document.write(new Date().getFullYear())
                  </script>
                  , made with ❤️ by <a href="https://pixinvent.com/" target="_blank" class="footer-link fw-semibold">PIXINVENT</a>
                </div>
                <div>
                  
                  <a href="https://themeforest.net/licenses/standard" class="footer-link me-4" target="_blank">License</a>
                  <a href="https://1.envato.market/pixinvent_portfolio" target="_blank" class="footer-link me-4">More Themes</a>
                  
                  <a href="https://pixinvent.com/demo/frest-clean-bootstrap-admin-dashboard-template/documentation-bs5/" target="_blank" class="footer-link me-4">Documentation</a>
                  
                  <a href="https://pixinvent.ticksy.com/" target="_blank" class="footer-link d-none d-sm-inline-block">Support</a>
                  
                </div>
                </div>
                </footer>
                
                
                      
                      <div class="content-backdrop fade"></div>
                    </div>
  
                    
                    {/* Content wrapper */}
                </div>
                {/* / Layout page */}
            </div>
            {/* Overlay */}
            <div className="layout-overlay layout-menu-toggle" />
            {/* Drag Target Area To SlideIn Menu On Small Screens */}
            <div className="drag-target" />
        </div>
        <Helmet>
        <script src="assets_admin/vendor/libs/jquery/jquery.js"></script>
        <script src="assets_admin/vendor/libs/popper/popper.js"></script>
        <script src="assets_admin/vendor/js/bootstrap.js"></script>
        <script src="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
        <script src="assets_admin/vendor/libs/hammer/hammer.js"></script>
        <script src="assets_admin/vendor/libs/i18n/i18n.js"></script>
        <script src="assets_admin/vendor/libs/typeahead-js/typeahead.js"></script>
        <script src="assets_admin/vendor/js/menu.js"></script>
  
  
      
  
  
  
        <script src="assets_admin/vendor/libs/select2/select2.js"></script>
  <script src="assets_admin/assets/vendor/libs/flatpickr/flatpickr.js"></script>
  <script src="assets_admin/assets/vendor/libs/moment/moment.js"></script>
  
  
  
  <script src="assets_admin/vendor/libs/datatables/jquery.dataTables.js"></script>
  <script src="assets_admin/vendor/libs/datatables-bs5/datatables-bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-responsive/datatables.responsive.js"></script>
  <script src="assets_admin/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/datatables-buttons.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/jszip/jszip.js"></script>
  <script src="assets_admin/vendor/libs/pdfmake/pdfmake.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/buttons.html5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/buttons.print.js"></script>
  
  
    
        <script src="assets_admin/js/main.js"></script>
     
  
  
        <script src="assets_admin/js/app-calendar-events.js"></script>
        <script src="assets_admin/js/app-calendar.js"></script>
        </Helmet>
    </div>
  )
}

export default CustomerAccountSetting