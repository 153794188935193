

import React from 'react'



export default function PricingSection1() {
  
    return (

        <div>


      


    <section class="bg-cover-2 py-6 pb-lg-9" style={{marginTop:'80px'}}>
        <div class="container">
            <div class="row text-center">
                <div class="col">
                    <h1 class="font-weight-bold text-white mb-4 mb-lg-6" data-aos="fade-up">Find the Plan to help<br/>your Business Grow</h1>
                </div>
            </div>

            <div class="card bg-light-primary smooth-shadow-2 p-5 mx-xxl-6">
                <div class="row justify-content-between align-items-end" data-aos="fade-up" data-aos-delay="50">
                    <div class="col-lg-7 mb-4 mb-lg-0">
                        <img class="img-fluid mb-2" src="./images/media/plant-0.png" alt=""/>

                        <h2 class="font-weight-bold h4">Free Plan</h2>
                        <p class="text-muted mb-4">All our packages include the following.</p>

                        <a href='/start-now' class="btn btn-outline-soft-primary waves-effect btn-sm">Start 30Days Free Trial</a>
                    </div>
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col">
                                <ul class="list-unstyled">
                                    <li class="d-flex align-items-center py-2">
                                        <span class="check-true m-0 mr-2"></span>

                                        <span>Online booking</span>
                                    </li>
                                    <li class="d-flex align-items-center py-2">
                                        <span class="check-true m-0 mr-2"></span>
                                        <span>Appointment</span>
                                    </li>
                                    <li class="d-flex align-items-center py-2">
                                        <span class="check-true m-0 mr-2"></span>
                                        <span>Social media integrations</span>
                                    </li>
                                    <li class="d-flex align-items-center py-2">
                                        <span class="check-true m-0 mr-2"></span>
                                        <span>Website/CMS Integrations </span>
                                    </li>
                                    <li class="d-flex align-items-center py-2">
                                    <span class="check-true m-0 mr-2"></span>
                                    <span>Confirmation emails</span>
                                </li>
                                <li class="d-flex align-items-center py-2">
                                <span class="check-true m-0 mr-2"></span>
                                <span>Multi device access</span>
                            </li>

                            <li class="d-flex align-items-center py-2">
                                <span class="check-true m-0 mr-2"></span>
                                <span>Security </span>
                            </li>
                            <li class="d-flex align-items-center py-2">
                            <span class="check-true m-0 mr-2"></span>
                            <span>Unlimited Customer support </span>
                        </li>
                                </ul>
                            </div>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="py-6">
    <div class="container">
        <div class="row justify-content-center text-center">
            
            <div class="col">

            <div class="btn-group bg-white" role="group" aria-label="Basic example">
            <ul class="nav nav-line-animated justify-content-center text-center" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="btn btn-outline-primary active" data-toggle="tab" href="#tab-pane-1" role="tab">Monthly</a>

              
                
            </li>
            <li class="nav-item" role="presentation">
            <span class="badge badge-pill badge-danger py-2 position-absolute top-0 right-0 mt-n3 mr-n4">12% OFF</span>
                <a class="btn btn-outline-primary"   data-toggle="tab" href="#tab-pane-2" role="tab">Half Yearly</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="btn btn-outline-primary"  data-toggle="tab" href="#tab-pane-3" role="tab">Yearly<span class="badge badge-pill badge-danger py-2 position-absolute top-0 right-0 mt-n3 mr-n4">15% OFF</span></a>
           
                <span class="badge badge-pill badge-danger py-2 position-absolute top-0 right-0 mt-n3 mr-n4">15% OFF</span>
                </li>
        </ul>
        </div>
            
            
            
            </div>
            
        </div>


           <div class="tab-content">
                                <div class="tab-pane fade show active" id="tab-pane-1" role="tabpanel">
                                    
                                <section id="pricing" class="pricing">
                                <div class="container">
                          
                                  <div class="section-title" data-aos="fade-up">
                                   
                                    <p>Check our Monthly Pricing</p>
                                  </div>
                          
                                  <div class="row" data-aos="fade-left">
                          
                                    <div class="col-lg-3 col-md-6">
                                      <div class="box" data-aos="zoom-in" data-aos-delay="100">
                                      <span class="advanced">Solo</span>
                                        <h3>Solo</h3>
                                        <h4><sup>Ksh</sup>1740<span> / month</span></h4>
                                        <ul>
                          
                          
                                        
                          
                          
                          
                          
                                          <li>Online booking</li>
                                          <li>Appointment </li>
                                          <li>Social media integrations</li>
                                          <li class="na">Website/CMS Integrations</li>
                                        <li class="na">Confirmation emails</li>
                                        <li class="na">Multi device access </li>
                                        <li class="na"> Security</li>
                                        <li class="na"> Unlimited Customer support</li>
                                        <li class="na"> Learning resources  </li>
                                        </ul>
                                        <div class="btn-wrap">
                                          <a href="/start-now" class="btn-buy">Start Free</a>
                                        </div>
                                      </div>
                                    </div>
                          
                                    <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
                                      <div class="box featured" data-aos="zoom-in" data-aos-delay="200">
                                        <h3>Small</h3>
                                        <h4><sup>Ksh</sup>3480<span> / month</span></h4>
                                      
                                        <ul>
                                        <li>Online booking</li>
                                        <li>Appointment </li>
                                        <li>Social media integrations </li>
                                        <li>Website/CMS Integrations</li>
                                        <li>Confirmation emails</li>
                                        <li> Multi device access </li>
                                        <li> Security </li>
                                        <li class="na"> Unlimited Customer support  </li>
                                        <li class="na"> Learning resources  </li>
                                        </ul>
                                        <div class="btn-wrap">
                                        <a href="/start-now" class="btn-buy">Start Small</a>
                                        </div>
                                      </div>
                                    </div>
                          
                                    <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
                                      <div class="box" data-aos="zoom-in" data-aos-delay="300">
                                      <span class="advanced">Medium</span>
                                        <h3>Medium</h3>
                                        <h4><sup>Ksh</sup>5550<span> / month</span></h4>
                                        <ul>
                                        <li>Online booking</li>
                                        <li>Appointment </li>
                                        <li>Social media integrations </li>
                                        <li>Website/CMS Integrations</li>
                                        <li>Confirmation emails</li>
                                        <li> Multi device access </li>
                                        <li> Security </li>
                                        <li> Unlimited Customer support  </li>
                                        <li class="na"> Learning resources  </li>
                                        </ul>
                                        <div class="btn-wrap">
                                        <a href="/start-now" class="btn-buy">Start Medium</a>
                                        </div>
                                      </div>
                                    </div>
                          
                                    <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
                                      <div class="box" data-aos="zoom-in" data-aos-delay="400">
                                        <span class="advanced">Large</span>
                                        <h3>Large</h3>
                                        <h4><sup>Ksh</sup>8400<span> / month</span></h4>
                                        <ul>
                                        <li>Online booking</li>
                                        <li>Appointment </li>
                                        <li>Social media integrations </li>
                                        <li>Website/CMS Integrations</li>
                                        <li>Confirmation emails</li>
                                        <li> Multi device access </li>
                                        <li> Security </li>
                                        <li> Unlimited Customer support  </li>
                                        <li> Learning resources  </li>
                                        </ul>
                                        <div class="btn-wrap">
                                        <a href="/start-now" class="btn-buy">Start Large</a>
                                        </div>
                                      </div>
                                    </div>
                          
                                  </div>
                          
                                </div>
                              </section>
                                </div>
                                <div class="tab-pane fade" id="tab-pane-2" role="tabpanel">
                                <section id="pricing" class="pricing">
                                <div class="container">
                          
                                  <div class="section-title" data-aos="fade-up">
                                   
                                    <p>Check Our Half Yearly Pricing</p>
                                  </div>
                          
                                  <div class="row" data-aos="fade-left">
                          
                                    <div class="col-lg-3 col-md-6">
                                      <div class="box" data-aos="zoom-in" data-aos-delay="100">
                                      <span class="advanced">Solo</span>
                                        <h3>Solo</h3>
                                        <h4><sup>Ksh</sup>1530<span> / half Yearly</span></h4>
                                        <ul>
                          
                          
                                        
                          
                          
                          
                          
                                          <li>Online booking</li>
                                          <li>Appointment </li>
                                          <li>Social media integrations</li>
                                          <li class="na">Website/CMS Integrations</li>
                                        <li class="na">Confirmation emails</li>
                                        <li class="na">Multi device access </li>
                                        <li class="na"> Security</li>
                                        <li class="na"> Unlimited Customer support</li>
                                        <li class="na"> Learning resources  </li>
                                        </ul>
                                        <div class="btn-wrap">
                                          <a href="/start-now" class="btn-buy">Start Free</a>
                                        </div>
                                      </div>
                                    </div>
                          
                                    <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
                                      <div class="box featured" data-aos="zoom-in" data-aos-delay="200">
                                        <h3>Small</h3>
                                        <h4><sup>Ksh</sup>3062<span> / Half Yearly</span></h4>
                                      
                                        <ul>
                                        <li>Online booking</li>
                                        <li>Appointment </li>
                                        <li>Social media integrations </li>
                                        <li>Website/CMS Integrations</li>
                                        <li>Confirmation emails</li>
                                        <li> Multi device access </li>
                                        <li> Security </li>
                                        <li class="na"> Unlimited Customer support  </li>
                                        <li class="na"> Learning resources  </li>
                                        </ul>
                                        <div class="btn-wrap">
                                        <a href="/start-now" class="btn-buy">Start Small</a>
                                        </div>
                                      </div>
                                    </div>
                          
                                    <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
                                      <div class="box" data-aos="zoom-in" data-aos-delay="300">
                                      <span class="advanced">Medium</span>
                                        <h3>Medium</h3>
                                        <h4><sup>Ksh</sup>29304<span> / Half Yearly</span></h4>
                                        <ul>
                                        <li>Online booking</li>
                                        <li>Appointment </li>
                                        <li>Social media integrations </li>
                                        <li>Website/CMS Integrations</li>
                                        <li>Confirmation emails</li>
                                        <li> Multi device access </li>
                                        <li> Security </li>
                                        <li> Unlimited Customer support  </li>
                                        <li class="na"> Learning resources  </li>
                                        </ul>
                                        <div class="btn-wrap">
                                        <a href="/start-now" class="btn-buy">Start Medium</a>
                                        </div>
                                      </div>
                                    </div>
                          
                                    <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
                                      <div class="box" data-aos="zoom-in" data-aos-delay="400">
                                        <span class="advanced">Large</span>
                                        <h3>Large</h3>
                                        <h4><sup>Ksh</sup>44352<span> / Half Yearly</span></h4>
                                        <ul>
                                        <li>Online booking</li>
                                        <li>Appointment </li>
                                        <li>Social media integrations </li>
                                        <li>Website/CMS Integrations</li>
                                        <li>Confirmation emails</li>
                                        <li> Multi device access </li>
                                        <li> Security </li>
                                        <li> Unlimited Customer support  </li>
                                        <li> Learning resources  </li>
                                        </ul>
                                        <div class="btn-wrap">
                                        <a href="/start-now" class="btn-buy">Start Large</a>
                                        </div>
                                      </div>
                                    </div>
                          
                                  </div>
                          
                                </div>
                              </section>
                                </div>
                                <div class="tab-pane fade" id="tab-pane-3" role="tabpanel">
                                <section id="pricing" class="pricing">
                                <div class="container">
                          
                                  <div class="section-title" data-aos="fade-up">
                                   
                                    <p>Check Our Yearly Pricing</p>
                                  </div>
                          
                                  <div class="row" data-aos="fade-left">
                          
                                    <div class="col-lg-3 col-md-6">
                                      <div class="box" data-aos="zoom-in" data-aos-delay="100">
                                      <span class="advanced">Solo</span>
                                        <h3>Solo</h3>
                                        <h4><sup>Ksh</sup>16704<span> /Yearly</span></h4>
                                        <ul>
                          
                          
                                        
                          
                          
                          
                          
                                          <li>Online booking</li>
                                          <li>Appointment </li>
                                          <li>Social media integrations</li>
                                          <li class="na">Website/CMS Integrations</li>
                                        <li class="na">Confirmation emails</li>
                                        <li class="na">Multi device access </li>
                                        <li class="na"> Security</li>
                                        <li class="na"> Unlimited Customer support</li>
                                        <li class="na"> Learning resources  </li>
                                        </ul>
                                        <div class="btn-wrap">
                                          <a href="/start-now" class="btn-buy">Start Free</a>
                                        </div>
                                      </div>
                                    </div>
                          
                                    <div class="col-lg-3 col-md-6 mt-4 mt-md-0">
                                      <div class="box featured" data-aos="zoom-in" data-aos-delay="200">
                                        <h3>Small</h3>
                                        <h4><sup>Ksh</sup>33408<span> / Yearly</span></h4>
                                      
                                        <ul>
                                        <li>Online booking</li>
                                        <li>Appointment </li>
                                        <li>Social media integrations </li>
                                        <li>Website/CMS Integrations</li>
                                        <li>Confirmation emails</li>
                                        <li> Multi device access </li>
                                        <li> Security </li>
                                        <li class="na"> Unlimited Customer support  </li>
                                        <li class="na"> Learning resources  </li>
                                        </ul>
                                        <div class="btn-wrap">
                                        <a href="/start-now" class="btn-buy">Start Small</a>
                                        </div>
                                      </div>
                                    </div>
                          
                                    <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
                                      <div class="box" data-aos="zoom-in" data-aos-delay="300">
                                      <span class="advanced">Medium</span>
                                        <h3>Medium</h3>
                                        <h4><sup>Ksh</sup>53280<span> / Yearly</span></h4>
                                        <ul>
                                        <li>Online booking</li>
                                        <li>Appointment </li>
                                        <li>Social media integrations </li>
                                        <li>Website/CMS Integrations</li>
                                        <li>Confirmation emails</li>
                                        <li> Multi device access </li>
                                        <li> Security </li>
                                        <li> Unlimited Customer support  </li>
                                        <li class="na"> Learning resources  </li>
                                        </ul>
                                        <div class="btn-wrap">
                                        <a href="/start-now" class="btn-buy">Start Medium</a>
                                        </div>
                                      </div>
                                    </div>
                          
                                    <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
                                      <div class="box" data-aos="zoom-in" data-aos-delay="400">
                                        <span class="advanced">Large</span>
                                        <h3>Large</h3>
                                        <h4><sup>Ksh</sup>80640<span> / Yearly</span></h4>
                                        <ul>
                                        <li>Online booking</li>
                                        <li>Appointment </li>
                                        <li>Social media integrations </li>
                                        <li>Website/CMS Integrations</li>
                                        <li>Confirmation emails</li>
                                        <li> Multi device access </li>
                                        <li> Security </li>
                                        <li> Unlimited Customer support  </li>
                                        <li> Learning resources  </li>
                                        </ul>
                                        <div class="btn-wrap">
                                        <a href="/start-now" class="btn-buy">Start Large</a>
                                        </div>
                                      </div>
                                    </div>
                          
                                  </div>
                          
                                </div>
                              </section>
                                </div>
                            </div>

       

        <div class="row justify-content-center text-center text-gray-500">
            <div class="col-12 text-size-small" data-aos="fade-up">
                <p>Pricing is in US Dollars. Inclusive of all taxes (if applicable).</p>
                <img class="img-fluid" src="./images/media/payment-accepted.jpg" alt=""/>
            </div>
        </div>
    </div>
</section>

   
    


    
    </div>

        )


}