import React from "react";

export default function Sidebar() {
	return (
		<div>
		<div className="side-content-wrap">
        <div className="sidebar-left open rtl-ps-none" data-perfect-scrollbar data-suppress-scroll-x="true" style={{backgroundColor:"#82b8af", fontColor:"white"}}>
          <ul className="navigation-left">
            <li className="nav-item"><a className="nav-item-hold" href="/dashboard"><i className="nav-icon i-Bar-Chart" /><span className="nav-text">Dashboard</span></a>
              <div className="triangle" />
            </li>
            <li className="nav-item"><a className="nav-item-hold" href="#"><i className="nav-icon i-Calendar-2" /><span className="nav-text">Calender</span></a>
              <div className="triangle" />
            </li>
            <li className="nav-item"><a className="nav-item-hold" href="/customer"><i className="nav-icon i-Conference" /><span className="nav-text">Customers</span></a>
              <div className="triangle" />
            </li>
            <li className="nav-item"><a className="nav-item-hold" href="#"><i className="nav-icon i-Monitor-5" /><span className="nav-text">Apps &amp; Intergrations</span></a>
              <div className="triangle" />
            </li>
            <li className="nav-item" data-item="widgets"><a className="nav-item-hold" href="#"><i className="nav-icon i-Data-Settings" /><span className="nav-text">Settings</span></a>
              <div className="triangle" />
            </li>
            <li className="nav-item"><a className="nav-item-hold" href="#"><i className="nav-icon i-Shield" /><span className="nav-text">Products</span></a>
              <div className="triangle" />
            </li>
            <li className="nav-item"><a className="nav-item-hold" href="#"><i className="nav-icon i-Administrator" /><span className="nav-text">Users</span></a>
              <div className="triangle" />
            </li>
            <li className="nav-item"><a className="nav-item-hold" href="datatables.html"><i className="nav-icon i-Bar-Chart-5" /><span className="nav-text">Reports</span></a>
              <div className="triangle" />
            </li>
            <li className="nav-item"><a className="nav-item-hold" href="http://demos.ui-lib.com/gull-html-doc/" target="_blank"><i className="nav-icon i-Safe-Box1" /><span className="nav-text">Support</span></a>
              <div className="triangle" />
            </li>
          </ul>
        </div>
        <div className="sidebar-left-secondary rtl-ps-none" data-perfect-scrollbar data-suppress-scroll-x="true">
          {/* Submenu Dashboards*/}
          <ul className="childNav" data-parent="widgets">
            <li className="nav-item"><a href="/account"><i className="nav-icon i-Add-UserStar" /><span className="item-name">Account</span></a></li>
            <li className="nav-item"><a href="<?php echo base_url().'dashboard/settings/staff' ?>"><i className="nav-icon i-Administrator" /><span className="item-name">Staff</span></a></li>
            <li className="nav-item"><a href="widget-list.html"><i className="nav-icon i-Suitcase" /><span className="item-name">Services </span></a></li>
            <li className="nav-item"><a href="widget-app.html"><i className="nav-icon i-Money1" /><span className="item-name">Payments </span></a></li>
            <li className="nav-item"><a href="weather-card.html"><i className="nav-icon i-Students" /><span className="item-name">Classes</span></a></li>
            <li className="nav-item"><a href="weather-card.html"><i className="nav-icon i-Bell" /><span className="item-name">Notifications</span></a></li>
            <li className="nav-item"><a href="weather-card.html"><i className="nav-icon i-Receipt-4" /><span className="item-name">Booking Page</span></a></li>
          </ul>
          {/* chartjs*/}
          <ul className="childNav" data-parent="charts">
            <li className="nav-item"><a href="charts.echarts.html"><i className="nav-icon i-File-Clipboard-Text--Image" /><span className="item-name">echarts</span></a></li>
            <li className="nav-item"><a href="charts.chartsjs.html"><i className="nav-icon i-File-Clipboard-Text--Image" /><span className="item-name">ChartJs</span></a></li>
            <li className="nav-item dropdown-sidemenu"><a href="#"><i className="nav-icon i-File-Clipboard-Text--Image" /><span className="item-name">Apex Charts</span><i className="dd-arrow i-Arrow-Down" /></a>
              <ul className="submenu">
                <li><a href="charts.apexAreaCharts.html">Area Charts</a></li>
                <li><a href="charts.apexBarCharts.html">Bar Charts</a></li>
                <li><a href="charts.apexBubbleCharts.html">Bubble Charts</a></li>
                <li><a href="charts.apexColumnCharts.html">Column Charts</a></li>
                <li><a href="charts.apexCandleStickCharts.html">CandleStick Charts</a></li>
                <li><a href="charts.apexLineCharts.html">Line Charts</a></li>
                <li><a href="charts.apexMixCharts.html">Mix Charts</a></li>
                <li><a href="charts.apexPieDonutCharts.html">PieDonut Charts</a></li>
                <li><a href="charts.apexRadarCharts.html">Radar Charts</a></li>
                <li><a href="charts.apexRadialBarCharts.html">RadialBar Charts</a></li>
                <li><a href="charts.apexScatterCharts.html">Scatter Charts</a></li>
                <li><a href="charts.apexSparklineCharts.html">Sparkline Charts</a></li>
              </ul>
            </li>
          </ul>
          <ul className="childNav" data-parent="extrakits">
            <li className="nav-item"><a href="image.cropper.html"><i className="nav-icon i-Crop-2" /><span className="item-name">Image Cropper</span></a></li>
            <li className="nav-item"><a href="loaders.html"><i className="nav-icon i-Loading-3" /><span className="item-name">Loaders</span></a></li>
            <li className="nav-item"><a href="ladda.button.html"><i className="nav-icon i-Loading-2" /><span className="item-name">Ladda Buttons</span></a></li>
            <li className="nav-item"><a href="toastr.html"><i className="nav-icon i-Bell" /><span className="item-name">Toastr</span></a></li>
            <li className="nav-item"><a href="sweet.alerts.html"><i className="nav-icon i-Approved-Window" /><span className="item-name">Sweet Alerts</span></a></li>
            <li className="nav-item"><a href="tour.html"><i className="nav-icon i-Plane" /><span className="item-name">User Tour</span></a></li>
            <li className="nav-item"><a href="upload.html"><i className="nav-icon i-Data-Upload" /><span className="item-name">Upload</span></a></li>
          </ul>
          <ul className="childNav" data-parent="uikits">
            <li className="nav-item"><a href="alerts.html"><i className="nav-icon i-Bell1" /><span className="item-name">Alerts</span></a></li>
            <li className="nav-item"><a href="accordion.html"><i className="nav-icon i-Split-Horizontal-2-Window" /><span className="item-name">Accordion</span></a></li>
            <li className="nav-item"><a href="badges.html"><i className="nav-icon i-Medal-2" /><span className="item-name">Badges</span></a></li>
            <li className="nav-item"><a href="buttons.html"><i className="nav-icon i-Cursor-Click" /><span className="item-name">Buttons</span></a></li>
            <li className="nav-item"><a href="cards.html"><i className="nav-icon i-Line-Chart-2" /><span className="item-name">Cards</span></a></li>
            <li className="nav-item"><a href="card.metrics.html"><i className="nav-icon i-ID-Card" /><span className="item-name">Card Metrics</span></a></li>
            <li className="nav-item"><a href="carousel.html"><i className="nav-icon i-Video-Photographer" /><span className="item-name">Carousels</span></a></li>
            <li className="nav-item"><a href="lists.html"><i className="nav-icon i-Belt-3" /><span className="item-name">Lists</span></a></li>
            <li className="nav-item"><a href="pagination.html"><i className="nav-icon i-Arrow-Next" /><span className="item-name">Paginations</span></a></li>
            <li className="nav-item"><a href="popover.html"><i className="nav-icon i-Speach-Bubble-2" /><span className="item-name">Popover</span></a></li>
            <li className="nav-item"><a href="progressbar.html"><i className="nav-icon i-Loading" /><span className="item-name">Progressbar</span></a></li>
            <li className="nav-item"><a href="tables.html"><i className="nav-icon i-File-Horizontal-Text" /><span className="item-name">Tables</span></a></li>
            <li className="nav-item"><a href="tabs.html"><i className="nav-icon i-New-Tab" /><span className="item-name">Tabs</span></a></li>
            <li className="nav-item"><a href="tooltip.html"><i className="nav-icon i-Speach-Bubble-8" /><span className="item-name">Tooltip</span></a></li>
            <li className="nav-item"><a href="modals.html"><i className="nav-icon i-Duplicate-Window" /><span className="item-name">Modals</span></a></li>
            <li className="nav-item"><a href="nouislider.html"><i className="nav-icon i-Width-Window" /><span className="item-name">Sliders</span></a></li>
          </ul>
          <ul className="childNav" data-parent="sessions">
            <li className="nav-item"><a href="http://demos.ui-lib.com/gull/html/sessions/signin.html"><i className="nav-icon i-Checked-User" /><span className="item-name">Sign in</span></a></li>
            <li className="nav-item"><a href="http://demos.ui-lib.com/gull/html/sessions/signup.html"><i className="nav-icon i-Add-User" /><span className="item-name">Sign up</span></a></li>
            <li className="nav-item"><a href="http://demos.ui-lib.com/gull/html/sessions/forgot.html"><i className="nav-icon i-Find-User" /><span className="item-name">Forgot</span></a></li>
          </ul>
          <ul className="childNav" data-parent="others">
            <li className="nav-item"><a href="http://demos.ui-lib.com/gull/html/sessions/not-found.html"><i className="nav-icon i-Error-404-Window" /><span className="item-name">Not Found</span></a></li>
            <li className="nav-item"><a href="user.profile.html"><i className="nav-icon i-Male" /><span className="item-name">User Profile</span></a></li>
            <li className="nav-item"><a className="open" href="blank.html"><i className="nav-icon i-File-Horizontal" /><span className="item-name">Blank Page</span></a></li>
          </ul>
        </div>
        <div className="sidebar-overlay" />
      </div>
		</div>
	);
}
