
import React, { useCallback,useState,useEffect,useContext } from 'react';

import HeaderFloating from '../../Header/HeaderFloating'

import {useNavigate} from 'react-router-dom'

import {useParams } from "react-router-dom"

import axios from 'axios';

import {Helmet} from 'react-helmet'

import DetailsContent from './DetailsContent';
import DetailsContentNew from './DetailsContentNew';

export default function SellerDetails() {

    

    
  return (
    <div>

    <HeaderFloating/>

    <DetailsContent/>
   
    
    
    
    </div>
  )
}
