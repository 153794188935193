import React,{useEffect, useState, useContext} from 'react';
import FeaturedInfo from './FeaturedInfo';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, Link  } from "react-router-dom";
import pimage from '../../assets/dist-assets/images/faces/3.jpg';
import { Helmet } from "react-helmet";
import { AuthContext } from '../../helpers/AuthContext';
import Cfooter from './Cfooter';




export default function BusinessHours() {
    const navigate=useNavigate();
    const {authState} =useContext(AuthContext);
    const [bussData, setBussData]=useState([]);
    const [selectedTimezone, setSelectedTimezone] = useState({})
    var uid=0;
    useEffect( ()=> {
        axios.get('http://localhost:3001/users/auth',{headers:{ accessToken:localStorage.getItem("accessToken"), }}).then((resp)=>{
            uid=resp.data.id;
            if(!localStorage.getItem("accessToken")){
                console.log('NOT LOADED');
            navigate("/login");  
            }else{
                const id=authState.id;
                    axios.get(`http://localhost:3001/business/byId/${uid}`,{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
                    console.log(response.data);
                    setBussData(response.data);
                    });
            }
        });
    },[]);

    const initialValues = {
        business_name: bussData.business_name,
        business_description: '',
        industry: '',
    }
    const validationSchema  = Yup.object().shape({
        business_name: Yup.string().min(4, "It's too short").max(15, "Its Long Maximu 15 Characters").required("Business Name Required"),
        business_description: Yup.string().min(10, "It's too short").max(250, "It exceeds the required characters"),
        industry: Yup.string().required("Must provide"),
    })
    const onSubmit = (data) => {
        console.log(data);
        axios.post("http://localhost:3001/business", data,{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
        console.log(response);
        // navigate("/services");
        // window.location.reload();
        // if (response.status === 200)
        // alert("hello")
        // handleClose();
        // toast.success('Success Message')
        // props.resetForm()
        }); 
        
    }

    return (
        <div>
            <Helmet>
                <link rel="stylesheet" href="dist-assets/css/themes/lite-purple.min.css" />
                <link rel="stylesheet" href="dist-assets/css/plugins/perfect-scrollbar.min.css" />
            </Helmet>
            {authState.status===true &&

            <div className="text-left">
                <div className="app-admin-wrap layout-sidebar-large">
                    <Topbar />
                    <Sidebar />
                    <div className="main-content-wrap sidenav-open d-flex flex-column" style={{marginTop:"40px"}}>
                        {/* ============ Body content start ============= */}
                        <div className="main-content">
                            {/* MAIN SIDEBAR CONTAINER*/}
                            <div className="inbox-main-sidebar-container" data-sidebar-container="main">
                                <div className="inbox-main-content" data-sidebar-content="main">
                                    <div className="inbox-secondary-sidebar-container box-shadow-1" data-sidebar-container="secondary">
                                        <div data-sidebar-content>
                                            <div className="inbox-secondary-sidebar-content position-relative" style={{minHeight: '500px'}}>
                                                <div className="inbox-topbar box-shadow-1 perfect-scrollbar rtl-ps-none pl-3" data-suppress-scroll-y="true">
                                                    <a className="link-icon d-md-none" data-sidebar-toggle="main"><i className="icon-regular i-Arrow-Turn-Left" /></a><a className="link-icon mr-3 d-md-none" data-sidebar-toggle="secondary"><i className="icon-regular mr-1 i-Left-3" /> Inbox</a>
                                                    <div className="d-flex">
                                                        <h3 style={{marginTop:10}}>Business Operating Hours</h3>
                                                    </div>
                                                </div>
                                                <div className="inbox-details perfect-scrollbar rtl-ps-none" data-suppress-scroll-x="true">
                                                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={ onSubmit }  >
                                                    <Form>   
                                                        <div className="flex-grow-1" style={{padding:"15px;"}}>
                                                            <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">Timezone</label>
                                                            <span>Required to configure all the time based on operations on your Number.</span>
                                                                <div className="col-sm-6">
                                                               


                                                                    <ErrorMessage name='business_name' component="span" style={{color:"#f44336"}} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-sm-4 col-form-label">Business Hours</label>
                                                                <span>Display the hours your business operates on your Booking Page.</span>
                                                                <div className="col-sm-6">
                                                                    <Field as="select" className="form-control" name="business_description">
                                                                        <option>Weekdays</option>
                                                                    </Field>
                                                                    <ErrorMessage name='business_description' component="span" style={{color:"#f44336"}} />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <div className="col-sm-4">
                                                                    <Field className="form-control" name="date"/>
                                                                    <ErrorMessage name='business_description' component="span" style={{color:"#f44336"}} />
                                                                </div>
                                                            </div>
                                                            <div className='form-group row'>
                                                                <div className="col-sm-4">
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <button className="btn btn-info" type="submit">Save</button><span style={{paddingLeft:"40px"}}>            </span>
                                                                    <button className="btn btn-light" type="button">Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* MAIN INBOX SIDEBAR*/}
                                <div className="inbox-main-sidebar" data-sidebar="main" data-sidebar-position="left">
                                    <div className="pt-3 pr-3 pb-3"><i className="sidebar-close i-Close" data-sidebar-toggle="main" />
                                        <div className="pl-3">
                                            <ul className="inbox-main-nav">
                                                <li><a className="active" href="/account"><i className="icon-regular i-Home1" /> Overview</a></li>
                                                <li><a href="/company_details"><i className="icon-regular i-Suitcase" /> Company Details</a></li>
                                                <li><a href="/business_hours"><i className="icon-regular i-Clock" /> Business Hours</a></li>
                                                {/* <li><a href="#"><i className="icon-regular i-Folder-Trash" /> Booking Policies</a></li>
                                                <li><a href="#"><i className="icon-regular i-Spam-Mail" /> Customizations</a></li>
                                                <li><a href="#"><i className="icon-regular i-David-Star" /> Reviews</a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Footer Start*/}
                            <Cfooter/>
                        </div>{/* Footer Start */}
                    </div>
                </div>
            </div> }
            <Helmet>
                <script src="dist-assets/js/plugins/jquery-3.3.1.min.js"></script>
                <script src="dist-assets/js/plugins/bootstrap.bundle.min.js"></script>
                <script src="dist-assets/js/plugins/perfect-scrollbar.min.js"></script>
                <script src="dist-assets/js/scripts/script.min.js"></script>
                <script src="dist-assets/js/scripts/sidebar.large.script.min.js"></script>
                <script src="dist-assets/js/scripts/sidebar.script.min.js"></script>
                <script src="dist-assets/js/plugins/echarts.min.js"></script>
                <script src="dist-assets/js/scripts/echart.options.min.js"></script>
                <script src="dist-assets/js/scripts/dashboard.v1.script.min.js"></script>
            </Helmet>
        </div>
    )
}
