import React from 'react'
import HeaderFloating from '../../../Header/HeaderFloating'
import HeaderNew from '../../../Header/HeaderNew'
import MainContent from './MainContent'

function Fitness() {
  return (
    <div>

    <HeaderFloating/>
    <MainContent/>
    
    
    </div>
  )
}

export default Fitness