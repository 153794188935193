import React from 'react'
import SideBarMenu from '../SideBarMenu'

export default function MainFeatures() {
  return (
    <div>

    <section class="bg-soft-primary-pattern-1 pt-6 pb-8">
    <div class="container">
    <div class="row align-items-center justify-content-center justify-content-lg-between mb-6">
    <div class="col-lg-6 col-md-10 mb-5 mb-lg-0 text-center text-lg-left">
       
        <h1 class="font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="50">Feedback System  </h1>
        <p class="text-muted" data-aos="fade-up" data-aos-delay="100">The online reputation manager on Tunep gives you complete control on how people treat, react and rate your business online. Get the real-time opinion of your clients before they rant on social media. </p>
        
        <div class="d-flex flex-column align-items-center flex-sm-row justify-content-center justify-content-lg-start mt-6">
            <a href="javascript:;" class="d-inline-block btn btn-primary waves-effect mr-4 mb-3 mb-sm-0" data-aos="fade-up" data-aos-delay="150">View our services</a>
            <a href="javascript:;" class="d-inline-block font-weight-semibold text-primary" data-aos="fade-up" data-aos-delay="200">Learn More <i class="icon-chevron-right"></i></a>
        </div>
    </div>
    {/**  <div class="col-xxl-4 col-lg-5 col-md-9" data-aos="fade-up" data-aos-delay="200">
       <div class="card shadow-lg p-3 p-xl-5">
            <div class="d-flex">
                <span class="mr-3 fs-32">👋</span>
                <div>
                    <h4 class="font-weight-bold mb-1">
                    The next feature of appointment </h4>
                    <p class="text-gray-400 font-size-sm mb-0">What solution are you interested?</p>
                </div>
            </div>
            
            <hr/>
            
          
       </div>
    </div> */}
   
</div>   
    </div>
</section>

    <section class="py-6 mt-lg-n11">
    <div class="container">

    

    <div class="row pt-6 pt-lg-8">

    <SideBarMenu/>
 
    <div class="col-lg-9 order-1 order-lg-2 mb-5 mb-lg-0">

     
    <div class="clear"></div>
                      <div class="container features-descriptions padding-bottom-37">
                          <div class="row">
                              <div class="col-md-12 features-headings">
                                  <h3>Feedback System </h3>
                                  <span>The online reputation manager on Tunep gives you complete control on how people treat, react and rate your business online.</span>
                                  <a href="unlimited-appointments-clients.html" class="btn btn-tt-lightbkgrd-small-inverted">Learn more</a>
                              </div>
                              <div class="col-md-6 topmargin-sm text-center large-horizontal">
                              <img src="images/features/feedback-system.jpg" alt="A scheduling system that offers unlimited appointments" />
                              </div>
                              <div class="col-md-6 topmargin-sm">
                              <ul class="steps vertical list-unstyled mb-0">
                              <li data-aos="fade-up">
                                <span>1</span> 
                                Enhanced reputation
                                <p>Gain more ratings through automated settings. Gets your business to stand out from your competition online and improves your business’ ranking.</p>
                              </li>
                              <li data-aos="fade-up" data-aos-delay="100">
                                <span>2</span>
                                Feedback alerts
                                <p>Get notifications and alerts each time a rating is given. </p>
                              </li>
                              <li data-aos="fade-up" data-aos-delay="150">
                                <span>3</span>
                                Feedback response
                                <p>Respond to negative and positive comments online from one clean and clear dashboard.</p>
                              </li>
                              <li data-aos="fade-up" data-aos-delay="150">
                              <span>4</span>
                              Rating by SMS
                              <p>Send out text messages to clients after the service to collect dynamic ratings by SMS.</p>
                            </li>

                            <li data-aos="fade-up" data-aos-delay="150">
                            <span>5</span>
                            Feedback at store
                            <p>Get clients to review your business during checkout using the software on your tablet. </p>
                          </li>

                          <li data-aos="fade-up" data-aos-delay="150">
                          <span>5</span>
                          Feedback questionnaires
                          <p>Setup feedback questions on staff performance, appointment timeliness, quality of services and more.</p>
                        </li>
                        
                          
                          </ul>
                              </div>
                          </div>
                          
    
    
                      </div>


      
       


     

    </div>
</div>
</div>
</section>
    
    
    </div>
  )
}
