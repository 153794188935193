import React,{useEffect}from 'react';
import HeaderNew from '../../Header/HeaderNew';

//import Header from '../../Header/Header';
import Header from '../../Header/HeaderOld';

import MainSectionSignUp from './MainSectionSignUp';

import { Widget,addResponseMessage  } from 'react-chat-widget';

import 'react-chat-widget/lib/styles.css';

import logo from '../../../../src/assets/images/tunepp.jpg';
import HeaderFloating from '../../Header/HeaderFloating';



function SignUpPage() {
    useEffect(() => {
        addResponseMessage('Hi! Welcome to Mavunosoko. Kindly conduct our customer care on 0724512561');
      }, []);
    
      const handleNewUserMessage = (newMessage) => {
        console.log(`New message incoming! ${newMessage}`);
        // // Now send the message throught the backend API
        // addResponseMessage(newMessage);
      };
    return (
        <div>
           

          <HeaderFloating/>

           <MainSectionSignUp/>

           <Widget 

           handleNewUserMessage={handleNewUserMessage}
           profileAvatar={logo}
           title="Welcome to mavunosoko"
           subtitle="24/7 customer support"
           
         
           />
            


           

        </div>
    )
}

export default SignUpPage
