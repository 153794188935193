import React from 'react'


import {Helmet} from 'react-helmet'

function HeaderFloating() {
  return (
    <div>
    <Helmet>
    
    

    <link href="/assets_fheader/vendor/fontawesome-free/css/all.min.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/animate.css/animate.min.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/boxicons/css/boxicons.min.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/glightbox/css/glightbox.min.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/remixicon/remixicon.css" rel="stylesheet"/>
    <link href="/assets_fheader/vendor/swiper/swiper-bundle.min.css" rel="stylesheet"/>
  
   
    <link href="/assets_fheader/css/style.css" rel="stylesheet"/>

    
    </Helmet>


    
    <div id="topbar" class="d-flex align-items-center fixed-top">
    <div class="container d-flex justify-content-between">
      <div class="contact-info d-flex align-items-center">
        <i class="bi bi-envelope"></i> <a href="mailto:contact@example.com">mavunosoko@info.com</a>
        <i class="bi bi-phone"></i> +254 724512561
      </div>
      <div class="d-none d-lg-flex social-links align-items-center">
        <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
        <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
        <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
        <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
      </div>
      <div class="cont-con">
      <a href="/login">Login</a>
  </div>
    </div>
  </div>

  <header id="header" class="fixed-top">
  <div class="container d-flex align-items-center">

   

   {/* <h1 class="logo me-auto"><a href="/">MavunoSoko</a></h1> */}


     <a href="/" class="logo me-auto"><img style={{  width: "100px",height: "300px" }} src="/logo.png" alt="" class="img-fluid"/></a>
 
    <nav id="navbar" class="navbar order-last order-lg-0">
      <ul>
        
        <li><a class="nav-link scrollto active" href="/features"></a></li>
        <li><a class="nav-link scrollto" href="/pricing"></a></li>
        <li><a class="nav-link scrollto" href="/industries"></a></li>
        <li><a class="nav-link scrollto" href="/about-us"></a></li>
        {/* <li><a class="nav-link scrollto" href="/support">Help & Support</a></li> */}
        {/* <li class="dropdown"><a href="#"><span>Drop Down</span> <i class="bi bi-chevron-down"></i></a>
          <ul>
            <li><a href="#">Drop Down 1</a></li>
            <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
              <ul>
                <li><a href="#">Deep Drop Down 1</a></li>
                <li><a href="#">Deep Drop Down 2</a></li>
                <li><a href="#">Deep Drop Down 3</a></li>
                <li><a href="#">Deep Drop Down 4</a></li>
                <li><a href="#">Deep Drop Down 5</a></li>
              </ul>
            </li>
            <li><a href="#">Drop Down 2</a></li>
            <li><a href="#">Drop Down 3</a></li>
            <li><a href="#">Drop Down 4</a></li>
          </ul>
        </li>*/}
       
        {/* <li><a class="nav-link scrollto" href="/contact-us">Contact</a></li> */}

        
      </ul>
      <i class="bi bi-list mobile-nav-toggle"></i>
    </nav>

    <a href="/start-now" class="appointment-btn scrollto"><span class="d-md-inline">Register</span> Here</a>

  </div>
</header>



<Helmet>
<script src="/assets_fheader/vendor/purecounter/purecounter.js"></script>
<script src="/assets_fheader/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
<script src="/assets_fheader/vendor/glightbox/js/glightbox.min.js"></script>
<script src="/assets_fheader/vendor/swiper/swiper-bundle.min.js"></script>

<script src="/assets_fheader/js/main.js"></script>


</Helmet>


    </div>
  )
}

export default HeaderFloating