import React, { useCallback,useState,useEffect,useContext } from 'react';

import {useNavigate} from 'react-router-dom'

import {useParams} from "react-router-dom"

import axios from 'axios';

import {toast,ToastContainer,Zoom,Bounce} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import {getGeo, getGeoByIp} from 'geoplugin';

import {Helmet} from 'react-helmet'


import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import API from '../../../services';
import OrderDetailsContext from '../../../helpers/OrderDetailsContext';
import { AuthContext } from '../../../helpers/AuthContext';

import { Modal, Button } from "react-bootstrap";


function randomNumberInRange(min, max) {
  // 👇️ get number between min (inclusive) and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function DetailsContent(props) {

    const [item, setItem] = useState("");

    let {id}=useParams("");

    let {pid}=useParams("");


    const [showBussInforCard,setShowBussInforCard]=useState(false);

    const [showProductCardInfor,setShowProductCardInfor]=useState(false);
  
    const [showCustomerDetailsForm,setShowCustomerDetailsForm]=useState(false);
  
    const [showSuccessAlert,setShowSuccessAlert]=useState(false);
  
    const [businessId, setbusinessId] = useState('');

    const [business_name, setbusiness_name] = useState('');

    const [cloudinaryUrl, setCloudinaryUrl] = useState('');

    const [business_location, setBusiness_location] = useState('');

    const [business_city, setBusiness_city] = useState('');

    const [business_contacts, setBusiness_contacts] = useState('');


    const [productFound, setProductFound] = useState(false);


    const [customerId, setCustomerId] = useState('');
    
    const [bookingId, setBookingId] = useState('');

    const [productId, setproductId] = useState('');


    

    const [quantity_ordered, setquantity_ordered] = useState(1);

    const [item_name, setitem_name] = useState('');

    const [order_description, setorder_description] = useState('');


    const [randomNo, setRandomNo] = useState(0);

    const [showAlert, setShowAlert] = useState(false);

    const [orderId, setorderId] = useState('');
  
    // const [password, setPassword] = useState("");


    const [role, setRole] = useState("");
    const [password, setPassword] = useState("");
    const {setAuthState } = useContext(AuthContext);

   // const {setAuthState } = useContext(AuthContext);


    const {customerOrders,setCustomerOrders} = useContext(OrderDetailsContext);

    const [show, setShow] = useState(false);

    const [showAllServicesDiv,setShowAllServicesDiv]=useState(false);

    const [showBuyerDetailsModal, setShowBuyerDetailsModal] = useState(false);


    const handleClose_ = () => setShowBuyerDetailsModal(false);


  

    const [seller_name, setSeller_name] = useState("");

    const [product_name, setProduct_name] = useState("");
    const [customer_phone_no, setCustomer_phone_no] = useState("");
    const [customer_address, setCustomer_address] = useState("");
    const [customer_lat, setCustomer_lat] = useState("");


    const [seller_email, setSeller_email] = useState("");
    const [seller_phone_no, setSeller_phone_no] = useState("");


    const [customer_longitude, setCustomer_longitude] = useState("");


    const [cust_place_of_residence, setCust_place_of_residence] = useState("");
    const [request_type, setRequest_type] = useState("");
    const [currency_code, setCurrency_code] = useState("");
    const [payment_method_id, setPayment_method_id] = useState("");
    const [request_made_at, setRequest_made_at] = useState("");




    const [request_accepted_at, setRequest_accepted_at] = useState("");
    const [request_declined_at, setRequest_declined_at] = useState("");
    const [request_cancelled_at, setRequest_cancelled_at] = useState("");

    const [address_line_2, setaddress_line_2] = useState("");

    const [postal_code, setPostal_code] = useState("");

    const [status, setStatus] = useState(null);


    const [city, setCity] = useState("");
    const [state, setState] = useState("");
  
  
    const [address, setAddress] = React.useState("");


    const [geo_loc, setGeo_loc] = useState("");

    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);


    const [email, setEmail] = useState("");
      const [phone_no, setPhone_no] = useState("");

      const [user_id, setUserId] = useState(1);

      const [name, setName] = useState("");


      const [productLat, setProductLat] = useState("");

      const [productLng, setProductLng] = useState("");

      

      const [showBookingDiv,setShowBookingDiv]=useState(false);


      const [initiateOrderProcessing,setInitiateOrderProcessing]=useState(false);




    const [user_data, setUser_data] = useState([]);


    const [mapCenter, setMapCenter] = useState({
      lat: 0,
      lng: 0
  
  });


    const [isLoading,setLoading]=useState(false);

    const history = useNavigate();



    useEffect(()=>{
  
        //   axios.get("https://tunepbackend.herokuapp.com/customer/mycustomers").then((response) => {
         API.get("/users/getuser/"+id).then((response) => {
            // axios.get("http://localhost:3001/users/getuser/"+id).then((response) => {
               // axios.get(`https://ngeritbackend.herokuapp.com/product/search/${item}`).then((response) => {
    
                setUser_data(response.data);

                setSeller_email(response.data.email)

                setSeller_phone_no(response.data.phone_no)

                setSeller_name(response.data.first_name)

               // console.log("THE SELLER DETAILS ARE "+response.data)
            });


            API.get("retailer/productloc/"+id).then((response) => {
              // axios.get("http://localhost:3001/users/getuser/"+id).then((response) => {
                 // axios.get(`https://ngeritbackend.herokuapp.com/product/search/${item}`).then((response) => {
      
                 // setUser_data(response.data);
  
                 // setSeller_email(response.data.email)
  
                  //setSeller_phone_no(response.data.phone_no)
  
                 // setSeller_name(response.data.first_name)


                 setProductLng(response.data.longitude)
                 setProductLat(response.data.latitude)

                 //console.log("THE PRODUCT LATITUDE "+response.data.latitude)
  
                 // console.log("THE SELLER DETAILS ARE "+response.data)
              });
  


             //   axios.get("https://tunepbackend.herokuapp.com/customer/mycustomers").then((response) => {
        //  API.get("/product/byId/"+id).then((response) => {
        //   // axios.get("http://localhost:3001/users/getuser/"+id).then((response) => {
        //      // axios.get(`https://ngeritbackend.herokuapp.com/product/search/${item}`).then((response) => {
  
        //       setUser_data(response.data);

        //       setSeller_email(response.data.email)

        //       setSeller_phone_no(response.data.phone_no)

        //       setSeller_name(response.data.first_name) 

        //       console.log("THE SELLER DETAILS ARE "+response.data)
        //   });


            if (!navigator.geolocation) {
              setStatus('Geolocation is not supported by your browser');
            } else {
                    setStatus('Locating...');
                    navigator.geolocation.getCurrentPosition((position) => {
                        setStatus(null);
                        setLat(position.coords.latitude);
                        setLng(position.coords.longitude);
        
                        setMapCenter({lat:position.coords.latitude,lng:position.coords.longitude})
        
                        getReverseGeocodingData(position.coords.latitude,position.coords.longitude)
        
        
                        const geoApi=`https://api.bigdatacloud.net/data/reverse-geocode-with-timezone?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en&key=9c255dc5e7f944ffb0df249d6588363c`
        
                        fetch(geoApi).then(res=>res.json()).then(data=>{
                          console.log("MY LOCATION IS "+data.plusCode)
                          console.log("MY CITY IS "+data.city)
                          setAddress(data.plusCode)
        
                          console.log("THE PLUS CODE ADDRESS IS " +data.compoundAddress);
        
                        })
        
        
        
                    }, () => {
                        setStatus('Unable to retrieve your location');
                    });
                }
        
      
      },[]);



      localStorage.setItem('selleremail', JSON.stringify(seller_email));


      localStorage.setItem('sellerId', JSON.stringify(id));

      localStorage.setItem('ProductId', JSON.stringify(pid));

      localStorage.setItem('seller_contacts', JSON.stringify(seller_phone_no));


       let selleremail=localStorage.getItem('selleremail')
       selleremail=JSON.parse(selleremail)


       let itemname=localStorage.getItem('itemsearched')
       itemname=JSON.parse(itemname)

       console.log("THE SELLER DETAILS ARE"+ selleremail)


       console.log("THE SEARCHED ITEM IS "+ itemname)
  
      // let seller_id=localStorage.getItem('seller_id')
      // seller_id=JSON.parse(id)

      // let seller_contacts=localStorage.getItem('seller_contacts')
      // seller_contacts=JSON.parse(seller_phone_no)


      const handleShow = () =>{

        setLoading(true)

        setShow(true);
      
        setTimeout(() => {

        setLoading(false)

        history("/customer-dashboard");
        window.location.reload(false);

      

        
    }, 5000);


  }



  const handleBuyerDetailsModal = () =>{

    setLoading(true)

    setShowBuyerDetailsModal(true);
  
    setTimeout(() => {

    setLoading(false)

    // history("/dashboard-customer");
    // window.location.reload(false);

  

    
}, 1000);


  }

     



    const data={
        product_name:itemname,
      
        seller_email:seller_email,
        seller_phone_no:seller_phone_no,
        customer_address:customer_address,
        customer_lat:customer_lat,
        customer_longitude:customer_longitude,
        payment_method_id:1,
        
        cust_place_of_residence:cust_place_of_residence,
        request_type:request_type,

        SellerId:id,
          
      }

      const getLoc=()=>{
        setLoading(true);
        getGeo()
        .then(response => console.log(response)) // handle success
        // .then(response => console.log(response.latitude)) // handle success
        .catch(error => console.log(error)) // handle error
        .then(() => {


            setTimeout(() => {
                setLoading(false);
                toast.info('Product saved successfully');
            }, 3000);
         



          }); // always executed
      }



      const checkOutAndBook=()=>{

        setLoading(true);
      
       
    
        setTimeout(() => {
            setLoading(false);
            setShowBookingDiv(false)
            //setIsDivLoading(true);
      
            setShowCustomerDetailsForm(true)
        
            setRandomNo(randomNumberInRange(1, 10000));
        
            setShowAllServicesDiv(false)
            setInitiateOrderProcessing(true)
    
            handleBuyerDetailsModal()
          
        }, 3000);
      
      }



      const user_details={
      name:name,
      last_name:name,
      email:email,
      phoneNo:phone_no,
      account_type:"",
      password:phone_no,
      
      state:"",
      city:null,
      role:'Customer',
      }




      const makeOrder = ()  => {
        setLoading(true);
      
      
        if(name=="" || phone_no==""){
      
          setLoading(false);
      
          setShowAlert(true)
      
      
          setTimeout(() => {
              setShowAlert(false)
             
           }, 2500);
      
           return
      
        }
        
         //API.post("https://tunepapi.herokuapp.com/customer",data).then((response)=>{
        
        
          API.post('users',user_details).then((response)=>{
        
            console.log("THE CUSTOMER DATA IS"+response.data)
        
            setCustomerId(response.data.id)
        
        
           
      
      
            if(response.data.error) {
      
              setTimeout(() => {
                 
                  toast.error(response.data.error);
                  setLoading(false);
              }, 500);
      
              //alert();
              //setLoading(false);
            }
      
            else{
      
           
      
      
      
      
          
      
        const data = { username:email, password: phone_no };
      
        API.post("users/login", data).then((rense) => {
          if (rense.data.error) {
            alert(rense.data.error);
            setLoading(false);
          } else {
            localStorage.setItem("accessToken", rense.data.token);
            setAuthState({
              username: rense.data.username,
              role: rense.data.role,
              first_name: rense.data.first_name,
              phone_no: rense.data.phone_no,
              id: rense.data.id,
              status: true,
            });
      
            console.log("Response is",rense.data)
      
            console.log("THE RETURNED USER ID IS ",rense.data.id)
      
      
            const customer_details={
             name:name,
             email:email,
             phone_no:phone_no,
             BusinessId:businessId,
             UserId:rense.data.id,
             
            }
      
          API.post('customer',customer_details).then((res)=>{
          
              console.log("THE CUSTOMER DATA IS->"+res.data)
          
              setBookingId(res.data.id)
              //setCustomerId(res.data.id)
      
      
      
      
              const order_details={
                  item_name:item_name,
                  quantity_ordered:quantity_ordered,
                  customer_phone_no:rense.data.phone_no,
                  order_description:order_description,
                  order_status:'pending',
                  orderId:randomNo,
                  ProductId:pid,
                  UserId:rense.data.id,
                  CustomerId:res.data.id,
                  RetailerId:id,
                  
                 
                  BusinessId:id,
                }
          API.post('order',order_details).then((res_b)=>{
          
          // console.log("The response is"+res_b.data)
          
          setorderId(res_b.data.id)
      
      
          const allCustOrders = [...customerOrders, res_b.data];
          setCustomerOrders(allCustOrders);
      
        
         // localStorage.setItem("order_details", allCustOrders);
      
          localStorage.setItem("order_details", res_b.data);
          
          
          console.log("THE  ORDER ID IS "+res_b.data.id)
          
          console.log("THE  ORDER ID TWO IS "+randomNo)
          
          })
          
      
      
      
          })
      
      
      /*
          const order_details={
              item_name:item_name,
              quantity_ordered:quantity_ordered,
              customer_phone_no:rense.data.phone_no,
              order_description:order_description,
              orderId:randomNo,
              ProductId:productId,
              UserId:rense.data.id,
              CustomerId:customerId,
              BusinessId:businessId,
            }
      API.post('order',order_details).then((res_b)=>{
      
      // console.log("The response is"+res_b.data)
      
      setorderId(res_b.data.id)
      
      
      
      console.log("THE  ORDER ID IS "+res_b.data.id)
      
      console.log("THE  ORDER ID TWO IS "+randomNo)
      
      })*/
      
      
      
            if(rense.data.role=="Customer"){
      
      
              API.get('users/auth', { headers: { accessToken: localStorage.getItem("accessToken") }}).then((res_auth) => {
      
              setUserId(res_auth.data.id)
      
      
          console.log("THE  USER ID IS "+res_auth.data.id)
      
      
      
          // const customer_details={
          //     name:name,
          //    email:email,
          //    phone_no:phone_no,
          //    BusinessId:businessId,
          //    UserId:res_auth.data.id,
             
          //   }
      
          // API.post('customer',customer_details).then((res)=>{
          
          //     console.log("The response is"+res.data)
          
          //     setBookingId(res.data.id)
          // })
      
      
      
      
      //             const order_details={
      //                         item_name:item_name,
      //                         quantity_ordered:quantity_ordered,
      //                         customer_phone_no:rense.data.phone_no,
      //                         order_description:order_description,
      //                         orderId:randomNo,
      //                         UserId:res_auth.data.id,
      //                         BusinessId:businessId,
      //                       }
      //             API.post('order',order_details).then((res_b)=>{
      
      //  // console.log("The response is"+res_b.data)
      
      //   setorderId(res_b.data.id)
      
        
      
      //  console.log("THE  ORDER ID IS "+res_b.data.id)
      
      //  console.log("THE  ORDER ID TWO IS "+randomNo)
      
      //     })
      
            
            
                 })
      
              setTimeout(() => {
                setLoading(false);
      
      
                
                //setLoading(false);
                //toast.info('Appointment saved!');
                setShowSuccessAlert(true)
        
                setShowCustomerDetailsForm(false)
      
                setShowAllServicesDiv(true)
      
                setShowBuyerDetailsModal(false)
      
                handleShow()
      
      
              
            }, 5000);
      
            }
      
          else if(rense.data.role=="Vendor"){
      
            setTimeout(() => {
              setLoading(false);
              history('/dashboard-vendor');
              window.location.reload(false);
          }, 2000);
      
          }
          else{
            setTimeout(() => {
              setLoading(false);
              history("/dashboard");
          }, 1000);
      
      
          }
          
          }
        });
        
        
        
         
        
        
         
          }  
        });
        
        
        
        }









    

      const addDetails = ()  => {

          setLoading(true);
    
            // axios.post("https://ngeritbackend.herokuapp.com/product",data).then((response)=>{
        
            API.post("/request",data).then((response)=>{
          
         //axios.post("http://localhost:3001/request",data).then((response)=>{
           
    
          console.log("The response is"+response.data)
    
             
              setTimeout(() => {
                  setLoading(false);
                  toast.info('Product saved successfully');
              }, 3000);
           
             //  history("/dashboard");
            
             
          })
    
      }


      const handleChange = address => {
        setAddress(address);
          };
  
  
  
        const handleSelect = address => {
          setAddress(address);
           geocodeByAddress(address)
            .then(results =>  getLatLng(results[0]))
            .then(latLng => {
              console.log('Success', latLng);
              
              // const address_one = address.results[0].formatted_address;
             
      
              // console.log('Formated Addres', address_one);
      
      
              // update center state
              setMapCenter(latLng );
      
              setaddress_line_2(address);
      
              
             
            });
            geocodeByAddress(address).then(response=>{
      
            
              var add= response[0].formatted_address ;
              var  value=add.split(",");
        
            let  count=value.length;
            let  country=value[count-1];
            let   state=value[count-2];
            let  city=value[count-3];
            let  postal_code=value[count-4];
      
           
        
              console.log('COUNTRY'+country);
              console.log('CITY'+city);
              console.log('STATE'+state);
              console.log('ZIP CODE'+postal_code);
      
              // console.log('THE ID IS'+propid);
      
      
              setCity(city);
      
              setPostal_code(postal_code);
      
              setState(state);
      
            
            
        
             // console.log('ADDRESS COMPONENTS',addressComponent[2]);
              
            })
            .catch(error => console.error('Error', error));
        };
  


      const getReverseGeocodingData=(lat,lng)=> {
        var latlng = new props.google.maps.LatLng(lat, lng);
        // This is making the Geocode request
        var geocoder = new props.google.maps.Geocoder();
        geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
            if (status !== props.google.maps.GeocoderStatus.OK) {
                alert(status);
            }
            // This is checking to see if the Geoeode Status is OK before proceeding
            if (status == props.google.maps.GeocoderStatus.OK) {
                console.log(results);
                var address = (results[0].formatted_address);

                var city = (results[1].formatted_address);


                setaddress_line_2(address)

                setCity(address)

                setState(address)


                console.log("THE ADDRESS IS " +address);

               // console.log("THE CITY IS " +city);

                
            }
        });
    }



      const addLoginRoute = ()  => {

        setLoading(true);
  
          // axios.post("https://ngeritbackend.herokuapp.com/product",data).then((response)=>{
      
          API.post("/request",data).then((response)=>{
        
     // axios.post("http://localhost:3001/request",data).then((response)=>{
         
  
        console.log("The response is"+response.data)
  
           
            setTimeout(() => {
                setLoading(false);
                toast.info('Product saved successfully');

                history('/customer-dashboard');

            }, 3000);
         
           //  history("/dashboard");
          
           
        })
  
    }



    const searchItem = () => {
    setLoading(true);
    
    setTimeout(() => {
      setLoading(false);
     // history.push('/search-location-avon-park-florida');
     history(`/search-item/${item}/${geo_loc}`);
    }, 3000);
    
      };
  return (
    <div>

    <section class="pt-6 cloud-pattern"  style={{marginTop:'80px'}}>
    <div class="container">
        <div class="row align-items-center justify-content-center justify-content-lg-between mb-6">
            <div class="col-lg-6 col-md-10 mb-5 mb-lg-0 text-center text-lg-left">
                {/* <div class="d-none d-lg-block mb-2" data-aos="fade-up">
                    <div class="alert alert-modern bg-primary text-white">
                      <span class="badge badge-danger font-weight-medium badge-pill py-1 px-2 align-middle mr-2">News</span>
                      <span class="alert-content">Get another product</span>
                    </div>
                </div> */}



                <div class="row">



            






                  </div>







                

                <div class="row g-2">
                <div class="col mb-0">
                


                  <div id='googleMaps'>
                    <PlacesAutocomplete

                      value={address}
                      onChange={handleChange}
                      onSelect={handleSelect}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>


                          <div class="form-group">
                          

                            <input
                              class="form-control"  id="inputAddress" autocomplete="off"
                              {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'multisteps-form__input form-control',
                              })}


                            />
                          </div>



                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span style={{ color: "blue", backgroundColor: "#E5E4E2", border: "1px", borderRadius: "1px" }}>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                </div>

                </div>

                <div class="row g-2">
              

                    <div class="col" style={{ height: "200px", padding: "5px" }}>

                      <Map
                        google={props.google}
                        initialCenter={{
                          lat: productLat,
                          lng: productLng
                        }}
                        center={{
                          lat: productLat,
                          lng: productLng
                        }}
                      >
                        <Marker
                          position={{
                            lat: productLat,
                            lng: productLng
                          }} />
                      </Map>
                    </div>

                  
                  </div>

                  </div>

              

             
             
            </div>
            
            <div class="col-xxl-4 col-lg-5 col-md-9" data-aos="fade-up" data-aos-delay="200">




            <div class="card shadow-lg p-3 p-xl-5">
                    <div class="d-flex">
                        <span class="mr-3 fs-32">👋</span>
                        <div>
                            <h4 class="font-weight-bold mb-1">Connect With The Seller Now </h4>
                          
                        </div>
                    </div>
                    
                    <hr/>
                    
                    <form>
                        <div class="form-row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="name">Name  {seller_name} {id} {pid}</label>
                                   
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="number">Number {seller_phone_no}</label>
                                   
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="emailAddress">Email address: {seller_email}</label>
                                    
                                </div>
                            </div>
                        
                          
                         
                        </div>
                    </form>
               </div>





               <div class="card shadow-lg p-3 p-xl-5">
                    {/* <div class="d-flex">
                        <span class="mr-3 fs-32">👋</span>
                       
                    </div> */}
                    
                    <hr/>


                    {showCustomerDetailsForm &&


                    <form>
                        <div class="form-row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="name">Name</label>
                                    <input type="text" class="form-control form-control-sm" id="name" aria-describedby="name"

onChange={(event) => {
  setName(event.target.value);
}} 
                                    
                                    placeholder="Francis Mbatha"/>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="number">Number</label>
                                    <input type="text" class="form-control form-control-sm" id="number"

onChange={(event) => {
  setPhone_no(event.target.value);
}}
                                    
                                    aria-describedby="number" placeholder="+254714639773"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="emailAddress">Email address</label>
                                    <input type="email" class="form-control form-control-sm" id="emailAddress"

            onChange={(event) => {
            setEmail(event.target.value);
             }}



                                    
                                    
                                    
                                    aria-describedby="emailAddress" placeholder="frankcode20@gmail.com"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Delivery method</label>
                                    
                                    <select class="custom-select custom-select-sm" 
                                    
                                    onChange={(event) => {
                                        setRequest_type(event.target.value);
                                      }}
                                    
                                    >
                                        <option selected>Select a delivery method</option>
                                        <option value="Home delivery">Home delivery</option>
                                        <option value="Visit seller premises">Visit seller premises</option>
                                       
                                      </select>
                                </div>
                            </div>
                            <div class="col-12">


                           {/* {!isLoading && <button type="submit" onClick={getLoc} class="btn btn-primary btn-md btn-block mt-3 waves-effect" value="Search">Contact Farmer Now</button>*/} 
                           
                           {/* {!isLoading && <button type="submit" onClick={addLoginRoute} class="btn btn-primary btn-md btn-block mt-3 waves-effect" value="Search">Contact Farmer Now</button>

                        } */}

{/* 
{!isLoading && <button type="submit" onClick={() => {
        checkOutAndBook()
      }} class="btn btn-primary btn-md btn-block mt-3 waves-effect" value="Search">Confirm now</button>

}






                        {isLoading &&
                          <button type="submit" class="btn btn-primary btn-md btn-block mt-3 waves-effect" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Contacting farmer....</button>
                        } */}

                       

                               
                            </div>
                            <p class="text-muted font-size-sm mt-3 mb-0">By submitting, you agree to our <a class="font-weight-medium" href="">terms &amp; conditions</a></p>
                        </div>
                    </form>

                      }

                    
                    <form>
                        <div class="form-row">
                          
                            <div class="col-12">


                           {/* {!isLoading && <button type="submit" onClick={getLoc} class="btn btn-primary btn-md btn-block mt-3 waves-effect" value="Search">Contact Farmer Now</button>*/} 
                           
                           {/* {!isLoading && <button type="submit" onClick={addLoginRoute} class="btn btn-primary btn-md btn-block mt-3 waves-effect" value="Search">Contact Farmer Now</button>

                        } */}


{!isLoading && <button type="submit" onClick={() => {
        checkOutAndBook()
      }} class="btn btn-primary btn-md btn-block mt-3 waves-effect" value="Search">Confirm now</button>

}






                        {isLoading &&
                          <button type="submit" class="btn btn-primary btn-md btn-block mt-3 waves-effect" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Contacting farmer....</button>
                        }

                       

                               
                            </div>
                            <p class="text-muted font-size-sm mt-3 mb-0">By submitting, you agree to our <a class="font-weight-medium" href="">terms &amp; conditions</a></p>
                        </div>
                    </form>
                    









                 









               </div>
            </div>
        </div> 



        <Modal class="modal fade" id="modaldemo8" show={showBuyerDetailsModal}>

<Modal.Header>
  <Modal.Title>Your Details and Contacts</Modal.Title>
</Modal.Header>
<Modal.Body class="modal-body">


<form>
<div class="row mb-3">

  <label class="col-sm-2 col-form-label" for="basic-icon-default-fullname">Your Name</label>
  <div class="col-sm-10">
    <div class="input-group input-group-merge">
      <span id="basic-icon-default-fullname2" class="input-group-text"><i class="bx bx-user"></i></span>
      <input type="text" class="form-control" id="basic-icon-default-fullname"  
      
      onChange={(event) => {
        setName(event.target.value);
      }} 

      placeholder='eg. Jane Masinde'
      
      aria-describedby="basic-icon-default-fullname2"/>
    </div>
  </div>
</div>

<div class="row mb-3">
  <label class="col-sm-2 col-form-label" for="basic-icon-default-email">Email</label>
  <div class="col-sm-10">
    <div class="input-group input-group-merge">
   
      <input type="text" id="basic-icon-default-email" class="form-control"
      onChange={(event) => {
        setEmail(event.target.value);
      }}

      placeholder='eg. jane@gmail.com'
      
      aria-describedby="Eg.mike20@gmail.com"/>
     
    </div>
    <div class="form-text"> You can use letters, numbers &amp; periods </div>
  </div>
</div>
<div class="row mb-3">
  <label class="col-sm-2 form-label" for="basic-icon-default-phone">Phone No</label>
  <div class="col-sm-10">
    <div class="input-group input-group-merge">
      <span id="basic-icon-default-phone2" class="input-group-text"><i class="bx bx-phone"></i></span>
      <input type="text" id="basic-icon-default-phone" class="form-control phone-mask"
      onChange={(event) => {
        setPhone_no(event.target.value);
      }}
      
      placeholder="eg. 0713876543" aria-label="eg. 0713876543" aria-describedby="basic-icon-default-phone2"/>
    </div>
  </div>
</div>


</form>






  

</Modal.Body>
<Modal.Footer class="modal-footer">


{!isLoading && <button type="submit" onClick={makeOrder} class="btn btn-primary">Place Order</button>

} 
{isLoading &&
  

    <button class="btn btn-primary my-1" type="button" disabled="">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Preparing Your Order....
</button>
} <button class="btn btn-light" onClick={handleClose_} data-bs-dismiss="modal">Close</button>

{/* <Button variant="secondary" onClick={handleClose}>
    Close
  </Button>
  <Button variant="primary" onClick={handleClose}>
    Save Changes
  </Button> */}
 
</Modal.Footer>
</Modal>






    </div>

  
</section>


    
    
    </div>
  )
}


export default GoogleApiWrapper({
  apiKey: ('AIzaSyALtlAXEgqBUE9v3akrQsHfi-Cs_cPOJ0g')
})(DetailsContent)
