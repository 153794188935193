import React from 'react'

export default function FeaturedInfo() {
    return (
        <div>
                {/* <!-- =============== Left side End ================--> */}
                <div className="main-content-wrap sidenav-open d-flex flex-column">
        {/* ============ Body content start ============= */}
        <div className="main-content">
          <div className="breadcrumb">
            <ul>
              <li><a href="#">Dashboard</a></li>
              <li>Home</li>
            </ul>
          </div>
          <div className="separator-breadcrumb border-top" />
          <div className="row">
            {/* ICON BG*/}
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                <div className="card-body text-center"><i className="i-Calendar-2" />
                  <div className="content" style={{maxWidth: '130px !important'}}>
                    <p className=" text-primary text-15 line-height-1 mb-2">This Week</p>
                    <p className="text-muted mt-2 mb-0">{/*?php $firstday = date('M d', strtotime("this week")); $lastday = date('M d', strtotime("Next Sunday")); echo $firstday.'-'.$lastday; ?*/}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                <div className="card-body text-center"><i className="i-Timer1" />
                  <div className="content">
                    <p className="text-primary text-15 line-height-1 mb-2">Appointments</p>
                    <p className="text-muted mt-2 mb-0">0</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                <div className="card-body text-center"><i className="i-Checkout-Basket" />
                  <div className="content">
                    <p className="text-primary text-15 line-height-1 mb-2">Confirmed Revenues</p>
                    <p className="text-muted mt-2 mb-0">KSh0.00</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                <div className="card-body text-center"><i className="i-Financial" />
                  <div className="content">
                    <p className="text-primary text-15 line-height-1 mb-2">Projected Revenues</p>
                    <p className="text-muted mt-2 mb-0">KSh0.00</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="card-title">CURRENT APPOINTMENTS</div>
                  <div style={{height: '300px'}} />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="card-title">THIS WEEK'S ACTIVITY</div>
                  <div style={{height: '300px'}} />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-md-6">
              <div className="card o-hidden mb-4">
                  <div className="card-header d-flex align-items-center">
                      <h3 className="w-50 float-left card-title m-0">New Users</h3>
                      <div className="dropdown dropleft text-right w-50 float-right">
                          <button className="btn bg-gray-100" id="dropdownMenuButton_table1" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="nav-icon i-Gear-2" /></button>
                          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton_table1"><a className="dropdown-item" href="#">Add new user</a><a className="dropdown-item" href="#">View All users</a><a className="dropdown-item" href="#">Something else here</a></div>
                      </div>
                  </div>
                  <div className="card-body">
                      <div className="table-responsive">
                          <div id="user_table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                              <div className="row">
                                  <div className="col-sm-12 col-md-6">
                                      <div className="dataTables_length" id="user_table_length"><label>Show <select name="user_table_length" aria-controls="user_table" className="form-control form-control-sm">
                                                  <option value={10}>10</option>
                                                  <option value={25}>25</option>
                                                  <option value={50}>50</option>
                                                  <option value={100}>100</option>
                                              </select> entries</label></div>
                                  </div>
                                  <div className="col-sm-12 col-md-6">
                                      <div id="user_table_filter" className="dataTables_filter"><label>Search:<input type="search" className="form-control form-control-sm" placeholder aria-controls="user_table" /></label></div>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-sm-12">
                                      <table className="table dataTable-collapse text-center dataTable no-footer" id="user_table" role="grid" aria-describedby="user_table_info">
                                          <thead>
                                              <tr role="row">
                                                  <th scope="col" className="sorting_asc" tabIndex={0} aria-controls="user_table" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="#: activate to sort column descending" style={{width: '10.7812px'}}>#</th>
                                                  <th scope="col" className="sorting" tabIndex={0} aria-controls="user_table" rowSpan={1} colSpan={1} aria-label="Name: activate to sort column ascending" style={{width: '62.4062px'}}>Name</th>
                                                  <th scope="col" className="sorting" tabIndex={0} aria-controls="user_table" rowSpan={1} colSpan={1} aria-label="Avatar: activate to sort column ascending" style={{width: '47.6406px'}}>Avatar</th>
                                                  <th scope="col" className="sorting" tabIndex={0} aria-controls="user_table" rowSpan={1} colSpan={1} aria-label="Email: activate to sort column ascending" style={{width: '121.719px'}}>Email</th>
                                                  <th scope="col" className="sorting" tabIndex={0} aria-controls="user_table" rowSpan={1} colSpan={1} aria-label="Status: activate to sort column ascending" style={{width: '64.3438px'}}>Status</th>
                                                  <th scope="col" className="sorting" tabIndex={0} aria-controls="user_table" rowSpan={1} colSpan={1} aria-label="Action: activate to sort column ascending" style={{width: '46.6094px'}}>Action</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <tr role="row" className="odd">
                                                  <th scope="row" className="sorting_1">1</th>
                                                  <td>Smith</td>
                                                  <td><img className="rounded-circle m-0 avatar-sm-table" src="../../dist-assets/images/faces/1.jpg" alt="" /></td>
                                                  <td>Smith@gmail.com</td>
                                                  <td><span className="badge badge-success">Active</span></td>
                                                  <td><a className="text-success mr-2" href="#"><i className="nav-icon i-Pen-2 font-weight-bold" /></a><a className="text-danger mr-2" href="#"><i className="nav-icon i-Close-Window font-weight-bold" /></a></td>
                                              </tr>
                                              <tr role="row" className="even">
                                                  <th scope="row" className="sorting_1">2</th>
                                                  <td>Jhon Doe</td>
                                                  <td><img className="rounded-circle m-0 avatar-sm-table" src="../../dist-assets/images/faces/1.jpg" alt="" /></td>
                                                  <td>Jhon@gmail.com</td>
                                                  <td><span className="badge badge-info">Pending</span></td>
                                                  <td><a className="text-success mr-2" href="#"><i className="nav-icon i-Pen-2 font-weight-bold" /></a><a className="text-danger mr-2" href="#"><i className="nav-icon i-Close-Window font-weight-bold" /></a></td>
                                              </tr>
                                              <tr role="row" className="odd">
                                                  <th scope="row" className="sorting_1">3</th>
                                                  <td>Alex</td>
                                                  <td><img className="rounded-circle m-0 avatar-sm-table" src="../../dist-assets/images/faces/1.jpg" alt="" /></td>
                                                  <td>Otto@gmail.com</td>
                                                  <td><span className="badge badge-warning">Not Active</span></td>
                                                  <td><a className="text-success mr-2" href="#"><i className="nav-icon i-Pen-2 font-weight-bold" /></a><a className="text-danger mr-2" href="#"><i className="nav-icon i-Close-Window font-weight-bold" /></a></td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                              <div className="row">
                                  <div className="col-sm-12 col-md-5">
                                      <div className="dataTables_info" id="user_table_info" role="status" aria-live="polite">Showing 1 to 3 of 3 entries</div>
                                  </div>
                                  <div className="col-sm-12 col-md-7">
                                      <div className="dataTables_paginate paging_simple_numbers" id="user_table_paginate">
                                          <ul className="pagination">
                                              <li className="paginate_button page-item previous disabled" id="user_table_previous"><a href="#" aria-controls="user_table" data-dt-idx={0} tabIndex={0} className="page-link">Previous</a></li>
                                              <li className="paginate_button page-item active"><a href="#" aria-controls="user_table" data-dt-idx={1} tabIndex={0} className="page-link">1</a></li>
                                              <li className="paginate_button page-item next disabled" id="user_table_next"><a href="#" aria-controls="user_table" data-dt-idx={2} tabIndex={0} className="page-link">Next</a></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
        </div>{/* Footer Start */}
        <div className="flex-grow-1" />
        <div className="app-footer">
          <div className="row">
            <div className="col-md-9">
              <p><strong>Pamojour</strong></p>
              <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero quis beatae officia saepe perferendis voluptatum minima eveniet voluptates dolorum, temporibus nisi maxime nesciunt totam repudiandae commodi sequi dolor quibusdam
                <sunt />
              </p>
            </div>
          </div>
          <div className="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
            {/* <a class="btn btn-primary text-white btn-rounded" href="https://themeforest.net/item/gull-bootstrap-laravel-admin-dashboard-template/23101970" target="_blank">Buy Gull HTML</a> */}
            <span className="flex-grow-1" />
            <div className="d-flex align-items-center">
              <img className="logo" src="<?php echo base_url(); ?>assets/dist-assets/images/logo.png" alt="" />
              <div>
                <p className="m-0">© 2021 Pamojour</p>
                <p className="m-0">All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
        {/* fotter end */}
      </div>
 

        </div>
    )
}
