import React from 'react';
import Phone from '../../images/phone.png';

export default function Section4() {
    return (
        <div>
                <div className="row bg-light" >
                        <div className="col-1"></div>
                        <div className="col-3  mt-5">
                          <h4 className="text-justify text-dark"><b>Buy and Sell Locally</b> </h4>
                          <p className="text-justify">Lorem ipsum idset Lorem ipsum idset 
                            Lorem ipsum idset Lorem ipsum idset Lorem ipsum idset
                            Lorem ipsum idset</p>
                            <button className="btn btn-primary w-100">Schedule</button>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-auto">
                          <img className="img-fluid" src={Phone} alt="" srcset="" />
                        </div>
                      </div>
                  
        </div>
    )
}
