import React,{useEffect, useState} from 'react';
import FeaturedInfo from './FeaturedInfo';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, Link  } from "react-router-dom";
import pimage from '../../assets/dist-assets/images/faces/3.jpg';
import { Helmet } from "react-helmet";
import CustomizedDialogs from './Addcategory';


export default function Staff() {
    

    const pathname = window.location.pathname;
    const [listOfServices, setListOfServices]=useState([]);
    const [listOfCategories, setListOfCategories]=useState([]);
    const navigate=useNavigate();
    // var data=0;
    useEffect( ()=> {
        if(!localStorage.getItem("accessToken")){
          navigate("/login");  
        }else{
            axios.get("http://localhost:3001/service",{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
            console.log(response.data);
            setListOfServices(response.data);
            });
            axios.get("http://localhost:3001/service/categories",{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
            console.log(response.data);
            setListOfCategories(response.data);
            });
        }
    },[]);

    // const getStaff=(id)=>{
    //     axios.get(`http://localhost:3001/staff/${id}`,{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
    //         console.log(response.data);
    //         setListOfStaff(response.data.myStaffList);
    //         setTopStaff(response.data.topStaff);
    //         });
    // }

    const updateStaff=()=>{
        alert("hello");
    }
    
    return (
        <div>
            <Helmet>
                <link rel="stylesheet" href="dist-assets/css/themes/lite-purple.min.css" />
                <link rel="stylesheet" href="dist-assets/css/plugins/perfect-scrollbar.min.css" />
            </Helmet>
            <div className="text-left">
                <div className="app-admin-wrap layout-sidebar-large">
                    <Topbar />
                    <Sidebar />
                    <div className="main-content-wrap sidenav-open d-flex flex-column">
                        {/* ============ Body content start ============= */}
                        <div className="main-content">
                            <div className="inbox-main-sidebar-container" data-sidebar-container="main">
                                <div className="inbox-main-content" data-sidebar-content="main">
                                    {/* SECONDARY SIDEBAR CONTAINER*/}
                                    <div className="inbox-secondary-sidebar-container box-shadow-1" data-sidebar-container="secondary">
                                        {/* Secondary Inbox sidebar*/}
                                        <div className="inbox-secondary-sidebar perfect-scrollbar rtl-ps-none" data-sidebar="secondary">
                                        <a href="/add-services" class="btn btn-outline-primary m-1"><span class="ul-btn__icon" Om><i className="i-Add"></i></span>Add</a>
                                        { listOfServices.map((value, key) => {
                                                    return (
                                            <div key={key} className="mail-item">
                                                <div className="avatar"><img src="../../dist-assets/images/faces/1.jpg" alt="" /></div>
                                                <div className="col-xs-6 details"><span className="name text-muted">{value.service_name}</span>
                                                    <p className="m-0">{value.service_hours} MINS</p>
                                                </div>
                                                <div className="col-xs-3 date"><span className="text-muted">KSH {value.service_cost}.00</span></div>
                                            </div>
                                            );
                                        }) }
                                        </div>
                                    </div>
                                </div>
                                {/* MAIN INBOX SIDEBAR*/}
                                <div className="inbox-main-sidebar" data-sidebar="main" data-sidebar-position="left">
                                    <div className="pt-3 pr-3 pb-3"><i className="sidebar-close i-Close" data-sidebar-toggle="main" />
                                        <div className="pl-3 box-shadow-1">
                                        <CustomizedDialogs title="Add Category"></CustomizedDialogs>
                                            <h5 style={{padding:"10px"}}>Categories</h5>
                                            <ul className="inbox-main-nav">
                                                <li><a className="active" href="#"><i className="icon-regular i-Folder" /> All Services</a></li>
                                                { listOfCategories.map((value, key) => {
                                                    return (
                                                        <li key={key}><a className="active" href="#"><i className="icon-regular i-Folder" />{value.category_name}</a></li>
                                                    );
                                                }) }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* Footer Start */}
                        <div className="flex-grow-1" />
                        <div className="app-footer">
                            <div className="row">
                                <div className="col-md-9">
                                    <p><strong>Pamojour</strong></p>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero quis beatae officia saepe perferendis voluptatum minima eveniet voluptates dolorum, temporibus nisi maxime nesciunt totam repudiandae commodi sequi dolor quibusdam
                                        <sunt />
                                    </p>
                                </div>
                            </div>
                            <div className="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
                                {/* <a class="btn btn-primary text-white btn-rounded" href="https://themeforest.net/item/gull-bootstrap-laravel-admin-dashboard-template/23101970" target="_blank">Buy Gull HTML</a> */}
                                <span className="flex-grow-1" />
                                <div className="d-flex align-items-center">
                                    <img className="logo" src="" alt="" />
                                    <div>
                                        <p className="m-0">© 2021 Pamojour</p>
                                        <p className="m-0">All rights reserved</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* fotter end */}
                    </div>
                </div>
            </div>
            <Helmet>
                <script src="dist-assets/js/plugins/jquery-3.3.1.min.js"></script>
                <script src="dist-assets/js/plugins/bootstrap.bundle.min.js"></script>
                <script src="dist-assets/js/plugins/perfect-scrollbar.min.js"></script>
                <script src="dist-assets/js/scripts/script.min.js"></script>
                <script src="dist-assets/js/scripts/sidebar.large.script.min.js"></script>
                <script src="dist-assets/js/scripts/sidebar.script.min.js"></script>
                <script src="dist-assets/js/plugins/echarts.min.js"></script>
                <script src="dist-assets/js/scripts/echart.options.min.js"></script>
                <script src="dist-assets/js/scripts/dashboard.v1.script.min.js"></script>
            </Helmet>
        </div>
    )
}
