import React from 'react'
import HeaderFloating from '../../Header/HeaderFloating'



import { useEffect,useState } from 'react';

import {useParams } from "react-router-dom"

import axios from 'axios';
import SearchContent from './SearchContent';
import SearchSectionNgerit from './SearchSectionNgerit';

import {Helmet} from 'react-helmet'

function SearchResults() {

    

  return (
    <div>

    <Helmet>
    
    

    <link href="assets_fheader/vendor/fontawesome-free/css/all.min.css" rel="stylesheet"/>
    <link href="assets_fheader/vendor/animate.css/animate.min.css" rel="stylesheet"/>
    <link href="assets_fheader/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet"/>
    <link href="assets_fheader/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet"/>
    <link href="assets_fheader/vendor/boxicons/css/boxicons.min.css" rel="stylesheet"/>
    <link href="assets_fheader/vendor/glightbox/css/glightbox.min.css" rel="stylesheet"/>
    <link href="assets_fheader/vendor/remixicon/remixicon.css" rel="stylesheet"/>
    <link href="assets_fheader/vendor/swiper/swiper-bundle.min.css" rel="stylesheet"/>
  
   
    <link href="assets_fheader/css/style.css" rel="stylesheet"/>

    
    </Helmet>

    
    <HeaderFloating/>

  <SearchSectionNgerit/>


    <SearchContent/>


    
<Helmet>
    
    

<script src="assets_fheader/vendor/purecounter/purecounter.js"></script>
<script src="assets_fheader/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
<script src="assets_fheader/vendor/glightbox/js/glightbox.min.js"></script>
<script src="assets_fheader/vendor/swiper/swiper-bundle.min.js"></script>


<script src="assets_fheader/js/main.js"></script>


</Helmet>



   
    
    
    </div>
  )
}

export default SearchResults