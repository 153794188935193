import React,{useEffect, useState} from 'react';
import FeaturedInfo from './FeaturedInfo';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, Link  } from "react-router-dom";
import pimage from '../../assets/dist-assets/images/faces/3.jpg';
import { Helmet } from "react-helmet";
import CustomizedDialogs from './Addcategory';
import { Button } from '@material-ui/core'
import { TextField } from '@material-ui/core'


export default function Addservices() {
    

    const pathname = window.location.pathname;
    const [listOfServices, setListOfServices]=useState([]);
    const [listOfCategories, setListOfCategories]=useState([]);
    const navigate=useNavigate();
    // var data=0;
    useEffect( ()=> {
        if(!localStorage.getItem("accessToken")){
          navigate("/login");  
        }else{
            axios.get("http://localhost:3001/service/categories",{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
            console.log(response.data);
            setListOfCategories(response.data);
            });
        }
    },[]);

    const initialValues = {
        service_name: '',
        service_type: '',
        service_cost: '',
        service_hours: '',
    }
    const validationSchema  = Yup.object().shape({
        service_name: Yup.string().min(4, "It's too short").max(15, "Its Long Maximu 15 Characters").required("Service Name Required"),
        service_type: Yup.string().min(10, "It's too short").max(250),
        service_cost: Yup.number("Must be more than 0").positive("Must be more than 0"),
        service_hours: Yup.number("Must be more than 0").positive("Must be greater than 0").required("Service Duration"),
    })

    // const getStaff=(id)=>{
    //     axios.get(`http://localhost:3001/staff/${id}`,{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
    //         console.log(response.data);
    //         setListOfStaff(response.data.myStaffList);
    //         setTopStaff(response.data.topStaff);
    //         });
    // }

    const onSubmit = (data) => {
        console.log("HHHHHHHHH");
        axios.post("http://localhost:3001/service", data,{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
        console.log(response);
        // navigate("/services");
        window.location.reload();
        // if (response.status === 200)
        // alert("hello")
        // handleClose();
        // toast.success('Success Message')
        // props.resetForm()
        }); 
        
    }

    const updateStaff=()=>{
        alert("hello");
    }
    
    return (
        <div>
            <Helmet>
                <link rel="stylesheet" href="dist-assets/css/themes/lite-purple.min.css" />
                <link rel="stylesheet" href="dist-assets/css/plugins/perfect-scrollbar.min.css" />
            </Helmet>
            <div className="text-left">
                <div className="app-admin-wrap layout-sidebar-large">
                    <Topbar />
                    <Sidebar />
                    <div className="main-content-wrap sidenav-open d-flex flex-column">
                        {/* ============ Body content start ============= */}
                        <div className="main-content">
                            <div className="inbox-main-sidebar-container" data-sidebar-container="main">
                                <div className="inbox-main-content" data-sidebar-content="main">
                                    <div className="inbox-secondary-sidebar-container box-shadow-1" data-sidebar-container="secondary">
                                        <div className="inbox-secondary-sidebar perfect-scrollbar rtl-ps-none" data-sidebar="secondary">
                                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={ onSubmit }  >
                                            <Form>   
                                                <div className="flex-grow-1" style={{padding:"15px;"}}>
                                                    <img className="avatar-md rounded mr-3" src={pimage} alt="" />
                                                    <div className="form-group row">
                                                        <div className="col-sm-12">
                                                            <Field className="form-control"  name="service_name"  type="text" placeholder="Service Name" />
                                                            <ErrorMessage name='service_name' component="span" style={{color:"#f44336"}} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Service Description</label>
                                                        <div className="col-sm-8">
                                                            <Field className="form-control" name="service_type"   placeholder="Brief Description" />
                                                            <ErrorMessage name='service_type' component="span" style={{color:"#f44336"}} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Service Category</label>
                                                        <div className="col-sm-8">
                                                            <Field as ="select" className="form-control" name="CategoryId"  type="text" >
                                                            <option value="">Category(0)</option>
                                                            { listOfCategories.map((value, key) => {
                                                             return (
                                                            <option value={value.id}>{value.category_name}</option>
                                                              );
                                                            }) }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Service Cost(hrs)</label>
                                                        <div className="col-sm-8">
                                                            <Field className="form-control" name="service_cost"  type="text"  />
                                                            <ErrorMessage name='service_cost' component="span" style={{color:"#f44336"}} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">Service Time(mins)</label>
                                                        <div className="col-sm-8">
                                                            <Field className="form-control" type="text" name="service_hours" placeholder="0.0" style={{color:"#f44336"}} />
                                                        </div>
                                                    </div>
                                                    <div className='form-group row'>
                                                    <div className="col-sm-4">
                                                        <Button className="btn btn-info" type="submit">Save</Button>
                                                        </div>
                                                        <div className="col-sm-4">
                                                        <button className="btn btn-danger" type="button">Cancel</button></div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Formik>
                                        </div>
                                    </div>
                                </div>
                                {/* MAIN INBOX SIDEBAR*/}
                                <div className="inbox-main-sidebar" data-sidebar="main" data-sidebar-position="left">
                                    <div className="pt-3 pr-3 pb-3"><i className="sidebar-close i-Close" data-sidebar-toggle="main" />
                                        <div className="pl-3 box-shadow-1">
                                        <CustomizedDialogs title="Add Category"></CustomizedDialogs>
                                            <h5 style={{padding:"10px"}}>Categories</h5>
                                            <ul className="inbox-main-nav">
                                                <li><a className="active" href="/services"><i className="icon-regular i-Folder" /> All Services</a></li>
                                                { listOfCategories.map((value, key) => {
                                                    return (
                                                        <li key={key}><a className="active" href="#"><i className="icon-regular i-Folder" />{value.category_name}</a></li>
                                                    );
                                                }) }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* Footer Start */}
                        <div className="flex-grow-1" />
                        <div className="app-footer">
                            <div className="row">
                                <div className="col-md-9">
                                    <p><strong>Pamojour</strong></p>
                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero quis beatae officia saepe perferendis voluptatum minima eveniet voluptates dolorum, temporibus nisi maxime nesciunt totam repudiandae commodi sequi dolor quibusdam
                                        <sunt />
                                    </p>
                                </div>
                            </div>
                            <div className="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
                                {/* <a class="btn btn-primary text-white btn-rounded" href="https://themeforest.net/item/gull-bootstrap-laravel-admin-dashboard-template/23101970" target="_blank">Buy Gull HTML</a> */}
                                <span className="flex-grow-1" />
                                <div className="d-flex align-items-center">
                                    <img className="logo" src="" alt="" />
                                    <div>
                                        <p className="m-0">© 2021 Pamojour</p>
                                        <p className="m-0">All rights reserved</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* fotter end */}
                    </div>
                </div>
            </div>
            <Helmet>
                <script src="dist-assets/js/plugins/jquery-3.3.1.min.js"></script>
                <script src="dist-assets/js/plugins/bootstrap.bundle.min.js"></script>
                <script src="dist-assets/js/plugins/perfect-scrollbar.min.js"></script>
                <script src="dist-assets/js/scripts/script.min.js"></script>
                <script src="dist-assets/js/scripts/sidebar.large.script.min.js"></script>
                <script src="dist-assets/js/scripts/sidebar.script.min.js"></script>
                <script src="dist-assets/js/plugins/echarts.min.js"></script>
                <script src="dist-assets/js/scripts/echart.options.min.js"></script>
                <script src="dist-assets/js/scripts/dashboard.v1.script.min.js"></script>
            </Helmet>
        </div>
    )
}
