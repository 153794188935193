import React from 'react'



function TunepForBusiness() {
  return (
    <div>


      <div class="container features-descriptions padding-bottom-37">
                      <div class="row">
                          <div class="col-md-12 features-headings">
                              <h3>Unlimited Appointments, Clients & Services</h3>
                              <span>Don't let software hold you back when you have a busy month.</span>
                              <a href="unlimited-appointments-clients.html" class="btn btn-tt-lightbkgrd-small-inverted">Learn more</a>
                          </div>
                          <div class="col-md-6 topmargin-sm text-center large-horizontal">
                              <img src="https://9a812d2609e610ab07eb-b463fa4ca2c8095be4f297e4d7f6781b.ssl.cf2.rackcdn.com/unlimited-appointments.png" alt="A scheduling system that offers unlimited appointments" />
                          </div>
                          <div class="col-md-6 topmargin-sm">
                              <p>There’s a certain amount of unnecessary anxiety when the scheduling software you use tells you that it’s free for up to 100 appointments a month. With TimeTap’s Web Scheduler, you don’t need to worry about barriers that are essential to your businesses success.</p>
                              <p>You get unlimited appointments and clients every month so you don’t have to worry that your success means your online booking system may be taken down until the next month.</p>
                              <div class="col-md-6 col-sm-6 col-xs-6 no-padding-left">
                                  <h4>Schedule 1-on-1s or Groups</h4>
                                  <p>Get people booked for your 1-on-1 meetings or your class or group sessions all from the same app. Keep track of which clients show up and which ones don’t make it and get instant reports on attendance rates.</p>
                              </div>
                              <div class="col-md-6 col-sm-6 col-xs-6 no-padding-right">
                                  <h4>Track your business growth</h4>
                                  <p>With no cap on your appointments, you can always aim to do more each month. Grow your bottom line by beating your booking rates month over month. It’s simple to setup and even easier to maintain!
                                  </p>
                              </div>
                          </div>
                      </div>
                      <div class="divider divider-short divider-center"><em class="icon-circle"></em></div>
                      <div class="row">
                          <div class="col-md-12 features-headings">
                              <h3>Complete Appointment Management</h3>
                              <span>Wake up every morning with your schedule already prepped</span>
                              <a href="appointment-management.html" class="btn btn-tt-lightbkgrd-small-inverted">Learn more</a>
                          </div>
                          <div class="col-md-6 col-sm-12 col-xs-12 text-center topmargin-sm pull-right large-horizontal">
                              <img src="https://9a812d2609e610ab07eb-b463fa4ca2c8095be4f297e4d7f6781b.ssl.cf2.rackcdn.com/complete-appointment-management.png" alt="Completely manage your schedule through online appointment booking" />
                          </div>
                          <div class="col-md-6 topmargin-sm">
                              <p>Paper based scheduling is a disjointed process. You take the client’s phone call and write down on a calendar when they’re coming in. You carry that calendar around with you so you can reference it at any time and then have to pull it out each night to send out email reminders. </p>
                              <p>TimeTap has combined all of these tasks into a total appointment management system. You’ll feel the joy of booking after just a few days of fewer logistical headaches and feel less stressed that you’re not doing enough to be available for your clients.</p>
                              <div class="col-md-6 col-sm-6 col-xs-6 no-padding-left">
                                  <h4>Offer comfort to your clients</h4>
                                  <p>Like you, your clients lead busy lives. They have kids to drop off at practice and work to finish before a deadline and nosey co-workers they don’t want eavesdropping on their phone calls. By offering an online booking system, you’re easing the burden of your clients’ (sometimes overwhelmingly) busy lives.</p>
                              </div>
                              <div class="col-md-6 col-sm-6 col-xs-6 no-padding-right">
                                  <h4>Show Clients you care</h4>
                                  <p>Your clients want to meet with you and you want to meet with your clients, but neither of you want to spend 30 minutes setting the appointment time. That’s at least how long it feels between emailing back & forth or playing phone tag to try and set a meeting date. Offering easy online booking shows your clients that you respect their time.</p>
                              </div>
                          </div>

                      </div>


                  </div>
    
    
    
    </div>
  )
}

export default TunepForBusiness