import { Link } from 'react-router-dom';
import React from 'react';
import "./navbar.css";
import InputIcon from '@material-ui/icons/Input';
import CallIcon from '@material-ui/icons/Call';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Dashboard from '@material-ui/icons/Dashboard';

const Navbar = props => {
    return ( 
        <nav id="navigation" className=" navbar navbar-expand-lg bg-success p-2 text-dark bg-opacity-50">
            <div className="container_fluid">
                
            </div>
                <Link className="navbar-brand" to="/">
                <h5 className='text-center text-dark mt-2' style={{marginLeft: 300}}>CALL TO MAKE A BOOKING: +254 724 512 561</h5>
                </Link>
                <but
                ton className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon bg-light"></span>
                </but>
                <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto ">
                    <CallIcon className='mt-2'/>
                    <li className="nav-item">
                    <Link className="nav-link active text-dark" aria-current="page" to="/contact">Contact</Link>
                    </li>
                    <InputIcon className='mt-2' />
                    <li className="nav-item">
                    <Link className="nav-link text-dark" to="/login">Login</Link>
                    </li>
                    <PersonAddIcon className='mt-2'/>
                    <li className="nav-item">
                    <Link className="nav-link text-dark" to="/create">Create Account</Link>
                    </li>
                    <Dashboard  className='mt-2' />
                    <li className="nav-item">
                    <Link className="nav-link text-dark" to="/dashboard">Dashboard</Link>
                    </li>
                </ul>
                </div>
            
            </nav> 

    );
}
 
export default Navbar;