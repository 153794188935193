import React from 'react'

export default function MainContent() {
  return (
    <div>


    <main>
          
    <section class="py-6 bg-light-primary overflow-hidden" style={{marginTop:'80px'}}>
        <div class="container">
            <div class="row align-items-center justify-content-center justify-content-lg-between mb-6">
                <div class="col-lg-6 col-md-10 mb-5 mb-lg-0 text-center text-lg-left">
                    <div class="d-none d-lg-block mb-2" data-aos="fade-up" >
                        <div class="alert alert-modern text-white"  style={{backgroundColor:"#085781"}}>
                          <span class="badge badge-danger font-weight-medium badge-pill py-1 px-2 align-middle mr-2">News</span>
                          <span class="alert-content">A flexible solution for any business🤩</span>
                        </div>
                    </div>
                    <h1 class="font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="50">Evolve & Grow Your Business with Online Booking.</h1>
                    <p class="text-muted" data-aos="fade-up" data-aos-delay="100">Tunep platform supports the needs of a variety of business. Any business that needs to unleash their potential.</p>
                    
                    <div class="d-flex flex-column align-items-center flex-sm-row justify-content-center justify-content-lg-start mt-6">
                        <a href="/start-now" class="d-inline-block btn btn-primary waves-effect mr-4 mb-3 mb-sm-0" data-aos="fade-up"   style={{backgroundColor:"#085781"}} data-aos-delay="150">Sign Up Free Today</a>
                       
                    </div>

                   
              
                </div>

               



                <div class="col-xl-5">
                <figure class="lg:maxw-lg md:mt-5"> 
                <picture>
                 <source srcset=" https://assets.setmore.com/website/v2/images/industry-pages/industry-root/setmore-customers-mobile.webp, https://assets.setmore.com/website/v2/images/industry-pages/industry-root/setmore-customers-mobile-2x.webp 2x " type="image/webp" media="(max-width: 767px)"/> 
                <source srcset=" https://assets.setmore.com/website/v2/images/industry-pages/industry-root/setmore-customers-mobile.png, https://assets.setmore.com/website/v2/images/industry-pages/industry-root/setmore-customers-mobile-2x.png 2x " type="image/png" media="(max-width: 767px)"/> 
                <source srcset=" https://assets.setmore.com/website/v2/images/industry-pages/industry-root/setmore-customers.webp, https://assets.setmore.com/website/v2/images/industry-pages/industry-root/setmore-customers-2x.webp 2x " type="image/webp"/>
                 <source srcset=" https://assets.setmore.com/website/v2/images/industry-pages/industry-root/setmore-customers.png, https://assets.setmore.com/website/v2/images/industry-pages/industry-root/setmore-customers-2x.png 2x " type="image/png"/> 
                 <img src="https://assets.setmore.com/website/v2/images/industry-pages/industry-root/setmore-customers.png" alt="setmore customers from different industries" class="imgholder-secondary"/>
                  </picture> 
                  </figure>
               
            </div>



                </div>
                
              
                
             
        </div>

        
    </section>

    

   {/*

    <section class="industry__nav bg-offset md:mt-2 md:mb-2" id="industry-nav"> 
    <nav class="container br-8 bg-white">
     <a href="#beauty" class="text-xs text-lg-grey active:bg-yellow hover:border-yellow scrollto">Beauty</a>
     <a href="#wellbeing" class="text-xs text-lg-grey active:bg-red hover:border-red">Wellbeing</a>
     <a href="#creative" class="text-xs text-lg-grey active:bg-light-grey hover:border-light-grey">Creative</a> 
     <a href="#healthcare" class="text-xs text-lg-grey active:bg-blue hover:border-blue">Healthcare</a>
     <a href="#homey" class="text-xs text-lg-grey active:bg-yellow hover:border-yellow">Home Maintenace</a>
    <a href="#education" class="text-xs text-lg-grey active:bg-orange hover:border-orange">Education</a>
      
    <a href="#professionalservices" class="text-xs text-lg-grey active:bg-green hover:border-green">Professional services</a> 
     
    <a href="#tradeservices" class="text-xs text-lg-grey active:bg-purple hover:border-purple">Trade</a> 
       
         </nav> 
    </section>
  
  
  */} 













</main>
    
    
    </div>
  )
}
