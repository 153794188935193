import React from "react";

import {Link} from 'react-router-dom'


import { Helmet } from "react-helmet";

export default function Sidebar() {
	return (
        
		<div>

        <Helmet>

            
        <link rel="preconnect" href="https://fonts.googleapis.com/" />
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;display=swap" rel="stylesheet" />
        <link rel="stylesheet" href="assets_admin/vendor/fonts/boxicons.css" />
        <link rel="stylesheet" href="assets_admin/vendor/fonts/fontawesome.css" />
        <link rel="stylesheet" href="assets_admin/vendor/fonts/flag-icons.css" />
        <link rel="stylesheet" href="assets_admin/vendor/css/rtl/core.css" class="template-customizer-core-css" />
        <link rel="stylesheet" href="assets_admin/vendor/css/rtl/theme-default.css" class="template-customizer-theme-css" />
        <link rel="stylesheet" href="assets_admin/css/demo.css" />
        <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
        <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
        <link rel="stylesheet" href="assets_admin/vendor/libs/apex-charts/apex-charts.css" />
    
    
        <link rel="stylesheet" href="assets_admin/vendor/libs/fullcalendar/fullcalendar.css" />
        <link rel="stylesheet" href="assets_admin/vendor/libs/flatpickr/flatpickr.css" />
        <link rel="stylesheet" href="assets_admin/vendor/libs/select2/select2.css" />
    
        <link rel="stylesheet" href="assets_admin/vendor/css/pages/app-calendar.css" />
    
        
        <script src="assets_admin/vendor/js/helpers.js"></script>
        <script src="assets_admin/vendor/js/template-customizer.js"></script>
        <script src="assets_admin/js/config.js"></script>
            </Helmet>
        
            <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
            <div className="app-brand demo" style={{marginTop:'20px'}}>
            <a href="/new-dashboard" className="app-brand-link">
               {/*<span className="app-brand-logo demo">
                 
               <img src="assets_admin/img/logos/tunepp.jpg"/>

               </span> */}
                <span className="app-brand-text demo menu-text fw-bold ms-2">Mazaokilimo</span>
            </a>
            <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto">
                <i className="bx menu-toggle-icon d-none d-xl-block fs-4 align-middle" />
                <i className="bx bx-x d-block d-xl-none bx-sm align-middle" />
            </a>
        </div>
                <div className="menu-divider mt-0  ">
                </div>
                <div className="menu-inner-shadow" />
                <ul className="menu-inner py-1">
                    {/* Dashboards */}
                    <li className="menu-item">
                        <a href="/new-dashboard" className="menu-link">
                            <i className="menu-icon tf-icons bx bx-home-circle" />
                            <div data-i18n="Dashboard">Dashboard</div>
                        </a>
                       
                    </li>

                    <li className="menu-item">
                    <a class="nav-link active" href="/new-dashboard">
                    <i class='bx bx-user'></i> New Requests</a>
                    </li>


                   
                    {/* Layouts */}
                   
                    {/* Apps & Pages */}
                    <li className="menu-header small text-uppercase"><span className="menu-header-text">Apps &amp; Pages</span></li>
                   

                    <li className="menu-item">

                    <a className="menu-link" href="/my-staff">

                    <i class="menu-icon tf-icons bx bx-user-check"></i>
                     <div data-i18n="Staff">Staff</div>
                    
                    </a>
                   
                  </li>



                    <li className="menu-item">

                    <a className="menu-link" href="/my-customers">

                    <i class="menu-icon tf-icons bx bx-user-check"></i>
                     <div data-i18n="Customers">Customers</div>
                    
                    </a>
                   
                  </li>


                  <li className="menu-item">

                  <a className="menu-link" href="/enroll-farmer">

                  <i class="menu-icon tf-icons bx bx-user-check"></i>
                   <div data-i18n="Farmers">Farmers</div>
                  
                  </a>
                 
                </li>

               


               
                  <li className="menu-item">
                  
                  
                  <a className="menu-link" href="/my-products">

                  <i class='menu-icon bx bxl-product-hunt'></i>

                  <div data-i18n="Services/Products">Services/Products</div>
                  
                  </a>
                   
                  </li>
                 
                    <li className="menu-item">
                        <a href="javascript:void(0);" className="menu-link menu-toggle">
                            <i className="menu-icon tf-icons bx bx-user" />
                            <div data-i18n="Users">Users</div>
                        </a>
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <a href="/list-users" className="menu-link">
                                    <div data-i18n="List">List</div>
                                </a>
                            </li>

                            <li className="menu-item">
                            <a href="/user-account" className="menu-link">
                                <div data-i18n="Account">View Account</div>
                            </a>
                            </li>


                            <li className="menu-item">
                            <a href="/user-notifications" className="menu-link">
                                <div data-i18n="Notifications">Notifications</div>
                            </a>
                        </li>
                            <li className="menu-item">
                                <a href="javascript:void(0);" className="menu-link menu-toggle">
                                    <div data-i18n="View">View</div>
                                </a>
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <a href="/user-account" className="menu-link">
                                            <div data-i18n="Account">Account</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="#" className="menu-link">
                                            <div data-i18n="Security">Security</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="#" className="menu-link">
                                            <div data-i18n="Billing & Plans">Billing &amp; Plans</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="#" className="menu-link">
                                            <div data-i18n="Notifications">Notifications</div>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="#" className="menu-link">
                                            <div data-i18n="Connections">Connections</div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>


                    <li className="menu-item">
                    <a href="#" className="menu-link menu-toggle">
                        <i className="menu-icon tf-icons bx bx-food-menu" />
                        <div data-i18n="Invoice">Invoice</div>
                    </a>
                    <ul className="menu-sub">
                        <li className="menu-item">
                            <a href="#" className="menu-link">
                                <div data-i18n="List">List</div>
                            </a>
                        </li>
                        <li className="menu-item">
                            <a href="#" className="menu-link">
                                <div data-i18n="Preview">Preview</div>
                            </a>
                        </li>
                        <li className="menu-item">
                            <a href="#" className="menu-link">
                                <div data-i18n="Edit">Edit</div>
                            </a>
                        </li>
                        <li className="menu-item">
                            <a href="app-invoice-add.html" className="menu-link">
                                <div data-i18n="Add">Add</div>
                            </a>
                        </li>
                    </ul>
                </li>


                    <li className="menu-item">
                  
                    <a className="menu-link" href="#">
                    
                    
                    <i class='menu-icon bx bxs-report' ></i>Reports
                    </a>
                    
                    </li>
                   
                    <li className="menu-item">
                  
                    <a className="menu-link" href="/account-settings">
  
                    <i class="menu-icon fa fa-cogs" aria-hidden="true"></i>
  
                    <div data-i18n="Setting">Settings</div>
                    
                   </a>
                     
                    </li>
                    

                    <li className="menu-item">
                    <a class="nav-link active" href="/profile">
                    <i class='bx bx-user'></i> Profile</a>
                    </li>
                   
                   
                 
                  
                    
                 
                    <li className="menu-header small text-uppercase"><span className="menu-header-text">Misc</span></li>
                    <li className="menu-item">
                        <a href="#" className="menu-link">
                            <i className="menu-icon tf-icons bx bx-support" />
                            <div data-i18n="Support">#</div>
                        </a>
                    </li>
                    <li className="menu-item">
                        <a href="#" className="menu-link">
                            <i className="menu-icon tf-icons bx bx-file" />
                            <div data-i18n="Documentation">#</div>
                        </a>
                    </li>
                </ul>
            </aside>

            <Helmet>
            <script src="assets_admin/vendor/libs/jquery/jquery.js"></script>
            <script src="assets_admin/vendor/libs/popper/popper.js"></script>
            <script src="assets_admin/vendor/js/bootstrap.js"></script>
            <script src="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
            <script src="assets_admin/vendor/libs/hammer/hammer.js"></script>
            <script src="assets_admin/vendor/libs/i18n/i18n.js"></script>
            <script src="assets_admin/vendor/libs/typeahead-js/typeahead.js"></script>
            <script src="assets_admin/vendor/js/menu.js"></script>
    
    
            <script src="assets_admin/vendor/libs/fullcalendar/fullcalendar.js"></script>
            <script src="assets_admin/vendor/libs/apex-charts/apexcharts.js"></script>
            <script src="assets_admin/js/main.js"></script>
            <script src="assets_admin/js/dashboards-analytics.js"></script>
    
    
    
    
            <script src="assets_admin/vendor/libs/select2/select2.js"></script>
    <script src="assets_admin/assets/vendor/libs/flatpickr/flatpickr.js"></script>
    <script src="assets_admin/assets/vendor/libs/moment/moment.js"></script>
    
    
    
        
            <script src="assets_admin/js/main.js"></script>
         
    
    
            <script src="assets_admin/js/app-calendar-events.js"></script>
            <script src="assets_admin/js/app-calendar.js"></script>
            </Helmet>
        </div>
    )
}