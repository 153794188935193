import React, { Component,useState } from 'react'
import BigCalendar from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import "react-big-calendar/lib/addons/dragAndDrop/styles.css"
import moment from 'moment'
// import Modal from '../Modal'

import ModalFC from '../Modals'
import events_json from './events'
import './index.css'


moment.locale(navigator.language, {
    week: {
      dow: 1
    },
  });
  const localizer = BigCalendar.momentLocalizer(moment)
  const formats = {
    timeGutterFormat: 'H:mm',
    agendaTimeFormat: 'H:mm',
    agendaHeaderFormat: ({ start, end }, culture, local) => (
      `${local.format(start, "MMMM D")} — ${local.format(end, "MMMM D")}`),
    dayHeaderFormat: 'dddd MMMM Do',
  }

 const DragAndDropCalendar = withDragAndDrop(BigCalendar)

export default function CalendarFC() {

    const [events,setEvents]=useState(events_json)

    const [modalIsOpen,setModalIsOpen]=useState(false)

    const [isNewEvent,setIsNewEvent]=useState(false)

    const [modalEvent,setModalEvent]=useState({
        title: '',
        start: null,
        end: null,
        desc: '',
        id: null
      })



    const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
        const { events } = events
        const idx = events.indexOf(event)
        let allDay = event.allDay
    
        if (!event.allDay && droppedOnAllDaySlot) {
          allDay = true
        } else if (event.allDay && !droppedOnAllDaySlot) {
          allDay = false
        }
    
        const updatedEvent = { ...event, start, end, allDay }
        const nextEvents = [...events]
        nextEvents.splice(idx, 1, updatedEvent)
    
        setEvents({
          events: nextEvents,
        })
      }


     const resizeEvent = ({ event, start, end }) => {
        const { events } = events
    
        const nextEvents = events.map(existingEvent => {
          return existingEvent.id === event.id
            ? { ...existingEvent, start, end }
            : existingEvent
        })
    
        setEvents({
          events: nextEvents,
        })
      }

     const selectSlot = (event) => {
        setIsNewEvent({ isNewEvent: true })
        event.start = event.slots[0]
        event.end = event.slots[event.slots.length - 1]
        openModal(event)
      }



     const selectEvent = (event) => {
        setIsNewEvent({ isNewEvent: false })
        openModal(event)
      }
    
      const openModal = (event) => {
        const id = event.id ? event.id : Date.now()
        setModalIsOpen({
          modalIsOpen: true,
          modalEvent: {
            ...event,
            id
          }
        });
      }


    function getEventStyle(event, start, end, isSelected) {
        const style = {}
        const todayDate = new Date().getDate()
    
        if (start.getDate() === todayDate) {
          style.backgroundColor = 'green'
        } else if (start.getDate() < todayDate) {
          style.backgroundColor = 'red'
        } else if (start.getDate() > todayDate) {
          style.backgroundColor = 'blue'
        }
        if (event.bgcolor) {
          style.backgroundColor = event.bgcolor
        }
    
        return { style }
      }


    const  closeModal = () => {
        setModalIsOpen({ modalIsOpen: false });
      }
    
    const  handleModalEventEdit = (key, newValue) => {
        const newData = { ...modalEvent }
        newData[key] = newValue
        setModalEvent({
          modalEvent: newData
        })
      }
    

    const  handleEventSave = (newEvent) => {
        const index = events.findIndex( event => event.id === newEvent.id )
        if (index > -1) {
          const newEvents = events
          newEvents[index] = { ...newEvent }
          setEvents({
            events: newEvents
          })
        } else {
            setEvents({
            events: [
              ...events,
              { ...newEvent },
            ],
          })
        }
      }

    const  handleEventDelete = () => {
        const index = events.findIndex(event => {
          return event.id === modalEvent.id
        })
        if (index > -1) {
          const newEvents = events
          newEvents.splice(index, 1)
          setEvents({
            events: newEvents
          })
        }
      }
 

  return (
    <div>

    <DragAndDropCalendar style={{ height: '100vh' }}
          localizer={localizer}
          formats={formats}
          events={events}
          defaultView={'month'}
          defaultDate={new Date()}  // onSelectEvent click doesn't fire without this
          min={moment('10:00am', 'H:mma').toDate()}
          max={moment('09:59pm', 'H:mma').toDate()}
          step={60}
          showMultiDayTimes={true}
          onEventDrop={moveEvent}
          resizable={true}
          onEventResize={resizeEvent}
          selectable={true}
          onSelectEvent={selectEvent}
          onSelectSlot={selectSlot}
          popup={true}
          tooltipAccessor={(e) => e.title}
          eventPropGetter={getEventStyle}
        />


        <ModalFC 
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        handleModalEventEdit={handleModalEventEdit}
        modalEvent={modalEvent}
        handleEventSave={handleEventSave}
        handleEventDelete={handleEventDelete}
        isNewEvent={isNewEvent}
        key={modalEvent.id}
      />
    
    
    </div>
  )
}
