import React from 'react'

import './TimeTapStyles.css'

function MainContent() {
  return (
    <div>
    
      <section class="cover-background" style={{backgroundImage:"url(https://9a812d2609e610ab07eb-b463fa4ca2c8095be4f297e4d7f6781b.ssl.cf2.rackcdn.com/art-class-scheduling.png)"}}>
                        <div class="opacity-medium bg-dark-gray"></div>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-5 col-sm-8 text-left position-relative industries-subheading">
                                    <h1 class="title-med text-uppercase letter-spacing-1 white-text font-weight-600 margin-bottom-25">Get Your Private Lessons & Group Classes Booked Up.<br/><br />Promote your services & make reservations easy.</h1>
									<a class="btn button btn-tt-lightbkgrd-med-inverted xs-margin-bottom-five xs-no-margin-right" href="/start-now" style={{backgroundColor: "#5fbd74",
                                        fontSize: "18px",
                                        padding: "10px 16px",
                                        color: "#fff"}}>Sign Up Free</a>
                                </div>
                            </div>
                        </div>
         </section>

         <div class="container benefits-demo-section">
     
      <div class="row">
        <div class="col-md-4 col-sm-4">        
        <div class="guide-box sm-display-none">
          <h3>Online Scheduling for Arts Studios & Outdoor Rec Facilities</h3>          
          <div class="separator-line bg-black no-margin-lr"></div>
          <p>Let clients view your services & make reservations without having to man the phone</p>
        </div>
        <div class="clearfix"></div>
        <div class="benefits-box">
              <h3>Perfect for:</h3>
              <ul class="benefits-list">
                <li>Booking group lessons or activities</li>
                <li>Automating confirmations & reminders</li>
                <li>Sending out survey links in follow up emails</li>
                <li>Displaying your wide range of services in one view</li>
                <li>Allowing clients to book after hours</li>
              </ul>
          </div>
        </div>

        <div class="col-md-8 col-sm-8">
        	<div class="demo-box">
        	<h3>Try it Out: <small>Use the embedded web scheduler below to try out scheduling an appointment online as if you were a client. You can also check out <a href="https://gametruck.timetap.com/" target="_blank">booking an appointment online</a> on the TimeTap mini website that comes with each account.</small></h3>
            <div class="intrinsic-container intrinsic-container-16x9" style={{textAlign:"center"}}>
             
            </div>
            </div>
        </div>

      </div>

      
  </div>

  


    
    </div>
  )
}

export default MainContent