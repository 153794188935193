import React from 'react'
import SideBarMenu from '../SideBarMenu'

export default function MainFeatures() {
  return (
    <div>

    <section class="bg-soft-primary-pattern-1 pt-6 pb-8">
    <div class="container">
    <div class="row align-items-center justify-content-center justify-content-lg-between mb-6">
    <div class="col-lg-6 col-md-10 mb-5 mb-lg-0 text-center text-lg-left">
       
        <h1 class="font-weight-bold mb-4" data-aos="fade-up" data-aos-delay="50">SELL ONLINE</h1>
        <p class="text-muted" data-aos="fade-up" data-aos-delay="100">The easiest way to take control over your schedule.</p>
        
        <div class="d-flex flex-column align-items-center flex-sm-row justify-content-center justify-content-lg-start mt-6">
            <a href="javascript:;" class="d-inline-block btn btn-primary waves-effect mr-4 mb-3 mb-sm-0" data-aos="fade-up" data-aos-delay="150">View our services</a>
            <a href="javascript:;" class="d-inline-block font-weight-semibold text-primary" data-aos="fade-up" data-aos-delay="200">Learn More <i class="icon-chevron-right"></i></a>
        </div>
    </div>
    
    {/**  <div class="col-xxl-4 col-lg-5 col-md-9" data-aos="fade-up" data-aos-delay="200">
       <div class="card shadow-lg p-3 p-xl-5">
            <div class="d-flex">
                <span class="mr-3 fs-32">👋</span>
                <div>
                    <h4 class="font-weight-bold mb-1">
                    The next feature of appointment </h4>
                    <p class="text-gray-400 font-size-sm mb-0">What solution are you interested?</p>
                </div>
            </div>
            
            <hr/>
            
          
       </div>
    </div> */}
</div>   
    </div>
</section>

    <section class="py-6 mt-lg-n11">
    <div class="container">

    

    <div class="row pt-6 pt-lg-8">

    <SideBarMenu/>
 
    <div class="col-lg-9 order-1 order-lg-2 mb-5 mb-lg-0">

     
    <div class="clear"></div>
                      <div class="container features-descriptions padding-bottom-37">
                          <div class="row">
                              <div class="col-md-12 features-headings">
                                  <h3>Sell Online</h3>
                                  <span>Boost the profitability of your business by selling gift vouchers and other related products in-store and online.</span>
                                  <a href="unlimited-appointments-clients.html" class="btn btn-tt-lightbkgrd-small-inverted">Learn more</a>
                              </div>
                              <div class="col-md-6 topmargin-sm text-center large-horizontal">
                              <img src="images/features/sell-online.jpg" alt="A scheduling system that offers unlimited appointments" />
                              </div>
                              <div class="col-md-6 topmargin-sm">
                                  <p>There’s a certain amount of unnecessary anxiety when the scheduling software you use tells you that it’s free for up to 100 appointments a month. With TimeTap’s Web Scheduler, you don’t need to worry about barriers that are essential to your businesses success.</p>
                                  <p>You get unlimited appointments and clients every month so you don’t have to worry that your success means your online booking system may be taken down until the next month.</p>
                                  <div class="col-md-6 col-sm-6 col-xs-6 no-padding-left">
                                      <h4>Schedule 1-on-1s or Groups</h4>
                                      <p>Get people booked for your 1-on-1 meetings or your class or group sessions all from the same app. Keep track of which clients show up and which ones don’t make it and get instant reports on attendance rates.</p>
                                  </div>
                                  <div class="col-md-6 col-sm-6 col-xs-6 no-padding-right">
                                      <h4>Track your business growth</h4>
                                      <p>With no cap on your appointments, you can always aim to do more each month. Grow your bottom line by beating your booking rates month over month. It’s simple to setup and even easier to maintain!
                                      </p>
                                  </div>
                              </div>
                          </div>
                          
    
    
                      </div>


    </div>
</div>
</div>
</section>
    
    
    </div>
  )
}
