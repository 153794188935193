import React from 'react';
import FeaturedInfo from './FeaturedInfo';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
// import '../../assets/dist-assets/css/themes/lite-purple.min.css';
// import '../../assets/dist-assets/css/plugins/perfect-scrollbar.min.css';

import { Helmet } from "react-helmet";



export default function Dashboard() {
    return (
        
        <div>
            <Helmet>
                <link rel="stylesheet" href="dist-assets/css/themes/lite-purple.min.css" />
                <link rel="stylesheet" href="dist-assets/css/plugins/perfect-scrollbar.min.css" />
            </Helmet>
            <div class="text-left">
            <div class="app-admin-wrap layout-sidebar-large">
            <Topbar />
            <Sidebar />
            <FeaturedInfo />
            </div>
            </div>
            
        </div>
    )
}

