import React from 'react';

export default function MainContentSection() {
  return(
   <div>

     <main>


     <section class="bg-cover-1 py-6 py-lg-8" style={{marginTop:'80px'}}>
     <div class="container">
         <div class="row">
             <div class="col-xl-8 col-lg-9">
                 <h1 class="font-weight-bold text-white">Contact Us</h1>
                 <p class="text-light mb-0">You are 1 step closer to build your dream project</p>
             </div>
         </div>
     </div>
 </section>


 <section class="py-6">
 <div class="container">
     <div class="row justify-content-between">
         <div class="col-lg-5 order-2 order-lg-1 pt-6">
             <h2 class="font-weight-bold mb-5">What will be Next Steps?</h2>

             <ul class="steps vertical list-unstyled mb-0">
                 <li data-aos="fade-up">
                     <span>1</span>
                     We'll prepare a proposal
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus velit exercitationem molestiae.</p>
                 </li>
                 <li data-aos="fade-up" data-aos-delay="50">
                     <span>2</span>
                     Together we discuss it
                     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas quae libero asperiores ducimus.</p>
                 </li>
                 <li data-aos="fade-up" data-aos-delay="100">
                     <span>3</span>
                     Let's start building
                     <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis culpa animi odit!</p>
                 </li>
                 <li data-aos="fade-up" data-aos-delay="150">
                     <span>4</span>
                     Test it and delever it
                     <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perferendis culpa animi odit!</p>
                 </li>
             </ul>
         </div>

         <div class="col-lg-6 order-1 order-lg-2 mt-lg-4 mt-lg-n11">
             <div class="card shadow-sm">
                 <div class="card-header bg-white">
                     <div class="media flex-column flex-sm-row py-2">
                         <div class="iconbox icon-lg rounded-circle iconbox-soft-primary mb-3 mb-sm-0 mr-2 mr-sm-3">
                             <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path opacity="0.4" d="M21.9999 15.9402C21.9999 18.7302 19.7599 20.9902 16.9699 21.0002H16.9599H7.04989C4.26989 21.0002 1.99989 18.7502 1.99989 15.9602V15.9502C1.99989 15.9502 2.00589 11.5242 2.01389 9.2982C2.01489 8.8802 2.49489 8.6462 2.82189 8.9062C5.19789 10.7912 9.44689 14.2282 9.49989 14.2732C10.2099 14.8422 11.1099 15.1632 12.0299 15.1632C12.9499 15.1632 13.8499 14.8422 14.5599 14.2622C14.6129 14.2272 18.7669 10.8932 21.1789 8.9772C21.5069 8.7162 21.9889 8.9502 21.9899 9.3672C21.9999 11.5762 21.9999 15.9402 21.9999 15.9402Z" fill="currentColor"/>
                                 <path d="M21.476 5.6736C20.61 4.0416 18.906 2.9996 17.03 2.9996H7.05001C5.17401 2.9996 3.47001 4.0416 2.60401 5.6736C2.41001 6.0386 2.50201 6.4936 2.82501 6.7516L10.25 12.6906C10.77 13.1106 11.4 13.3196 12.03 13.3196C12.034 13.3196 12.037 13.3196 12.04 13.3196C12.043 13.3196 12.047 13.3196 12.05 13.3196C12.68 13.3196 13.31 13.1106 13.83 12.6906L21.255 6.7516C21.578 6.4936 21.67 6.0386 21.476 5.6736Z" fill="currentColor"/>
                             </svg>                                            
                         </div>

                         <div class="media-body">
                             <h4 class="font-weight-bold">Let’s talk -share your excitement with us</h4>
                             <p class="text-muted font-size-sm mb-0">Please complete the details below and then click on submit and we’ll be in contact</p>    
                         </div>
                     </div>
                 </div>

                 <div class="card-body">
                     <form>
                         <div class="form-row">
                             <div class="col-md px-2">
                                 <div class="form-group">
                                     <label for="fullName">Full name</label>
                                     <input class="form-control" type="text" id="fullName" placeholder="eg. Janet Muthoni" aria-describedby="fullName"/>
                                 </div>
                             </div>
                             <div class="col-md px-2">
                                 <div class="form-group">
                                     <label for="phoneNumber">Phone number</label>
                                     <input class="form-control" type="text" id="phoneNumber" placeholder="0796875345" aria-describedby="phoneNumber"/>
                                 </div>
                             </div>
                         </div>
                         <div class="form-row">
                             <div class="col-md px-2">
                                 <div class="form-group">
                                     <label for="emailAddress">Email address</label>
                                     <input class="form-control" type="email" id="emailAddress" placeholder="janetm@email.com" aria-describedby="emailAddress"/>
                                 </div>
                             </div>
                             <div class="col-md px-2">
                                 <div class="form-group">
                                     <label for="companyName">Business/ Name</label>
                                     <input class="form-control" type="text" id="companyName"  aria-describedby="companyName"/>
                                 </div>
                             </div>
                         </div>
                         <div class="form-row">
                             <div class="col-md px-2">
                                 <div class="form-group">
                                     <label for="emailAddress">Subject</label>
                                     <select class="custom-select">
                                         <option selected>Select a subject</option>
                                         <option value="1">Enrollment</option>
                                         <option value="2">Complaint</option>
                                         <option value="3">Clarification</option>
                                         <option value="4">Account</option>
                                         <option value="5">Other</option>
                                     </select>
                                 </div>
                             </div>
                             
                         </div>
                         <div class="form-row">
                             <div class="col-md px-2">
                                 <div class="form-group">
                                     <label for="projectDetails">Description</label>
                                     <textarea class="form-control" placeholder="Describe your project details here..." id="projectDetails" rows="3"></textarea>
                                 </div>
                             </div>
                         </div>
                         
                         <div class="form-row mt-5">
                             <div class="col-md px-2">
                                 <button type="submit" class="btn btn-primary font-size-sm">Submit</button>
                             </div>
                         </div>
                         <div class="form-row mt-3">
                             <div class="col-md small text-muted">By submitting this form you agree to our <a href="" class="link">terms and conditions</a>  and our <a href="" class="link">Privacy Policy</a>. We will collect your email and occasionally email you about products and services. you can unscribe anytime. </div>
                         </div>
                     </form>
                 </div>
             </div>
         </div>
     </div>
 </div>
</section>

<section class="py-6 bg-light-primary">
<div class="container">
    <div class="row justify-content-center row-cols-sm-2 row-cols-lg-3">
        <div class="col-lg mb-5 mb-lg-0 text-center" data-aos="fade-up">
            <div class="iconbox icon-lg rounded-circle iconbox-soft-primary mb-5">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
                    <path opacity="0.4" d="M21.9999 15.9402C21.9999 18.7302 19.7599 20.9902 16.9699 21.0002H16.9599H7.04989C4.26989 21.0002 1.99989 18.7502 1.99989 15.9602V15.9502C1.99989 15.9502 2.00589 11.5242 2.01389 9.2982C2.01489 8.8802 2.49489 8.6462 2.82189 8.9062C5.19789 10.7912 9.44689 14.2282 9.49989 14.2732C10.2099 14.8422 11.1099 15.1632 12.0299 15.1632C12.9499 15.1632 13.8499 14.8422 14.5599 14.2622C14.6129 14.2272 18.7669 10.8932 21.1789 8.9772C21.5069 8.7162 21.9889 8.9502 21.9899 9.3672C21.9999 11.5762 21.9999 15.9402 21.9999 15.9402Z" fill="currentColor"/>
                    <path d="M21.476 5.6736C20.61 4.0416 18.906 2.9996 17.03 2.9996H7.05001C5.17401 2.9996 3.47001 4.0416 2.60401 5.6736C2.41001 6.0386 2.50201 6.4936 2.82501 6.7516L10.25 12.6906C10.77 13.1106 11.4 13.3196 12.03 13.3196C12.034 13.3196 12.037 13.3196 12.04 13.3196C12.043 13.3196 12.047 13.3196 12.05 13.3196C12.68 13.3196 13.31 13.1106 13.83 12.6906L21.255 6.7516C21.578 6.4936 21.67 6.0386 21.476 5.6736Z" fill="currentColor"/>
                </svg>                                            
            </div>

            <h5 class="font-weight-semibold">Write an E-mail to us</h5>
            <p class="text-muted mb-4">Email us for general queries, including marketing and partnership opportunities.</p>
            <a class="font-weight-semibold btn btn-outline-primary light-shadow" href="mailto:info@uichamp.com">info@mytunep.com</a>
        </div>

        <div class="col-lg mb-5 mb-lg-0 text-center" data-aos="fade-up" data-aos-delay="50">
            <div class="iconbox icon-lg rounded-circle iconbox-soft-primary mb-5">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.4" d="M14.4183 5.48994C13.9423 5.402 13.5051 5.70579 13.4145 6.17048C13.3238 6.63516 13.6286 7.08884 14.0917 7.17978C15.4859 7.4516 16.5625 8.53085 16.8354 9.92989V9.93089C16.9131 10.3336 17.2676 10.6264 17.6759 10.6264C17.7307 10.6264 17.7855 10.6214 17.8412 10.6114C18.3043 10.5185 18.6091 10.0658 18.5185 9.60012C18.1111 7.51055 16.5027 5.89666 14.4183 5.48994Z" fill="currentColor"/>
                    <path opacity="0.4" d="M14.3561 2.00793C14.133 1.97595 13.9089 2.04191 13.7307 2.18381C13.5474 2.32771 13.4329 2.53557 13.408 2.76841C13.3552 3.23908 13.6948 3.66479 14.1649 3.71776C17.4065 4.07951 19.9262 6.60477 20.2907 9.85654C20.3395 10.2922 20.705 10.621 21.1412 10.621C21.174 10.621 21.2059 10.619 21.2388 10.615C21.4668 10.59 21.67 10.4771 21.8134 10.2972C21.9558 10.1174 22.0206 9.89351 21.9947 9.66467C21.5405 5.60746 18.4005 2.45862 14.3561 2.00793Z" fill="currentColor"/>
                    <path d="M17.6049 14.673C16.9566 14.5351 16.4437 14.8349 15.9896 15.0967C15.5245 15.3665 14.6391 16.0811 14.1332 15.8972C11.5379 14.8339 9.09693 12.5705 8.04028 9.97625C7.85405 9.45961 8.56811 8.57321 8.83601 8.10554C9.09793 7.65185 9.39272 7.1352 9.25827 6.48565C9.13677 5.90105 7.56524 3.90942 7.00952 3.36479C6.64204 3.00504 6.26758 2.80717 5.88216 2.7742C4.43313 2.71324 2.81479 4.63892 2.53095 5.0996C1.81888 6.08193 1.82386 7.38903 2.5429 8.97394C4.27577 13.231 10.8298 19.6556 15.1201 21.4464C15.9109 21.8151 16.6359 22 17.2862 22C17.9226 22 18.4893 21.8231 18.9763 21.4724C19.3428 21.2615 21.3565 19.5697 21.3027 18.0877C21.2718 17.7099 21.0736 17.3322 20.7171 16.9665C20.1734 16.4078 18.1875 14.7959 17.6049 14.673Z" fill="currentColor"/>
                </svg>                                                                         
            </div>

            <h5 class="font-weight-semibold">Call us</h5>
            <p class="text-muted mb-4">Call us to speak to a member of our team. We are always happy to help.</p>
            <a class="font-weight-semibold btn btn-outline-primary light-shadow" href="tel:+245 713 292 421">+245 713 292 421</a>
        </div>

        <div class="col-lg text-center" data-aos="fade-up" data-aos-delay="100">
            <div class="iconbox icon-lg rounded-circle iconbox-soft-primary mb-5">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.4" d="M11.9912 18.6215L5.49945 21.8641C5.00921 22.1302 4.39768 21.9525 4.12348 21.4643C4.0434 21.3108 4.00106 21.1402 4 20.9668V13.7088C4 14.4284 4.40573 14.8726 5.47299 15.3701L11.9912 18.6215Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.89526 2H15.0695C17.7773 2 19.9735 3.06605 20 5.79337V20.9668C19.9989 21.1374 19.9565 21.3051 19.8765 21.4554C19.7479 21.7007 19.5259 21.8827 19.2615 21.9598C18.997 22.0368 18.7128 22.0023 18.4741 21.8641L11.9912 18.6215L5.47299 15.3701C4.40573 14.8726 4 14.4284 4 13.7088V5.79337C4 3.06605 6.19625 2 8.89526 2ZM8.22492 9.62227H15.7486C16.1822 9.62227 16.5336 9.26828 16.5336 8.83162C16.5336 8.39495 16.1822 8.04096 15.7486 8.04096H8.22492C7.79137 8.04096 7.43991 8.39495 7.43991 8.83162C7.43991 9.26828 7.79137 9.62227 8.22492 9.62227Z" fill="currentColor"/>
                </svg>                                          
            </div>

            <h5 class="font-weight-semibold">Need any Support ?</h5>
            <p class="text-muted mb-4">Check out helpful resources, FAQs and developer tools.</p>
            <a class="font-weight-semibold btn btn-outline-primary light-shadow" href="">Support Center</a>
        </div>
    </div>
</div>
</section>  


<div class="py-6">
<div class="container">
    <div class="row">
        <div class="col" data-aos="fade-up">
            <h2 class="font-weight-bold mb-5">Find our Location on Map</h2>
           
        </div>
    </div>
</div>
</div>
          
     









            <div class="modal fade" id="startTrialModal2" tabindex="-1" aria-labelledby="startTrialModalLabel2" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down modal-xl">
            <div class="modal-content overflow-hidden">
                <button type="button" class="modal-close close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
    
                <div class="modal-body p-0">
                
                <div class="container">
                    <div class="row">
                        <div class="col-xl-5 order-2 order-xl-1 bg-dark text-white d-none d-xl-block signup-modal-bg-media-01">
                        </div>
    
                        <div class="col-xl-7 order-1 order-xl-2 px-2 px-sm-5 py-5">
                            <h2 class="h3 font-weight-bold">Create an Account</h2>
                            <p class="text-muted font-size-sm">100% Money-back guarantee, cancel any time.</p>
    
                            <div class="d-flex flex-column flex-sm-row pt-3">
                                <a href="javascript:;" class="btn btn-sm btn-white waves-effect mb-2 mb-sm-0">
                                    <svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M3.54594 9.66899L2.989 11.7481L0.953406 11.7912C0.345063 10.6628 0 9.37186 0 7.99999C0 6.67339 0.322625 5.42239 0.8945 4.32086H0.894938L2.70719 4.65311L3.50106 6.45449C3.33491 6.93889 3.24434 7.45889 3.24434 7.99999C3.24441 8.58724 3.35078 9.14989 3.54594 9.66899Z" fill="#FBBB00"/>
                                        <path d="M15.8602 6.50549C15.9521 6.98943 16 7.48921 16 7.99999C16 8.57274 15.9398 9.13143 15.8251 9.67034C15.4357 11.5042 14.4181 13.1055 13.0084 14.2387L13.008 14.2382L10.7253 14.1218L10.4023 12.1051C11.3377 11.5565 12.0687 10.698 12.4537 9.67034H8.1759V6.50549H12.5161H15.8602Z" fill="#518EF8"/>
                                        <path d="M13.0079 14.2383L13.0083 14.2387C11.6373 15.3407 9.89578 16 7.99996 16C4.95337 16 2.30459 14.2972 0.953369 11.7912L3.5459 9.66904C4.22149 11.4721 5.96084 12.7556 7.99996 12.7556C8.87643 12.7556 9.69756 12.5187 10.4022 12.1051L13.0079 14.2383Z" fill="#28B446"/>
                                        <path d="M13.1063 1.84175L10.5147 3.9635C9.78547 3.50769 8.92347 3.24437 7.99997 3.24437C5.91469 3.24437 4.14281 4.58678 3.50106 6.4545L0.894908 4.32088H0.89447C2.22591 1.75384 4.9081 0 7.99997 0C9.94106 0 11.7208 0.691437 13.1063 1.84175Z" fill="#F14336"/>
                                    </svg>
                                    <span>Signup with Google</span>
                                </a>
                                <a href="javascript:;" class="btn btn-sm btn-light border waves-effect ml-sm-2 mb-2 mb-sm-0">
                                    <svg class="mr-2 mr-sm-0" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M14.5527 0H1.44733C0.648 0 0 0.648 0 1.44733V14.5526C0 15.352 0.648 16 1.44733 16H14.5526C15.352 16 16 15.352 16 14.5526V1.44733C16 0.648 15.352 0 14.5527 0ZM4.95108 13.8155C4.95108 14.0481 4.76251 14.2366 4.52988 14.2366H2.73696C2.50434 14.2366 2.31577 14.0481 2.31577 13.8155V6.2996C2.31577 6.06697 2.50434 5.87841 2.73696 5.87841H4.52988C4.76251 5.87841 4.95108 6.06697 4.95108 6.2996V13.8155ZM3.63342 5.16993C2.69273 5.16993 1.93014 4.40733 1.93014 3.46664C1.93014 2.52595 2.69273 1.76335 3.63342 1.76335C4.57412 1.76335 5.33671 2.52595 5.33671 3.46664C5.33671 4.40733 4.57416 5.16993 3.63342 5.16993ZM14.3208 13.8494C14.3208 14.0632 14.1474 14.2366 13.9336 14.2366H12.0096C11.7958 14.2366 11.6224 14.0632 11.6224 13.8494V10.324C11.6224 9.79811 11.7766 8.01948 10.248 8.01948C9.06228 8.01948 8.82178 9.2369 8.77349 9.78325V13.8494C8.77349 14.0632 8.60013 14.2366 8.38622 14.2366H6.52545C6.31158 14.2366 6.13818 14.0632 6.13818 13.8494V6.26568C6.13818 6.05181 6.31158 5.87841 6.52545 5.87841H8.38622C8.60008 5.87841 8.77349 6.05181 8.77349 6.26568V6.92138C9.21315 6.26157 9.86655 5.75229 11.2578 5.75229C14.3385 5.75229 14.3208 8.63045 14.3208 10.2119V13.8494Z" fill="#0077B7"/>
                                    </svg>   
                                    
                                    <span class="d-sm-none">Signup with Linkedln</span>
                                </a>
                                <a href="javascript:;" class="btn btn-sm btn-light border waves-effect ml-sm-2">
                                    <svg class="mr-2 mr-sm-0" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M5.78926 16C3.40798 15.9863 1.43823 11.1253 1.43823 8.64969C1.43823 4.60581 4.47183 3.7205 5.64095 3.7205C6.16783 3.7205 6.73042 3.92741 7.22664 4.1105C7.57364 4.23822 7.93251 4.37006 8.13211 4.37006C8.25158 4.37006 8.53311 4.25791 8.78167 4.1595C9.31176 3.94847 9.97139 3.68619 10.7395 3.68619C10.7409 3.68619 10.7427 3.68619 10.7441 3.68619C11.3176 3.68619 13.0567 3.81206 14.1022 5.38219L14.3471 5.75022L13.9947 6.01619C13.4911 6.39613 12.5724 7.08919 12.5724 8.46203C12.5724 10.088 13.6129 10.7133 14.1128 11.0141C14.3334 11.1468 14.5618 11.2837 14.5618 11.5831C14.5618 11.7785 13.0018 15.9757 10.7363 15.9757C10.182 15.9757 9.79011 15.8091 9.44448 15.6622C9.09476 15.5134 8.79308 15.3853 8.29458 15.3853C8.04189 15.3853 7.72236 15.5047 7.38408 15.6315C6.92179 15.8041 6.39855 16 5.80483 16H5.78926Z" fill="black"/>
                                        <path d="M10.9795 0C11.0385 2.12737 9.51713 3.60325 7.9975 3.51069C7.74709 1.81297 9.51694 0 10.9795 0Z" fill="black"/>
                                    </svg>      
                                    <span class="d-sm-none">Signup with Apple ID</span>                                                                                                              
                                </a>
                            </div>
    
                            <div class="divider font-size-sm">Or Signup with</div>
                            <form>
                                <div class="form-row">
                                    <div class="col-lg px-2">
                                        <div class="form-group">
                                            <label for="firstName">First name</label>
                                            <input class="form-control" type="text" id="firstName" placeholder="Robert" aria-describedby="firstName"/>
                                        </div>
                                    </div>
                                    <div class="col-lg px-2">
                                        <div class="form-group">
                                            <label for="lastName">Last name</label>
                                            <input class="form-control" type="text" id="lastName" placeholder="Matthews" aria-describedby="lastName"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-lg px-2">
                                        <div class="form-group">
                                            <label for="emailAddress">Email address</label>
                                            <input class="form-control" type="email" id="emailAddress" placeholder="robertmatthews@email.com" aria-describedby="emailAddress"/>
                                        </div>
                                    </div>
                                    <div class="col-lg px-2">
                                        <div class="form-group">
                                        <label>Password</label>
                                        <div class="input-group password-input">
                                            <input type="password" class="form-control rounded password" placeholder="Enter your password" required=""/>
                                            
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">
                                                    <i class="pw-btn-toggle icon-eye"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
    
                                <div class="form-row">
                                    <div class="col-lg px-2">
                                        <button type="submit" class="btn btn-primary font-size-sm">Create an Account</button>
                                        <div class="small text-muted mt-3">By submitting this form you agree to our <a href="">terms and conditions</a>  and our <a href="">Privacy Policy</a>.</div>
                                    
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
                </div>
    
            </div>
            </div>
        </div>




        <div class="modal fade" id="startTrialModal3" tabindex="-1" aria-labelledby="startTrialModalLabel3" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down modal-xl">
        <div class="modal-content overflow-hidden  border-0">
            <button type="button" class="modal-close close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>

            <div class="modal-body p-0">
                <div class="row align-items-center no-gutters">
                    <div class="col-xl-7 px-2 px-sm-5 py-5">
                        <h2 class="h3 font-weight-bold">Create an Account</h2>
                        <p class="text-muted font-size-sm">100% Money-back guarantee, cancel any time.</p>

                        <div class="d-flex flex-column flex-sm-row pt-3">
                            <a href="javascript:;" class="btn btn-sm btn-white waves-effect mb-2 mb-sm-0">
                                <svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" >
                                    <path d="M3.54594 9.66899L2.989 11.7481L0.953406 11.7912C0.345063 10.6628 0 9.37186 0 7.99999C0 6.67339 0.322625 5.42239 0.8945 4.32086H0.894938L2.70719 4.65311L3.50106 6.45449C3.33491 6.93889 3.24434 7.45889 3.24434 7.99999C3.24441 8.58724 3.35078 9.14989 3.54594 9.66899Z" fill="#FBBB00"/>
                                    <path d="M15.8602 6.50549C15.9521 6.98943 16 7.48921 16 7.99999C16 8.57274 15.9398 9.13143 15.8251 9.67034C15.4357 11.5042 14.4181 13.1055 13.0084 14.2387L13.008 14.2382L10.7253 14.1218L10.4023 12.1051C11.3377 11.5565 12.0687 10.698 12.4537 9.67034H8.1759V6.50549H12.5161H15.8602Z" fill="#518EF8"/>
                                    <path d="M13.0079 14.2383L13.0083 14.2387C11.6373 15.3407 9.89578 16 7.99996 16C4.95337 16 2.30459 14.2972 0.953369 11.7912L3.5459 9.66904C4.22149 11.4721 5.96084 12.7556 7.99996 12.7556C8.87643 12.7556 9.69756 12.5187 10.4022 12.1051L13.0079 14.2383Z" fill="#28B446"/>
                                    <path d="M13.1063 1.84175L10.5147 3.9635C9.78547 3.50769 8.92347 3.24437 7.99997 3.24437C5.91469 3.24437 4.14281 4.58678 3.50106 6.4545L0.894908 4.32088H0.89447C2.22591 1.75384 4.9081 0 7.99997 0C9.94106 0 11.7208 0.691437 13.1063 1.84175Z" fill="#F14336"/>
                                </svg>
                                <span>Signup with Google</span>
                            </a>
                            <a href="javascript:;" class="btn btn-sm btn-light border waves-effect ml-sm-2 mb-2 mb-sm-0">
                                <svg class="mr-2 mr-sm-0" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M14.5527 0H1.44733C0.648 0 0 0.648 0 1.44733V14.5526C0 15.352 0.648 16 1.44733 16H14.5526C15.352 16 16 15.352 16 14.5526V1.44733C16 0.648 15.352 0 14.5527 0ZM4.95108 13.8155C4.95108 14.0481 4.76251 14.2366 4.52988 14.2366H2.73696C2.50434 14.2366 2.31577 14.0481 2.31577 13.8155V6.2996C2.31577 6.06697 2.50434 5.87841 2.73696 5.87841H4.52988C4.76251 5.87841 4.95108 6.06697 4.95108 6.2996V13.8155ZM3.63342 5.16993C2.69273 5.16993 1.93014 4.40733 1.93014 3.46664C1.93014 2.52595 2.69273 1.76335 3.63342 1.76335C4.57412 1.76335 5.33671 2.52595 5.33671 3.46664C5.33671 4.40733 4.57416 5.16993 3.63342 5.16993ZM14.3208 13.8494C14.3208 14.0632 14.1474 14.2366 13.9336 14.2366H12.0096C11.7958 14.2366 11.6224 14.0632 11.6224 13.8494V10.324C11.6224 9.79811 11.7766 8.01948 10.248 8.01948C9.06228 8.01948 8.82178 9.2369 8.77349 9.78325V13.8494C8.77349 14.0632 8.60013 14.2366 8.38622 14.2366H6.52545C6.31158 14.2366 6.13818 14.0632 6.13818 13.8494V6.26568C6.13818 6.05181 6.31158 5.87841 6.52545 5.87841H8.38622C8.60008 5.87841 8.77349 6.05181 8.77349 6.26568V6.92138C9.21315 6.26157 9.86655 5.75229 11.2578 5.75229C14.3385 5.75229 14.3208 8.63045 14.3208 10.2119V13.8494Z" fill="#0077B7"/>
                                </svg>   
                                
                                <span class="d-sm-none">Signup with Linkedln</span>
                            </a>
                            <a href="javascript:;" class="btn btn-sm btn-light border waves-effect ml-sm-2">
                                <svg class="mr-2 mr-sm-0" width="16" height="16" viewBox="0 0 16 16" fill="none" >
                                    <path d="M5.78926 16C3.40798 15.9863 1.43823 11.1253 1.43823 8.64969C1.43823 4.60581 4.47183 3.7205 5.64095 3.7205C6.16783 3.7205 6.73042 3.92741 7.22664 4.1105C7.57364 4.23822 7.93251 4.37006 8.13211 4.37006C8.25158 4.37006 8.53311 4.25791 8.78167 4.1595C9.31176 3.94847 9.97139 3.68619 10.7395 3.68619C10.7409 3.68619 10.7427 3.68619 10.7441 3.68619C11.3176 3.68619 13.0567 3.81206 14.1022 5.38219L14.3471 5.75022L13.9947 6.01619C13.4911 6.39613 12.5724 7.08919 12.5724 8.46203C12.5724 10.088 13.6129 10.7133 14.1128 11.0141C14.3334 11.1468 14.5618 11.2837 14.5618 11.5831C14.5618 11.7785 13.0018 15.9757 10.7363 15.9757C10.182 15.9757 9.79011 15.8091 9.44448 15.6622C9.09476 15.5134 8.79308 15.3853 8.29458 15.3853C8.04189 15.3853 7.72236 15.5047 7.38408 15.6315C6.92179 15.8041 6.39855 16 5.80483 16H5.78926Z" fill="black"/>
                                    <path d="M10.9795 0C11.0385 2.12737 9.51713 3.60325 7.9975 3.51069C7.74709 1.81297 9.51694 0 10.9795 0Z" fill="black"/>
                                </svg>      
                                <span class="d-sm-none">Signup with Apple ID</span>                                                                                                              
                            </a>
                        </div>

                        <div class="divider font-size-sm">Or Signup with</div>
                        <form>
                            <div class="form-row">
                                <div class="col-lg px-2">
                                    <div class="form-group">
                                        <label for="firstName">First name</label>
                                        <input class="form-control" type="text" id="firstName" placeholder="Robert" aria-describedby="firstName"/>
                                    </div>
                                </div>
                                <div class="col-lg px-2">
                                    <div class="form-group">
                                        <label for="lastName">Last name</label>
                                        <input class="form-control" type="text" id="lastName" placeholder="Matthews" aria-describedby="lastName"/>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-lg px-2">
                                    <div class="form-group">
                                        <label for="emailAddress">Email address</label>
                                        <input class="form-control" type="email" id="emailAddress" placeholder="robertmatthews@email.com" aria-describedby="emailAddress"/>
                                    </div>
                                </div>
                                <div class="col-lg px-2">
                                    <div class="form-group">
                                    <label>Password</label>
                                    <div class="input-group password-input">
                                        <input type="password" class="form-control rounded password" placeholder="Enter your password" required=""/>
                                        
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <i class="pw-btn-toggle icon-eye"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-lg px-2">
                                    <button type="submit" class="btn btn-primary font-size-sm">Create an Account</button>
                                    <div class="small text-muted mt-3">By submitting this form you agree to our <a href="">terms and conditions</a>  and our <a href="">Privacy Policy</a>.</div>
                                
                                </div>
                            </div>
                            
                        </form>
                    </div>

                    <div class="col-xl-5">
                        <div id="carouselExampleCaptions" class="carousel carousel-bottom-gradient slide" data-ride="carousel">
                            <ol class="carousel-indicators">
                              <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
                              <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                              <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                            </ol>
                            <div class="carousel-inner">
                              <div class="carousel-item active">
                                <img src="./images/media/media-01.jpg" class="d-block w-100" alt="..."/>
                                <div class="carousel-caption">
                                  <h5>First slide label</h5>
                                  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                </div>
                              </div>
                              <div class="carousel-item">
                                <img src="./images/media/media-01.jpg" class="d-block w-100" alt="..."/>
                                <div class="carousel-caption">
                                  <h5>Second slide label</h5>
                                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                              </div>
                              <div class="carousel-item">
                                <img src="./images/media/media-01.jpg" class="d-block w-100" alt="..."/>
                                <div class="carousel-caption">
                                  <h5>Third slide label</h5>
                                  <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                                </div>
                              </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                    </div>
                </div>
            </div>

        </div>
        </div>
    </div>   
        
        

 
        </main>
   
   
   </div>

  )
}
