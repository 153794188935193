import React from 'react'

import { useEffect,useState } from 'react';

import {useParams} from "react-router-dom"

import { Link,NavLink } from 'react-router-dom';

import axios from 'axios';
import LoadingSpinner from '../../../utils/LoadingSpinner';

import API from '../../../services';

import {useNavigate} from 'react-router-dom'


import { Modal, Button } from "react-bootstrap";

function SearchContent() {
    
    let { item } = useParams();

    let { geo_loc } = useParams();

    let { address } = useParams();


    let { string_lng } = useParams();

    

    

    const [productsList, setProductsList] = useState([]);

    const [seller_name, setSeller_name] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    const [isDivLoading, setIsDivLoading] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [selectedSeller, setSelectedSeller] = useState(null); // To store selected seller details




    localStorage.setItem('itemsearched', JSON.stringify(item));

    const history = useNavigate();


    const [show, setShow] = useState(false);


    const handleClose = () =>{

        //setLoading(true)

        
     
         setShow(false);
         setSelectedSeller(null); // Clear the selected seller
       
         setTimeout(() => {
     
        // setLoading(false)
     
         // history("/dashboard-customer");
         // window.location.reload(false);
     
       
     
         
     }, 500);
     
     
       }



    const handleWithrawModal = (seller) =>{

     //setLoading(true)

     setSelectedSeller(seller);
  
      setShow(true);
    
      setTimeout(() => {
  
     // setLoading(false)
  
      // history("/dashboard-customer");
      // window.location.reload(false);
  
    
  
      
  }, 500);

}
  
  
  
  
    useEffect(()=>{

        setIsDivLoading(true);
  
    //   axios.get("https://tunepbackend.herokuapp.com/customer/mycustomers").then((response) => {

    API.get("/product/search/"+item+"/"+string_lng).then((response) => {
   //axios.get("http://localhost:3001/product/search/"+item+"/"+string_lng).then((response) => {
           // axios.get(`https://ngeritbackend.herokuapp.com/product/search/${item}`).then((response) => {

           

         
            setTimeout(() => {
                setProductsList(response.data);

               // setSeller_name(response.data.Users);
                setIsDivLoading(false)   // Hide loading screen 
               // toast.info('Product saved successfully');
            }, 1000);

            //setSeller_name(response.data.Users.first_name)
            
        }).catch(() => {
            setErrorMessage("Unable to fetch your products list");
            setIsDivLoading(false);
         });
  
  
  },[]);


  

  const selectSeller = (sellerId,sellerName,sellerContacts,prodId,productName,price) => {
    setLoading(true);

    const productDetails = { sellerId,sellerName,sellerContacts, prodId, productName, price };
    localStorage.setItem('productDetails', JSON.stringify(productDetails));
    // alert('Product details saved to localStorage!');
   
    
    setTimeout(() => {
      setLoading(false);
      //setAddress(string_lng)
     // history.push('/search-location-avon-park-florida');
     history(`/seller-details/${sellerId}/${prodId}`);

     
    }, 500);
    
      };

 // console.log("THE FARMER Data"+seller_name)


  const buyerSearchLoader = (

    <div class="row justify-content-center">
    <div class="col-12 text-center">
        <ul class="nav nav-pills-group mb-3" id="reviewsTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="customersTab" data-toggle="pill" href="#customers" role="tab" aria-controls="customersTab" aria-selected="true">Customers</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="expertTab" data-toggle="pill" href="#expert" role="tab" aria-controls="expertTab" aria-selected="false">Experts</a>
            </li>
        </ul>
    </div>

    <div class="col-12">
        <div class="tab-content" id="reviewsTabContent">
            <div class="tab-pane fade show active" id="customers" role="tabpanel" aria-labelledby="customers">


            <div class="row row-cols-xl-4 row-cols-md-2 row-cols-1">

            {productsList.map((value, key) => {
                return (
                <div class="col mb-3" key={key} data-aos="fade-up">




  {/* Modal - only rendered when selectedSeller is not null */}
  {selectedSeller && (
                    
            <Modal class="modal fade" id="modaldemo8"  show={selectedSeller === value.id} onHide={handleClose}>

<Modal.Header>
  <Modal.Title>{value.name}</Modal.Title>
</Modal.Header>
<Modal.Body class="modal-body">


<div class="ml-3">
                               
                               {/* <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">{value.User.first_name}</h6>
                               <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">{value.User.phone_no}</h6> */}
                              
   
                               <span class="line-height-1 text-gray-600">Price Per Item {value.price}</span>
                           </div>



<img class="avatar-img" src={value.cloudinary_url} alt=""/>









  

</Modal.Body>
<Modal.Footer class="modal-footer">

 
{!isLoading && <button type="submit"  onClick={() => {
                                    selectSeller(value.RetailerId,value.User.first_name,value.User.phone_no,value.id,value.name,value.price);
                                      }} class="btn btn-primary">Contact Seller</button>

} 
{isLoading &&
  

    <button class="btn btn-primary my-1" type="button" disabled="">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Just a min....
</button>
} 

<button class="btn btn-light"  onClick={() => {
                                    handleClose();
                                      }}  data-bs-dismiss="modal">Close</button> 

{/* <Button variant="secondary" onClick={handleClose}>
    Close
  </Button>
  <Button variant="primary" onClick={handleClose}>
    Save Changes
  </Button> */}
 
</Modal.Footer>
</Modal>
              

            )}





                    <div class="card card-body align-items-start">
                    <div class="rating-group mb-4">
                    <img src="/images/icons/star.svg" alt=""/>
                    <img src="/images/icons/star.svg" alt=""/>
                    <img src="/images/icons/star.svg" alt=""/>
                    <img src="/images/icons/star.svg" alt=""/>
                    <img src="/images/icons/rating.svg" alt=""/>
                </div>

                <div class="rating-group mb-4">
                <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">{value.User.first_name}</h6>
                                    <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">{value.User.phone_no}</h6>

                <button  onClick={() => {
                                    handleWithrawModal(value.id);
                                      }}   class="appointment-btn scrollto"> <span class="d-md-inline">View</span> Photos </button>
                    
                </div>

                
                    <a href="javascript:;" class="d-inline-block mt-4">
                            <div class="d-flex align-items-center">
                                <div class="avatar avatar-xs">
                                     {/* <img class="avatar-img rounded-circle" src="/images/avatar/user-6.jpg" alt=""/>  */}

                                   
                                     <img class="avatar-img rounded-circle" src={value.cloudinary_url} alt=""/>
                                    
                                </div>
            
                                <div class="ml-3">
                               
                                    {/* <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">{value.User.first_name}</h6>
                                    <h6 class="font-size-sm font-weight-semibold line-height-1 mb-0">{value.User.phone_no}</h6> */}
                                    <span class="line-height-1 text-gray-600 small">{value.name}</span>
                                    <br/>
        
                                    <span class="line-height-1 text-gray-600 small">Price Per Item {value.price}</span>
                                </div>
                               
                            </div>
                        </a>
                    
                        <p class="font-size-sm"> {value.product_description}</p>

                        {/* <p class="font-size-sm">Retailer Id is {value.RetailerId}</p> */}
        
        
                        {/* <Link  to={`/seller-details/${value.RetailerId}/${value.id}`} class="appointment-btn scrollto"> <span class="d-md-inline">Contact</span> Seller </Link> */}
                        
                        {!isLoading &&  <button  onClick={() => {
                                    selectSeller(value.RetailerId,value.User.first_name,value.User.phone_no,value.id,value.name,value.price);
                                      }}  class="appointment-btn scrollto"> <span class="d-md-inline">Contact</span> Seller </button>}


{isLoading &&
  <button type="submit" class="advanced_search_submit_button" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Just a min....</button>
}
        
        
                       
        
    
                        
                    </div>
                </div>
                );
            })}
        
        
        
               
            
            </div>











                <div class="row text-center justify-content-center" data-aos="fade-up" data-aos-delay="200">
                    <div class="col-xl-9 col-lg-10">
                        <p class="mb-0 font-size-sm text-muted">Trusted by companies who care about their customers rated 4.86/5 based on <a href="">19,456 reviews</a></p>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="expert" role="tabpanel" aria-labelledby="expert">
                <div class="row row-cols-xl-4 row-cols-md-2 row-cols-1">
                    <div class="col mb-3">
                        
                    </div>
                    <div class="col mb-3">
                        
                    </div>
                    <div class="col mb-3">
                        
                    </div>
                    <div class="col mb-3">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


   



  )
  return (
    <div>


    <section class="py-6 bg-light-primary mt-n1 mt-sm-0">
    <div class="container">
        <div class="row justify-content-center text-center mb-5">
            <div class="col-xl-7 col-lg-10">
                <h2 class="font-weight-bold">Highly Rated by Customers</h2>
               
            </div>
        </div>

        
        {isDivLoading ? <LoadingSpinner/>: buyerSearchLoader}

        {errorMessage && <div className="error">{errorMessage}</div>}

       
    </div>
</section>
    
    
    
    </div>
  )
}

export default SearchContent