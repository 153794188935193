import React from 'react'

import { useEffect,useState } from 'react';


import Sidebar from './Fsidebar';
import Topbar from './Ftopbar';
import Footer from './Ffooter';

import axios from 'axios';



import { Helmet } from "react-helmet";



import {toast,ToastContainer,Zoom,Bounce} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import API from '../../services';

function BusinessAccountSetting(props) {

    
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhone_no] = useState("");

  const [business_name, setbusiness_name] = useState("");
  const [business_type, setbusiness_type] = useState("");

  const [business_description, setbusiness_description] = useState("");

  const [industry, setindustry] = useState("");
  const [location, setlocation] = useState("");



  const [city, setCity] = useState("");


  const [state, setState] = useState("");
  const [country, setcountry] = useState("");
  const [status, setStatus] = useState("");
  
  const [userId, setUserId] = useState('');

  const [customersList, setCustomersList] = useState([]);


  const [mybussList, setMybussList] = useState([]);






    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [address_line_1, setaddress_line_1] = useState("");
    const [address_line_2, setaddress_line_2] = useState("");

    const [postal_code, setPostal_code] = useState("");


  
      const [showingInfoWindow, setShowingInfoWindow] = useState(false);

      const [activeMarker, setActiveMarker] = useState({});
      const [selectedPlace, setSelectedPlace] = useState({});

      const [lat, setLat] = useState(null);
      const [lng, setLng] = useState(null);

      const [loclat, setLoclat] = useState(null);


      const [businessId, setbusinessId] = useState('');
    
  
  
    const [address, setAddress] = React.useState("");

    const [mapCenter, setMapCenter] = useState({
      lat: 0,
      lng: 0
  
  });







  useEffect(()=>{

    API.get('/users/mybusinesses', { headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {

    //axios.get('http://localhost:3001/users/mybusinesses', { headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {

      setMybussList(response.data)
    
     })



     API.get('/users/mybusiness', { headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {

   // axios.get('http://localhost:3001/users/mybusiness', { headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {

    if(response.data!=null){

      setbusinessId(response.data.id);
  
    
  
    }
    else{
  
  
      setbusinessId(0)
    }

    
     })


     API.get('/users/auth', { headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {
    // axios.get('http://localhost:3001/users/auth', { headers: { accessToken: localStorage.getItem("accessToken") } }).then((response) => {

        setUserId(response.data.id)

        setEmail(response.data.email)
  
  
       })

       API.get("/customer/mycustomers").then((response) => {
      //axios.get("http://localhost:3001/customer/mycustomers").then((response) => {
      setCustomersList(response.data);
      })



      if (!navigator.geolocation) {
        setStatus('Geolocation is not supported by your browser');
      } else {
              setStatus('Locating...');
              navigator.geolocation.getCurrentPosition((position) => {
                  setStatus(null);
                  setLat(position.coords.latitude);
                  setLng(position.coords.longitude);

                  setMapCenter({lat:position.coords.latitude,lng:position.coords.longitude})

                  getReverseGeocodingData(position.coords.latitude,position.coords.longitude)



                  const geoApi=`https://api.bigdatacloud.net/data/reverse-geocode-with-timezone?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en&key=9c255dc5e7f944ffb0df249d6588363c`

                  fetch(geoApi).then(res=>res.json()).then(data=>{
                    console.log("MY LOCATION IS "+data.plusCode)
                 
                   // setaddress_line_2(data.plusCode)

                    //console.log("THE PLUS CODE ADDRESS IS " +address);

                  })



              }, () => {
                  setStatus('Unable to retrieve your location');
              });
          }



},[]);



const handleChange = address => {
  setAddress(address);
    };



  const handleSelect = address => {
    setAddress(address);
     geocodeByAddress(address)
      .then(results =>  getLatLng(results[0]))
      .then(latLng => {
        console.log('Success', latLng);
        
        // const address_one = address.results[0].formatted_address;
       

        // console.log('Formated Addres', address_one);


        // update center state
        setMapCenter(latLng );

        setaddress_line_2(address);

        
       
      });
      geocodeByAddress(address).then(response=>{

      
        var add= response[0].formatted_address ;
        var  value=add.split(",");
  
      let  count=value.length;
      let  country=value[count-1];
      let   state=value[count-2];
      let  city=value[count-3];
      let  postal_code=value[count-4];

     
  
        console.log('COUNTRY'+country);
        console.log('CITY'+city);
        console.log('STATE'+state);
        console.log('ZIP CODE'+postal_code);

        // console.log('THE ID IS'+propid);


        setCity(city);

        setPostal_code(postal_code);

        setState(state);

      
      
  
       // console.log('ADDRESS COMPONENTS',addressComponent[2]);
        
      })
      .catch(error => console.error('Error', error));
  };




const getReverseGeocodingData=(lat, lng)=> {
  var latlng = new props.google.maps.LatLng(lat, lng);
  // This is making the Geocode request
  var geocoder = new props.google.maps.Geocoder();
  geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
      if (status !== props.google.maps.GeocoderStatus.OK) {
          alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == props.google.maps.GeocoderStatus.OK) {
          console.log(results);
          var address = (results[0].formatted_address);

          var city = (results[1].formatted_address);


          setaddress_line_2(address)

          setCity(address)

          setState(address)


          console.log("THE ADDRESS IS " +address);

         // console.log("THE CITY IS " +city);

          
      }
  });
}





const buss_data={

    business_name:business_name,
    business_type:industry,
    industry:industry,
    location:location,
    email:email,

    address_line_1:address_line_1,
    latitude:latitude,
    longitude:longitude,
    city:city,
    state:state,
    country:country,
    contacts:phone_no,
    status:status,
    UserId:userId,
   
}
 


  const data={
    name:name,
    
      email:email,
      phone_no:phone_no,
      
  }

  const [isLoading,setLoading]=useState(false);

 

  const addDetails = ()  => {
      setLoading(true);

      API.post("/customer",data).then((response)=>{
      
     // axios.post("http://localhost:3001/customer",data).then((response)=>{

      console.log("The response is"+response.data)

         
          setTimeout(() => {
              setLoading(false);
              toast.info('Customer saved successfully');
          }, 1000);
       
         //  history("/dashboard");
        
         
      })

  }



  const saveBusinessInfor = ()  => {
    setLoading(true);

    API.post("/retailer/bussinfor",buss_data).then((response)=>{
    
    //axios.put("http://localhost:3001/business",buss_data).then((response)=>{

    console.log("The response is"+response.data)

       
        setTimeout(() => {
            setLoading(false);
            toast.info('Infor Saved');
        }, 1000);
     
       //  history("/dashboard");
      
       
    })

}


const locdata={

  geo_location:address_line_2,
  city:city,
  state:state,
  country:country,
  latitude:latitude,
  longitude:longitude,
  UserId:userId,
  businessId:businessId,
    
}


const updateLocationDeatails = ()  => {
  setLoading(true);

  API.put("/business/updateBuss",locdata).then((response)=>{
  
 // axios.put("http://localhost:3001/business/updateBuss",locdata).then((response)=>{

  console.log("The response is"+response.data)

     
      setTimeout(() => {
          setLoading(false);
          toast.info('Location Details Saved');
      }, 1000);
   
     //  history("/dashboard");
    
     
  })

}


  return (
    <div>
 


    <Helmet>
  
  
    
    <link rel="preconnect" href="https://fonts.googleapis.com/" />
    <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;display=swap" rel="stylesheet" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/boxicons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/fontawesome.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/flag-icons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/core.css" class="template-customizer-core-css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/theme-default.css" class="template-customizer-theme-css" />
    <link rel="stylesheet" href="assets_admin/css/demo.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/apex-charts/apex-charts.css" />
  
  
  
  
  
    <link rel="stylesheet" href="assets_admin/vendor/libs/select2/select2.css" />
  
  
    <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-bs5/datatables.bootstrap5.css"/>
  <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css"/>
  <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.css"/>
  
  
  
    
  
    
    <script src="assets_admin/vendor/js/helpers.js"></script>
    <script src="assets_admin/vendor/js/template-customizer.js"></script>
    <script src="assets_admin/js/config.js"></script>
        </Helmet>
  
        
        
        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                {/* Menu */}
                
                
                <Sidebar />
  
  
                {/* / Menu */}
                {/* Layout container */}
                <div className="layout-page">
                    {/* Navbar */}
                    
                    <Topbar />
  
                    {/* / Navbar */}
                    {/* Content wrapper */}
                    
                    <div class="content-wrapper">
  
  
  
                    <div class="container-xxl flex-grow-1 container-p-y">
              
              
  
                    <h4 class="py-3 breadcrumb-wrapper mb-4">
                      <span class="text-muted fw-light">Business /Account</span> 
                    </h4>
                    
                    <div class="row">
                  
                      <div class="col-xl-3 col-lg-4 col-md-4 mb-lg-0 mb-4">
  
                      <div class="card h-100">
        <div class="card-header">
          <h3 class="card-title mb-2">My Business</h3>
  
  
          <div className="pt-2 pb-2 pl-3 pr-3 d-flex align-items-center o-hidden box-shadow-1 chat-topbar p-4" style={{backgroundColor:"aliceblue"}}><a className="link-icon d-md-none" data-sidebar-toggle="chat"><i className="icon-regular ml-0 mr-3 i-Left" /></a>
          <div class="mb-0">
              <button class="btn btn-primary" data-repeater-create data-bs-toggle="modal" data-bs-target="#modalCenter">
                <i class="bx bx-plus"></i>
                <span class="align-middle">Add</span>
              </button>
            </div>
      </div>
         
        </div>
        <div class="card-body">
        <div class="nav-align-left">
                       
        <ul class="nav nav-pills w-100 gap-1">
  
        {mybussList.map((value, key) => {
          return (
            <li class="nav-item">
            <button class="nav-link" data-bs-target="javascript:void(0);">{value.business_name}</button>
          </li>
  
          );
      })}
         
          
         
        </ul>
      </div>
  
  
      <div class="modal fade" id="modalCenter" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalCenterTitle">Add Customer</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col mb-3">
                <label for="nameWithTitle" class="form-label">Name</label>
                <input type="text" id="nameWithTitle" class="form-control" placeholder="Enter Name"
                
                onChange={(event) => {
                    setName(event.target.value);
                  }}
                   
                />
              </div>
            </div>
            <div class="row g-2">
              <div class="col mb-0">
                <label for="emailWithTitle" class="form-label">Email</label>
                <input type="text" id="emailWithTitle" class="form-control" placeholder="xxxx@xxx.xx"
                
                
                onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                   />
              </div>
              <div class="col mb-0">
                <label for="dobWithTitle" class="form-label">Phone No.</label>
                <input type="text" id="phoneno" class="form-control"
  
                onChange={(event) => {
                    setPhone_no(event.target.value);
                  }}
                   
                
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>
  
  
            
            {!isLoading && <button type="submit" onClick={addDetails} class="btn btn-primary"  style={{backgroundColor:"#085781"}}>Save changes</button>
  
        } 
        {isLoading &&
            <button type="submit" class="btn btn-primary btn-md btn-block mt-3 waves-effect" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Saving Infor</button>
        }
  
        <ToastContainer />
            
          </div>
          
        </div>
      </div>
    </div>
  
  
  
        </div>
      </div>
                      
                      
                      </div>
                     
  
                      <div class="col-xl-9 col-lg-8 col-md-8">
                        <div class="card overflow-hidden">
                          <div class="card-body">


                          <div class="card mb-3">
                          <div class="card-header border-bottom">
                            <ul class="nav nav-tabs card-header-tabs" role="tablist">
                              <li class="nav-item">
                                <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#form-tabs-personal" role="tab" aria-selected="true">Business Info</button>
                              </li>
                              <li class="nav-item">
                                <button class="nav-link " data-bs-toggle="tab" data-bs-target="#form-tabs-account" role="tab" aria-selected="false">Location Details</button>
                              </li>
                              <li class="nav-item">
                                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#form-tabs-social" role="tab" aria-selected="false">Social Links</button>
                              </li>
                            </ul>
                          </div>
                    
                          <div class="tab-content">
                           
                            <div class="tab-pane fade active show" id="form-tabs-personal" role="tabpanel">
                              <form>
                                <div class="row g-3">
                                  <div class="col-md-6">
                                    <div class="row">
                                      <label class="col-sm-3 col-form-label text-sm-end" for="formtabs-first-name">Business Name</label>
                                      <div class="col-sm-9">
                                        <input type="text" id="formtabs-first-name" class="form-control" 
                                        
                                        onChange={(event) => {
                                            setbusiness_name(event.target.value);
                                          }}
                                        
                                        
                                        placeholder="Eg. Edith Salon" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="row">
                                    
                                      <label class="col-sm-3 col-form-label text-sm-end" for="formtabs-last-name">Business Description</label>
                                      <div class="col-sm-9">
                                      <textarea id="basic-icon-default-message" class="form-control" placeholder="Hi, Do you have a moment to talk Joe?" aria-label="Hi, My business deals with beauty services?" 
                                      
                                      onChange={(event) => {
                                        setbusiness_description(event.target.value);
                                      }}

                                      aria-describedby="basic-icon-default-message2"></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="row">
                                      <label class="col-sm-3 col-form-label text-sm-end" for="formtabs-country">County</label>
                                      <div class="col-sm-9">
                                        <select id="formtabs-country" class="select2 form-select"
                                        
                                        onChange={(event) => {
                                            setCity(event.target.value);
                                          }}
    
                                        
                                        data-allow-clear="true">
                                          <option value="">Select</option>
                                          <option value="Nairobi">Nairobi</option>
                                          <option value="Kiambu">Kiambu</option>
                                          <option value="Machakos">Machakos</option>
                                          <option value="Kakuru">Kakuru</option>
                                          <option value="Makueni">Makueni</option>
                                          <option value="Nyeri">Nyeri</option>
                                          
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6 select2-primary">
                                    <div class="row">
                                      <label class="col-sm-3 col-form-label text-sm-end" for="formtabs-language">Industry</label>
                                      <div class="col-sm-9">
                                        <select id="formtabs-language" class="select2 form-select"


                                        onChange={(event) => {
                                            setindustry(event.target.value);
                                          }}
                                        
                                        
                                        multiple>
                                          <option value="Beauty" selected>Beauty</option>
                                          <option value="Education" selected>Education</option>
                                          <option value="Automotive">Wellbeing</option>
                                          <option value="pt">Automotive</option>
                                          <option value="home care">Home Care</option>
                                          <option value="Maintenance">Maintenance</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="row">
                                    <label class="col-sm-3 col-form-label text-sm-end" for="formtabs-first-name">Location</label>
                                    <div class="col-sm-9">
                                      <input type="text" id="formtabs-first-name" class="form-control"
                                      
                                      
                                      onChange={(event) => {
                                        setlocation(event.target.value);
                                      }}
                                      
                                      placeholder="Eg. Ruai By Pass" />
                                    </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="row">
                                      <label class="col-sm-3 col-form-label text-sm-end" for="formtabs-phone">Phone No</label>
                                      <div class="col-sm-9">
                                        <input type="text" id="formtabs-phone" class="form-control phone-mask"
                                        
                                        onChange={(event) => {
                                            setPhone_no(event.target.value);
                                          }}
                                        placeholder="0714639773" aria-label="0714639773" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row mt-4">
                                  <div class="col-md-6">
                                    <div class="row justify-content-end">
                                      <div class="col-sm-9">
                                        

                                        {!isLoading && <button type="submit" onClick={saveBusinessInfor} class="btn btn-primary me-sm-3 me-1">Submit</button>
  
                                    } 
                                    {isLoading &&
                                        <button type="submit" class="btn btn-primary me-sm-3 me-1" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Saving Infor</button>
                                    }
                                        <button type="reset" class="btn btn-label-secondary">Cancel</button>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            
                            <div class="tab-pane fade" id="form-tabs-account" role="tabpanel">
                          



                              <div class="row g-2">
                              <div class="col mb-0">
                                <label for="description" class="form-label">Location</label>


                                <div id='googleMaps'>
                                  <PlacesAutocomplete

                                    value={address}
                                    onChange={handleChange}
                                    onSelect={handleSelect}
                                  >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                      <div>


                                        <div class="form-group">
                                          <label for="inputAddress">Address Line 1</label>


                                          <input
                                            class="form-control"  id="inputAddress" autocomplete="off"
                                            {...getInputProps({
                                              placeholder: 'Search Places ...',
                                              className: 'multisteps-form__input form-control',
                                            })}


                                          />
                                        </div>



                                        <div className="autocomplete-dropdown-container">
                                          {loading && <div>Loading...</div>}
                                          {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                              ? 'suggestion-item--active'
                                              : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                              <div
                                                {...getSuggestionItemProps(suggestion, {
                                                  className,
                                                  style,
                                                })}
                                              >
                                                <span style={{ color: "blue", backgroundColor: "#E5E4E2", border: "1px", borderRadius: "1px" }}>{suggestion.description}</span>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>
                              </div>

                              </div>

                              <div class="row g-2">
                            

                                  <div class="col" style={{ height: "200px", padding: "5px" }}>

                                    <Map
                                      google={props.google}
                                      initialCenter={{
                                        lat: mapCenter.lat,
                                        lng: mapCenter.lng
                                      }}
                                      center={{
                                        lat: mapCenter.lat,
                                        lng: mapCenter.lng
                                      }}
                                    >
                                      <Marker
                                        position={{
                                          lat: mapCenter.lat,
                                          lng: mapCenter.lng
                                        }} />
                                    </Map>
                                  </div>

                                
                                </div>

                                </div>



                                <div class="row g-2">
                                <div class="col mb-0">
                                  <label for="description" class="form-label">Latitude</label>

                                  <input class="form-control"

                                    value={mapCenter.lat}

                                    onChange={(event) => {
                                      setLatitude(event.target.value);
                                    }}

                                    name="latitude" type="text" placeholder="latitude" />
                                </div>

                                <div class="col mb-0">
                                  <label for="description" class="form-label">Longitude</label>

                                  <input class="form-control"

                                    value={mapCenter.lng}

                                    onChange={(event) => {
                                      setLongitude(event.target.value);
                                    }}


                                    name="longitude" type="text" placeholder="longitude" />
                                </div>
                                <div class="col mb-0">
                                  <label for="dobWithTitle" class="form-label">Address Line 2</label>
                                  <input class="form-control"


                                    value={address_line_2}

                                    onChange={(event) => {
                                      setaddress_line_2(event.target.value);
                                    }}


                                    name="address_line_2" type="text" placeholder="address_line_2" />
                                </div>
                              </div>




                              
                              <div class="row g-2">
                                <div class="col mb-0">
                                  <label for="description" class="form-label">City</label>

                                  <input class="form-control"


                                        value={state}
                                        onChange={(event) => {
                                          setCity(event.target.value);
                                        }}

                                        name="city" type="text" placeholder="city" />
                                </div>

                                <div class="col mb-0">
                                  <label for="description" class="form-label">State</label>

                                  <input class="multisteps-form__input form-control"



                                        value={state}

                                        onChange={(event) => {
                                          setState(event.target.value);
                                        }}

                                        name="state" type="text" placeholder="state" />
                                </div>
                                <div class="col mb-0">
                                  <label for="dobWithTitle" class="form-label">Postal Code</label>
                                  <input class=" form-control"

                                        value={postal_code}

                                        onChange={(event) => {
                                          setPostal_code(event.target.value);
                                        }}

                                        name="postal_code" type="text" placeholder="postal code" />
                                </div>
                              </div>


                              <input class=" form-control"

                                        value={businessId}

                                        onChange={(event) => {
                                          setbusinessId(event.target.value);
                                        }}

                                        name="postal_code" type="text" placeholder="postal code" />


                             






                       

                              <div class="row g-2">
                                <div class="col mb-0">
                                  <label class="form-label" for="basic-default-upload-file">Business Profile</label>
                                  <input type="file" class="form-control" id="basic-default-upload-file" required />
                                </div>

                              </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-label-secondary">Close</button>


          
          {!isLoading && <button type="submit" onClick={updateLocationDeatails} class="btn btn-primary"  style={{backgroundColor:"#085781"}}>Save changes</button>

      } 
      {isLoading &&
          <button type="submit" class="btn btn-primary btn-md btn-block mt-3 waves-effect" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Saving Infor</button>
      }

      <ToastContainer />
          
      



                                



                            </div>
                           
                            <div class="tab-pane fade" id="form-tabs-social" role="tabpanel">
                              <form>
                                <div class="row g-3">
                                  <div class="col-md-6">
                                    <div class="row">
                                      <label class="col-sm-3 col-form-label text-sm-end" for="formtabs-twitter">Twitter</label>
                                      <div class="col-sm-9">
                                        <input type="text" id="formtabs-twitter" class="form-control" placeholder="https://twitter.com/abc" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="row">
                                      <label class="col-sm-3 col-form-label text-sm-end" for="formtabs-facebook">Facebook</label>
                                      <div class="col-sm-9">
                                        <input type="text" id="formtabs-facebook" class="form-control" placeholder="https://facebook.com/abc" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="row">
                                      <label class="col-sm-3 col-form-label text-sm-end" for="formtabs-google">Google+</label>
                                      <div class="col-sm-9">
                                        <input type="text" id="formtabs-google" class="form-control" placeholder="https://plus.google.com/abc" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="row">
                                      <label class="col-sm-3 col-form-label text-sm-end" for="formtabs-linkedin">Linkedin</label>
                                      <div class="col-sm-9">
                                        <input type="text" id="formtabs-linkedin" class="form-control" placeholder="https://linkedin.com/abc" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="row">
                                      <label class="col-sm-3 col-form-label text-sm-end" for="formtabs-instagram">Instagram</label>
                                      <div class="col-sm-9">
                                        <input type="text" id="formtabs-instagram" class="form-control" placeholder="https://instagram.com/abc" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="row">
                                      <label class="col-sm-3 col-form-label text-sm-end" for="formtabs-quora">Quora</label>
                                      <div class="col-sm-9">
                                        <input type="text" id="formtabs-quora" class="form-control" placeholder="https://quora.com/abc" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row mt-4">
                                  <div class="col-md-6">
                                    <div class="row justify-content-end">
                                      <div class="col-sm-9">
                                        <button type="submit" class="btn btn-primary me-sm-3 me-1">Submit</button>
                                        <button type="reset" class="btn btn-label-secondary">Cancel</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
  
  
  
                          
                          
                    
                            <a class="btn btn-label-primary" href="pages-help-center-landing.html">
                              <i class="bx bx-chevron-left bx-sm scaleX-n1-rtl"></i>
                              <span>Back to help center</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                    
                                
                              </div>
  
    
  
    
                    
                    
                <footer class="content-footer footer bg-footer-theme">
                <div class="container-fluid d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                <div class="mb-2 mb-md-0">
                  © <script>
                  document.write(new Date().getFullYear())
                  </script>
                  , made with ❤️ by <a href="https://pixinvent.com/" target="_blank" class="footer-link fw-semibold">PIXINVENT</a>
                </div>
                <div>
                  
                  <a href="https://themeforest.net/licenses/standard" class="footer-link me-4" target="_blank">License</a>
                  <a href="https://1.envato.market/pixinvent_portfolio" target="_blank" class="footer-link me-4">More Themes</a>
                  
                  <a href="https://pixinvent.com/demo/frest-clean-bootstrap-admin-dashboard-template/documentation-bs5/" target="_blank" class="footer-link me-4">Documentation</a>
                  
                  <a href="https://pixinvent.ticksy.com/" target="_blank" class="footer-link d-none d-sm-inline-block">Support</a>
                  
                </div>
                </div>
                </footer>
                
                
                      
                      <div class="content-backdrop fade"></div>
                    </div>
  
                    
                    {/* Content wrapper */}
                </div>
                {/* / Layout page */}
            </div>
            {/* Overlay */}
            <div className="layout-overlay layout-menu-toggle" />
            {/* Drag Target Area To SlideIn Menu On Small Screens */}
            <div className="drag-target" />
        </div>
        <Helmet>
        <script src="assets_admin/vendor/libs/jquery/jquery.js"></script>
        <script src="assets_admin/vendor/libs/popper/popper.js"></script>
        <script src="assets_admin/vendor/js/bootstrap.js"></script>
        <script src="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
        <script src="assets_admin/vendor/libs/hammer/hammer.js"></script>
        <script src="assets_admin/vendor/libs/i18n/i18n.js"></script>
        <script src="assets_admin/vendor/libs/typeahead-js/typeahead.js"></script>
        <script src="assets_admin/vendor/js/menu.js"></script>
  
  
      
  
  
  
        <script src="assets_admin/vendor/libs/select2/select2.js"></script>
  <script src="assets_admin/assets/vendor/libs/flatpickr/flatpickr.js"></script>
  <script src="assets_admin/assets/vendor/libs/moment/moment.js"></script>
  
  
  
  <script src="assets_admin/vendor/libs/datatables/jquery.dataTables.js"></script>
  <script src="assets_admin/vendor/libs/datatables-bs5/datatables-bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-responsive/datatables.responsive.js"></script>
  <script src="assets_admin/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/datatables-buttons.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/jszip/jszip.js"></script>
  <script src="assets_admin/vendor/libs/pdfmake/pdfmake.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/buttons.html5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/buttons.print.js"></script>
  
  
    
        <script src="assets_admin/js/main.js"></script>
     
  
  
        <script src="assets_admin/js/app-calendar-events.js"></script>
        <script src="assets_admin/js/app-calendar.js"></script>
        </Helmet>
    </div>
  )
}


export default GoogleApiWrapper({
  apiKey: ('AIzaSyAOJjEor9H6PWdsKLAQSr3dIH1fWJNveGI')
})(BusinessAccountSetting)