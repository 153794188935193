import React from 'react';

import {Formik,Form,Field, ErrorMessage} from "formik"
import { useEffect,useState } from 'react';
import './home.css';

import axios from 'axios';

import { useNavigate } from "react-router-dom";


import * as Yup from 'yup';


import {toast,ToastContainer,Zoom,Bounce} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import API from '../../../services';


function SignUp() {


    const [first_name, setFirst_name] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [phone_no, setPhone_no] = useState("");
    const [account_type, setAccount_type] = useState(1);

    // const [password, setPassword] = useState("");





    const data={
        first_name:first_name,
        last_name:first_name,
        username:email,
        email:email,
        phoneNo:phone_no,
        account_type:account_type,
        password:phone_no,
        state:'Nairobi',
        city:null,
        role:'Seller',
    }

    const [isLoading,setLoading]=useState(false);

   

    const addDetails = ()  => {
        setLoading(true);

        // axios.post("https://kilimomazaoapi-dmi-cyber.herokuapp.com/users",data).then((response)=>{
        
        API.post('/users',data).then((response)=>{

        console.log("The response is"+response.data)

        
            setTimeout(() => {
                setLoading(false);
                toast.info('Signed Up successfully');
            }, 3000);
         
           //  history("/dashboard");
          
           
        })

    }
  return (


      <div class="col-xxl-4 col-lg-5 col-md-9" style={{marginTop:'80px'}} data-aos="fade-up" data-aos-delay="200">
      <div class="card shadow-lg p-3 p-xl-5">
           <div class="d-flex">
               <span class="mr-3 fs-32">👋</span>
               <div>
                   <h4 class="font-weight-bold mb-1">Get your FREE account </h4>
                  
               </div>
           </div>
           
           <hr/>

           
           
        
               <div class="form-row">
                   <div class="col-12">
                       <div class="form-group">
                           <label for="name">Name</label>
                         
                           <input type="text" class="form-control form-control-sm" id="first_name"
                           onChange={(event) => {
                            setFirst_name(event.target.value);
                          }}
                           
                           name="first_name" aria-describedby="name" placeholder="Judy Ken"/>
                       </div>
                   </div>
                   <div class="col-12">
                       <div class="form-group">
                           <label for="number">Number</label>
                           
                           <input type="text" class="form-control form-control-sm" id="phone_no"  
                           onChange={(event) => {
                            setPhone_no(event.target.value);
                          }}
                           name="phone_no" aria-describedby="number" placeholder="+245 713 292 421"/>

                          
                       </div>
                   </div>
                   <div class="col-12">
                       <div class="form-group">
                           <label for="emailAddress">Email address</label>
                           <input type="email" class="form-control form-control-sm" 
                           onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                           
                           id="emailAddress"  name="email" aria-describedby="emailAddress" placeholder="kamau@email.com"/>
                       </div>
                   </div>
                   
                   <div class="col-12">

                   {!isLoading && <button type="submit" onClick={addDetails} class="btn btn-primary btn-md btn-block mt-3 waves-effect"  style={{backgroundColor:"#085781"}}>Submit</button>

                } 
                {isLoading &&
                    <button type="submit" class="btn btn-primary btn-md btn-block mt-3 waves-effect" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Saving Infor</button>
                }



                
                   </div>
                   <p class="text-muted font-size-sm mt-3 mb-0">By submitting, you agree to our <a class="font-weight-medium" href="">terms &amp; conditions</a></p>
               </div>

               

            <ToastContainer />
         
      </div>
   </div>
      
      
    
      )
}

export default SignUp;
