import React from 'react'

export default function Footbottom() {
    return (
        <div>
        
           <div class="footer-copyright text-center py-3 bg-dark text-white">
            MavunoSoko uses cookies to make sure you get the best experience
            <button class="btn btn-info btn-sm">Got it</button> 
                                           
        </div>
        </div>
    )
}
