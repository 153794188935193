import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Modal, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import API from '../../../services';
import OrderDetailsContext from '../../../helpers/OrderDetailsContext';
import { AuthContext } from '../../../helpers/AuthContext';

function Delivery(props) {
  const [address, setAddress] = useState("");
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [isLoading, setLoading] = useState(false);
  const [showBuyerDetailsModal, setShowBuyerDetailsModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhone_no] = useState("");
  const [request_type, setRequest_type] = useState("");

  const history = useNavigate();
  const { setAuthState } = useContext(AuthContext);
  const { customerOrders, setCustomerOrders } = useContext(OrderDetailsContext);
  const steps = ['Ready for collection', 'Dispatched', 'On-transit', 'Delivered'];
  const icons = ['store', 'truck-loading', 'shipping-fast', 'check-circle'];

  const [currentStep, setCurrentStep] = useState(0); // Start with the first step (index 0)

  // Function to handle step change when a button is clicked
  const handleStepClick = (index) => {
    setCurrentStep(index); // Update the current step to the clicked one
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setMapCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      }, () => {
        console.log("Unable to retrieve your location");
      });
    }
  }, []);

  const handleChange = address => {
    setAddress(address);
  };

  const handleSelect = address => {
    setAddress(address);
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        setMapCenter(latLng);
      })
      .catch(error => console.error('Error', error));
  };

  const checkOutAndBook = () => {
    history("/trackproduct");
   
  };

  const makeOrder = () => {
    setLoading(true);
    if (name === "" || phone_no === "") {
      setLoading(false);
      toast.error("Please fill in all fields");
      return;
    }
    
    // API calls and order processing logic here
    // ...

    setLoading(false);
    setShowBuyerDetailsModal(false);
    toast.success("Order placed successfully!");
    history("/customer-dashboard");
  };

  return (
    <div>
      <section className="pt-6 cloud-pattern" style={{marginTop: '80px'}}>
        <div className="container">
          <div className="row align-items-center justify-content-center justify-content-lg-between mb-6">
            {/* <div className="col-lg-6 col-md-10 mb-5 mb-lg-0 text-center text-lg-left" style={{minHeight: '600px'}}>
              <div className="row g-2">
                <div className="col mb-0">
                  <PlacesAutocomplete
                    value={address}
                    onChange={handleChange}
                    onSelect={handleSelect}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <div className="form-group">
                          <input
                            {...getInputProps({
                              placeholder: 'Search Places ...',
                              className: 'form-control',
                            })}
                          />
                        </div>
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, { style })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>
              <div className="row g-2">
                <div className="col" style={{height: '400px', padding: '5px'}}>
                  <Map
                    google={props.google}
                    initialCenter={mapCenter}
                    center={mapCenter}
                    zoom={14}
                  >
                    <Marker position={mapCenter} />
                  </Map>
                </div>
              </div>
            </div> */}
            
            <div 
  className="d-flex justify-content-center align-items-center" 
  style={{ height: '100vh' }} 
  data-aos="fade-up" 
  data-aos-delay="200"
>
  <div 
    className="card shadow-lg p-3 p-xl-5 w-100" 
    style={{ maxWidth: '100%', height: '100%', position: 'relative' }}
  >
    {/* Confirm Order Button */}
    <button 
      onClick={checkOutAndBook} 
      className="btn btn-primary"
      disabled={isLoading}
      style={{ position: 'absolute', top: '20px', right: '20px' }}
    >
      {isLoading ? 'Processing...' : 'Track Order'}
    </button>

    {/* Delivery Progress Title */}
    <h4 className="font-weight-bold mb-4">Delivery Progress</h4>
    <hr />

    {/* Delivery Steps */}
    <div className="delivery-steps">
      {steps.map((step, index) => (
        <div key={index} className="form-group d-flex align-items-center mb-4">
          <button 
            className="btn d-flex align-items-center p-0" 
            onClick={() => handleStepClick(index)} 
            style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
          >
            <i 
              className={`fas fa-${icons[index]} fa-2x mr-3`} 
              style={{ color: currentStep === index ? 'green' : 'black' }}
            ></i>
            <span className="h5 font-weight-bold mb-0">{step}</span>
          </button>
        </div>
      ))}
    </div>

    {/* Terms & Conditions */}
    <p className="text-muted font-size-sm mt-3 mb-0">
      By submitting, you agree to our 
      <a href="#" className="font-weight-medium">terms & conditions</a>
    </p>
  </div>
</div>



          </div>
        </div>
      </section>

      <Modal show={showBuyerDetailsModal} onHide={() => setShowBuyerDetailsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Your Details and Contacts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label className="form-label">Your Name</label>
              <input 
                type="text" 
                className="form-control" 
                placeholder="e.g. Jane Masinde"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <input 
                type="email" 
                className="form-control" 
                placeholder="e.g. jane@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Phone Number</label>
              <input 
                type="tel" 
                className="form-control" 
                placeholder="e.g. 0713876543"
                value={phone_no}
                onChange={(e) => setPhone_no(e.target.value)}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBuyerDetailsModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={makeOrder} disabled={isLoading}>
            {isLoading ? 'Processing...' : 'Place Order'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: ('YOUR_GOOGLE_MAPS_API_KEY')
})(Delivery);