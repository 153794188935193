import React from 'react'
import SideBarMenu from './SideBarMenu'

export default function FeaturesSideBar() {
  return (
    <div>

    <section class="bg-soft-primary-pattern-1 pt-6 pb-8" style={{marginTop:'80px'}}>
    <div class="container">
        <div class="row justify-content-center text-center" data-aos="fade-up">
        <div id="section-home" class="heading-block title-center nobottomborder page-section ">
        <h1>Need a Web Scheduler that's got your back?</h1>
        <h2 class="margin-one letter-spacing-2">Explore tunep's features and unleash your potential. Book more appointments, reach a wider audience and share what you do best</h2>
    </div>
    <div class="center bottommargin features-hero-buttons">
        <a class="btn btn-medium button btn-tt-lightbkgrd-small-inverted xs-margin-bottom-five xs-no-margin-right" href="https://www.timetap.com/businessWeb/web/signup/TEAMBUSM">Sign Up Free</a> <a class="btn btn-medium button btn-tt-yellowbkgrd xs-margin-bottom-five xs-no-margin-right" href="online-support-demo.html">Schedule a Demo</a>
    </div>
    <div class="clear"></div>
        </div>
    </div>
</section>

    <section class="py-6 mt-lg-n11">
    <div class="container">

    

    <div class="row pt-6 pt-lg-8">

    <SideBarMenu/>
 
    <div class="col-lg-9 order-1 order-lg-2 mb-5 mb-lg-0">

     
    <div class="clear"></div>
                      <div class="container features-descriptions padding-bottom-37">
                          <div class="row">
                              <div class="col-md-12 features-headings">
                                  <h3>Unlimited Appointments, Clients & Services</h3>
                                  <span>Don't let software hold you back when you have a busy month.</span>
                                  <a href="unlimited-appointments-clients.html" class="btn btn-tt-lightbkgrd-small-inverted">Learn more</a>
                              </div>
                              <div class="col-md-6 topmargin-sm text-center large-horizontal">
                                 
                             

                                  <img src="images/features/online-booking.jpg" alt="A scheduling system that offers unlimited appointments" />
                             
                                  </div>


                                  <div class="col-lg-5">
                                  <div class="media flex-column flex-sm-row mb-5" data-aos="fade-up" data-aos-delay="50">
                                      <div class="mb-3 mb-sm-0 mr-2 mr-sm-4 text-primary">
                                          <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M10.1528 5.55553C10.2037 5.65919 10.2373 5.77021 10.2524 5.88434L10.5308 10.0243L10.669 12.1051C10.6705 12.3191 10.704 12.5317 10.7687 12.736C10.9356 13.1326 11.3372 13.3846 11.7741 13.367L18.4313 12.9315C18.7196 12.9268 18.998 13.0346 19.2052 13.2313C19.3779 13.3952 19.4894 13.6096 19.5246 13.8402L19.5364 13.9802C19.2609 17.7949 16.4592 20.9767 12.6524 21.798C8.84555 22.6193 4.94186 20.8843 3.06071 17.5349C2.51839 16.5618 2.17965 15.4923 2.06438 14.389C2.01623 14.0624 1.99503 13.7325 2.00098 13.4025C1.99503 9.31273 4.90747 5.77696 8.98433 4.92457C9.47501 4.84816 9.95603 5.10792 10.1528 5.55553Z" fill="currentColor"/>
                                              <path opacity="0.4" d="M12.87 2.00082C17.4299 2.11683 21.2623 5.39579 22 9.81229L21.993 9.84488L21.9728 9.89227L21.9756 10.0224C21.9652 10.1947 21.8986 10.3605 21.784 10.4945C21.6645 10.634 21.5013 10.729 21.3216 10.7659L21.212 10.7809L13.5312 11.2786C13.2757 11.3038 13.0213 11.2214 12.8314 11.052C12.673 10.9107 12.5718 10.7201 12.5432 10.5147L12.0277 2.84506C12.0187 2.81913 12.0187 2.79102 12.0277 2.76508C12.0347 2.55367 12.1278 2.35384 12.2861 2.21023C12.4443 2.06662 12.6547 1.9912 12.87 2.00082Z" fill="currentColor"/>
                                          </svg>                    
                                      </div>
      
                                      <div class="media-body">
                                          <h6 class="font-weight-bold">Add online booking to your website</h6>
                                          <p class="text-muted font-size-sm mb-0">The online booking widget for your website makes it easy and convenient for your guests to schedule appointments.</p>    
                                      </div>
                                  </div>
                                  <div class="media flex-column flex-sm-row mb-5" data-aos="fade-up" data-aos-delay="100">
                                      <div class="mb-3 mb-sm-0 mr-2 mr-sm-4 text-primary">
                                          <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path opacity="0.4" d="M16.6756 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0711 3.92889 22 7.33333 22H16.6756C20.08 22 22 20.0711 22 16.6667V7.33333C22 3.92889 20.08 2 16.6756 2Z" fill="currentColor"/>
                                              <path d="M7.36872 9.36898C6.91539 9.36898 6.54205 9.74231 6.54205 10.2045V17.0756C6.54205 17.529 6.91539 17.9023 7.36872 17.9023C7.83094 17.9023 8.20428 17.529 8.20428 17.0756V10.2045C8.20428 9.74231 7.83094 9.36898 7.36872 9.36898Z" fill="currentColor"/>
                                              <path d="M12.0354 6.08898C11.582 6.08898 11.2087 6.46231 11.2087 6.92453V17.0756C11.2087 17.529 11.582 17.9023 12.0354 17.9023C12.4976 17.9023 12.8709 17.529 12.8709 17.0756V6.92453C12.8709 6.46231 12.4976 6.08898 12.0354 6.08898Z" fill="currentColor"/>
                                              <path d="M16.6399 12.9956C16.1777 12.9956 15.8044 13.369 15.8044 13.8312V17.0756C15.8044 17.529 16.1777 17.9023 16.631 17.9023C17.0932 17.9023 17.4666 17.529 17.4666 17.0756V13.8312C17.4666 13.369 17.0932 12.9956 16.6399 12.9956Z" fill="currentColor"/>
                                          </svg>                                                           
                                      </div>
      
                                      <div class="media-body">
                                          <h6 class="font-weight-bold">Social media widget</h6>
                                          <p class="text-muted font-size-sm mb-0">Attract new clients by adding an online booking widget on your social media pages. Clients are likely to take inspiration from your posts and book straightaway. </p>    
                                      </div>
                                  </div>
                                  <div class="media flex-column flex-sm-row mb-5" data-aos="fade-up" data-aos-delay="150">
                                      <div class="mb-3 mb-sm-0 mr-2 mr-sm-4 text-primary">
                                          <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path fill-rule="evenodd" clip-rule="evenodd" d="M3 16.87V9.257H21V16.931C21 20.07 19.0241 22 15.8628 22H8.12733C4.99561 22 3 20.03 3 16.87ZM7.95938 14.41C7.50494 14.431 7.12953 14.07 7.10977 13.611C7.10977 13.151 7.46542 12.771 7.91987 12.75C8.36443 12.75 8.72997 13.101 8.73985 13.55C8.7596 14.011 8.40395 14.391 7.95938 14.41ZM12.0198 14.41C11.5653 14.431 11.1899 14.07 11.1701 13.611C11.1701 13.151 11.5258 12.771 11.9802 12.75C12.4248 12.75 12.7903 13.101 12.8002 13.55C12.82 14.011 12.4643 14.391 12.0198 14.41ZM16.0505 18.09C15.596 18.08 15.2305 17.7 15.2305 17.24C15.2206 16.78 15.5862 16.401 16.0406 16.391H16.0505C16.5148 16.391 16.8902 16.771 16.8902 17.24C16.8902 17.71 16.5148 18.09 16.0505 18.09ZM11.1701 17.24C11.1899 17.7 11.5653 18.061 12.0198 18.04C12.4643 18.021 12.82 17.641 12.8002 17.181C12.7903 16.731 12.4248 16.38 11.9802 16.38C11.5258 16.401 11.1701 16.78 11.1701 17.24ZM7.09989 17.24C7.11965 17.7 7.49506 18.061 7.94951 18.04C8.39407 18.021 8.74973 17.641 8.72997 17.181C8.72009 16.731 8.35456 16.38 7.90999 16.38C7.45554 16.401 7.09989 16.78 7.09989 17.24ZM15.2404 13.601C15.2404 13.141 15.596 12.771 16.0505 12.761C16.4951 12.761 16.8507 13.12 16.8705 13.561C16.8804 14.021 16.5247 14.401 16.0801 14.41C15.6257 14.42 15.2503 14.07 15.2404 13.611V13.601Z" fill="currentColor"/>
                                              <path opacity="0.4" d="M3.00336 9.2569C3.0162 8.6699 3.0656 7.5049 3.15846 7.1299C3.63267 5.0209 5.24298 3.6809 7.54485 3.4899H16.4559C18.738 3.6909 20.3681 5.0399 20.8423 7.1299C20.9342 7.4949 20.9836 8.6689 20.9964 9.2569H3.00336Z" fill="currentColor"/>
                                              <path d="M8.30486 6.59C8.73955 6.59 9.06556 6.261 9.06556 5.82V2.771C9.06556 2.33 8.73955 2 8.30486 2C7.87017 2 7.54416 2.33 7.54416 2.771V5.82C7.54416 6.261 7.87017 6.59 8.30486 6.59Z" fill="currentColor"/>
                                              <path d="M15.6949 6.59C16.1197 6.59 16.4556 6.261 16.4556 5.82V2.771C16.4556 2.33 16.1197 2 15.6949 2C15.2603 2 14.9342 2.33 14.9342 2.771V5.82C14.9342 6.261 15.2603 6.59 15.6949 6.59Z" fill="currentColor"/>
                                          </svg>                   
                                      </div>
      
                                      <div class="media-body">
                                          <h6 class="font-weight-bold">Integrated online payments</h6>
                                          <p class="text-muted font-size-sm mb-0">An integrated online payment gateway for bookings made on the website or through social media pages</p>    
                                      </div>
                                  </div>
      
                                  <button class="btn btn-outline-primary waves-effect" data-aos="fade-up" data-aos-delay="200">Explore more Features</button>
                              </div>
                           

                              
                          </div>

                          
                          
    
    
                      </div>


                      


      
       


     

    </div>

    
</div>
</div>
</section>
    
    
    </div>
  )
}
