import React from 'react'

import { useEffect,useState,useContext} from 'react';

import Sidebar from '../Fsidebar';
import Topbar from '../Ftopbar';
import Footer from '../Ffooter';

import axios from 'axios';

import { Helmet } from "react-helmet";

import {toast,ToastContainer,Zoom,Bounce} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from '../../../helpers/AuthContext'
import LoadingSpinner from './LoadingSpinner';
// import API from '../../../../services';

import API from '../../../services';

function MyProducts()  {

  const {authState} = useContext(AuthContext);

    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [product_description, setProduct_description] = useState("");
    const [price, setPrice] = useState("");

    const [quantity, setQuantity] = useState("");

    const [geo_location, setGeo_location] = useState("");

    const [userId, setUserId] = useState("");

    const [retailerId, setRetailerId] = useState(1);



  
    const [productsList, setProductsList] = useState([]);

    const [errorMessage, setErrorMessage] = useState("");

    const [isDivLoading, setIsDivLoading] = useState(false);
  
  
  
    useEffect(()=>{
  
       //axios.get("https://ngeritbackend.herokuapp.com/product/getproducts").then((response) => {

        setIsDivLoading(true);



        API.get('/users/mybizz',{headers:{
          accessToken:localStorage.getItem("accessToken"),
        }}).then((response)=>{
      
      
      
             setUserId(response.data.id);

             setRetailerId(response.data.my_buss.id)
            
      
             console.log("THE BUSINESS ID IS "+response.data.my_buss.id)
      
           });

    
        // API.get("https://kilimomazaoapi-dmi-cyber.herokuapp.com/users/myproducts", {
         API.get("/users/myproducts", {
          headers: { accessToken: localStorage.getItem("accessToken")},
        })
        .then((response) => {
            setProductsList(response.data);

            console.log("THE SELLER DATA IS"+ response.data)

            setTimeout(() => {
              setIsDivLoading(false)   // Hide loading screen 
             // toast.info('Product saved successfully');
          }, 1000);

         
        }).catch(() => {
          setErrorMessage("Unable to fetch your products list");
          setIsDivLoading(false);
       });


        // axios.get('https://kilimomazaoapi-dmi-cyber.herokuapp.com/users/auth',{headers:{
         API.get('/users/auth',{headers:{
            accessToken:localStorage.getItem("accessToken"),
          }}).then((response)=>{
        
        
        
               setUserId(response.data.id);
              
        
               console.log("THIS IS THE USER DATA "+response.data)
        
             });
  
  },[]);
   
  
  
    const data={
      name:name,
      type:type,
      product_description:product_description,
      price: price,
      quantity:quantity,
      geo_location:geo_location,
      UserId:userId,
      businessId:retailerId,
        
    }
  
    const [isLoading,setLoading]=useState(false);
  
   
  
    const addDetails = ()  => {
        setLoading(true);
  
        // API.post('/product',data).then((response)=>{

        API.post('/product/save_item',data).then((response)=>{
        
      //axios.post("http://localhost:3001/product",data).then((response)=>{

      const newProduct = response.data;

      // Update the productsList state by adding the new product to the existing list
      setProductsList((prevProductsList) => [...prevProductsList, newProduct]);
         
  
        console.log("The response is"+response.data)
  
           
            setTimeout(() => {
                setLoading(false);
                toast.info('Product saved successfully');
            }, 3000);
         
           //  history("/dashboard");
          
           
        })
  
    }

    const renderUserProducts = (

      <div class="table-responsive text-nowrap">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Name</th>
       
          <th>Images</th>
          <th>Quantity</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
      {productsList.map((value, key) => {
        return (
        <tr>
          <td> <strong>{value.name}</strong></td>
        
          <td>
            <ul class="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
              <li data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" class="avatar avatar-xs pull-up" title="Lilian Fuller">
                <img src="/images/people/team/Melons.png" alt="Avatar" class="rounded-circle"/>
              </li>
              <li data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" class="avatar avatar-xs pull-up" title="Sophia Wilkerson">
                <img src="/images/people/team/Melons.png" alt="Avatar" class="rounded-circle"/>
              </li>
              <li data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" class="avatar avatar-xs pull-up" title="Christina Parker">
                <img src="/images/people/team/Melons.png" alt="Avatar" class="rounded-circle"/>
              </li>
            </ul>
          </td>
          <td><span class="badge bg-label-primary me-1">{value.quantity}</span></td>
          <td><span class="badge bg-label-primary me-1">{value.status}</span></td>
          <td>
            <div class="dropdown">
              <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown"><i class="bx bx-dots-vertical-rounded"></i></button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="javascript:void(0);"><i class="bx bx-edit-alt me-1"></i> Edit</a>
                <a class="dropdown-item" href="javascript:void(0);"><i class="bx bx-trash me-1"></i> Delete</a>
              </div>
            </div>
          </td>
        </tr>
        );
    })}
        
       
        
      </tbody>
    </table>
  </div>



    )

    return (
    <div>

    <Helmet>
          
    <link rel="preconnect" href="https://fonts.googleapis.com/" />
    <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&amp;display=swap" rel="stylesheet" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/boxicons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/fontawesome.css" />
    <link rel="stylesheet" href="assets_admin/vendor/fonts/flag-icons.css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/core.css" class="template-customizer-core-css" />
    <link rel="stylesheet" href="assets_admin/vendor/css/rtl/theme-default.css" class="template-customizer-theme-css" />
    <link rel="stylesheet" href="assets_admin/css/demo.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/apex-charts/apex-charts.css" />
  
  
  
  
  
    <link rel="stylesheet" href="assets_admin/vendor/libs/select2/select2.css" />
  
  
    <link rel="stylesheet" href="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/typeahead-js/typeahead.css" />
    <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-bs5/datatables.bootstrap5.css"/>
  <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css"/>
  <link rel="stylesheet" href="assets_admin/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.css"/>
  
  
  
    
  
    
    <script src="assets_admin/vendor/js/helpers.js"></script>
    <script src="assets_admin/vendor/js/template-customizer.js"></script>
    <script src="assets_admin/js/config.js"></script>
        </Helmet>
  
        
        
        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                {/* Menu */}


                
                
               
                <Sidebar />

               
  
  
                {/* / Menu */}
                {/* Layout container */}
                <div className="layout-page">
                    {/* Navbar */}
                    
                    <Topbar />
  
                    {/* / Navbar */}
                    {/* Content wrapper */}
                    
                    <div class="content-wrapper">
  
  
  
                    <div class="container-xxl flex-grow-1 container-p-y">
              
              
  
                    <h4 class="py-3 breadcrumb-wrapper mb-4">
                      <span class="text-muted fw-light">Customers /</span> scheduling
                    </h4>
                    
                    <div class="row">
                  
                      <div class="col-xl-3 col-lg-4 col-md-4 mb-lg-0 mb-4">
  
                      <div class="card h-100">
        <div class="card-header">
          <h3 class="card-title mb-2">#Products</h3>
  
  
          <div className="pt-2 pb-2 pl-3 pr-3 d-flex align-items-center o-hidden box-shadow-1 chat-topbar p-4" style={{backgroundColor:"aliceblue"}}><a className="link-icon d-md-none" data-sidebar-toggle="chat"><i className="icon-regular ml-0 mr-3 i-Left" /></a>
          <div class="mb-0">
              <button class="btn btn-primary" data-repeater-create data-bs-toggle="modal" data-bs-target="#modalCenter">
                <i class="bx bx-plus"></i>
                <span class="align-middle">Add</span>
              </button>
            </div>
      </div>
         
        </div>
        <div class="card-body">
        <div class="nav-align-left">
                       
        <ul class="nav nav-pills w-100 gap-1">
  
        {productsList.map((value, key) => {
          return (
            <li class="nav-item">
            <button class="nav-link" data-bs-target="javascript:void(0);">{value.name}</button>
          </li>
  
          );
      })}
         
          <li class="nav-item">
            <button class="nav-link active" data-bs-target="javascript:void(0);">Betty Kamau</button>
          </li>
         
         
        </ul>
      </div>
  
  
      <div class="modal fade" id="modalCenter" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalCenterTitle">Add Your Product</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col mb-3">
                <label for="nameWithTitle" class="form-label">Product Name</label>

                <input type="hidden" id="price" class="form-control" value={userId}

                onChange={(event) => {
                    setUserId(event.target.value);
                  }}
                />

                
                <input type="text" id="nameWithTitle" class="form-control" placeholder="Enter Name"
                
                onChange={(event) => {
                    setName(event.target.value);
                  }}
                   
                />
              </div>

              <div class="col mb-3">
              <label class="form-label" for="multicol-country">Type</label>
              <select id="multicol-country" class="form-control select2 form-select"   
              onChange={(event) => {
                setType(event.target.value);
              }}
              
              data-allow-clear="true">
                <option value="">Select Type</option>
                <option value="Agricultural">Agricultural</option>
                <option value="Livestock">Livestock</option>
                <option value="Aquatic">Aquatic</option>
               
                
              </select>
            </div>
            </div>
            <div class="row g-2">
              <div class="col mb-0">
                <label for="description" class="form-label">Description</label>
                

                   <textarea name="address" class="form-control"   onChange={(event) => {
                    setProduct_description(event.target.value);
                  }} id="address" rows="2" placeholder="Your Product desciption"></textarea>
              </div>
           
            </div>

            <div class="row g-2">
            <div class="col mb-0">
              <label for="description" class="form-label">Quantity</label>

              <input type="number" id="price" class="form-control"

              onChange={(event) => {
                  setQuantity(event.target.value);
                }}
              />
                 
            </div>
            <div class="col mb-0">
              <label for="dobWithTitle" class="form-label">Price</label>
              <input type="number" id="price" class="form-control"

              onChange={(event) => {
                  setPrice(event.target.value);
                }}
                 
              
              />
            </div>
          </div>

          <div class="row g-2">
          <div class="col mb-0">
            <label for="description" class="form-label">Location</label>


            <select id="multicol-country" class="select2 form-select"   
            onChange={(event) => {
              setGeo_location(event.target.value);
            }}
            
            data-allow-clear="true">
            <option selected>Select Produce Location</option>
            <option value="Kangemi-Market">Kangemi-Market</option>
            <option value="Home delivery">Mowlem-Dandora</option>
            <option value="Saika">Saika</option>
            <option value="Utawala-Benedicta">Utawala-Benedicta</option>
             
              
            </select>
            

             
          </div>
       
        </div>

          <div class="row g-2">
          <div class="col mb-0">
          <label class="form-label" for="basic-default-upload-file">Product Pic</label>
          <input type="file" class="form-control" id="basic-default-upload-file" required />
          </div>
       
        </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-label-secondary" data-bs-dismiss="modal">Close</button>
  
  
            
            {!isLoading && <button type="submit" onClick={addDetails} class="btn btn-primary"  style={{backgroundColor:"#085781"}}>Save changes</button>
  
        } 
        {isLoading &&
            <button type="submit" class="btn btn-primary btn-md btn-block mt-3 waves-effect" title="Save" disabled> <i class="fas fa-sync fa-spin"></i>Saving Infor</button>
        }
  
        <ToastContainer />
            
          </div>
          
        </div>
      </div>
    </div>
  
  
  
        </div>
      </div>
                      
                      
                      </div>
                     
  
                      <div class="col-xl-9 col-lg-8 col-md-8">
                        <div class="card overflow-hidden">
                          <div class="card-body">
  
  
  
                          <div class="card shadow-none text-center border mb-3">
                          <div class="card-header border-bottom">
                            <ul class="nav nav-tabs card-header-tabs" role="tablist">
                              <li class="nav-item">
                                <button type="button" class="nav-link active" role="tab" data-bs-toggle="tab" data-bs-target="#navs-within-card-active" aria-controls="navs-within-card-active" aria-selected="true">Active</button>
                              </li>
                              <li class="nav-item"><button type="button" class="nav-link" role="tab" data-bs-toggle="tab" data-bs-target="#navs-within-card-link" aria-controls="navs-within-card-link" aria-selected="false">Link</button>
                              </li>
                              <li class="nav-item">
                                <button type="button" class="nav-link disabled" data-bs-toggle="tab" role="tab" aria-selected="false">Disabled</button>
                              </li>
                            </ul>
                          </div>
                          <div class="tab-content">
                            <div class="tab-pane fade show active" id="navs-within-card-active" role="tabpanel">
                            <div class="card">
  <h5 class="card-header">Your Current Products</h5>


  {isDivLoading ? <LoadingSpinner/>: renderUserProducts}

  {errorMessage && <div className="error">{errorMessage}</div>}
  




</div>
                            </div>
                            <div class="tab-pane fade" id="navs-within-card-link" role="tabpanel">
                              <h4 class="card-title">Special link title</h4>
                              <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                              <a href="javascript:void(0)" class="btn btn-secondary">Go somewhere</a>
                            </div>
                          </div>
                        </div>
                          
                          
                    
                            <a class="btn btn-label-primary" href="pages-help-center-landing.html">
                              <i class="bx bx-chevron-left bx-sm scaleX-n1-rtl"></i>
                              <span>Back to help center</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                    
                                
                              </div>
  
    
  
    
                    
                    
                <footer class="content-footer footer bg-footer-theme">
                <div class="container-fluid d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                {/* <div class="mb-2 mb-md-0">
                  © <script>
                  document.write(new Date().getFullYear())
                  </script>
                  , made with ❤️ by <a href="https://pixinvent.com/" target="_blank" class="footer-link fw-semibold">PIXINVENT</a>
                </div> */}
                <div>
                  
                  <a href="https://themeforest.net/licenses/standard" class="footer-link me-4" target="_blank">License</a>
                  <a href="https://1.envato.market/pixinvent_portfolio" target="_blank" class="footer-link me-4">More Themes</a>
                  
                  <a href="https://pixinvent.com/demo/frest-clean-bootstrap-admin-dashboard-template/documentation-bs5/" target="_blank" class="footer-link me-4">Documentation</a>
                  
                  <a href="https://pixinvent.ticksy.com/" target="_blank" class="footer-link d-none d-sm-inline-block">Support</a>
                  
                </div>
                </div>
                </footer>
                
                
                      
                      <div class="content-backdrop fade"></div>
                    </div>
  
                    
                    {/* Content wrapper */}
                </div>
                {/* / Layout page */}
            </div>
            {/* Overlay */}
            <div className="layout-overlay layout-menu-toggle" />
            {/* Drag Target Area To SlideIn Menu On Small Screens */}
            <div className="drag-target" />
        </div>
        <Helmet>
        <script src="assets_admin/vendor/libs/jquery/jquery.js"></script>
        <script src="assets_admin/vendor/libs/popper/popper.js"></script>
        <script src="assets_admin/vendor/js/bootstrap.js"></script>
        <script src="assets_admin/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"></script>
        <script src="assets_admin/vendor/libs/hammer/hammer.js"></script>
        <script src="assets_admin/vendor/libs/i18n/i18n.js"></script>
        <script src="assets_admin/vendor/libs/typeahead-js/typeahead.js"></script>
        <script src="assets_admin/vendor/js/menu.js"></script>
  
  
      
  
  
  
        <script src="assets_admin/vendor/libs/select2/select2.js"></script>
  <script src="assets_admin/assets/vendor/libs/flatpickr/flatpickr.js"></script>
  <script src="assets_admin/assets/vendor/libs/moment/moment.js"></script>
  
  
  
  <script src="assets_admin/vendor/libs/datatables/jquery.dataTables.js"></script>
  <script src="assets_admin/vendor/libs/datatables-bs5/datatables-bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-responsive/datatables.responsive.js"></script>
  <script src="assets_admin/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/datatables-buttons.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.js"></script>
  <script src="assets_admin/vendor/libs/jszip/jszip.js"></script>
  <script src="assets_admin/vendor/libs/pdfmake/pdfmake.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/buttons.html5.js"></script>
  <script src="assets_admin/vendor/libs/datatables-buttons/buttons.print.js"></script>
  
  
    
        <script src="assets_admin/js/main.js"></script>
     
  
  
        <script src="assets_admin/js/app-calendar-events.js"></script>
        <script src="assets_admin/js/app-calendar.js"></script>
        </Helmet>
    </div>
    )
  }
  
  export default MyProducts