import React from 'react';
import PricingSection1 from './PricingSection1';

import SignUp from './SignUp';

export default function MainSectionPricing() {
  return(
   <div>

     <main>
          
            <PricingSection1/>


         


 
        </main>
   
   
   </div>

  )
}
