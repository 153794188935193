import React,{useEffect}from 'react';
import HeaderNew from '../../Header/HeaderNew'
import MainContent from './MainContent'
import MoreFeatures from './MoreFeatures'

import { Widget,addResponseMessage  } from 'react-chat-widget';

import 'react-chat-widget/lib/styles.css';

import logo from '../../../../src/assets/images/tunepp.jpg';
import GreatFeatures from './GreatFeatures';
import FeaturesSideBar from './FeaturesSideBar';
import HeaderFloating from '../../Header/HeaderFloating';


function Features() {

  useEffect(() => {
    addResponseMessage('Hi! Welcome to tunep. Kindly conduct our customer care on 0713292421');
  }, []);

  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    // // Now send the message throught the backend API
    // addResponseMessage(newMessage);
  };
  return (
    <div>

    <HeaderFloating/>

    <FeaturesSideBar/>

    <GreatFeatures/>

  

   



    <Widget 

    handleNewUserMessage={handleNewUserMessage}
    profileAvatar={logo}
    title="Welcome to Tunep"
    subtitle="24/7 customer support"
    
  
    />
    
    
    </div>
  )
}

export default Features