import React from 'react'
import HeaderFloating from '../../../Header/HeaderFloating'
import HeaderNew from '../../../Header/HeaderNew'
import MainFeatures from './MainFeatures'

function Integrations() {
  return (
    <div>
  <HeaderFloating/>
    <MainFeatures/>
    
    </div>
  )
}

export default Integrations