import React from 'react'
import Footbody from './Footbody'
import Footbottom from './Footbottom'
import Foothead from './Foothead'

export default function Footer() {
    return (
        <div>
        
            <Footbottom />

        </div>
    )
}
