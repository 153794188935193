import React,{useContext} from 'react'
import { AuthContext } from '../../helpers/AuthContext';
import { useNavigate } from "react-router-dom";
import pimage from '../../assets/dist-assets/images/faces/3.jpg';
// import Logo from '../../assets/dist-assets/images/logo.png';
// import Logo from '../images/pamojor-logo.png';
// import Logo_icon from '../../assets/dist-assets/images/faces/1.jpg';
// import {Link} from 'react-router-dom';
// import {Language, NotificationsNone, Settings} from "@material-ui/icons";


export default function Topbar() {
  const {authState} =useContext(AuthContext);
  const {setAuthState} = useContext(AuthContext);
  const navigate=useNavigate();
  // alert(authState.status);
  const logout = ()=>{
    localStorage.removeItem("accessToken");
    navigate("/")
    setAuthState({username:"", id:0, status:false});
  }
  // alert(authState.id);
    return (
        <div>
             <div className="main-header">
        <div className="logo">
          <h4 className="ml-2">TUNEPP</h4>
          {/* <img src="<?php echo base_url(); ?>assets/dist-assets/images/logo.png" alt=""> */}
        </div>
        <div className="menu-toggle">
          <div />
          <div />
          <div />
        </div>
        <div className="d-flex align-items-center">
        </div>
        <div style={{margin: 'auto'}} />
        <div className="header-part-right">
          {/* Full screen toggle */}
          <i className="i-Full-Screen header-icon d-none d-sm-inline-block" data-fullscreen />
          {/* Grid menu Dropdown */}
          <div className="dropdown">
            <i className="i-Safe-Box text-muted header-icon" role="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <div className="menu-icon-grid">
                <a href="#"><i className="i-Shop-4" /> Home</a>
                <a href="#"><i className="i-Library" /> UI Kits</a>
                <a href="#"><i className="i-Drop" /> Apps</a>
                <a href="#"><i className="i-File-Clipboard-File--Text" /> Forms</a>
                <a href="#"><i className="i-Checked-User" /> Sessions</a>
                <a href="#"><i className="i-Ambulance" /> Support</a>
              </div>
            </div>
          </div>
          {/* Notificaiton */}
          <div className="dropdown">
            <div className="badge-top-container" role="button" id="dropdownNotification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="badge badge-primary">3</span>
              <i className="i-Bell text-muted header-icon" />
            </div>
            {/* Notification dropdown */}
            <div className="dropdown-menu dropdown-menu-right notification-dropdown rtl-ps-none" aria-labelledby="dropdownNotification" data-perfect-scrollbar data-suppress-scroll-x="true">
              <div className="dropdown-item d-flex">
                <div className="notification-icon">
                  <i className="i-Speach-Bubble-6 text-primary mr-1" />
                </div>
                <div className="notification-details flex-grow-1">
                  <p className="m-0 d-flex align-items-center">
                    <span>New message</span>
                    <span className="badge badge-pill badge-primary ml-1 mr-1">new</span>
                    <span className="flex-grow-1" />
                    <span className="text-small text-muted ml-auto">10 sec ago</span>
                  </p>
                  <p className="text-small text-muted m-0">James: Hey! are you busy?</p>
                </div>
              </div>
              <div className="dropdown-item d-flex">
                <div className="notification-icon">
                  <i className="i-Receipt-3 text-success mr-1" />
                </div>
                <div className="notification-details flex-grow-1">
                  <p className="m-0 d-flex align-items-center">
                    <span>New order received</span>
                    <span className="badge badge-pill badge-success ml-1 mr-1">new</span>
                    <span className="flex-grow-1" />
                    <span className="text-small text-muted ml-auto">2 hours ago</span>
                  </p>
                  <p className="text-small text-muted m-0">1 Headphone, 3 iPhone x</p>
                </div>
              </div>
              <div className="dropdown-item d-flex">
                <div className="notification-icon">
                  <i className="i-Empty-Box text-danger mr-1" />
                </div>
                <div className="notification-details flex-grow-1">
                  <p className="m-0 d-flex align-items-center">
                    <span>Product out of stock</span>
                    <span className="badge badge-pill badge-danger ml-1 mr-1">3</span>
                    <span className="flex-grow-1" />
                    <span className="text-small text-muted ml-auto">10 hours ago</span>
                  </p>
                  <p className="text-small text-muted m-0">Headphone E67, R98, XL90, Q77</p>
                </div>
              </div>
              <div className="dropdown-item d-flex">
                <div className="notification-icon">
                  <i className="i-Data-Power text-success mr-1" />
                </div>
                <div className="notification-details flex-grow-1">
                  <p className="m-0 d-flex align-items-center">
                    <span>Server Up!</span>
                    <span className="badge badge-pill badge-success ml-1 mr-1">3</span>
                    <span className="flex-grow-1" />
                    <span className="text-small text-muted ml-auto">14 hours ago</span>
                  </p>
                  <p className="text-small text-muted m-0">Server rebooted successfully</p>
                </div>
              </div>
            </div>
          </div>
          {/* Notificaiton End */}
          {/* User avatar dropdown */}
          <div className="dropdown" >
            <div className="user col align-self-end">
              <img src={pimage} id="userDropdown" alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown" style={{backgroundColor:"aliceblue"}}>
                <div className="dropdown-item">
                  <i className="i-Lock-User mr-1" /> {authState.username}
                </div>
                <div>
                  <a className="dropdown-item"><i className="i-Lock-User mr-1" />Account settings</a>
                </div>
                <div>
                  <a className="dropdown-item"><i className="i-Refresh mr-1" />Refresh</a>
                </div>
                <div> {authState.status && 
                  <a className="dropdown-item" onClick={logout}><i className="i-Key-Lock mr-1" />Sign Out</a>}
                </div>

                <div> 
                    <a className="dropdown-item" onClick={logout}><i className="i-Key-Lock mr-1" />Sign Out</a>
                </div>
              </div>
            </div>
          </div>
        </div>
             </div>
        </div>
    )
}
