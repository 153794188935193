import React from 'react'

import '../MyStyles.css'

export default function MainContent() {
  return (
    <div>



    <section class="cover-background" style={{backgroundImage:"url(images/industries/beauty/salon-scheduling-online.png)"}}>
    <div class="opacity-medium bg-dark-gray"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-sm-8 text-left position-relative industries-subheading">
                <h1 class="title-med text-uppercase letter-spacing-1 white-text font-weight-600 margin-bottom-25">Focus on helping your clients look their best.<br/><br />We’ll take care of the scheduling.</h1>
                <a class="btn button btn-tt-lightbkgrd-med-inverted xs-margin-bottom-five xs-no-margin-right" href="https://www.timetap.com/businessWeb/web/signup/TEAMBUSM">Sign Up Free</a>
            </div>
        </div>
    </div>
</section>


<div class="container benefits-demo-section">
    
      <div class="row">
		<div class="col-md-4 col-sm-4">    
         <div class="guide-box sm-display-none">
          <h3>Schedule Spa & Beauty Appointments online</h3>          
          <hr></hr>
          <p>TimeTap’s web scheduler takes the stress off the front desks for salons & spas</p>
        </div>
        <div class="clearfix"></div>
        <div class="benefits-box">
              <h3>Perfect for:</h3>
              <ul class="benefits-list">
                <li>Seeing who’s available & who’s booked</li>
                <li>Blocking off enough time to recuperate between clients</li>
                <li>Keeping you from feeling short-staffed on busy days</li>
                <li>Providing privacy between staff to keep from client poaching</li>
                <li>Looking up client’s past appointments & preferences</li>
                <li>Allowing clients to see availability 24/7</li>                
              </ul>
          </div>
        </div>

        <div class="col-md-8 col-sm-8">
        	<div class="demo-box">
        	<h3>Try it Out: <small>Use the embedded appointment scheduler below to try out scheduling an appointment as if you were a client. You can also check out <a href="https://lightheart.timetap.com/" target="_blank">booking an appointment online</a> on the TimeTap mini website that comes with each account:</small></h3>
             <div class="intrinsic-container intrinsic-container-16x9" style={{textAlign:"center"}}>
               <iframe src='https://www.timetap.com/emb/15324' class="tab-resize"></iframe>
            </div>
        </div>
		</div>
      </div>
</div>
    
    
    
    </div>
  )
}
