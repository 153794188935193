import React,{useEffect, useState, useContext} from 'react';
import FeaturedInfo from './FeaturedInfo';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, Link  } from "react-router-dom";
import pimage from '../../assets/dist-assets/images/faces/3.jpg';
import { Helmet } from "react-helmet";
import { AuthContext } from '../../helpers/AuthContext';
import Cfooter from './Cfooter';
// import { BusinessContext } from '../../helpers/BusinessContext';



export default function Account() {
    const navigate=useNavigate();
    const {authState} =useContext(AuthContext);
    const [bussData, setBussData]=useState([]);
    const {setAuthState}=useContext(AuthContext);
    const [userId, setUserId]=useState(0);
    var uid=0;

     
    useEffect( ()=> {
        
    //     console.log("USERNAME IS: "+username)
    //     try{ const my_token=localStorage.getItem("accessToken")
    
    //        console.log("THIS MY TOKEN"+JSON.parse(my_token))
    
    // }

    //     catch(err){
    //         console.log("YOU HAVE AN ERROR"+err)
    //     }
        // JSON.parse(serializedState);
        axios.get('http://localhost:3001/users/auth',{headers:{ accessToken:localStorage.getItem("accessToken"), }}).then((resp)=>{
            uid=resp.data.id;
            if(!localStorage.getItem("accessToken")){
                console.log('NOT LOADED');
            navigate("/login");  
            }else{
                const id=authState.id;
                    axios.get(`http://localhost:3001/business/byId/${uid}`,{headers:{accessToken:localStorage.getItem("accessToken")}}).then((response)=>{
                    console.log(response.data);
                    setBussData(response.data);
                    });
            }
        });

    },[]);
    
    const getRedirect=(url)=>{
        navigate(url);
    }
    return (
        <div>
            <Helmet>
                <link rel="stylesheet" href="dist-assets/css/themes/lite-purple.min.css" />
                <link rel="stylesheet" href="dist-assets/css/plugins/perfect-scrollbar.min.css" />
            </Helmet>

            <div className="text-left">
                <div className="app-admin-wrap layout-sidebar-large">
                    <Topbar />
                    <Sidebar />
                    <div className="main-content-wrap sidenav-open d-flex flex-column" style={{marginTop:"40px"}}>
                        {/* ============ Body content start ============= */}
                        <div className="main-content">
                            {/* MAIN SIDEBAR CONTAINER*/}
                            <div className="inbox-main-sidebar-container" data-sidebar-container="main">
                                <div className="inbox-main-content" data-sidebar-content="main">
                                    {/* SECONDARY SIDEBAR CONTAINER*/}
                                    <div className="inbox-secondary-sidebar-container box-shadow-1" data-sidebar-container="secondary">
                                        <div data-sidebar-content>
                                            <div className="inbox-secondary-sidebar-content position-relative" style={{minHeight: '500px'}}>
                                                <div className="inbox-topbar box-shadow-1 perfect-scrollbar rtl-ps-none pl-3" data-suppress-scroll-y="true">
                                                    {/* <span class="d-sm-none">Test</span>*/}<a className="link-icon d-md-none" data-sidebar-toggle="main"><i className="icon-regular i-Arrow-Turn-Left" /></a><a className="link-icon mr-3 d-md-none" data-sidebar-toggle="secondary"><i className="icon-regular mr-1 i-Left-3" /> Inbox</a>
                                                    <div className="d-flex"><h3 style={{marginTop:10}}>Let's get you more bookings</h3></div>
                                                </div>
                                                {/* EMAIL DETAILS*/}
                                                <div className="inbox-details perfect-scrollbar rtl-ps-none" data-suppress-scroll-x="true">

                                                    
                                                <div>
                                                    <h4>Business Information</h4>
                                                    <p>
                                                        {bussData.details}
                                                    </p>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-md-4 col-6">
                                                            <div className="mb-4">
                                                                <p className="text-primary mb-1"><i className="i-Building text-16 mr-1" /> Business Name</p><span>{bussData.business_name}</span>
                                                            </div>
                                                            <div className="mb-4">
                                                                <p className="text-primary mb-1"><i className="i-Edit-Map text-16 mr-1" /> Business Type</p><span>{bussData.business_type}</span>
                                                            </div>
                                                            <div className="mb-4">
                                                                <p className="text-primary mb-1"><i className="i-Factory1 text-16 mr-1" /> industry</p><span>{bussData.industry}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-6">
                                                            <div className="mb-4">
                                                                <p className="text-primary mb-1"><i className="i-Home1 text-16 mr-1" /> Location</p><span>{bussData.location}</span>
                                                            </div>
                                                            <div className="mb-4">
                                                                <p className="text-primary mb-1"><i className="i-Address-Book-2 text-16 mr-1" /> Address</p><span>{bussData.address_line1}</span>
                                                            </div>
                                                            <div className="mb-4">
                                                                <p className="text-primary mb-1"><i className="i-Globe text-16 mr-1" /> Website</p><span>{bussData.website}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-6">
                                                            <div className="mb-4">
                                                                <p className="text-primary mb-1"><i className="i-Face-Style-4 text-16 mr-1" /> Country</p><span>{bussData.country}</span>
                                                            </div>
                                                            <div className="mb-4">
                                                                <p className="text-primary mb-1"><i className="i-Professor text-16 mr-1" /> State</p><span>{bussData.state}</span>
                                                            </div>
                                                            <div className="mb-4">
                                                                <p className="text-primary mb-1"><i className="i-Building text-16 mr-1" /> City</p><span>{bussData.city}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                                <hr/>



                                                    <h4 className="mb-3">Here's your Booking Page URL</h4>
                                                    <p>nnnnnnnn</p>
                                                    <h4 style={{textAlign:"center",marginBotton:40 }}>Benefits of your Booking Page</h4>
                                                    <div className='row'>
                                                        <div className='col-sm-4'><h1><i className="i-Bell" style={{textAlign: "center", color:"blue"}}/></h1><h5>Notifications</h5><p>Get instant booking updates and reduce no-shows by automating reminders.</p></div>
                                                        <div className='col-sm-4'><h1><i className="i-Credit-Card" style={{textAlign: "center", color:"blue"}}/></h1><h5>Payments</h5><p>Forget about chasing invoices and accept payments easily and securely online.</p></div>
                                                        <div className='col-sm-4'><h1><i className="i-Like" style={{textAlign: "center", color:"blue"}}/></h1><h5>Reviews</h5><p>Get instant booking updates and reduce no-shows by automating reminders.</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* MAIN INBOX SIDEBAR*/}
                                <div className="inbox-main-sidebar" data-sidebar="main" data-sidebar-position="left">
                                    <div className="pt-3 pr-3 pb-3"><i className="sidebar-close i-Close" data-sidebar-toggle="main" />
                                        <div className="pl-3">
                                            <ul className="inbox-main-nav">
                                                <li><a className="active" onClick={()=>{ getRedirect ('/account')}}><i className="icon-regular i-Home1" /> Overview</a></li>
                                                <li><a href="/company_details"><i className="icon-regular i-Suitcase" /> Company Details</a></li>
                                                <li><a href="/business_hours"><i className="icon-regular i-Clock" /> Business Hours</a></li>
                                                {/* <li><a href="#"><i className="icon-regular i-Folder-Trash" /> Booking Policies</a></li>
                                                <li><a href="#"><i className="icon-regular i-Spam-Mail" /> Customizations</a></li>
                                                <li><a href="#"><i className="icon-regular i-David-Star" /> Reviews</a></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Cfooter/>
                        </div>{/* Footer Start */}
                    </div>
                </div>
            </div>


            <Helmet>
                <script src="dist-assets/js/plugins/jquery-3.3.1.min.js"></script>
                <script src="dist-assets/js/plugins/bootstrap.bundle.min.js"></script>
                <script src="dist-assets/js/plugins/perfect-scrollbar.min.js"></script>
                <script src="dist-assets/js/scripts/script.min.js"></script>
                <script src="dist-assets/js/scripts/sidebar.large.script.min.js"></script>
                <script src="dist-assets/js/scripts/sidebar.script.min.js"></script>
                <script src="dist-assets/js/plugins/echarts.min.js"></script>
                <script src="dist-assets/js/scripts/echart.options.min.js"></script>
                <script src="dist-assets/js/scripts/dashboard.v1.script.min.js"></script>
            </Helmet>
        </div>
    )
}
